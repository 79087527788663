import { progress } from '@approvalmax/ui';
import { api } from 'modules/api';
import { createRoot } from 'react-dom/client';

import Application from './Application';

export default async function startApplication() {
    progress.reset();

    try {
        await api.auth.getIsSignedIn();
    } catch {
        // Infinite loading if there was an error other than 401
        return;
    }

    const container = document.getElementById('app-root');
    const root = createRoot(container!);

    root.render(<Application />);
}
