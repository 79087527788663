import { mods } from '@approvalmax/theme';
import styled, { createGlobalStyle, css } from 'styled-components';

const GLOBAL_MIN_HEIGHT = 600;
const GLOBAL_MIN_WIDTH = 600;

export const GlobalStyle = createGlobalStyle<{ isResizing: boolean }>`
  body {
      ${(props) =>
          props.isResizing &&
          css`
              cursor: col-resize;
              user-select: none;
          `}
  }
`;

export const Root = styled.div<{
    isUnpinning: boolean;
    drawerWidth: number;
}>`
    display: flex;

    ${({ drawerWidth }) => mods('isUnpinning', true)`
        margin-left: -${drawerWidth}px;
        width: calc(100% + ${drawerWidth}px);
        transition: all 300ms ease-in-out;
    `};
`;

export const PinnedDrawerContainer = styled.div<{
    drawerWidth: number;
}>`
    position: relative;
    user-select: none;
    width: ${({ drawerWidth }) => drawerWidth}px;
`;

export const Handle = styled.div<{
    isResizing: boolean;
}>`
    position: absolute;
    top: 0;
    left: calc(100% - 10px);
    height: 100%;
    width: 15px;
    background-color: transparent;
    user-select: none;
    cursor: col-resize;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 8px;
        width: 2px;
        height: 100%;
        background-color: #4c8b5d;
        opacity: 0;
        transition: 0.3s;

        ${mods('isResizing', true)`
          opacity: 1;
        `};
    }

    &:hover:after {
        opacity: 1;
    }
`;

export const ScrollContainer = styled.div`
    overflow-y: auto;
    position: relative;
    z-index: 0;
    height: 100%;
    flex: 1;
`;

export const Content = styled.div`
    position: relative;
    height: 100%;
    min-height: ${GLOBAL_MIN_HEIGHT}px;
    min-width: ${GLOBAL_MIN_WIDTH}px;

    & > * {
        height: 100%;
    }
`;
