import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.StorageQueueDetailsPage.MessageDetailsPopup', {
    popupTitle: 'Message {id}',
    doneButton: 'Done',
    generalHeader: 'General information',
    idField: 'Id',
    insertionTimeField: 'Insertion time',
    expirationTimeField: 'Expiration time',
    rawDataHeader: 'Details',
});
