import { DataTable, LoadingBar, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo } from 'react';

import { useStorageQueues } from './hooks/useStorageQueues';
import { dataTableColumns } from './StorageQueuesPage.config';
import { messages } from './StorageQueuesPage.messages';

const StorageQueuesPage: FC = () => {
    const { queues } = useStorageQueues();

    usePermissionsRedirect((permissionFlags) => permissionFlags.storageQueues.any);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar title={messages.pageTitle} />

                    {!queues ? (
                        <div>
                            <LoadingBar />
                        </div>
                    ) : (
                        <DataTable css='flex: 1; overflow: hidden;' data={queues} columns={dataTableColumns} />
                    )}
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
};

export default memo(StorageQueuesPage);
