import styled from 'styled-components';

export const Content = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const InfoPanel = styled.div`
    width: 350px;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
`;

export const DetailsButtonContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
`;
