import { mods } from '@approvalmax/theme';
import styled from 'styled-components';

import { NativeDataTableProps, NativeDataTableTheme } from './NativeDataTable.types';

export const Root = styled.div`
    overflow-x: auto;
`;

export const Table = styled.table<Pick<NativeDataTableProps, 'theme'>>`
    width: 100%;

    thead {
        font-size: 13px;
        background: #f9f9f9;
        height: 50px;
        position: sticky;
        top: 0;
        left: 0;

        th {
            padding: 0 4px 0 16px;
            text-align: left;
            color: #565656;
            min-width: 150px;
            text-transform: uppercase;
            font-weight: 600;

            ${mods('theme', NativeDataTableTheme.system)`
                text-transform: none;
            `};
        }

        th:first-child {
            padding-left: 60px;
        }

        th:last-child {
            padding-right: 30px;
        }
    }

    tbody {
        font-size: 13px;
        vertical-align: top;

        tr {
            td {
                padding: 12px 4px 12px 16px;
                transition: 0.2s;
            }

            td:first-child {
                padding-left: 60px;
            }

            td:last-child {
                padding-right: 30px;
            }

            &:hover td {
                background-color: #f5f5f5;
            }
        }
    }

    ${mods('theme', NativeDataTableTheme.system)`
        thead {
            height: 40px;
            font-size: 11px;

            th:first-child {
                padding-left: 30px;
            }
        }

        tbody {
            font-size: 11px;

            tr {
                td:first-child {
                    padding-left: 30px;
                }
            }
        }
    `};
`;
