import { useEffect, useRef } from 'react';

/**
 * Usage note: when you are inside a useEffect, it is easier to just use a local variable:
 * ```
 * useEffect(() => {
 *   let isCancelled = false;
 *   await myRequest();
 *   if (!isCancelled) {
 *       finaliseLoading();
 *   }
 *   return () => {
 *     isCancelled = true;
 *   };
 * }, []);
 * ```
 * However, if you are inside a callback (on mouse click for example), the unmounted ref is useful.
 */
export const useUnmountedRef = () => {
    const unmountedRef = useRef<boolean>(false);

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    return unmountedRef;
};
