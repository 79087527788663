import { mixins, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { SwitchProps } from './Switch.types';

export const Input = styled.input<Pick<SwitchProps, 'disabled'>>`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 100px;
    border: 1px solid ${theme.color.midnight70};
    display: block;
    transition: ${theme.duration.medium}ms ease-in-out;
    ${mixins.position.absoluteCenter};

    &:focus {
        ${mods.disabled.false`
            border-color: ${theme.color.blue100};
        `};
    }
`;

export const Control = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 100px;
    transition: ${theme.duration.medium}ms ease-in-out;
`;

export const Icon = styled.div`
    pointer-events: none;
    position: relative;
    margin-left: 2px;
    margin-right: 2px;
    transition: ${theme.duration.medium}ms ease-in-out;
`;

export const Label = styled.div``;

const rootDisabledMixin = css`
    ${mods.disabled.true`
        ${Icon} {
            background-color: ${theme.color.midnight60};
        }

        ${Input} {
            border-color: ${theme.color.midnight60};
            background-color: ${theme.color.white100};
        }

        ${Label} {
            color: ${theme.color.midnight60};
        }

        ${mods.checked.true`
            ${Icon} {
                background-color: ${theme.color.white100};
            }

            ${Input} {
                background-color: ${theme.color.midnight60};
            }
        `};
    `};

    ${mods.disabled.false`
        &,
        ${Input} {
            cursor: pointer;
        }
    `};
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    boxWidth: number,
    boxHeight: RequiredMods['spacing'],
    spacingTop: RequiredMods['spacing'],
    spacingLeft: RequiredMods['spacing']
) => css`
    ${Control} {
        width: ${boxWidth}px;
        height: ${boxHeight}px;
    }

    ${Icon} {
        width: ${boxHeight - 4}px;
        height: ${boxHeight - 4}px;
        border-radius: ${boxHeight}px;
    }

    ${Label} {
        ${mixins.font('label', fontSize)};
        margin-top: ${spacingTop}px;
        margin-left: ${spacingLeft}px;
    }

    ${mods.checked.true`
        ${Icon} {
            transform: translateX(${boxWidth - boxHeight}px);
        }
    `};

    &:active {
        ${mods.disabled.false`
            ${Icon} {
                width: ${boxHeight + 8}px;
            }

            ${mods.checked.true`
                ${Icon} {
                    transform: translateX(${boxWidth - boxHeight - 12}px);
                }
            `};
        `};
    }
`;

const rootColorMixin = (
    primary: RequiredMods['color'],
    secondary: RequiredMods['color'],
    hover: RequiredMods['color'],
    inactiveSecondary: RequiredMods['color']
) => css`
    ${Input} {
        border-color: ${theme.color[primary]};
    }

    ${Icon} {
        background-color: ${theme.color[primary]};
    }

    ${Control} {
        background-color: ${theme.color[secondary]};
    }

    &:hover,
    &:focus,
    &.focused {
        ${mods.disabled.false`
            ${Input} {
                border-color: ${theme.color[hover]};

                 ${mods.invalid.true`
                    border-color: ${theme.color.red70};
                `}

                ${mods.checked.true`
                    background-color: ${theme.color[hover]};

                    ${mods.invalid.true`
                        background-color: ${theme.color.red70};
                    `}
                `}

                ${mods.checked.false`
                    background-color: ${theme.color[inactiveSecondary]};

                     ${mods.invalid.true`
                        background-color: ${theme.color.red20};
                     `}
                `}
            }

            ${Icon} {
                ${mods.checked.false`
                    background-color: ${theme.color[hover]};

                    ${mods.invalid.true`
                        background-color: ${theme.color.red70};
                     `}
                `}
            }

            ${Label} {
                color: ${theme.color[hover]};

                ${mods.invalid.true`
                    color: ${theme.color.red100};
                `}
            }
        `};
    }

    ${mods.checked.true`
        ${mods.disabled.false`
            ${Input} {
                background-color: ${theme.color[primary]};
            }

            ${Icon} {
                background-color: ${theme.color[secondary]};
            }
        `};
    `};

    ${mods.invalid.true`
        ${Input} {
            border-color: ${theme.color.red70};
        }

        ${Icon} {
            background-color: ${theme.color.red70};
        }

        ${mods.checked.true`
            ${Input} {
                background-color: ${theme.color.red70};
            }

            ${Icon} {
                background-color: ${theme.color[secondary]};
            }
        `};
    `};
`;

export const Root = styled.label<
    StyledMods<Pick<SwitchProps, 'disabled' | 'checked' | 'size' | 'color' | 'invalid' | 'block'>>
>`
    display: inline-flex;
    align-items: start;
    vertical-align: middle;

    ${mods.size.small(rootSizeMixin('small', 30, 16, 2, 8))};
    ${mods.size.medium(rootSizeMixin('medium', 42, 20, 2, 8))};

    ${mods.color.blue90(rootColorMixin('blue90', 'white100', 'blue100', 'blue10'))};
    ${mods.color.white100(rootColorMixin('white100', 'midnight100', 'white100', 'transparent'))};

    ${mods.block.true`
        display: flex;
        width: 100%;
    `};

    ${rootDisabledMixin};
`;
