import { compile } from 'path-to-regexp';

/**
 * Replacing route path with parameters placeholders with passed parameters values
 *
 * @example pathToUrl('/requests/:requestId/workflow/:workflowId', { requestId: 'one', workflowId: 'two' })
 *
 * /requests/:requestId/workflow/:workflowId
 * /requests/one/workflow/two
 */
export const pathToUrl = <Params extends object>(path: string, params?: Params) => {
    return compile<Params>(path)(params);
};
