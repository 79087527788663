import { useGet } from 'modules/data/api';

import { supportApiPaths } from '../paths';
import { mapData } from './useGetNetSuiteCacheStatuses.map';
import {
    UseGetNetSuiteCacheStatusesBackend,
    UseGetNetSuiteCacheStatusesResponse,
} from './useGetNetSuiteCacheStatuses.types';

export const useGetNetSuiteCacheStatuses = (companyId: string) => {
    return useGet<UseGetNetSuiteCacheStatusesBackend, UseGetNetSuiteCacheStatusesResponse>(
        supportApiPaths.getNetSuiteCacheStatuses,
        {
            params: {
                path: { companyId },
            },
            queryOptions: { refetchOnMount: false },
            mapData,
            apiVersion: 'v1',
            method: 'get',
        }
    );
};
