import type { ApiVersion } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import Cookies from 'js-cookie';

import { Env, getAppConfig } from '../configs';
import { ApiSource } from '../types';

export const getDefaultApiUrl = (apiSource: ApiSource) => {
    const apiUrlPrefix = Cookies.get(apiSource);

    return apiUrlPrefix || null;
};

export const setApiUrlPrefixes = (apiUrl: string, apiSource: ApiSource, apiVersion: ApiVersion) => {
    let postfix = '';

    switch (apiSource) {
        case 'webApp':
            postfix = `api/${apiVersion}/`;
            break;

        case 'myAccount':
            postfix = `api/${apiVersion}/`;
            break;

        case 'adminPanel':
            postfix = `${apiVersion}/`;
            break;

        case 'devPortal':
            postfix = `api/${apiVersion}/`;
            break;

        case 'identity':
            postfix = 'connection/';
            break;

        case 'slack':
            postfix = '';
            break;

        default:
            throw errorHelpers.assertNever(apiSource);
    }

    return `https://${apiUrl}/${postfix}`;
};

export const getApiUrl = (apiSource: ApiSource, defaultEnv: Env, apiVersion: ApiVersion) => {
    const appConfig = getAppConfig(defaultEnv);
    const defaultApiUrl = getDefaultApiUrl(apiSource);

    return setApiUrlPrefixes(defaultApiUrl || appConfig[apiSource], apiSource, apiVersion);
};

export const setApiUrl = (apiUrl: string | null | undefined, apiSource: ApiSource, reload: boolean) => {
    const published = window.location.hostname.includes('approvalmax.com');
    const cookieOptions = {
        domain: published ? window.location.hostname : undefined,
        expires: 365,
        path: '/',
    };

    if (apiUrl) {
        Cookies.set(apiSource, apiUrl, cookieOptions);
    } else {
        Cookies.remove(apiSource, cookieOptions);
    }

    if (reload) {
        window.location.reload();
    }
};

export const setBackendApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'webApp', reload);
};

export const setMyAccountApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'myAccount', reload);
};

export const setAdminPanelApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'adminPanel', reload);
};

export const setDevPortalApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'devPortal', reload);
};

export const setIdentityApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'identity', reload);
};

export const setSlackApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'slack', reload);
};

export const resetBackendApiUrl = (reload = true) => {
    setApiUrl(null, 'webApp', reload);
};

export const resetMyAccountApiUrl = (reload = true) => {
    setApiUrl(null, 'myAccount', reload);
};

export const resetAdminPanelApiUrl = (reload = true) => {
    setApiUrl(null, 'adminPanel', reload);
};

export const resetDevPortalApiUrl = (reload = true) => {
    setApiUrl(null, 'devPortal', reload);
};

export const resetIdentityApiUrl = (reload = true) => {
    setApiUrl(null, 'identity', reload);
};

export const resetSlackApiUrl = (reload = true) => {
    setApiUrl(null, 'slack', reload);
};

if (window.ApprovalMax) {
    window.ApprovalMax.setBackendApiUrl = setBackendApiUrl;
    window.ApprovalMax.setMyAccountApiUrl = setMyAccountApiUrl;
    window.ApprovalMax.setAdminPanelApiUrl = setAdminPanelApiUrl;
    window.ApprovalMax.setDevPortalApiUrl = setDevPortalApiUrl;
    window.ApprovalMax.setIdentityApiUrl = setIdentityApiUrl;
    window.ApprovalMax.setSlackApiUrl = setSlackApiUrl;
    window.ApprovalMax.resetBackendApiUrl = resetBackendApiUrl;
    window.ApprovalMax.resetMyAccountApiUrl = resetMyAccountApiUrl;
    window.ApprovalMax.resetAdminPanelApiUrl = resetAdminPanelApiUrl;
    window.ApprovalMax.resetDevPortalApiUrl = resetDevPortalApiUrl;
    window.ApprovalMax.resetIdentityApiUrl = resetIdentityApiUrl;
    window.ApprovalMax.resetSlackApiUrl = resetSlackApiUrl;
}
