import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminPortalAccount, api, QueryKeys } from 'modules/api';

export const useEditAdminPortalUser = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutate: editAdminPortalUser } = useMutation(
        (user: Omit<AdminPortalAccount, 'email'>) => api.portalManagement.editAccount(user.id, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.AdminPortalAccounts]);
            },
        }
    );

    return {
        editAdminPortalUser,
        isLoading,
    };
};
