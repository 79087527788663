import { QueryParams } from '@approvalmax/data';
import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Button, Form, Grid, Label, Progress, Spacing, Table, TextField } from '@approvalmax/ui/src/components';
import { hooks } from '@approvalmax/utils';
import { NavBarMode, PageLayout, StyledScrollableArea, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import {
    UseAmaxPayBankAccountsItem,
    UseAmaxPayBankAccountsParams,
    UseAmaxPayBankAccountsPathParams,
    useGetAmaxPayBankAccounts,
} from 'modules/data/queries';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { dataTableColumns } from './AmaxPayBankAccountsPage.config';
import { accountIdQueryParam, companyIdQueryParam, institutionIdQueryParam } from './AmaxPayBankAccountsPage.constants';
import { messages } from './AmaxPayBankAccountsPage.messages';
import { GetAmaxPayPaymentsPayload } from './AmaxPayBankAccountsPage.types';

export const AmaxPayBankAccountsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.amaxPay.bankAccountsView);

    const history = useHistory();

    const [companyId, setCompanyId] = hooks.useQueryParam(companyIdQueryParam);
    const [accountId, setAccountId] = hooks.useQueryParam(accountIdQueryParam);
    const [institutionId, setInstitutionId] = hooks.useQueryParam(institutionIdQueryParam);

    const form = useForm<QueryParams<UseAmaxPayBankAccountsPathParams> & UseAmaxPayBankAccountsParams>({
        defaultValues: {
            companyId,
            accountId,
            institutionId,
        },
    });

    const [getAmaxPayPaymentsPayload, setGetAmaxPayPaymentsPayload] = useState<GetAmaxPayPaymentsPayload>({
        pathParams: { companyId },
        params: { accountId, institutionId },
    });

    const { data, isFetching } = useGetAmaxPayBankAccounts(
        getAmaxPayPaymentsPayload.pathParams,
        getAmaxPayPaymentsPayload.params
    );

    const onSubmit = form.handleSubmit((data) => {
        setCompanyId(data.companyId ?? '');
        setAccountId(data.accountId ?? '');
        setInstitutionId(data.institutionId ?? '');

        setGetAmaxPayPaymentsPayload({
            pathParams: { companyId: data.companyId },
            params: { accountId: data.accountId, institutionId: data.institutionId },
        });
    });

    const handleRowClick = useCallback(
        (bankAccount: UseAmaxPayBankAccountsItem) => {
            const hasSelectedText = !!document.getSelection()?.toString();

            if (getAmaxPayPaymentsPayload.pathParams.companyId && !hasSelectedText) {
                history.push(
                    getPath('amaxPayBankAccount', getAmaxPayPaymentsPayload.pathParams.companyId, bankAccount.id)
                );
            }
        },
        [getAmaxPayPaymentsPayload.pathParams.companyId, history]
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <StyledScrollableArea>
                        <Toolbar title={messages.pageTitle} />

                        <Box height={12}>{isFetching && <Progress />}</Box>

                        <Box spacing='0 40 20 60'>
                            <Form form={form} onSubmit={onSubmit}>
                                <Grid gridTemplateColumns='200px 1fr 200px' gap='8px' alignItems='center'>
                                    <Label required>{messages.companyId}</Label>

                                    <TextField name='companyId' placeholder={messages.placeHolder} required />

                                    <Button color='blue80' type='submit' disabled={isFetching}>
                                        {messages.search}
                                    </Button>

                                    <Label>{messages.accountId}</Label>

                                    <TextField name='accountId' placeholder={messages.placeHolder} />

                                    <div />

                                    <Label>{messages.institutionId}</Label>

                                    <TextField name='institutionId' placeholder={messages.placeHolder} />
                                </Grid>
                            </Form>

                            <Spacing height={16} />

                            <Table data={data || []} columns={dataTableColumns} onRowClick={handleRowClick} />
                        </Box>
                    </StyledScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

AmaxPayBankAccountsPage.displayName = 'AmaxPayBankAccountsPage';
