import React, { useEffect } from 'react';

export const useFocusOnMount = <T extends HTMLElement>(
    focusOnMount: boolean | undefined,
    elRef: React.RefObject<T>
) => {
    useEffect(() => {
        if (focusOnMount) {
            elRef.current!.focus();
        }
    }, [elRef, focusOnMount]);
};
