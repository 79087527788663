import { ExtractComponentProp } from '@approvalmax/types';
import { LoadingSpinner } from '@approvalmax/ui';
import { Box, Collapse, Grid } from '@approvalmax/ui/src/components';
import { useGetRequestVersions } from 'modules/data';
import { memo, useCallback, useState } from 'react';

import { VersionItem } from '../VersionItem/VersionItem';
import { messages } from './RequestDetailsVersions.messages';
import { RequestDetailsVersionsProps } from './RequestDetailsVersions.types';

export const RequestDetailsVersions = memo<RequestDetailsVersionsProps>((props) => {
    const { companyId, requestId, requestOrigin, integrationName } = props;

    const [collapsed, setCollapsed] = useState(true);

    const { data: requestVersions, isFetching } = useGetRequestVersions(
        {
            companyId,
            requestId,
        },
        {
            enabled: !collapsed,
        }
    );

    const onCollapse = useCallback<ExtractComponentProp<typeof Collapse, 'onCollapse'>>((value) => {
        setCollapsed(value ?? false);
    }, []);

    return (
        <Collapse name={messages.title} collapsed={collapsed} headerSpacing='16 0' onCollapse={onCollapse}>
            {isFetching ? (
                <Box spacing='24'>
                    <LoadingSpinner />
                </Box>
            ) : (
                <Grid gap={12}>
                    {(requestVersions ?? []).map((version) => (
                        <VersionItem
                            key={version.version}
                            companyId={companyId}
                            requestId={requestId}
                            version={version}
                            requestOrigin={requestOrigin}
                            integrationName={integrationName}
                        />
                    ))}
                </Grid>
            )}
        </Collapse>
    );
});

RequestDetailsVersions.displayName = 'RequestDetailsVersions';
