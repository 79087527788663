import { domHelpers, miscHelpers } from '@approvalmax/utils';
import { CSS } from '@dnd-kit/utilities';
import Skeleton from 'react-loading-skeleton';

import { DragIcon } from '../../../../icons';
import Checkbox from '../../../Checkbox/Checkbox';
import Radio from '../../../Radio/Radio';
import { checkboxColumnId, typedMemo } from '../../Table.helpers';
import { BaseItem, TableCheckType } from '../../Table.types';
import { BodyCell } from '..';
import { useBodyRow } from './BodyRow.hooks';
import { Root, StyledCell, StyledCellDragHandler } from './BodyRow.styles';
import type { BodyRowProps } from './BodyRow.types';

export const BodyRow = typedMemo(<Item extends BaseItem>(props: BodyRowProps<Item>) => {
    const {
        item,
        columns,
        getRowId,
        checkedItems,
        invalidItems,
        onCheckedItemsChange,
        onClick,
        bordered,
        checkboxColumnCover,
        sticky,
        reorderable,
        checkType,
        divider,
        rounded,
        progress,
        qa,
        getIsDisabledRow,
        ...otherProps
    } = props;

    const {
        ref,
        rowId,
        active,
        checked,
        invalid,
        disabled,
        transform,
        transition,
        attributes,
        listeners,
        isHovering,
        isCheckboxCoverVisible,
        checkboxCoverValue,
        checkboxSpacingValue,
        handleItemCheck,
    } = useBodyRow(props);

    return (
        <Root
            ref={ref}
            $clickable={!!onClick && !disabled}
            $checked={checked}
            $invalid={invalid}
            $bordered={bordered}
            $sticky={sticky}
            $divider={divider}
            $rounded={rounded}
            $disabled={disabled}
            onClick={onClick && !disabled ? () => onClick(item) : undefined}
            $transform={CSS.Transform.toString(transform)}
            $transition={transition}
            $isDragging={active?.id === item.id}
            data-qa={domHelpers.generateDataQa(qa, 'table-row')}
            data-qa-id={rowId}
            {...otherProps}
            {...attributes}
        >
            <StyledCellDragHandler
                $hide={!reorderable}
                $textAlign='center'
                $isDragging={active?.id === item.id}
                {...listeners}
            >
                <DragIcon size='20' color='blue100' />
            </StyledCellDragHandler>

            {checkType === TableCheckType.checkbox && (
                <>
                    <StyledCell $hide={!(checkedItems && isCheckboxCoverVisible)} $textAlign='center'>
                        {checkboxCoverValue}
                    </StyledCell>

                    <StyledCell
                        key={checkboxColumnId}
                        $spacing={miscHelpers.spacingPropToCss(checkboxSpacingValue)}
                        $textAlign='center'
                        $hide={!(checkedItems && !isCheckboxCoverVisible)}
                    >
                        <Checkbox size='small' checked={checked} onChange={handleItemCheck} />
                    </StyledCell>
                </>
            )}

            {checkType === TableCheckType.radio && (
                <StyledCell
                    key={checkboxColumnId}
                    $spacing={miscHelpers.spacingPropToCss(checkboxSpacingValue)}
                    $textAlign='center'
                    $hide={!(checkedItems && !isCheckboxCoverVisible)}
                >
                    <Radio.Group>
                        <Radio size='small' checked={checked} onChange={handleItemCheck} disabled={disabled} />
                    </Radio.Group>
                </StyledCell>
            )}

            {columns.map((column) => {
                const value = column.value ? column.value(item) : item[column.id as keyof Item] || null;
                const title = column.title ? column.title(item) : typeof value === 'string' ? value : undefined;

                if (progress) {
                    return (
                        <StyledCell key={column.id} $spacing={miscHelpers.spacingPropToCss(column.spacing || '12 8')}>
                            <Skeleton />
                        </StyledCell>
                    );
                }

                const CellComponent = column.cellComponent || BodyCell;

                return (
                    <CellComponent
                        key={column.id}
                        title={title}
                        columnDefinition={column}
                        item={item}
                        value={value}
                        bordered={bordered}
                        hovered={isHovering}
                        data-column-id={column.id}
                    />
                );
            })}
        </Root>
    );
});
