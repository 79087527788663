import { useCallback, useEffect, useMemo, useState } from 'react';

import { ContextValue, GroupProps } from './Group.types';

export const useCheckedValues = (props: Pick<GroupProps, 'value' | 'multiple'>) => {
    const { value, multiple } = props;

    const checkboxValue = useMemo(() => (Array.isArray(value) ? value : [value].filter(Boolean)), [value]);
    const radioValue = useMemo(() => (Array.isArray(value) ? value[0] : value), [value]);

    const [checkedValue, toggleCheckedValue] = useState<GroupProps['value']>(
        multiple ? checkboxValue || [] : radioValue || ''
    );

    useEffect(() => {
        toggleCheckedValue(multiple ? checkboxValue || [] : radioValue || '');
    }, [checkboxValue, multiple, radioValue]);

    const getCheckedStatus = useCallback<ContextValue['contextProps']['getCheckedStatus']>(
        (currentValue) =>
            Boolean(multiple ? currentValue && checkedValue?.includes(currentValue) : currentValue === checkedValue),
        [checkedValue, multiple]
    );

    return {
        checkboxValue,
        radioValue,
        getCheckedStatus,
        toggleCheckedValue,
    };
};
