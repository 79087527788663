import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.components.TwoFaCodePopup', {
    popupTitle: '2FA Code',
    description: `Enter 6-digit code provided by the Authenticator app. If you have problems entering the codes please see <supportlink>support article</supportlink>`,
    codeFieldTitle: 'Enter Authenticator Code',
    wrongCodeErrorText: 'Wrong code',
    userCodePlaceholder: 'Enter authenticator code...',
    submitButtonText: 'Submit',
});
