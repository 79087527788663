import { Reference } from '@approvalmax/types';
import { DropdownEditor, Field, Popup, usePopupContext } from '@approvalmax/ui';
import { arrayHelpers } from '@approvalmax/utils';
import { PlainDataProvider } from 'modules/components';
import { useEditMyAccountPermissionsForUser } from 'pages/myAccountPermissions/hooks/useEditMyAccountPermissionsForUser';
import { useMyAccountPermissions } from 'pages/myAccountPermissions/hooks/useMyAccountPermissions';
import { useMyAccountPermissionsForUser } from 'pages/myAccountPermissions/hooks/useMyAccountPermissionsForUser';
import { FC, memo, useCallback, useEffect, useState } from 'react';

import { useCurrentAccount } from '../../../../modules/data/hooks/useCurrentAccount';
import { messages } from './EditPermissionsPopup.messages';
import { Content } from './EditPermissionsPopup.styles';
import { EditPermissionsPopupProps } from './EditPermissionsPopup.types';

const EditPermissionsPopup: FC<EditPermissionsPopupProps> = memo((props) => {
    const { user } = props;

    const { permissions: allPermissions } = useMyAccountPermissions();
    const { permissions: userPermissions } = useMyAccountPermissionsForUser(user.id);
    const { isLoading, editMyAccountPermissionsForUser } = useEditMyAccountPermissionsForUser();
    const { permissionFlags } = useCurrentAccount();

    const { onRequestClose } = usePopupContext();

    const [permissions, setPermissions] = useState<Reference[]>(arrayHelpers.emptyArray);

    const canManageMyaccountPermission = Boolean(permissionFlags?.accounts.manageMyaccountPermission);

    const onEdit = useCallback(async () => {
        await editMyAccountPermissionsForUser({
            userId: user.id,
            myAccountPermissions: permissions.map((p) => p.id),
        });
        onRequestClose();
    }, [editMyAccountPermissionsForUser, onRequestClose, permissions, user.id]);

    useEffect(() => {
        if (allPermissions && userPermissions) {
            setPermissions(allPermissions.filter((p) => userPermissions.includes(p.id)));
        }
    }, [allPermissions, userPermissions]);

    return (
        <Popup.DefaultContent
            title={messages.title({
                user: user.firstName,
            })}
            buttons={
                <Popup.DefaultContent.Button execute={onEdit} disabled={isLoading || !canManageMyaccountPermission}>
                    {messages.doneButton}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <Field title={messages.myAccountPermissionsField}>
                    <PlainDataProvider items={allPermissions || []}>
                        <DropdownEditor
                            value={permissions}
                            disabled={!canManageMyaccountPermission}
                            onChange={(v: Reference[]) => setPermissions(v)}
                            multiple
                            placeholder={messages.placeholderMyAccountPermissions}
                        />
                    </PlainDataProvider>
                </Field>
            </Content>
        </Popup.DefaultContent>
    );
});

export default EditPermissionsPopup;
