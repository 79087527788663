import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useStorageQueues = () => {
    const { isInitialLoading, data: queues } = useQuery(
        [QueryKeys.StorageQueues],
        () => api.infra.getStorageQueues(),
        {}
    );

    return {
        queues,
        isInitialLoading,
    };
};
