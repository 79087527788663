import { Mods } from '@approvalmax/theme';

export interface ProgressProps
    extends Mods<'rounded'>,
        Mods<'size', 'uxsmall' | 'usmall' | 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large'> {
    /**
     * Value of progress in percent
     */
    value?: number;

    /**
     * Shape of progress
     */
    shape?: keyof typeof ProgressShapes;
}

export enum ProgressShapes {
    circle = 'circle',
    line = 'line',
}
