import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, MaintenanceInfo, QueryKeys } from 'modules/api';

export const useSaveMaintenanceInfo = () => {
    const queryClient = useQueryClient();

    const { isLoading, mutate: saveMaintenanceInfo } = useMutation(
        (data: MaintenanceInfo) => api.internalSupport.saveMaintenanceInfo(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.MaintenanceInfo]);

                api.webApp.clearMaintenanceCache();
                api.myAccount.clearMaintenanceCache();
            },
        }
    );

    return {
        saveMaintenanceInfo,
        isLoading,
    };
};
