import { theme } from '@approvalmax/theme';
import { DownloadIcon } from '@approvalmax/ui/src/icons';
import styled from 'styled-components';

export const StyledDownloadIcon = styled(DownloadIcon)`
    color: ${theme.color.midnight100};

    &:hover {
        color: ${theme.color.blue100};
    }
`;
