import axios, { AxiosError } from 'axios';
import { Env, getConfig, getConfigForEnv } from 'config';
import { getPath } from 'urlBuilder';

import { toaster } from '../components';
import { MessageRoot, MessageTitle } from './config.styles';
import { ResponseError } from './types';

const REQUEST_TIMEOUT_SECONDS = 40;

const LOCAL_STORAGE_API_BASE_URL_KEY = 'debug_apiBaseUrlOverride';

const changeApi = (env: Env) => {
    const newUrl = getConfigForEnv(env).apiBaseUrl;

    if (newUrl !== getConfig().apiBaseUrl) {
        localStorage.setItem(LOCAL_STORAGE_API_BASE_URL_KEY, newUrl);
    } else {
        localStorage.removeItem(LOCAL_STORAGE_API_BASE_URL_KEY);
    }

    axios.defaults.baseURL = `${newUrl}/v1`;
};

(window as any).changeApi = changeApi;

const API_BASE_URL = getConfig().apiBaseUrl;
const apiBaseUrl = localStorage.getItem(LOCAL_STORAGE_API_BASE_URL_KEY) || API_BASE_URL;

axios.defaults.baseURL = `${apiBaseUrl}/v1`;

axios.defaults.timeout = REQUEST_TIMEOUT_SECONDS * 1000;
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

let isRedirectingToSignInPage = false;

axios.defaults.validateStatus = (status) => {
    if (status === 401 && !isRedirectingToSignInPage) {
        isRedirectingToSignInPage = true;

        const redirectUrlParam = encodeURIComponent(window.location.toString());

        window.location.href = `${apiBaseUrl}/v1/auth/google/signIn?redirectUrl=${redirectUrlParam}`;
    }

    if (status === 403) {
        if (window.location.pathname !== '' && window.location.pathname !== getPath('dashboard')) {
            // Redirect to the home page if the user has no access to the current one
            window.location.pathname = '';
        }
    }

    return !!status && status >= 200 && status < 300;
};

axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError<ResponseError>) => {
        const data = error.response?.data;

        if (data) {
            if (data.title || data.detail) {
                toaster.error(getErrorMessage(data.title, data.detail));

                throw error;
            }
        } else {
            toaster.error(error.message);
        }

        throw error;
    }
);

const getErrorMessage = (title?: string, detail?: string) => {
    return (
        <MessageRoot>
            {title ? <MessageTitle hasDetails={!!detail}>{title}</MessageTitle> : null}

            {detail ? <div>{detail}</div> : null}
        </MessageRoot>
    );
};
