import get from 'lodash/get';

/* Gen variables for Styled Components with theme by origin variables */
export const genVariables = <Variables extends Record<string, any>>(variables: Variables, path: string[] = []) => {
    return Object.keys(variables).reduce((acc, item) => {
        const value = variables[item];
        const newPath = [...path, item];

        const func = (props: Record<string, Variables>) => get(props.theme, newPath.join('.'));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[item] = typeof value === 'string' ? func : genVariables(value, newPath);

        return acc;
    }, {} as Variables);
};
