import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useSaveCompanyBetaFeatures = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutate: saveCompanyBetaFeatures } = useMutation(
        (options: { betaFeatures: string[] }) => api.internalSupport.updateCompanyBetaFeatures(options),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.AvailableCompanyBetaFeatures]);
            },
        }
    );

    return {
        saveCompanyBetaFeatures,
        isLoading,
    };
};
