import { FC, memo, useMemo } from 'react';

import { Circle, CircleBack, CircleBar, Line, LineBar } from './Progress.styles';
import { ProgressProps, ProgressShapes } from './Progress.types';

/**
 * Progress indicators commonly known as spinners, express an unspecified wait time or display the length of a process.
 */
const Progress: FC<ProgressProps> = memo((props) => {
    const { rounded, shape = 'line', size = 'medium', value, ...restProps } = props;

    const hasValue = typeof value === 'number';
    const percentValue = hasValue ? (value > 100 ? 100 : value < 0 ? 0 : value) : undefined;

    const circleRadius = 42;
    const circlePercentage = useMemo(() => {
        if (shape !== ProgressShapes.circle || !hasValue) return {};

        const circumference = circleRadius * 2 * Math.PI;
        const strokeDasharray = `${circumference} ${circumference}`;
        const strokeDashoffset = circumference - ((percentValue || 0) / 100) * circumference;

        return {
            strokeDasharray,
            strokeDashoffset,
        };
    }, [shape, hasValue, percentValue]);

    if (shape === ProgressShapes.circle) {
        return (
            <Circle viewBox='0 0 100 100' $size={size} $value={percentValue} {...restProps}>
                <CircleBack cx='50' cy='50' r={circleRadius} />

                <CircleBar
                    cx='50'
                    cy='50'
                    r={circleRadius}
                    $value={percentValue}
                    strokeDashoffset={circlePercentage.strokeDashoffset}
                    strokeDasharray={circlePercentage.strokeDasharray}
                />
            </Circle>
        );
    }

    return (
        <Line $size={size} $rounded={rounded} {...restProps}>
            <LineBar $value={percentValue} />
        </Line>
    );
});

export default Progress;
