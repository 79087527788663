import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { PermissionFlags } from '../selectors/getPermissionFlags';
import { useCurrentAccount } from './useCurrentAccount';

/**
 * Redirects to the dashboard page if the permissions select returns false
 */
export const usePermissionsRedirect = (permissionSelector: (permissionFlags: PermissionFlags) => boolean) => {
    const { permissionFlags } = useCurrentAccount();
    const history = useHistory();

    useEffect(() => {
        if (permissionFlags && !permissionSelector(permissionFlags)) {
            history.replace(getPath('dashboard'));
        }
    }, [history, permissionFlags, permissionSelector]);
};
