import { memo } from 'react';

import { columnsCreator } from './ValuesList.config';
import { DataTableHeadless } from './ValuesList.styles';
import { ValuesListProps } from './ValuesList.types';

const ValuesList = memo<ValuesListProps>((props) => {
    const { values } = props;

    return <DataTableHeadless columns={columnsCreator()} data={values} />;
});

export default ValuesList;
