import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.UndeliveredEmailsPage', {
    pageTitle: 'Undelivered Emails',
    breadcrumbRoot: 'Customer Success',
    searchPlaceholder: 'Enter email',
    searchContainsPlaceholder: 'Enter email (2 chars at least)',
    noEmailError: 'No email found',
    defaultErrorMessage: 'Oops, something went wrong',
    emailCol: 'Email',
    reasonCol: 'Reason',
    dateCol: 'Date',
});
