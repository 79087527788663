import styled from 'styled-components';

export const CellContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;
