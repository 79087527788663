import { mixins, Mods, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { ToggleButtonProps } from './ToggleButton.types';

export const Label = styled.div``;

export const Control = styled.div`
    opacity: 0;

    &,
    > * {
        ${mixins.position.absoluteFill};
    }
`;

const rootSizeMixin = (fontSize: RequiredMods['fontSize'], padding: RequiredMods['spacing']) => css`
    padding: ${padding}px;

    ${Label} {
        ${mixins.font('label', fontSize)};
    }
`;

const rootColorMixin = (
    color: NonNullable<ToggleButtonProps['color']>,
    hoverColor: 'blue10' | 'blue20',
    hoverInactiveColor: 'blue100' | 'midnight100'
) => css`
    border-color: ${theme.color[color]};
    color: ${theme.color[color]};

    &:first-child {
        border-left: 1px solid ${theme.color[color]};
    }

    &:last-child {
        border-right-color: ${theme.color[color]};
    }

    ${mods.disabled.false`
        &:hover,
        &:focus,
        &.focused {
            background-color: ${theme.color[hoverColor]};
            border-color: ${theme.color[hoverInactiveColor]};

            ${mods.checked.true`
                background-color: ${theme.color[hoverInactiveColor]};
            `}
        }

        ${mods.checked.true`
            background-color: ${theme.color[color]};
            color: ${theme.color.white100};
            border-right-color: rgba(255,255,255,.3);
        `};
    `}
`;

const rootDisabledMixin = css`
    ${mods.disabled.true`
        border-color: ${theme.color.midnight60};
        color: ${theme.color.midnight60};

        &:first-child {
            border-left: 1px solid ${theme.color.midnight60};
        }

        &:last-child {
            border-right-color: ${theme.color.midnight60};
        }

        ${mods.checked.true`
            background-color: ${theme.color.midnight60};
            color: ${theme.color.white100};
        `}
    `}
`;

export const Root = styled.div<
    StyledMods<Pick<ToggleButtonProps, 'color' | 'size' | 'block' | 'width'> & Mods<'checked' | 'disabled'>>
>`
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    position: relative;
    text-align: center;
    transition: ${theme.duration.medium}ms;

    &:first-child {
        border-top-left-radius: ${theme.radius.small};
        border-bottom-left-radius: ${theme.radius.small};
    }

    &:last-child {
        border-top-right-radius: ${theme.radius.small};
        border-bottom-right-radius: ${theme.radius.small};
    }

    ${mods.color.blue90(rootColorMixin('blue90', 'blue10', 'blue100'))};
    ${mods.color.midnight80(rootColorMixin('midnight80', 'blue20', 'midnight100'))};

    ${mods.size.xsmall(rootSizeMixin('xsmall', 4))};
    ${mods.size.small(rootSizeMixin('small', 8))};
    ${mods.size.medium(rootSizeMixin('medium', 12))};

    ${mods.block.true`
        flex-grow: 1;
    `};

    ${rootDisabledMixin};

    ${mixins.whByModValue('width')};
`;
