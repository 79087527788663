import { Popup, RadioGroupEditor } from '@approvalmax/ui';
import { memo, useCallback, useState } from 'react';

import { useForceMigrationMutation } from './ForceMigrationPopup.hooks';
import { messages } from './ForceMigrationPopup.messages';
import { Content, Label } from './ForceMigrationPopup.styles';
import { AmountType, ForceMigrationPopupProps } from './ForceMigrationPopup.types';

const ForceMigrationPopup = memo<ForceMigrationPopupProps>((props) => {
    const { companyId, onClose } = props;

    const [amountType, setAmountType] = useState(AmountType.Gross);

    const { forceMatchingMigration, isLoading } = useForceMigrationMutation(companyId, amountType, onClose);

    const onAmountTypeChange = useCallback((value: AmountType) => {
        setAmountType(value);
    }, []);

    return (
        <Popup.DefaultContent
            title={messages.popupTitle}
            buttons={
                <Popup.DefaultContent.Button disabled={isLoading} execute={forceMatchingMigration}>
                    {messages.submitButton}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <Label>{messages.amountTypeLabel}</Label>

                <RadioGroupEditor value={amountType} onChange={onAmountTypeChange}>
                    <RadioGroupEditor.Item id={AmountType.Gross}>{messages.gross}</RadioGroupEditor.Item>

                    <RadioGroupEditor.Item id={AmountType.Net}>{messages.net}</RadioGroupEditor.Item>
                </RadioGroupEditor>
            </Content>
        </Popup.DefaultContent>
    );
});

export default ForceMigrationPopup;
