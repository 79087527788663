import { Identifiable } from '@approvalmax/types';
import { ColumnDefinition } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { AmaxPayConsentHistoryItem } from 'modules/data';

import { messages } from './AmaxPayConsentDetailsPage.messages';

export const dataTableColumns: ColumnDefinition<AmaxPayConsentHistoryItem & Identifiable>[] = [
    {
        id: 'consentId',
        name: messages.consentId,
    },
    {
        id: 'createdAt',
        name: messages.createdAt,
        value: (item) => dateTimeHelpers.formatDateTime(item.createdAt),
    },
    {
        id: 'status',
        name: messages.status,
    },
];
