import { enabledByParams, QueryOptions } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { ocrApiPaths } from '../paths';
import { UseGetOcrResultParams, UseGetOcrResultPathParams, UseGetOcrResultResponse } from './useGetOcrResult.types';

// TODO: Зарефактори меня
export const useGetOcrResult = (
    pathParams: UseGetOcrResultPathParams,
    params: UseGetOcrResultParams,
    queryOptions?: QueryOptions<any>
) => {
    return useGet<UseGetOcrResultResponse>(ocrApiPaths.getOcrResult, {
        params: {
            path: pathParams,
            query: params,
        },
        queryOptions: {
            enabled: enabledByParams<UseGetOcrResultPathParams>(pathParams),
            ...queryOptions,
        },
        apiVersion: 'v1',
        method: 'get',
    });
};
