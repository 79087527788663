import { createError } from './createError';

/**
 * Simplified way to throw an error. Throws an Error object with the specified name and message.
 * @example
 * core.utils.helpers.throwError('Request is invalid.');
 * @param {String} message Error message.
 * @param {String} [name='Error'] Error name (`name` attribute of Error object).
 * */
export const throwError = (message: string, name = 'Error'): never => {
    throw createError(message, name);
};
