import { QueryOptions } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { requestsApiPaths } from '../paths';
import { UseGetRequestVersionDataPathParams, UseGetRequestVersionDataResponse } from './useGetRequestVersionData.types';

export const useGetRequestVersionData = (
    pathParams: UseGetRequestVersionDataPathParams,
    queryOptions?: QueryOptions<UseGetRequestVersionDataResponse>
) => {
    return useGet<UseGetRequestVersionDataResponse>(requestsApiPaths.getRequestVersionData, {
        params: {
            path: pathParams,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
