import axios from 'axios';

import { errorCatch } from './helpers/errors';

const rootApi = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    withXSRFToken: true,
});

rootApi.interceptors.request.use(
    async (config) => {
        return config;
    },
    (error) => {
        console.error(error);

        throw error;
    }
);

rootApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (e) => {
        const error = errorCatch(e, 'v2');

        console.error(error);

        throw error;
    }
);

export default rootApi;
