import { BudgetValueIdentifiable } from '../../BudgetDetailsPage.types';

export interface ValuesListProps {
    values: BudgetValueIdentifiable[];
}

export enum ColumnId {
    name = 'name',
    accountCode = 'accountCode',
    plannedAmount = 'plannedAmount',
    startDate = 'startDate',
}
