import { useMutation } from '@tanstack/react-query';
import { api } from 'modules/api';
import { toaster } from 'modules/components';

import { messages } from './ForceMigrationPopup.messages';
import { AmountType } from './ForceMigrationPopup.types';

export const useForceMigrationMutation = (companyId: string, amountType: AmountType, onSuccess: () => void) => {
    const { mutate: forceMatchingMigration, ...other } = useMutation(
        () => api.internalSupport.forceMatchingMigration(companyId, amountType),
        {
            onSuccess: () => {
                toaster.info(messages.successMessage);

                onSuccess();
            },
        }
    );

    return {
        forceMatchingMigration,
        ...other,
    };
};
