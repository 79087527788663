import { Reference } from '@approvalmax/types';
import axios from 'axios';
import { getConfig } from 'config';
import { SortOrder } from 'modules/types';
import { stringify } from 'query-string';

import { BudgetDetails, BudgetPreview, BudgetValue } from '../types/Budget';
import {
    AdminPortalAccount,
    AnnouncementInfo,
    CompanyDetails,
    CompanySearchEntry,
    DeletedParticipant,
    DeleteRestoreRequestData,
    EligibleToEnforceFraudDetectionWorkflow,
    IgnoredEmail,
    IgnoredEmailDetails,
    IgnoredEmailsWithContinuationToken,
    IntegrationConfiguration,
    MaintenanceInfo,
    NextCompanySyncCommand,
    Report,
    ReportDetails,
    ReportRaw,
    RequestDetails,
    RequestFindData,
    ShardItem,
    SqlQueryExecutionResult,
    StartTwoFaData,
    StorageQueue,
    StorageQueueMessage,
    SupportedClient,
    TenantCurrentMovementData,
    TenantMovementData,
    TfaDisablingHistoryItem,
    User,
    UserDetails,
    VerifyTFACodeRequestData,
} from './types';

const webAppBaseUrl = getConfig().appUrl;
const myAccountBaseUrl = getConfig().accountUrl;

export const api = {
    auth: {
        getIsSignedIn: async () => {
            await axios.get('/auth/google/isSignedIn');
        },
        signOut: async () => {
            await axios.post('/auth/google/signOut');
        },
    },
    internalSupport: {
        findUsers: async (params: {
            userEmailQuery: string;
            sortingBy?: string;
            sortingOrder?: SortOrder;
        }): Promise<User[]> => {
            const response = await axios.get('/support/users', {
                params,
            });

            return response.data;
        },
        getUserDetails: async (userId: string): Promise<UserDetails> => {
            const response = await axios.get(`/support/users/${userId}`);

            return response.data;
        },
        saveUserBetaFeatures: async (
            userId: string,
            data: {
                betaFeatures: string[];
            }
        ): Promise<void> => {
            await axios.put(`/support/users/${userId}/betaFeatures`, data);
        },
        getAvailableUserBetaFeatures: async (): Promise<string[]> => {
            const response = await axios.get(`/support/users/betaFeatures`);

            return response.data;
        },
        getAvailableCompanyBetaFeatures: async (): Promise<string[]> => {
            const response = await axios.get(`/support/companies/betaFeatures`);

            return response.data;
        },
        findCompanies: async (params: {
            companyQuery: string;
            companyQueryType: 'byIdOrName' | 'byIntegrationIdOrName' | 'byEmail';
            companyName?: string;
            accountOwnerEmail?: string;
            companyStatuses?: string[];
            companyIntegrationTypes?: string[];
            companyIntegrationStatus?: boolean;
            sortingBy?: string;
            sortingOrder?: SortOrder;
        }): Promise<CompanySearchEntry[]> => {
            const response = await axios.get('/support/companies', {
                params,
                paramsSerializer: (p) => {
                    return stringify(p, {
                        arrayFormat: 'none',
                    });
                },
            });

            return response.data;
        },
        getCompanyDetails: async (companyId: string): Promise<CompanyDetails> => {
            const response = await axios.get(`/support/companies/${companyId}`);

            return response.data;
        },
        saveCompanyBetaFeatures: async (
            companyId: string,
            data: {
                betaFeatures: string[];
            }
        ): Promise<void> => {
            await axios.put(`/support/companies/${companyId}/betaFeatures`, data);
        },
        findRequest: async (requestId: string): Promise<RequestFindData> => {
            const response = await axios.get(`/support/companies/requests`, { params: { requestId } });

            return response.data;
        },
        getRequestDetails: async (companyId: string, requestId: string): Promise<RequestDetails> => {
            const response = await axios.get(`/support/companies/${companyId}/requests/${requestId}`);

            return response.data;
        },
        deleteRequests: async (data: DeleteRestoreRequestData): Promise<void> => {
            const { companyId } = data;

            await axios.post(`/support/companies/${companyId}/requests/commands/delete`, data);
        },
        restoreRequests: async (data: DeleteRestoreRequestData): Promise<void> => {
            const { companyId } = data;

            await axios.post(`/support/companies/${companyId}/requests/commands/restore`, data);
        },
        getReportsByCompanyId: async (query: string): Promise<Report[]> => {
            const response = await axios.get(`/support/companies/${query}/reports`);

            return response.data.map((report: ReportRaw) => ({
                id: report.reportId,
                name: report.name,
                companyId: report.companyId,
            }));
        },
        getReportsByReportId: async (query: string): Promise<Report[]> => {
            const response = await axios.get(`/support/companies/reports`, { params: { reportId: query } });

            if (response.data) {
                response.data = [{ ...response.data }];
            } else {
                response.data = [];
            }

            return response.data.map((report: ReportRaw) => ({
                id: report.reportId,
                name: report.name,
                companyId: report.companyId,
            }));
        },
        getReports: async (query: string, type: 'companyId' | 'reportId'): Promise<Report[]> => {
            let response;

            if (type === 'companyId') {
                response = await axios.get(`/support/companies/${query}/reports`);
            } else {
                response = await axios.get(`/support/companies/reports`, { params: { reportId: query } });

                if (response.data) {
                    response.data = [{ ...response.data }];
                } else {
                    response.data = [];
                }
            }

            return response.data.map((report: ReportRaw) => ({
                id: report.reportId,
                name: report.name,
                companyId: report.companyId,
            }));
        },
        getReportDetails: async (companyId: string, reportId: string): Promise<ReportDetails> => {
            const response = await axios.get(`/support/companies/${companyId}/reports/${reportId}`);

            return response.data;
        },
        updateUserBetaFeatures: async (data: { betaFeatures: string[] }): Promise<void> => {
            await axios.put(`/support/users/betaFeatures`, data);
        },
        updateCompanyBetaFeatures: async (data: { betaFeatures: string[] }): Promise<void> => {
            await axios.put(`/support/companies/betaFeatures`, data);
        },
        getEligibleToEnforceFraudDetectionWorkflows: async (
            companyId: string
        ): Promise<EligibleToEnforceFraudDetectionWorkflow[]> => {
            const response = await axios.get(
                `/support/companies/${companyId}/workflows/eligibleToEnforceFraudDetection`
            );

            return response.data;
        },
        enforceFraudDetection: async (
            companyId: string,
            data: {
                workflowId: string;
                effectiveDate: string;
            }
        ): Promise<void> => {
            await axios.post(`/support/companies/${companyId}/workflows/enforceFraudDetection`, data);
        },
        forceMatchingMigration: async (companyId: string, amountType: string): Promise<void> => {
            await axios.post(`/support/companies/${companyId}/matchingMigration?amountType=${amountType}`);
        },
        listIgnoredEmails: async (params: {
            emailPart: string;
            limit?: number;
            lastEmail?: string;
            lastReason?: string;
        }): Promise<IgnoredEmail[]> => {
            const response = await axios.get('/support/ignoredEmails/search', {
                params,
            });

            return (response.data as IgnoredEmail[]).map((e, i) => ({
                ...e,
                id: e.email + e.reason + i,
            }));
        },
        listIgnoredEmailsWithContinuationToken: async (params: {
            emailStartsWith?: string;
            continuationToken?: string;
            limit?: number;
        }): Promise<IgnoredEmailsWithContinuationToken> => {
            const response = await axios.get<IgnoredEmailsWithContinuationToken>('/support/ignoredEmails', {
                params,
            });

            const data = response.data;

            return {
                items: (data?.items || []).map((e, i) => ({
                    ...e,
                    id: e.email + e.reason + i,
                })),
                continuationToken: data.continuationToken,
            };
        },
        getIgnoredEmailDetails: async (email: string, params: { reason: string }): Promise<IgnoredEmailDetails> => {
            const response = await axios.get(`/support/ignoredEmails/${email}`, {
                params,
            });

            let rawData = response.data.rawData;

            try {
                rawData = JSON.parse(response.data.rawData.MessageBody);
                rawData.Message = JSON.parse(rawData.Message);

                return {
                    rawData,
                };
            } catch {
                return {
                    rawData,
                };
            }
        },
        deleteIgnoredEmail: async (email: string, params: { reason: string }): Promise<void> => {
            await axios.delete(`/support/ignoredEmails/${email}`, {
                params,
            });
        },
        getMaintenanceInfo: async (): Promise<MaintenanceInfo> => {
            const response = await axios.get('/maintenances/message');

            return response.data;
        },
        saveMaintenanceInfo: async (data: MaintenanceInfo): Promise<void> => {
            await axios.put('/maintenances/message', data);
        },
        getAnnouncementInfo: async (): Promise<AnnouncementInfo> => {
            const response = await axios.get('/announcements/message');

            return response.data;
        },
        saveAnnouncementInfo: async (data: AnnouncementInfo): Promise<void> => {
            await axios.put('/announcements/message', data);
        },
        getCompanyWorkflows: async (
            companyId: string
        ): Promise<
            {
                id: string;
                name: string;
                integrationCode: string;
            }[]
        > => {
            const response = await axios.get(`/support/companies/${companyId}/workflows`);

            return response.data;
        },
        getWorkflowDetails: async (
            companyId: string,
            workflowId: string
        ): Promise<{
            workflowRawData: string;
            workflowVersions: {
                version: number;
                author: string;
                createdDate: string;
                modifiedDate: string;
            }[];
        }> => {
            const response = await axios.get(`/support/companies/${companyId}/workflows/${workflowId}`);

            return response.data;
        },
        getWorkflowVersionDetails: async (
            companyId: string,
            workflowId: string,
            versionId: number
        ): Promise<{
            workflowVersionRawData: string;
        }> => {
            const response = await axios.get(
                `/support/companies/${companyId}/workflows/${workflowId}/versions/${versionId}`
            );

            return response.data;
        },
        getDeletedParticipants: async (companyId: string): Promise<DeletedParticipant[]> => {
            const response = await axios.get(`/support/companies/${companyId}/deletedParticipants`);

            return response.data;
        },
        disable2FA: async (userId: string, data: { comment: string; supportTicketId: string }): Promise<void> => {
            await axios.delete(`/support/users/${userId}/tfa`, {
                data,
            });
        },
        get2FADisablingHistory: async (userId: string): Promise<TfaDisablingHistoryItem[]> => {
            const response = await axios.get(`/support/users/${userId}/tfaDisablingHistory`);

            return response.data;
        },
        getSupportedClients: async (): Promise<SupportedClient[]> => {
            const response = await axios.get(`/support/supportedClients`);

            return response.data;
        },
        editSupportedClients: async (data: Omit<SupportedClient, 'maxVersion'>) => {
            return await axios.post<string, void, typeof data>(`/support/supportedClients`, data);
        },
    },
    portalManagement: {
        getAccounts: async (): Promise<AdminPortalAccount[]> => {
            const response = await axios.get('/accounts');

            return response.data;
        },
        getMyAccount: async (): Promise<AdminPortalAccount> => {
            const response = await axios.get('/accounts/me');

            return response.data;
        },
        getPermissions: async (): Promise<Reference[]> => {
            const response = await axios.get('/accounts/permissions');

            return response.data;
        },
        createAccount: async (data: Omit<AdminPortalAccount, 'id'>): Promise<AdminPortalAccount> => {
            const response = await axios.post('/accounts', data);

            return {
                ...data,
                id: response.data,
            };
        },
        editAccount: async (accountId: string, data: Omit<AdminPortalAccount, 'id' | 'email'>): Promise<void> => {
            await axios.put(`/accounts/${accountId}`, data);
        },
        deleteAccount: async (accountId: string): Promise<void> => {
            await axios.delete(`/accounts/${accountId}`);
        },
        getMyAccountPermissions: async (): Promise<Reference[]> => {
            const response = await axios.get('/accounts/myAccountPermissions');

            return response.data;
        },
        getMyAccountPermissionsForUser: async (userId: string): Promise<string[]> => {
            const response = await axios.get(`/accounts/myAccountPermissions/${userId}`);

            return response.data;
        },
        editMyAccountPermissionsForUser: async (
            userId: string,
            data: {
                myAccountPermissions: string[];
            }
        ): Promise<Reference[]> => {
            const response = await axios.put(`/accounts/myAccountPermissions/${userId}`, data);

            return response.data;
        },
    },
    infra: {
        getStorageQueues: async (): Promise<StorageQueue[]> => {
            const response = await axios.get('/infra/storageQueues');

            return response.data;
        },
        getStorageQueue: async (queueId: string): Promise<StorageQueue> => {
            const response = await axios.get(`/infra/storageQueues/${queueId}`);

            return response.data;
        },
        clearStorageQueue: async (queueId: string): Promise<void> => {
            await axios.delete(`/infra/storageQueues/${queueId}/messages/poison`);
        },
        moveStorageQueuePoisonMessages: async (queueId: string): Promise<void> => {
            await axios.post(`/infra/storageQueues/${queueId}/messages/poison/commands/replay`);
        },
        getStorageQueueMessages: async (queueId: string): Promise<StorageQueueMessage[]> => {
            const response = await axios.get(`/infra/storageQueues/${queueId}/messages`);

            return response.data;
        },
        getStorageQueuePoisonMessages: async (queueId: string): Promise<StorageQueueMessage[]> => {
            const response = await axios.get(`/infra/storageQueues/${queueId}/messages/poison`);

            return response.data;
        },
        executeSqlQuery: async (
            data: { sqlQueryText: string },
            twoFaCode: string
        ): Promise<SqlQueryExecutionResult | undefined> => {
            const response = await axios.post(`/infra/databases/queries/fetchData`, data, {
                headers: {
                    'x-tfa-code': twoFaCode,
                },
                validateStatus: (status) => {
                    return (!!status && status >= 200 && status < 300) || status === 403;
                },
            });

            if (response.status === 403) {
                const errorText = response.data?.detail ?? 'Something went wrong';

                throw Error(errorText);
            }

            return response.data;
        },
        downloadSqlQueryAsCSV: async (data: { sqlQueryText: string }, twoFaCode: string): Promise<BlobPart[]> => {
            const response = await axios.post(`/infra/databases/queries/exportCsv`, data, {
                headers: {
                    'x-tfa-code': twoFaCode,
                },
                validateStatus: (status) => {
                    return (!!status && status >= 200 && status < 300) || status === 403;
                },
            });

            if (response.status === 403) {
                const errorText = response.data?.detail ?? 'Something went wrong';

                throw Error(errorText);
            }

            return [String(response.data)];
        },
        downloadSqlQueryAsJSON: async (data: { sqlQueryText: string }, twoFaCode: string): Promise<BlobPart[]> => {
            const response = await axios.post(`/infra/databases/queries/exportJson`, data, {
                headers: {
                    'x-tfa-code': twoFaCode,
                },
                validateStatus: (status) => {
                    return (!!status && status >= 200 && status < 300) || status === 403;
                },
            });

            if (response.status === 403) {
                const errorText = response.data?.detail ?? 'Something went wrong';

                throw Error(errorText);
            }

            return [JSON.stringify(response.data, null, 2)];
        },
        getCompanySyncCommandsCount: async (companyId: string): Promise<number> => {
            const response = await axios.get(`/infra/syncCommands/${companyId}/count`);

            return response.data;
        },
        getNextCompanySyncCommand: async (companyId: string): Promise<NextCompanySyncCommand | undefined> => {
            const response = await axios.get(`/infra/syncCommands/${companyId}/next`);

            return response.data;
        },
        deleteSyncCommand: async (syncCommandId: string, companyId: string): Promise<void> => {
            const response = await axios.delete(`/infra/syncCommands/${companyId}/${syncCommandId}`);

            return response.data;
        },
        getShardList: async (): Promise<ShardItem[]> => {
            const response = await axios.get('/infra/sharding/shards');

            return response.data;
        },
        getCompanyShard: async (companyId: string): Promise<string> => {
            const response = await axios.get(`/infra/sharding/companies/${companyId}/shard`);

            return response.data;
        },
        getTenantMovementData: async (companyId: string, shardId: string): Promise<TenantMovementData> => {
            const response = await axios.get(
                `/infra/sharding/companies/${companyId}/shards/${shardId}/move/validation`,
                {
                    // for this endpoint we need 3 minutes timeout
                    timeout: 3 * 60 * 1000,
                }
            );

            return response.data;
        },
        getTenantCurrentMovement: async (): Promise<TenantCurrentMovementData> => {
            const response = await axios.get('/infra/sharding/move/current');

            return response.data;
        },
        startShardMove: async (companyId: string, shardId: string): Promise<{ started: boolean }> => {
            const response = await axios.post(`/infra/sharding/companies/${companyId}/shards/${shardId}/move/start`);

            return response.data;
        },
        cancelTenantCurrentMovement: async (): Promise<void> => {
            const response = await axios.put('/infra/sharding/move/cancel');

            return response.data;
        },
    },
    webApp: {
        clearMaintenanceCache: () => {
            axios.post('/ForceMaintenanceInfoUpdate', undefined, {
                baseURL: webAppBaseUrl,
            });
        },
        clearAnnouncementCache: () => {
            axios.post('/ForceAnnouncementInfoUpdate', undefined, {
                baseURL: webAppBaseUrl,
            });
        },
    },
    myAccount: {
        clearMaintenanceCache: () => {
            axios.post('/ForceMaintenanceInfoUpdate', undefined, {
                baseURL: myAccountBaseUrl,
            });
        },
    },
    account: {
        startTwoFa: async (): Promise<StartTwoFaData> => {
            const response = await axios.post('accounts/me/tfa/startTFAEnabling');

            return response.data;
        },
        verifyTwoFaCode: async (data: VerifyTFACodeRequestData): Promise<void> => {
            const response = await axios.post('accounts/me/tfa', data, {
                validateStatus: (status) => {
                    return (!!status && status >= 200 && status < 300) || status === 403;
                },
            });

            if (response.status === 403) {
                const errorText = response.data?.detail ?? 'Something went wrong';

                throw Error(errorText);
            }

            return response.data;
        },
    },
    integrationConfigurations: {
        getIntegrationConfigurations: async () => {
            const response = await axios.get<{ integrationConfigurations: IntegrationConfiguration[] }>(
                '/support/integrationConfigurations'
            );

            return response.data;
        },
        setIntegrationConfigurations: async (data: { integrationConfigurations: IntegrationConfiguration[] }) => {
            const response = await axios.put('/support/integrationConfigurations', data, {
                validateStatus: (status) => {
                    return (!!status && status >= 200 && status < 300) || status === 403;
                },
            });

            if (response.status === 403) {
                const errorText = response.data?.detail ?? 'Something went wrong';

                throw Error(errorText);
            }

            return response.data;
        },
    },
    budgets: {
        getBudgetsByCompanyId: async (companyId: string) => {
            const response = await axios.get<BudgetPreview[]>(`/support/budgets/${companyId}`);

            return response.data;
        },
        getBudgetDetails: async (companyId: string, budgetId: string) => {
            const response = await axios.get<BudgetDetails>(`/support/budgets/${companyId}/${budgetId}`);

            return response.data;
        },
        getBudgetValues: async (companyId: string, budgetId: string, params: { month: string; year: string }) => {
            const response = await axios.get<BudgetValue[]>(`/support/budgets/${companyId}/${budgetId}/values`, {
                params,
            });

            return response.data;
        },
    },
};
