import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { BaseItem, ColumnDefinition } from '../../Table.types';

export const StyledCol = styled.col<StyledMods<Pick<ColumnDefinition<BaseItem>, 'width'> & Mods<'bordered'>>>`
    ${mods('width')(
        (value) => css`
            width: ${value}px;
            min-width: ${value}px;
        `
    )};

    ${mods.bordered.true`
        border: 1px solid ${theme.color.midnight50};
    `};
`;
