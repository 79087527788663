import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { dateTimeHelpers } from '@approvalmax/utils';

import { messages } from './TFADisablingHistory.messages';
import { CellWithWrap } from './TFADisablingHistory.styles';
import { TfaDisablingHistoryItemWithId } from './TFADisablingHistory.types';

export const tableDef: DataTableColumnDefinition<TfaDisablingHistoryItemWithId>[] = [
    {
        id: 'date',
        name: messages.dateCol,
        value: (item) => dateTimeHelpers.formatDateTime(item.createdDate),
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'actor',
        name: messages.actorCol,
        value: (item) => item.actorEmail,
        sortable: false,
    },
    {
        id: 'comment',
        name: messages.commentCol,
        cellComponent: CellWithWrap,
        value: (item) => item.comment,
        sortable: false,
    },
    {
        id: 'ticketNumber',
        name: messages.ticketNumberCol,
        value: (item) => item.supportTicketId,
        sortable: false,
    },
];
