import { AtomEffect } from 'recoil';

type ConnectToLocalStorage = <StateType>(storageKey: string) => AtomEffect<StateType>;

export const connectToLocalStorage: ConnectToLocalStorage =
    (storageKey: string) =>
    ({ setSelf, onSet }) => {
        const storageValue = localStorage.getItem(storageKey);

        if (storageValue) {
            setSelf(JSON.parse(storageValue));
        }

        onSet((newValue) => {
            localStorage.setItem(storageKey, JSON.stringify(newValue));
        });
    };
