import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Content = styled.div`
    padding: 30px 40px;
    display: flex;
    flex-flow: column;
`;

export const Description = styled.div`
    ${font(13, '#000')};
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    & > * {
        margin-left: 20px;
    }
`;
