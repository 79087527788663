import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnnouncementInfo, api, QueryKeys } from 'modules/api';

export const useSaveAnnouncementInfo = () => {
    const queryClient = useQueryClient();

    const { isLoading, mutate: saveAnnouncementInfo } = useMutation(
        (data: AnnouncementInfo) => api.internalSupport.saveAnnouncementInfo(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.AnnouncementInfo]);

                api.webApp.clearAnnouncementCache();
            },
        }
    );

    return {
        saveAnnouncementInfo,
        isLoading,
    };
};
