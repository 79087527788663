import { miscHelpers } from '@approvalmax/utils';

import { CaretUpIcon } from '../../../../icons';
import { typedMemo } from '../../Table.helpers';
import { defaultSpacing } from '../../Table.styles';
import type { BaseItem } from '../../Table.types';
import { useStickyShadow, useTableSort } from './ColumnHeader.hooks';
import { Content, iconSize, Root, SortIcon } from './ColumnHeader.styles';
import type { ColumnHeaderProps } from './ColumnHeader.types';

const ColumnHeader = typedMemo(<Item extends BaseItem>(props: ColumnHeaderProps<Item>) => {
    const { children, columnDefinition, onSort, color, stickyHeader, bordered, reorderable, ...otherProps } = props;

    const handleClick = useTableSort(columnDefinition, onSort);

    const { name, sortable, spacing = defaultSpacing } = columnDefinition;

    const { setCellRef, withStickyShadow } = useStickyShadow(columnDefinition.sticky);

    return (
        <Root
            ref={setCellRef}
            title={name}
            $sortable={sortable}
            $color={color}
            $bordered={bordered}
            $spacing={miscHelpers.spacingPropToCss(spacing)}
            $stickyHeader={stickyHeader}
            $sticky={columnDefinition.sticky}
            $withStickyShadow={withStickyShadow}
            $textAlign={columnDefinition.headerTextAlign}
            $verticalAlign={columnDefinition.headerVerticalAlign}
            onClick={handleClick}
            {...otherProps}
        >
            {children ?? (
                <Content>
                    <div>{columnDefinition.name}</div>

                    {columnDefinition.sortable && (
                        <SortIcon $sortOrder={columnDefinition.sortOrder}>
                            <CaretUpIcon size={iconSize} />
                        </SortIcon>
                    )}
                </Content>
            )}
        </Root>
    );
});

export default ColumnHeader;
