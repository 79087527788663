import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.AmaxPayBankAccountsPage', {
    pageTitle: 'Amax Pay Bank Accounts',
    breadcrumbRoot: 'Admin portal Management',
    placeHolder: 'Enter a search query...',
    companyId: 'Company ID',
    accountId: 'Account ID',
    institutionId: 'Institution ID',
    search: 'Search',
    id: 'ID',
    nickname: 'Nickname',
    currency: 'Currency',
    accountNumber: 'Account Number',
    iban: 'IBAN',
});
