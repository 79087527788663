import { DataTable, ScrollableArea } from '@approvalmax/ui';
import styled from 'styled-components';

export const Body = styled(ScrollableArea)`
    flex: 1;
    overflow: hidden;
`;

export const List = styled(DataTable)`
    flex: 1;
    overflow: hidden;
`;
