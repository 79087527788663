import { FC, memo, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import { prepareChildren } from '../../Form.helpers';

/**
 * Use `Form.Part` for nested components, this allows you to avoid using the `Controller` subcomponent for child components.
 */
export const Part: FC<PropsWithChildren> = memo((props) => {
    const { children } = props;

    const form = useFormContext();

    return <>{prepareChildren(children, form)}</>;
});

export default Part;
