import './radioGroupItem.scss';

import { Radiobox } from '@approvalmax/ui/src/old/ui';
import { hooks } from '@approvalmax/utils';
import { FC, PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';
import bemFactory from 'react-bem-factory';

import { RadioGroupEditorContext } from './RadioGroupEditorContext';

interface RadioGroupItemProps extends PropsWithChildren {
    id: any;
    className?: string;
    disabled?: boolean;
}

const RadioGroupItem: FC<RadioGroupItemProps> = (props) => {
    const { className, id, children, disabled: localDisabled } = props;

    const {
        disabled: globalDisabled,
        theme,
        value,
        onCheck,
        onPrev,
        onNext,
        registerItem,
        unregisterItem,
    } = useContext(RadioGroupEditorContext);
    const disabled = globalDisabled || localDisabled;
    const bem = bemFactory.block('form-radio-group-item').themed(theme);
    const qa = bemFactory.qa('form-radio-group-item');

    const radioRef = useRef<HTMLDivElement>(null);

    const onCheckInternal = useCallback(() => {
        if (disabled) {
            return;
        }

        onCheck(id);
        radioRef.current!.focus();
    }, [disabled, id, onCheck]);

    const radioId = hooks.useId();
    const labelId = hooks.useId();

    useEffect(() => {
        registerItem(radioId, id);

        return () => {
            unregisterItem(radioId);
        };
    }, [registerItem, unregisterItem, radioId, id]);

    return (
        <div
            className={bem.add(className)(null, { disabled })}
            data-qa={qa()}
            data-qa-id={id}
            onClick={onCheckInternal}
        >
            <Radiobox
                id={radioId}
                ref={radioRef}
                className={bem('radiobox')}
                checked={value === id}
                disabled={disabled}
                onPrev={onPrev}
                onNext={onNext}
                onCheck={onCheckInternal}
                labelledBy={labelId}
            />

            <label className={bem('content')} id={labelId} htmlFor={radioId}>
                {children}
            </label>
        </div>
    );
};

export default RadioGroupItem;
