import { font, TransparentButton, uLink } from '@approvalmax/ui';
import styled from 'styled-components';

export const ButtonDropdown = styled.div`
    width: 11px;
    height: 11px;
    z-index: 2;
    position: absolute;
    right: -6px;
    top: 15px;
    border-radius: 50%;
    display: flex;
    padding-top: 4px;
    padding-left: 2px;
    cursor: pointer;
    background: hsla(0, 0%, 100%, 0.8);
`;

export const DropdownButton = styled(TransparentButton)`
    position: relative;

    &:hover ${ButtonDropdown} {
        background: #fff;
    }
`;

export const DropdownPanel = styled.div`
    margin: 2px 0;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    width: 300px;
`;

export const Actions = styled.div`
    display: flex;
    flex-flow: column;
    padding: 15px 0 10px 0;

    & > a {
        ${uLink('#000')};
    }
`;

export const MessagePanel = styled.div`
    margin-bottom: 10px;
    padding: 0 30px;
    ${font(13, '#000', 'semibold')};
`;

export const ActionItem = styled.div`
    display: flex;
    align-items: center;
    height: 35px;
    cursor: pointer;

    &:hover {
        background-color: #f3f3f3;
    }

    &:last-of-type {
        margin-top: 10px;
    }
`;

export const ActionItemText = styled.div`
    flex: 1;
    padding: 0 30px;
    height: 100%;
    display: flex;
    align-items: center;
    ${font(13, '#000')};
    ${uLink('#000')};
`;

export const ActionItemTextLogout = styled.div`
    flex: 1;
    padding: 0 30px;
    height: 100%;
    display: flex;
    align-items: center;
    ${font(13, '#a3341a', 'semibold')};
    ${uLink('#000')};

    &:hover {
        ${font(13, '#a3341a', 'semibold')}
    }
`;
