import { Button, Field, TextAreaEditor } from '@approvalmax/ui';
import { Spacing } from '@approvalmax/ui/src/components';
import { Section, toaster } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { useMaintenanceInfo } from 'pages/maintenance/hooks/useMaintenanceInfo';
import { useSaveMaintenanceInfo } from 'pages/maintenance/hooks/useSaveMaintenanceInfo';
import { FC, memo, useCallback, useState } from 'react';

import { messages } from './MaintenancePanel.messages';

const MaintenancePanel: FC = () => {
    const { permissionFlags } = useCurrentAccount();

    const { maintenanceInfo } = useMaintenanceInfo();
    const { saveMaintenanceInfo, isLoading: isSavingMaintenanceInfo } = useSaveMaintenanceInfo();

    const [message, setMessage] = useState(maintenanceInfo?.value);

    const onToggleMaintenanceMode = useCallback(() => {
        const newEnabled = !maintenanceInfo?.isEnabled;

        if (!message && newEnabled) {
            toaster.error(messages.messageIsRequiredMessage);

            return;
        }

        saveMaintenanceInfo({
            isEnabled: newEnabled,
            value: message || '',
        });
    }, [maintenanceInfo, message, saveMaintenanceInfo]);

    if (!permissionFlags?.maintenances.manageMessage) {
        return null;
    }

    return (
        <Section header={messages.maintenanceHeader}>
            <Field title={messages.maintenanceMessage}>
                <TextAreaEditor value={message || ''} onChange={setMessage} />
            </Field>

            <Spacing height={20} />

            <Button execute={onToggleMaintenanceMode} disabled={isSavingMaintenanceInfo} colorTheme='red'>
                {maintenanceInfo?.isEnabled ? messages.stopMaintenanceButton : messages.startMaintenanceButton}
            </Button>
        </Section>
    );
};

export default memo(MaintenancePanel);
