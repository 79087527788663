import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { SortConfig } from 'modules/types';

import { messages } from './BudgetsPage.messages';
import { BudgetPrviewIdentifiable, ColumnId } from './BudgetsPage.types';

export const columnsCreator = (sortConfig?: SortConfig): DataTableColumnDefinition<BudgetPrviewIdentifiable>[] => [
    {
        id: ColumnId.companyId,
        name: messages.columnCompanyId,
        value: (item) => item.companyId,
        sortable: true,
        sortOrder: sortConfig?.columnId === ColumnId.companyId ? sortConfig.sortOrder : undefined,
        padding: DataTablePadding.Left60,
    },
    {
        id: ColumnId.budgetId,
        name: messages.columnBudgetId,
        value: (item) => item.budgetId,
        sortable: true,
        sortOrder: sortConfig?.columnId === ColumnId.budgetId ? sortConfig.sortOrder : undefined,
    },
    {
        id: ColumnId.budgetName,
        name: messages.columnBudgetName,
        value: (item) => item.budgetName,
        sortable: true,
        sortOrder: sortConfig?.columnId === ColumnId.budgetName ? sortConfig.sortOrder : undefined,
    },
];
