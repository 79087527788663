import { AmLogo } from '@approvalmax/ui';
import { PinIcon, UnpinIcon } from '@approvalmax/ui/src/old/icons';
import { FC, memo, useCallback } from 'react';

import { useDrawerContext } from '../../Drawer.context';
import { Head, HeadContent, PinButton, Root } from './Header.styles';

const Header: FC = () => {
    const { isPinned, setIsPinned, onClose: onRequestClose } = useDrawerContext();

    const onToggle = useCallback(() => {
        onRequestClose();
        setIsPinned(!isPinned);
    }, [isPinned, onRequestClose, setIsPinned]);

    return (
        <Root>
            <Head>
                <HeadContent>
                    <AmLogo width={125} height={24} />
                </HeadContent>

                <PinButton execute={onToggle}>{isPinned ? <UnpinIcon /> : <PinIcon />}</PinButton>
            </Head>
        </Root>
    );
};

export default memo(Header);
