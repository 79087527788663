import { Reference } from '@approvalmax/types';
import { SortConfig } from 'modules/types';

export type QueryTypeReference = Reference & {
    companyQueryType: CompanyQueryType;
};

export interface QueryParams {
    companyQuery: string;
    companyQueryType?: string;
}

export enum CompanyQueryType {
    byIdOrName = 'byIdOrName',
    byIntegrationIdOrName = 'byIntegrationIdOrName',
    byEmail = 'byEmail',
}

export interface UseFindCompaniesProps {
    companyQuery?: string;
    companyQueryType: CompanyQueryType;
    filters: ColumnFilterState | null;
    sortConfig?: SortConfig | null;
    isEnabled?: boolean;
}

export enum StatusFilterOption {
    Active = 'Active',
    Partner = 'Partner',
    Retired = 'Retired',
    Trial = 'Trial',
    TrialExpired = 'TrialExpired',
}

export enum IntegrationStatusOption {
    All = 'All',
    Enabled = 'Enabled',
    Disabled = 'Disabled',
}

export interface ColumnFilterState {
    status: {
        [key: string]: boolean;
    };
    companyName: string;
    accountOwnerEmail: string;
    companyIntegrationType: {
        [key: string]: boolean;
    };
    companyIntegrationStatus: IntegrationStatusOption;
}

export type ColumnFilterStateKeys = keyof ColumnFilterState;
