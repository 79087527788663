import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { messages } from '../UndeliveredEmailDetailsPage.messages';

export const useUndeliveredEmailDetails = (email: string) => {
    const history = useHistory();

    const { isInitialLoading, data: undeliveredEmailDetails } = useQuery(
        [QueryKeys.UndeliveredEmails, email, QueryKeys.UndeliveredEmailDetails],
        async () => {
            const entries = await api.internalSupport.listIgnoredEmails({
                emailPart: email,
            });

            const emailEntry = entries.find((e) => e.email === email);

            if (!emailEntry) {
                toaster.error(messages.noEmailError);

                history.push(getPath('root'));

                return;
            }

            const details = await api.internalSupport.getIgnoredEmailDetails(email, {
                reason: emailEntry.reason.toLowerCase(),
            });

            return {
                ...emailEntry,
                rawData: details.rawData,
            };
        },
        {}
    );

    return {
        undeliveredEmailDetails,
        isInitialLoading,
    };
};
