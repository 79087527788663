import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.userSettings.components.TwoFaSetupPopup.components.TwoFaSetupStep1', {
    title: 'Install authenticator app to log in',
    description: `
        When you log in, you’ll need to enter a code generated by an Authenticator app.
        {br}{br}
        1) Download the verification app such as Google Authenticator to your smartphone from App Store (for iPhone) or Google Play (for Android smartphones).
        {br}{br}
        2) Use the app to scan the QR code below or type in the key. If you don’t have a smartphone or need more help, please see <supportlink>support article</supportlink>
    `,
    enterManuallyButton: 'Or enter key manually',
    copyCodeButton: 'Copy to Clipboard',
    nextButton: 'Next',
});
