import { LoadingBar, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, SearchBar, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { dataTableColumns } from './SyncCommandManagementPage.config';
import { useCompanySyncCommandsCount, useNextCompanySyncCommand } from './SyncCommandManagementPage.hooks';
import { messages } from './SyncCommandManagementPage.messages';
import { StyledDataTable } from './SyncCommandManagementPage.styles';

const SyncCommandManagementPage = memo(() => {
    const [searchText, setSearchText] = useState('');
    const [companyId, setCompanyId] = useState<string | null>(null);

    usePermissionsRedirect((permissionFlags) => permissionFlags.admin.manageSyncCommands);

    const {
        data: commandsCount,
        refetch: loadCompanySyncCommandsCount,
        isFetching: isCompanySyncCommandsCountLoading,
    } = useCompanySyncCommandsCount(companyId || '');

    const {
        data: nextSyncCommand,
        error: nextSyncCommandError,
        refetch: loadNextCompanySyncCommand,
        isFetching: isNextCompanySyncCommandLoading,
    } = useNextCompanySyncCommand(companyId || '');

    const onSearch = useCallback(async () => {
        const { data: command, error } = await loadNextCompanySyncCommand();

        if (command && !error) {
            loadCompanySyncCommandsCount();
        }
    }, [loadNextCompanySyncCommand, loadCompanySyncCommandsCount]);

    const onSearchSubmit = useCallback(() => {
        if (searchText === companyId) {
            onSearch();
        } else {
            setCompanyId(searchText);
        }
    }, [searchText, companyId, onSearch]);

    useEffect(() => {
        if (companyId && companyId.length > 0) {
            onSearch();
        }
    }, [companyId, onSearch]);

    const dataTableMeta = useMemo(
        () => ({
            onDeleteSuccess: () => {
                onSearch();
            },
        }),
        [onSearch]
    );

    const isLoading = isCompanySyncCommandsCountLoading || isNextCompanySyncCommandLoading;

    const hasData = nextSyncCommand && !nextSyncCommandError;

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar
                        filters={
                            <SearchBar
                                placeholder={messages.searchPlaceholder}
                                disabled={isLoading}
                                onSearch={onSearchSubmit}
                                value={searchText}
                                onChange={setSearchText}
                                focusOnMount
                            />
                        }
                        actionButtons={null}
                    />

                    {isLoading ? (
                        <div>
                            <LoadingBar />
                        </div>
                    ) : (
                        <>
                            <Box spacing='20 60'>
                                {hasData && typeof commandsCount === 'number' && (
                                    <div>
                                        Commands in queue: <b>{commandsCount}</b>
                                    </div>
                                )}
                            </Box>

                            {hasData && (
                                <StyledDataTable
                                    data={[nextSyncCommand]}
                                    columns={dataTableColumns}
                                    meta={dataTableMeta}
                                />
                            )}
                        </>
                    )}
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

export default SyncCommandManagementPage;
