import { Reference } from '@approvalmax/types';
import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';

import { messages } from './UserDetailsPopup.messages';

export const dataTableColumns: DataTableColumnDefinition<Reference>[] = [
    {
        id: 'name',
        name: messages.nameCol,
        value: (permission) => permission.text,
        sortable: false,
        padding: DataTablePadding.PopupLeft,
    },
];
