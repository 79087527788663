import { notSupportedError } from './notSupportedError';

/**
 * Throws NotSupportedError. The exception should be thrown when an invoked method is not supported.
 * For example: some class doesn't support all the methods of the interface it implements.
 * @example
 * // Inside of implementation of some Stream class
 * seek() {
 *     // Some code here ...
 *     utilsApi.helpers.throwNotSupportedError('The network stream doesn't support `seek`.');
 *     // Some code here ...
 * }
 * @param {String} [message='The operation is not supported'] Error message.
 * */
export const throwNotSupportedError = (message?: string): never => {
    throw notSupportedError(message);
};
