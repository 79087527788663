import { TextButton, usePopupContext } from '@approvalmax/ui';
import { FC, memo, useCallback } from 'react';

import { useDeleteAdminPortalUser } from '../../hooks/useDeleteAdminPortalUser';
import { messages } from './DeleteUserPopup.messages';
import { Actions, Content, Description, Title } from './DeleteUserPopup.styles';
import { DeleteUserPopupProps } from './DeleteUserPopup.types';

const DeleteUserPopup: FC<DeleteUserPopupProps> = (props) => {
    const { user } = props;

    const { isLoading, deleteAdminPortalUser } = useDeleteAdminPortalUser();
    const { onRequestClose } = usePopupContext();

    const onDelete = useCallback(async () => {
        await deleteAdminPortalUser(user.id);

        onRequestClose();
    }, [deleteAdminPortalUser, onRequestClose, user.id]);

    return (
        <Content>
            <Title>{messages.title}</Title>

            <Description>
                {messages.description({
                    email: user.email,
                    b: (chunks) => <b>{chunks}</b>,
                })}
            </Description>

            <Actions>
                <TextButton size='large' execute={onRequestClose} disabled={isLoading}>
                    {messages.cancelButton}
                </TextButton>

                <TextButton size='large' colorTheme='red' execute={onDelete} disabled={isLoading}>
                    {messages.deleteButton}
                </TextButton>
            </Actions>
        </Content>
    );
};

export default memo(DeleteUserPopup);
