import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys, SupportedClient } from 'modules/api';

export const useGetSupportedClients = () => {
    return useQuery([QueryKeys.SupportedClients], () => api.internalSupport.getSupportedClients());
};

export const useEditSupportedClients = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutate: editSupportedClients } = useMutation(
        (data: Omit<SupportedClient, 'maxVersion'>) => api.internalSupport.editSupportedClients(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.SupportedClients]);
            },
        }
    );

    return {
        editSupportedClients,
        isLoading,
    };
};
