import {
    ActionMenu,
    DataTable,
    DataTableColumnDefinition,
    DataTablePadding,
    TextButton,
    TransparentButton,
} from '@approvalmax/ui';
import { TbEllipsisIcon } from '@approvalmax/ui/src/old/icons';
import { AdminPortalAccount } from 'modules/api';

import { messages } from './PermissionsPage.messages';
import { ActionMenuWrapper, CellInner, DisabledTwoFACell, EnabledTwoFACell } from './PermissionsPage.styles';
import { PermissionsPageMeta } from './PermissionsPage.types';

export const dataTableColumns: DataTableColumnDefinition<AdminPortalAccount>[] = [
    {
        id: 'email',
        name: messages.emailCol,
        value: (user) => user.email,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: '2fa',
        name: messages.twoFaCol,
        value: (user) => user.isTFAEnabled,
        sortable: false,
        cellComponent: (props) => {
            const user = props.item as AdminPortalAccount;

            return (
                <DataTable.DefaultCell {...props}>
                    <CellInner>
                        {user.isTFAEnabled ? (
                            <EnabledTwoFACell>{messages.twoFaEnabled}</EnabledTwoFACell>
                        ) : (
                            <DisabledTwoFACell>{messages.twoFaDisabled}</DisabledTwoFACell>
                        )}
                    </CellInner>
                </DataTable.DefaultCell>
            );
        },
    },
    {
        id: 'permissions',
        name: messages.permissionsCol,
        cellComponent: (props) => {
            const { currentAccountPermissionFlags, onDeleteUser, onEditUser, onViewUser } =
                props.meta as PermissionsPageMeta;
            const user = props.item as AdminPortalAccount;

            return (
                <DataTable.DefaultCell {...props}>
                    <CellInner>
                        <div>
                            <TextButton execute={() => onViewUser(user)}>{user.permissions.length}</TextButton>
                        </div>

                        <ActionMenuWrapper>
                            <ActionMenu
                                panelFlow='to-left'
                                button={
                                    <TransparentButton css='padding: 5px 10px;'>
                                        <TbEllipsisIcon width={16} height={4} />
                                    </TransparentButton>
                                }
                            >
                                <ActionMenu.Item
                                    execute={() => onEditUser(user)}
                                    disabled={!currentAccountPermissionFlags.accounts.update}
                                >
                                    {messages.editMenuItem}
                                </ActionMenu.Item>

                                <ActionMenu.Item
                                    execute={() => onDeleteUser(user)}
                                    disabled={!currentAccountPermissionFlags.accounts.delete}
                                >
                                    {messages.deleteMenuItem}
                                </ActionMenu.Item>
                            </ActionMenu>
                        </ActionMenuWrapper>
                    </CellInner>
                </DataTable.DefaultCell>
            );
        },
        sortable: false,
    },
];
