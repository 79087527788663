import { mods } from '@approvalmax/theme';
import styled from 'styled-components';

export const maxContentWidth = 1600;
export const minContentHeight = 600;
export const minContentWidth = 600;

export const Root = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    height: 100%;
    min-height: ${minContentHeight}px;
    min-width: ${minContentWidth}px;
`;

export const HeaderBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    z-index: -1;
    background: #4c8b5d;
`;

export const ContentHeader = styled.div`
    flex: none;
    max-width: ${maxContentWidth}px;
    height: 60px;
    width: 100%;
    padding: 0 30px;

    & > * {
        height: 100%;
    }
`;

export const ContentBody = styled.div`
    max-width: ${maxContentWidth}px;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
`;

export const ContentBodyInner = styled.div<{ fixedWidth: boolean }>`
    padding: 0 30px;
    height: 100%;
    min-width: 100%;
    display: inline-block;

    ${mods('fixedWidth', true)`
        width: 100%;
    `};

    & > * {
        height: 100%;
        width: 100%;
    }
`;
