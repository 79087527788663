import { FocusEventHandler, ForwardedRef, MouseEventHandler, RefObject, useRef, useState } from 'react';

import { hooks } from '../hooks';

export const useCaptureFocus = (ref?: RefObject<HTMLInputElement> | ForwardedRef<HTMLInputElement>) => {
    const [focused, setFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const composedRefs = hooks.useComposedRefs(ref, inputRef);

    const handleParentFocus: FocusEventHandler<HTMLElement> = () => {
        inputRef.current?.focus();
        setFocused(true);
    };

    const stopFocusing = () => {
        inputRef.current?.blur();
        setFocused(false);
    };

    const handleParentBlur: FocusEventHandler<HTMLElement> = () => {
        stopFocusing();
    };

    const handleMouseLeave: MouseEventHandler<HTMLElement> = () => {
        stopFocusing();
    };

    return {
        inputRef: composedRefs,
        parentProps: {
            className: focused ? 'focused' : undefined,
            onFocus: handleParentFocus,
            onBlur: handleParentBlur,
            onMouseLeave: handleMouseLeave,
        },
    };
};
