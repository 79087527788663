import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { RequestDetails } from 'modules/api';

import { messages } from './RequestsPage.messages';

export const dataTableColumns: DataTableColumnDefinition<RequestDetails>[] = [
    {
        id: 'Id',
        name: messages.idCol,
        value: (request) => request.id,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'Name',
        name: messages.nameCol,
        value: (request) => request.name,
        sortable: false,
    },
    {
        id: 'Deleted',
        name: messages.deletedCol,
        value: (request) => (request.isDeleted ? messages.deletedYes : messages.deletedNo),
        sortable: false,
    },
];
