import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useCallback,
    useMemo,
} from 'react';

import { Checkbox } from '../../../Checkbox/Checkbox';
import { Radio } from '../../../Radio/Radio';
import GroupController from '../GroupController/GroupController';
import { GroupContext } from './Group.context';
import { useCheckedValues } from './Group.hooks';
import { ChildrenComponents, GroupProps } from './Group.types';

/**
 * The `ToggleButton.Group` component allows to use `ToggleButton` components as a group.
 * Use the `ToggleButton` component only with `ToggleButton.Group`
 * You can also pass some properties from `ToggleButton` to `ToggleButton.Group` to apply to all radios.
 */
const Group = memo(
    forwardRef<HTMLInputElement, GroupProps>((props, ref) => {
        const { multiple, children, value, onChange: onChangeSrc, ...restProps } = props;

        const { getCheckedStatus, toggleCheckedValue, checkboxValue, radioValue } = useCheckedValues({
            value,
            multiple,
        });

        const onChange = useCallback(
            (value: GroupProps['value'], event: ChangeEvent<HTMLInputElement>) => {
                toggleCheckedValue(value);

                onChangeSrc?.(value, event);
            },
            [onChangeSrc, toggleCheckedValue]
        );

        const contextValue = useMemo(
            () => ({
                contextProps: {
                    multiple,
                    size: restProps.size,
                    color: restProps.color,
                    block: restProps.block,
                    name: restProps.name,
                    getCheckedStatus,
                },
                ref,
            }),
            [getCheckedStatus, multiple, restProps.block, restProps.color, restProps.name, restProps.size, ref]
        );

        return (
            <GroupContext.Provider value={contextValue}>
                {multiple ? (
                    <Checkbox.Group {...restProps} spacing='0' value={checkboxValue} onChange={onChange}>
                        {children}
                    </Checkbox.Group>
                ) : (
                    <Radio.Group {...restProps} spacing='0' value={radioValue} onChange={onChange}>
                        {children}
                    </Radio.Group>
                )}
            </GroupContext.Provider>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<GroupProps & RefAttributes<HTMLInputElement>>> & ChildrenComponents;

Group.displayName = 'Group';
Group.Controller = GroupController;

export default Group;
