import { useTheme } from '@approvalmax/theme';
import { useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';
import useMeasure from 'react-use/lib/useMeasure';

export const useAnimatedHeight = (open: boolean) => {
    const { theme } = useTheme();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [initialHeight, setInitialHeight] = useState(0);
    const [animatedRef, { height }] = useMeasure<HTMLDivElement>();

    useEffect(() => {
        if (isFirstRender && height > 0) {
            setInitialHeight(height);
            setIsFirstRender(false);
        }
    }, [height, isFirstRender]);

    const animatedStyles = useSpring({
        config: {
            duration: +theme.duration.medium,
        },
        from: {
            height: isFirstRender ? initialHeight : height,
            opacity: 1,
            y: 0,
            visibility: 'visible',
        },
        to: {
            height: open ? height : 0,
            opacity: Number(open),
            y: open ? 0 : -12,
            visibility: open ? 'visible' : 'hidden',
        },
        immediate: isFirstRender,
    });

    return {
        animatedRef,
        animatedStyles,
    };
};
