import { Ref, useImperativeHandle } from 'react';

export type BackwardReference<TReturnData = unknown> = Ref<BackwardReferenceData<TReturnData>>;

export interface BackwardReferenceData<TReturnData = unknown> {
    getData: () => TReturnData;
}

export interface UseBackwardReference {
    <TReturnData = unknown>(data: TReturnData, ref: BackwardReference<TReturnData>): void;
}

export const useBackwardReference: UseBackwardReference = (data, ref) => {
    useImperativeHandle(
        ref,
        () => ({
            getData: () => data,
        }),
        [data]
    );
};
