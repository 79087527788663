import { FC } from 'react';

import { Header, Root } from './Section.styles';
import { SectionProps } from './Section.types';

const Section: FC<SectionProps> = (props) => {
    const { header, children } = props;

    return (
        <Root>
            <Header>{header}</Header>

            <div>{children}</div>
        </Root>
    );
};

export default Section;
