import { useMemo } from 'react';

const experimentOnValue = 'on';
const experimentOffValue = 'off';

export const useIsLocaleExperimentOn = (experimentName: string) => {
    return useMemo(() => {
        let isExperimentOn = false;

        const experimentStorageValue = localStorage.getItem(experimentName);

        if (experimentStorageValue) {
            isExperimentOn = experimentStorageValue === experimentOnValue;
        } else {
            isExperimentOn = Math.random() < 0.5;

            localStorage.setItem(experimentName, isExperimentOn ? experimentOnValue : experimentOffValue);
        }

        return isExperimentOn;
    }, [experimentName]);
};
