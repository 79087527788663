import { font, Popup, uTextEllipsis } from '@approvalmax/ui';
import styled from 'styled-components';

export const Content = styled(Popup.EmptyContent)`
    padding: 30px 60px;
    display: flex;
    flex-flow: column;
`;

export const Title = styled.div`
    ${font(20, '#333', 'semibold')}
    ${uTextEllipsis}
`;

export const Description = styled.div`
    ${font(14, '#333')}
    margin-top: 16px;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    & > * {
        margin-left: 16px;
    }
`;
