import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.Drawer', {
    sectionCustomerSuccess: 'Customer Success',
    linkDashboard: 'Dashboard',
    linkUsers: 'Users',
    linkCompanies: 'Companies',
    linkRequests: 'Requests',
    linkReports: 'Reports',
    linkFeatureFlags: 'Feature Flags',
    linkUndeliveredEmails: 'Undelivered Emails',
    linkMaintenance: 'Maintenance & Announcements',
    sectionInfrastructure: 'Infrastructure',
    linkDatabase: 'Database Access (SQL Execution)',
    linkStorageQueues: 'Storage queues',
    sectionManagement: 'Admin Portal Management',
    linkPermissions: 'Users & Permissions',
    linkAuditLog: 'Audit Log',
    linkMyAccountPermissions: 'My account permissions',
    linkEnforcement2fa: '2FA Enforcement',
    linkMobileMinVersion: 'Mobile minVersion Management',
    linkBudgets: 'Budgets',
    linkSyncCommandManagement: 'Sync Command Management',
    sectionShardingManagement: 'Sharding Management',
    linkFindCompanyShard: 'Find Company Shard',
    linkManageTenants: 'Manage Tenants',
    sectionAmaxPay: 'Amax Pay',
    amaxPayBankAccounts: 'Bank accounts',
    amaxPayPayments: 'Payments',
    amaxPayPaymentHistory: 'Payment history',
    amaxPayConsents: 'Consents',
});
