import { GlobalHotKeys, LoadingSpinner } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { FileType } from 'modules/types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import ImageViewer from './components/ImageViewer/ImageViewer';
import PdfViewer from './components/PdfViewer/PdfViewer';
import UnknownDocumentViewer from './components/UnknownDocumentViewer/UnknownDocumentViewer';
import { ContentLoading, Root } from './DocumentPreviewPanel.styles';
import { DocumentPreviewPanelProps } from './DocumentPreviewPanel.types';

const DocumentPreviewPanel = memo<DocumentPreviewPanelProps>((props) => {
    const { attachment, onRequestBack, onRequestClose, onRequestNext } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [loadFailed, setLoadFailed] = useState(false);

    const setInitialState = useCallback(() => {
        setLoadFailed(false);

        setIsLoading(attachment.fileType !== FileType.Other);
    }, [attachment]);

    const handleMouseDownOnResizer = useCallback(() => {
        const pdfOverlayElement = document.querySelector<HTMLDivElement>('[data-type="Pdf-overlay"]');

        if (pdfOverlayElement) {
            pdfOverlayElement.style.visibility = 'visible';
        }
    }, []);

    const handleMouseUp = useCallback(() => {
        const pdfOverlayElement = document.querySelector<HTMLDivElement>('[data-type="Pdf-overlay"]');

        if (pdfOverlayElement?.style.visibility === 'visible') {
            pdfOverlayElement.style.visibility = 'hidden';
        }
    }, []);

    useEffect(() => {
        /**
         * If there is a PDF viewer inside SplitView components, it captures all events above PDF viewer, disrupting
         * the SplitView resizer behavior. To address this issue, we implement an overlay div during resizing.
         * When not actively resizing, we hide this overlay to enable user interaction with the PDF viewer.
         */
        const resizerElement = document.querySelector('[data-type="Resizer"]');

        if (resizerElement) {
            resizerElement.addEventListener('mousedown', handleMouseDownOnResizer);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            const resizerElement = document.querySelector('[data-type="Resizer"]');

            resizerElement?.removeEventListener('mousedown', handleMouseDownOnResizer);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseUp, handleMouseDownOnResizer]);

    useEffect(() => {
        setInitialState();
    }, [attachment, setInitialState]);

    const onDocumentLoaded = useCallback(() => {
        setIsLoading(false);
    }, []);

    const onLoadError = useCallback(() => {
        setIsLoading(false);

        setLoadFailed(true);
    }, []);

    const handlers = useMemo(() => {
        const requestGoBack = () => {
            if (onRequestBack) {
                onRequestBack();
            }
        };

        const requestGoNext = () => {
            if (onRequestNext) {
                onRequestNext();
            }
        };

        const requestClose = () => {
            if (onRequestClose) {
                onRequestClose();
            }
        };

        return {
            left: requestGoBack,
            right: requestGoNext,
            escape: requestClose,
        };
    }, [onRequestBack, onRequestClose, onRequestNext]);

    let content;

    if (loadFailed) {
        content = <UnknownDocumentViewer attachment={attachment} hasError />;
    } else {
        switch (attachment.fileType) {
            case FileType.Image:
                content = (
                    <ImageViewer
                        attachment={attachment}
                        isLoading={isLoading}
                        onImageLoaded={onDocumentLoaded}
                        onLoadError={onLoadError}
                    />
                );
                break;

            case FileType.Pdf:
                content = <PdfViewer attachment={attachment} />;
                break;

            case FileType.Other:
                content = <UnknownDocumentViewer attachment={attachment} hasError={false} />;
                break;

            default:
                throw errorHelpers.invalidOperationError();
        }
    }

    return (
        <GlobalHotKeys handlers={handlers}>
            <Root>
                {content}

                {isLoading && (
                    <ContentLoading>
                        <LoadingSpinner />
                    </ContentLoading>
                )}
            </Root>
        </GlobalHotKeys>
    );
});

export default DocumentPreviewPanel;
