import { Mods, mods } from '@approvalmax/theme';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-flow: column;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

export const ViewerDiv = styled.div<Mods<'hide'>>`
    ${mods.hide.true`
        display: none;
    `};

    .json-formatter-row .json-formatter-string {
        white-space: normal;
        word-break: break-all;
    }
`;
