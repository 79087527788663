import { FC, memo } from 'react';

import { useDrawerContext } from '../../Drawer.context';
import { Inner, Portal } from '..';
import { DrawerContainer } from './Sliding.styles';
import { SlidingProps } from './Sliding.types';

export const Sliding: FC<SlidingProps> = (props) => {
    const { drawerWidth = 300 } = props;

    const { isOpen, onClose, isPinned } = useDrawerContext();

    return (
        <Portal isOpen={isOpen} onClose={onClose} isPinned={isPinned}>
            <DrawerContainer drawerWidth={drawerWidth}>
                <Inner />
            </DrawerContainer>
        </Portal>
    );
};

export default memo(Sliding);
