import { Box, Spacing } from '@approvalmax/ui/src/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { FC, memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { useDrawerContext } from '../../Drawer.context';
import { Header } from '..';
import { messages } from './Inner.messages';
import { NavigationItem, NavigationSection, Root } from './Inner.styles';

const Inner: FC = () => {
    const { permissionFlags } = useCurrentAccount();
    const { onClose: onNavigate } = useDrawerContext();

    const pathname = useLocation().pathname;

    return (
        <Root>
            <Header />

            {permissionFlags ? (
                <>
                    <NavigationItem active={pathname.startsWith(getPath('dashboard'))}>
                        <Link to={getPath('dashboard')} onClick={onNavigate}>
                            {messages.linkDashboard}
                        </Link>
                    </NavigationItem>

                    {(permissionFlags.companies.any ||
                        permissionFlags.users.any ||
                        permissionFlags.ignoredEmails.any ||
                        permissionFlags.requests.any ||
                        permissionFlags.reports.read ||
                        permissionFlags.maintenances.any ||
                        permissionFlags.announcements.any ||
                        permissionFlags.admin.any) && (
                        <>
                            <Spacing height={16} />

                            <NavigationSection>{messages.sectionCustomerSuccess}</NavigationSection>
                        </>
                    )}

                    {permissionFlags.users.any && (
                        <NavigationItem active={pathname.startsWith(getPath('users'))}>
                            <Link to={getPath('users')} onClick={onNavigate}>
                                {messages.linkUsers}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.companies.any && (
                        <NavigationItem active={pathname.startsWith(getPath('companies'))}>
                            <Link to={getPath('companies')} onClick={onNavigate}>
                                {messages.linkCompanies}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.requests.any && (
                        <NavigationItem active={pathname.startsWith(getPath('requests'))}>
                            <Link to={getPath('requests')} onClick={onNavigate}>
                                {messages.linkRequests}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.budgets?.readBudgetsId && (
                        <NavigationItem active={pathname.startsWith(getPath('budgets'))}>
                            <Link to={getPath('budgets')} onClick={onNavigate}>
                                {messages.linkBudgets}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.reports.read && (
                        <NavigationItem active={pathname.startsWith(getPath('reports'))}>
                            <Link to={getPath('reports')} onClick={onNavigate}>
                                {messages.linkReports}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.admin.manageBetaFeatures && permissionFlags.admin.readBetaFeatures && (
                        <NavigationItem active={pathname.startsWith(getPath('featureFlags'))}>
                            <Link to={getPath('featureFlags')} onClick={onNavigate}>
                                {messages.linkFeatureFlags}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.ignoredEmails.any && (
                        <NavigationItem active={pathname.startsWith(getPath('undeliveredEmails'))}>
                            <Link to={getPath('undeliveredEmails')} onClick={onNavigate}>
                                {messages.linkUndeliveredEmails}
                            </Link>
                        </NavigationItem>
                    )}

                    {(permissionFlags.maintenances.any || permissionFlags.announcements.any) && (
                        <NavigationItem active={pathname.startsWith(getPath('maintenance'))}>
                            <Link to={getPath('maintenance')} onClick={onNavigate}>
                                {messages.linkMaintenance}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.admin.manageIntegrationConfigurations && (
                        <NavigationItem active={pathname.startsWith(getPath('enforcement2fa'))}>
                            <Link to={getPath('enforcement2fa')} onClick={onNavigate}>
                                {messages.linkEnforcement2fa}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.admin.manageMobileMinVersion && (
                        <NavigationItem active={pathname.startsWith(getPath('mobileMinVersion'))}>
                            <Link to={getPath('mobileMinVersion')} onClick={onNavigate}>
                                {messages.linkMobileMinVersion}
                            </Link>
                        </NavigationItem>
                    )}

                    {(permissionFlags.databases.any ||
                        permissionFlags.storageQueues.read ||
                        permissionFlags.admin.manageSyncCommands) && (
                        <>
                            <Spacing height={16} />

                            <NavigationSection>{messages.sectionInfrastructure}</NavigationSection>
                        </>
                    )}

                    {permissionFlags.databases.any && (
                        <NavigationItem active={pathname.startsWith(getPath('databaseAccess'))}>
                            <Link to={getPath('databaseAccess')} onClick={onNavigate}>
                                {messages.linkDatabase}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.storageQueues.read && (
                        <NavigationItem active={pathname.startsWith(getPath('storageQueues'))}>
                            <Link to={getPath('storageQueues')} onClick={onNavigate}>
                                {messages.linkStorageQueues}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.admin.manageSyncCommands && (
                        <NavigationItem active={pathname.startsWith(getPath('syncCommandManagement'))}>
                            <Link to={getPath('syncCommandManagement')} onClick={onNavigate}>
                                {messages.linkSyncCommandManagement}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.sharding.any && (
                        <>
                            <Spacing height={16} />

                            <NavigationSection>{messages.sectionShardingManagement}</NavigationSection>
                        </>
                    )}

                    {permissionFlags.sharding.findCompanyShard && (
                        <NavigationItem active={pathname.startsWith(getPath('findCompanyShard'))}>
                            <Link to={getPath('findCompanyShard')} onClick={onNavigate}>
                                {messages.linkFindCompanyShard}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.sharding.manageTenants && (
                        <NavigationItem active={pathname.startsWith(getPath('manageTenants'))}>
                            <Link to={getPath('manageTenants')} onClick={onNavigate}>
                                {messages.linkManageTenants}
                            </Link>
                        </NavigationItem>
                    )}

                    {permissionFlags.accounts.any && (
                        <>
                            <Spacing height={16} />

                            <NavigationSection>{messages.sectionManagement}</NavigationSection>

                            <NavigationItem active={pathname.startsWith(getPath('permissions'))}>
                                <Link to={getPath('permissions')} onClick={onNavigate}>
                                    {messages.linkPermissions}
                                </Link>
                            </NavigationItem>

                            <NavigationItem active={pathname.startsWith(getPath('myAccountPermissions'))}>
                                <Link to={getPath('myAccountPermissions')} onClick={onNavigate}>
                                    {messages.linkMyAccountPermissions}
                                </Link>
                            </NavigationItem>

                            <NavigationItem active={pathname.startsWith(getPath('auditLog'))}>
                                <Link to={getPath('auditLog')} onClick={onNavigate}>
                                    {messages.linkAuditLog}
                                </Link>
                            </NavigationItem>
                        </>
                    )}

                    {(permissionFlags.amaxPay.bankAccountsView ||
                        permissionFlags.amaxPay.consentsView ||
                        permissionFlags.amaxPay.consentsView) && (
                        <>
                            <Spacing height={16} />

                            <NavigationSection>{messages.sectionAmaxPay}</NavigationSection>

                            {permissionFlags.amaxPay.bankAccountsView && (
                                <NavigationItem active={pathname.startsWith(getPath('amaxPayBankAccounts'))}>
                                    <Link to={getPath('amaxPayBankAccounts')} onClick={onNavigate}>
                                        {messages.amaxPayBankAccounts}
                                    </Link>
                                </NavigationItem>
                            )}

                            {permissionFlags.amaxPay.paymentsView && (
                                <NavigationItem active={pathname.startsWith(getPath('amaxPayPayments'))}>
                                    <Link to={getPath('amaxPayPayments')} onClick={onNavigate}>
                                        {messages.amaxPayPayments}
                                    </Link>
                                </NavigationItem>
                            )}

                            {permissionFlags.amaxPay.consentsView && (
                                <NavigationItem active={pathname.startsWith(getPath('amaxPayConsents'))}>
                                    <Link to={getPath('amaxPayConsents')} onClick={onNavigate}>
                                        {messages.amaxPayConsents}
                                    </Link>
                                </NavigationItem>
                            )}
                        </>
                    )}
                </>
            ) : (
                <Box spacing='0 24 0 20'>
                    <Skeleton count={10} height={40} />
                </Box>
            )}
        </Root>
    );
};

Inner.displayName = 'Inner';

export default memo(Inner);
