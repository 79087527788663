import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminPortalAccount, api, QueryKeys } from 'modules/api';

export const useCreateAdminPortalUser = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutateAsync: createAdminPortalUser } = useMutation(
        (user: Omit<AdminPortalAccount, 'id'>) => api.portalManagement.createAccount(user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.AdminPortalAccounts]);
            },
        }
    );

    return {
        createAdminPortalUser,
        isLoading,
    };
};
