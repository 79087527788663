import { domHelpers } from '@approvalmax/utils';
import { useMutation } from '@tanstack/react-query';
import { api } from 'modules/api';
import moment from 'moment';

export const useDownloadCSV = () => {
    const {
        isLoading,
        mutate: downloadCSV,
        data: queryResult,
    } = useMutation(async (options: { sqlQueryText: string; twoFaCode: string }) => {
        const data = await api.infra.downloadSqlQueryAsCSV({ sqlQueryText: options.sqlQueryText }, options.twoFaCode);

        const dateTime = moment().format('YYYYMMDD-HHmmss');

        domHelpers.downloadBlob(data, {
            type: 'text/csv',
            extension: 'csv',
            fileName: `export-${dateTime}`,
        });
    });

    return {
        downloadCSV,
        queryResult,
        isLoading,
    };
};
