/**
 * Developer: Stepan Burguchev
 * Date: 5/29/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import isFunction from 'lodash/isFunction';
import React, { Component } from 'react';

import Dropdown from './Dropdown';
import { AutoDropdownProps } from './types';

interface OwnState {
    isOpen: boolean;
}

class AutoDropdown extends Component<AutoDropdownProps, OwnState> {
    public static defaultProps = Dropdown.defaultProps;

    public state = {
        isOpen: false,
    };

    public render() {
        const { onOpen, ...dropdownProps } = this.props;

        const child = React.Children.only(this.props.button) as React.ReactElement<any>;
        const button = React.cloneElement(child, {
            execute: this._toggle,
            onBlur: this._close,
        });

        const children = isFunction(this.props.children)
            ? this.props.children({
                  onRequestClose: this._close,
              })
            : this.props.children;

        return (
            <Dropdown {...dropdownProps} button={button} isOpen={this.state.isOpen} onRequestClose={this._close}>
                {children}
            </Dropdown>
        );
    }

    private _toggle = () => {
        this.setState(
            (state) => ({ isOpen: !state.isOpen }),
            () => this.state.isOpen && this.props.onOpen?.()
        );
    };

    private _close = () => {
        this.setState({
            isOpen: false,
        });
    };
}

export default AutoDropdown;
