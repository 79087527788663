import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { dateTimeHelpers } from '@approvalmax/utils';
import { IgnoredEmail } from 'modules/api';

import { messages } from './UndeliveredEmailsPage.messages';
import { EmailSearchType } from './UndeliveredEmailsPage.types';

export const dataTableColumns: DataTableColumnDefinition<IgnoredEmail>[] = [
    {
        id: 'Email',
        name: messages.emailCol,
        value: (item) => item.email,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'Reason',
        name: messages.reasonCol,
        value: (item) => item.reason,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'Date',
        name: messages.dateCol,
        value: (item) => dateTimeHelpers.formatDateTime(item.ignoredAt),
        sortable: false,
    },
];

export const emailFilterValues = [
    {
        id: 'startsWithSearch',
        emailQueryType: EmailSearchType.StartsWithSearch,
        text: 'StartsWith search',
    },
    {
        id: 'containsSearch',
        emailQueryType: EmailSearchType.ContainsSearch,
        text: 'Contains search',
    },
];
