import { Reference } from '@approvalmax/types';
import { Button, DropdownEditor, Field, LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Spacing } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, PlainDataProvider, Toolbar } from 'modules/components';
import { useAvailableCompanyBetaFeatures } from 'modules/customerSupport/hooks/useAvailableCompanyBetaFeatures';
import { useAvailableUserBetaFeatures } from 'modules/customerSupport/hooks/useAvailableUserBetaFeatures';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback, useEffect, useState } from 'react';

import { getFeatureOptions } from './FeatureFlagsPage.helpers';
import { messages } from './FeatureFlagsPage.messages';
import { useSaveCompanyBetaFeatures } from './hooks/useSaveCompanyBetaFeatures';
import { useSaveUserBetaFeatures } from './hooks/useSaveUserBetaFeatures';

const validBetaFeatureRegex = /^[a-zA-Z][a-zA-Z0-9]+$/;

const FeatureFlagsPage: FC = () => {
    const { availableCompanyBetaFeatures } = useAvailableCompanyBetaFeatures();
    const { availableUserBetaFeatures } = useAvailableUserBetaFeatures();
    const { isLoading: isSavingUserFeatures, saveUserBetaFeatures } = useSaveUserBetaFeatures();
    const { isLoading: isSavingCompanyFeatures, saveCompanyBetaFeatures } = useSaveCompanyBetaFeatures();

    const isLoading = isSavingUserFeatures || isSavingCompanyFeatures;

    const [allUserBetaFeatureOptions, setAllUserBetaFeatureOptions] = useState<Reference[]>([]);
    const [selectedUserBetaFeatures, setSelectedUserBetaFeatures] = useState<Reference[]>([]);

    const [allCompanyBetaFeatureOptions, setAllCompanyBetaFeatureOptions] = useState<Reference[]>([]);
    const [selectedCompanyBetaFeatures, setSelectedCompanyBetaFeatures] = useState<Reference[]>([]);

    const { permissionFlags } = useCurrentAccount();

    usePermissionsRedirect(
        (permissionFlags) => permissionFlags.admin.manageBetaFeatures && permissionFlags.admin.readBetaFeatures
    );

    useEffect(() => {
        if (availableUserBetaFeatures && availableCompanyBetaFeatures) {
            const userOptions = getFeatureOptions(availableUserBetaFeatures);
            const companyOptions = getFeatureOptions(availableCompanyBetaFeatures);

            setAllUserBetaFeatureOptions(userOptions);
            setSelectedUserBetaFeatures(userOptions);

            setAllCompanyBetaFeatureOptions(companyOptions);
            setSelectedCompanyBetaFeatures(companyOptions);
        }
    }, [availableUserBetaFeatures, availableCompanyBetaFeatures]);

    const onSaveUserBetaFeatures = useCallback(() => {
        saveUserBetaFeatures({ betaFeatures: selectedUserBetaFeatures.map((x) => x.id) });
    }, [saveUserBetaFeatures, selectedUserBetaFeatures]);

    const onSaveCompanyBetaFeatures = useCallback(() => {
        saveCompanyBetaFeatures({ betaFeatures: selectedCompanyBetaFeatures.map((x) => x.id) });
    }, [saveCompanyBetaFeatures, selectedCompanyBetaFeatures]);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                {!availableUserBetaFeatures || !availableCompanyBetaFeatures ? (
                    <div>
                        <LoadingBar />
                    </div>
                ) : (
                    <ScrollableAreaWrapper>
                        <Toolbar title={messages.pageTitle} />

                        <ScrollableArea css='flex: 1; overflow: hidden;'>
                            <Box spacing='20 60'>
                                <Field title={messages.userBetaFeaturesTitle}>
                                    <PlainDataProvider items={allUserBetaFeatureOptions}>
                                        <DropdownEditor
                                            value={selectedUserBetaFeatures}
                                            onChange={(v: Reference[]) => setSelectedUserBetaFeatures(v)}
                                            disabled={
                                                isLoading ||
                                                !permissionFlags ||
                                                !permissionFlags.admin.manageBetaFeatures
                                            }
                                            multiple
                                            placeholder={messages.placeholderUserBetaFeatures}
                                            canCreateItemFn={(text) =>
                                                !!text &&
                                                validBetaFeatureRegex.test(text) &&
                                                !allUserBetaFeatureOptions.some((o) => o.id === text)
                                            }
                                            createItemDisplayTextFn={(name) =>
                                                messages.createUserBetaFeatureText({ name })
                                            }
                                            onCreateItem={async (text): Promise<Reference> => {
                                                const newFeature = {
                                                    id: text!,
                                                    text: text!,
                                                };

                                                setAllUserBetaFeatureOptions([
                                                    ...allUserBetaFeatureOptions,
                                                    newFeature,
                                                ]);

                                                return newFeature;
                                            }}
                                        />
                                    </PlainDataProvider>
                                </Field>

                                <Spacing height={12} />

                                <Button execute={onSaveUserBetaFeatures} disabled={isLoading}>
                                    {messages.save}
                                </Button>

                                <Spacing height={32} />

                                <Field title={messages.companyBetaFeaturesTitle}>
                                    <PlainDataProvider items={allCompanyBetaFeatureOptions}>
                                        <DropdownEditor
                                            value={selectedCompanyBetaFeatures}
                                            onChange={(v: Reference[]) => setSelectedCompanyBetaFeatures(v)}
                                            multiple
                                            disabled={
                                                isLoading ||
                                                !permissionFlags ||
                                                !permissionFlags.admin.manageBetaFeatures
                                            }
                                            placeholder={messages.placeholderCompanyBetaFeatures}
                                            canCreateItemFn={(text) =>
                                                !!text &&
                                                validBetaFeatureRegex.test(text) &&
                                                !allCompanyBetaFeatureOptions.some((o) => o.id === text)
                                            }
                                            createItemDisplayTextFn={(name) =>
                                                messages.createCompanyBetaFeatureText({ name })
                                            }
                                            onCreateItem={async (text): Promise<Reference> => {
                                                const newFeature = {
                                                    id: text!,
                                                    text: text!,
                                                };

                                                setAllCompanyBetaFeatureOptions([
                                                    ...allCompanyBetaFeatureOptions,
                                                    newFeature,
                                                ]);

                                                return newFeature;
                                            }}
                                        />
                                    </PlainDataProvider>
                                </Field>

                                <Spacing height={12} />

                                <Button execute={onSaveCompanyBetaFeatures} disabled={isLoading}>
                                    {messages.save}
                                </Button>
                            </Box>
                        </ScrollableArea>
                    </ScrollableAreaWrapper>
                )}
            </Box>
        </PageLayout>
    );
};

export default memo(FeatureFlagsPage);
