import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useAnnouncementInfo = () => {
    const { isInitialLoading, data: announcementInfo } = useQuery(
        [QueryKeys.AnnouncementInfo],
        () => api.internalSupport.getAnnouncementInfo(),
        {}
    );

    return {
        announcementInfo,
        isInitialLoading,
    };
};
