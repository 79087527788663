export enum NetSuiteCacheAction {
    sync,
    delete,
}

export interface NetSuiteSyncAndDeleteCachePopupProps {
    companyId: string;
    onClose: () => void;
    action: NetSuiteCacheAction;
}
