import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import { Allotment } from 'allotment';
import styled from 'styled-components';

import { SplitViewProps } from './SplitView.types';

export const Root = styled.div<StyledMods<Pick<SplitViewProps, 'pinned'> & Mods<'open'> & { fullscreen: boolean }>>`
    height: 100%;

    ${mods.pinned.true`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${theme.color.white100};
        z-index: 10000;
    `};

    ${mods.open.false`
        && .sash {
            display: none;
        }

        && .split-view-view {
            position: relative;
            overflow: initial;
        }
    `}

    ${mods('fullscreen', true)`
        && .sash {
            display: none;
        }
    `}
`;

export const Container = styled(Allotment)`
    & .sash {
        --sash-hover-size: 1px;

        &::before {
            background: ${theme.color.midnight50};
            box-shadow: 0 0 0 5px transparent;
            transition: all 0.5s;
            transition-delay: 0.05s;
        }

        &:hover::before {
            background: ${theme.color.midnight50};
            box-shadow: 0 0 0 5px ${theme.color.blue10};
        }
    }
`;
