import { DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useCallback } from 'react';

import { BaseItem, TableProps } from './Table.types';

export const useTableReorder = <Item extends BaseItem>(
    params: Pick<TableProps<Item>, 'reorderable' | 'columns' | 'data' | 'onReorderColumns' | 'onReorderRows'>
) => {
    const { reorderable, columns, data, onReorderColumns, onReorderRows } = params;

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
    );

    const handleReorderColumn = useCallback(
        (active: DragEndEvent['active'], over: DragEndEvent['over']) => {
            if (!onReorderColumns) {
                return;
            }

            if (over && active.id !== over?.id) {
                const indexPrev = columns.findIndex((col) => col.id === active.id);
                const indexNext = columns.findIndex((col) => col.id === over.id);

                const columnsReordered = arrayMove(columns, indexPrev, indexNext);

                onReorderColumns(columnsReordered, indexPrev, indexNext, columns);
            }
        },
        [columns, onReorderColumns]
    );

    const handleReorderRow = useCallback(
        (active: DragEndEvent['active'], over: DragEndEvent['over']) => {
            if (!onReorderRows) {
                return;
            }

            if (over && active.id !== over?.id) {
                const indexPrev = data.findIndex((col) => col.id === active.id);
                const indexNext = data.findIndex((col) => col.id === over.id);

                const dataReordered = arrayMove(data, indexPrev, indexNext);

                onReorderRows(dataReordered, indexPrev, indexNext, data);
            }
        },
        [data, onReorderRows]
    );

    const handleReordered = useCallback(
        (event: DragEndEvent) => {
            if (!reorderable) {
                return;
            }

            const { active, over } = event;

            switch (active.data.current?.type) {
                case 'column':
                    handleReorderColumn(active, over);
                    break;

                case 'row':
                    handleReorderRow(active, over);
                    break;
            }
        },
        [handleReorderColumn, handleReorderRow, reorderable]
    );

    return {
        sensors,
        handleReordered,
    };
};
