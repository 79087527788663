import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { User } from 'modules/api';

import { messages } from './MyAccountPermissionsPage.messages';

export const dataTableColumns: DataTableColumnDefinition<User>[] = [
    {
        id: 'FirstName',
        name: messages.firstNameCol,
        value: (user) => user.firstName,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'LastName',
        name: messages.lastNameCol,
        value: (user) => user.lastName,
        sortable: false,
    },
    {
        id: 'Email',
        name: messages.emailCol,
        value: (user) => user.email,
        sortable: false,
    },
    {
        id: 'DatabaseId',
        name: messages.idCol,
        value: (user) => user.id,
        sortable: false,
    },
];
