import { BudgetValue } from 'modules/types';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetBudgetDetails } from '../budgets/hooks/useGetBudgetDetails/useGetBudgetDetails';
import { useGetBudgetValues } from '../budgets/hooks/useGetBudgetValues/useGetBudgetValues';
import { BudgetDetailsParams, BudgetValueIdentifiable } from './BudgetDetailsPage.types';

export const useBudgetDetails = () => {
    const { companyId, budgetId } = useParams<BudgetDetailsParams>();

    const { data: budget = null, isLoading: isBudgetLoading } = useGetBudgetDetails(companyId, budgetId);

    return {
        budget,
        isBudgetLoading,
    };
};

export const useBudgetValues = () => {
    const { companyId, budgetId } = useParams<BudgetDetailsParams>();

    const [filter, setFilter] = useState<string | null>(null);
    const [year, month] = filter ? filter.split('-') : [];

    const { data, refetch, remove, isFetching: isValuesLoading } = useGetBudgetValues(companyId, budgetId, year, month);

    const onFilterChange = useCallback(
        async (date: string | null) => {
            if (date) {
                await setFilter(date);
                await refetch();
            } else {
                await remove();
                await setFilter(date);
            }
        },
        [refetch, remove]
    );

    return {
        filter,
        onFilterChange,
        isValuesLoading,
        values: data?.map(convertToBudgetValueIdentifiable) || [],
    };
};

const convertToBudgetValueIdentifiable = (value: BudgetValue): BudgetValueIdentifiable => {
    const id = (Math.floor(Math.random() * 10000) + 1).toString();

    return { ...value, id };
};
