export const theme = {
    fontFamily: {
        primary:
            "font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', " +
            "'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
        mono: "font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;",
    },
    fontWeight: {
        standard: '400',
        semibold: '600',
        bold: '700',
    },
};
