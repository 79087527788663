import { ComponentProps, ComponentType, memo } from 'react';

/**
 * Then a component use generics it is currently impossible to drill generics through
 * memo.
 *
 * @credits: https://stackoverflow.com/a/77850237/7252759
 */
export const genericMemo: <P extends object, Component extends ComponentType<P>>(
    component: Component,
    propsAreEqual?: (
        prevProps: Readonly<ComponentProps<Component>>,
        nextProps: Readonly<ComponentProps<Component>>
    ) => boolean
) => Component & { displayName?: string } = memo;
