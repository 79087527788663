import { componentHelpers, miscHelpers } from '@approvalmax/utils';
import { forwardRef } from 'react';

import { Root } from './Grid.styles';
import { GridProps } from './Grid.types';

export const Grid = componentHelpers.genericMemo(
    forwardRef<HTMLDivElement, GridProps>((props, ref) => {
        const {
            children,
            gridArea,
            gridAutoColumns,
            gridAutoFlow,
            gridAutoRows,
            gridColumn,
            gridColumnEnd,
            gridColumnStart,
            gridRow,
            gridRowEnd,
            gridRowStart,
            gridTemplate,
            gridTemplateAreas,
            gridTemplateColumns,
            gridTemplateRows,
            columnGap,
            rowGap,
            gap,
            alignContent,
            alignItems,
            justifyContent,
            justifyItems,
            width,
            height,
            maxWidth,
            maxHeight,
            minHeight,
            overflowX,
            overflowY,
            inline,
            padding,
            ...restProps
        } = props;

        return (
            <Root
                {...restProps}
                ref={ref}
                $gridArea={gridArea}
                $gridAutoColumns={gridAutoColumns}
                $gridAutoFlow={gridAutoFlow}
                $gridAutoRows={gridAutoRows}
                $gridColumn={gridColumn}
                $gridColumnEnd={gridColumnEnd}
                $gridColumnStart={gridColumnStart}
                $gridRow={gridRow}
                $gridRowEnd={gridRowEnd}
                $gridRowStart={gridRowStart}
                $gridTemplate={gridTemplate}
                $gridTemplateAreas={gridTemplateAreas}
                $gridTemplateColumns={gridTemplateColumns}
                $gridTemplateRows={gridTemplateRows}
                $columnGap={columnGap}
                $rowGap={rowGap}
                $gap={gap}
                $alignContent={alignContent}
                $alignItems={alignItems}
                $justifyContent={justifyContent}
                $justifyItems={justifyItems}
                $width={width}
                $height={height}
                $maxWidth={maxWidth}
                $maxHeight={maxHeight}
                $minHeight={minHeight}
                $overflowX={overflowX}
                $overflowY={overflowY}
                $inline={inline}
                $padding={miscHelpers.spacingPropToCss(padding)}
            >
                {children}
            </Root>
        );
    })
);

Grid.displayName = 'Grid';
