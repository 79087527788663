import { IntegrationConfiguration } from 'modules/api';
import { toaster } from 'modules/components';
import { EnforcementTfaType, IntegrationType } from 'modules/types';
import { useCallback, useEffect, useState } from 'react';

import { messages } from './EnforcementTfaPage.messages';
import { EnforcementTfaState } from './EnforcementTfaPage.types';
import { useGetIntegrationConfigurations, useSetIntegrationConfigurations } from './hooks';

export const useEnforcementTfaPageData = () => {
    const { data, isInitialLoading, isFetchedAfterMount, isSuccess } = useGetConfigurations();
    const { mutate: updateConfiguration } = useSetConfigurations();

    const [values, setValues] = useState<EnforcementTfaState>({});

    useEffect(() => {
        if (isFetchedAfterMount && isSuccess) {
            setValues(data);
        }
    }, [data, isFetchedAfterMount, isSuccess]);

    const onChange = useCallback((glOption: IntegrationType, value: any) => {
        setValues((prevState) => ({ ...prevState, [glOption]: value }));
    }, []);

    const onSubmit = useCallback(() => {
        updateConfiguration({
            integrationConfigurations: convertConfigurationsForSave(values),
        });
    }, [updateConfiguration, values]);

    return {
        values,
        onChange,
        onSubmit,
        isLoading: isInitialLoading,
    };
};

const useGetConfigurations = () => {
    return useGetIntegrationConfigurations({
        select: (data: { integrationConfigurations: IntegrationConfiguration[] }) => {
            return convertConfigurationsForDropdown(data.integrationConfigurations);
        },
    });
};

const useSetConfigurations = () => {
    return useSetIntegrationConfigurations({
        onSuccess: () => {
            toaster.info(messages.saveSuccess);
        },
    });
};

const convertConfigurationsForDropdown = (integrationConfiguration: IntegrationConfiguration[]) => {
    return integrationConfiguration.reduce<EnforcementTfaState>((result, configuration) => {
        result[configuration.integrationType] = {
            id: configuration.enforcementTfaType,
            text: getEnforcementTfaLabelByType(configuration.enforcementTfaType),
        };

        return result;
    }, {});
};

const convertConfigurationsForSave = (values: EnforcementTfaState): IntegrationConfiguration[] => {
    return Object.keys(values).map<IntegrationConfiguration>((integrationType: IntegrationType) => ({
        integrationType,
        enforcementTfaType: values[integrationType]!.id,
    }));
};

export const getIntegrationLabelByType = (integrationType: IntegrationType) => {
    switch (integrationType) {
        case IntegrationType.None:
            return messages.optionNone;

        case IntegrationType.Xero:
            return messages.optionXero;

        case IntegrationType.QBooks:
            return messages.optionQbooks;

        case IntegrationType.Dear:
            return messages.optionDear;

        case IntegrationType.NetSuite:
            return messages.optionNetSuite;
    }
};

export const getEnforcementTfaLabelByType = (enforcementTfaType: EnforcementTfaType) => {
    switch (enforcementTfaType) {
        case EnforcementTfaType.None:
            return messages.enforcementOptionNone;

        case EnforcementTfaType.Soft:
            return messages.enforcementOptionSoft;

        case EnforcementTfaType.Hard:
            return messages.enforcementOptionHard;
    }
};
