import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.companyDetails.components.EnforceFraudDetectionPopup', {
    popupTitle: 'Workflows',
    idCol: 'ID',
    integrationCodeCol: 'Integration Code',
    effectiveDateCol: 'Effective Date',
    actionsCol: 'Actions',
    enforceButton: 'Enforce',
    successMessage: 'Effective Date changed',
    defaultErrorMessage: 'Oops, something went wrong',
});
