import { LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { useDebounce } from '@approvalmax/utils/src/hooks/useDebounce';
import { Report } from 'modules/api';
import {
    NativeDataTable,
    NavBarMode,
    PageLayout,
    PageLayoutWidthBehavior,
    SearchBar,
    Toolbar,
} from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { useFindReports } from './hooks/useFindReports';
import useQueryParams from './hooks/useQueryParams';
import { dataTableColumns } from './ReportsPage.config';
import { messages } from './ReportsPage.messages';
import { Row } from './ReportsPage.styles';
import { QueryType } from './ReportsPage.types';

const ReportsPage: FC = () => {
    const history = useHistory();

    const queryParams = useQueryParams();
    const [query, setQuery] = useState(queryParams.query);

    const debouncedQuery = useDebounce(query, 1000);
    const { isFetching, reports } = useFindReports({ query: debouncedQuery, queryType: QueryType.companyId });

    const { permissionFlags } = useCurrentAccount();

    usePermissionsRedirect((permissionFlags) => permissionFlags.reports.read);

    useEffect(() => {
        queryParams.push({ query });
    }, [queryParams, query]);

    const onRowClick = (report: Report) => {
        history.push(getPath('reports', report.id, report.companyId));
    };

    const filters = (
        <SearchBar
            focusOnMount
            disabled={!permissionFlags || !permissionFlags.reports.read}
            placeholder={messages.searchBarPlaceholder}
            value={query}
            onChange={setQuery}
            withoutButton
        />
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar filters={filters} />

                    {isFetching && <LoadingBar absolute />}

                    <ScrollableArea css='flex: 1; overflow: hidden;'>
                        <NativeDataTable>
                            <thead>
                                <tr>
                                    {dataTableColumns.map((colDef, i) => (
                                        <th key={i}>{colDef.name}</th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {(reports ?? []).map((searchEntry, i) => (
                                    <Row key={i} onClick={() => onRowClick(searchEntry)}>
                                        {dataTableColumns.map((colDef, j) => (
                                            <td key={j} width={colDef.width}>
                                                {colDef.value!(searchEntry)}
                                            </td>
                                        ))}
                                    </Row>
                                ))}
                            </tbody>
                        </NativeDataTable>
                    </ScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
};

export default memo(ReportsPage);
