import { Accordion, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { JsonViewer, NavBarMode, PageLayout, Section, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { usePageGoBack } from 'modules/react/hooks/usePageGoBack';
import { memo } from 'react';

import { useBudgetDetails, useBudgetValues } from './BudgetDetailsPage.hooks';
import { messages } from './BudgetDetailsPage.messages';
import { Body, EmptyList, FilterHeader, FilterPicker, LoadingBarStyled } from './BudgetDetailsPage.styles';
import ValuesList from './components/ValuesList/ValuesList';

const BudgetDetailsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.budgets.readBudgetsId);

    const { onBack } = usePageGoBack('budgets');
    const { budget, isBudgetLoading } = useBudgetDetails();
    const { values, filter, isValuesLoading, onFilterChange } = useBudgetValues();

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle, messages.pageDetailsTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                <LoadingBarStyled active={isBudgetLoading} />

                {budget && (
                    <ScrollableAreaWrapper>
                        <Body>
                            <Toolbar title={budget.budgetRawData.budgetName} />

                            <Box spacing='20 60'>
                                <Section header={messages.sectionDetails}>
                                    <JsonViewer json={budget.budgetRawData} />
                                </Section>

                                <Section
                                    header={
                                        <FilterHeader>
                                            {messages.sectionValues}

                                            <FilterPicker
                                                value={filter}
                                                placeholder={messages.sectionValuesPlaceholder}
                                                onMonthSelect={onFilterChange}
                                            />
                                        </FilterHeader>
                                    }
                                >
                                    <Accordion title={messages.sectionValuesAccordion}>
                                        <LoadingBarStyled active={isValuesLoading} />

                                        {values.length ? (
                                            <ValuesList values={values} />
                                        ) : (
                                            <EmptyList>
                                                {filter ? messages.errorNoValues : messages.errorSelectDate}
                                            </EmptyList>
                                        )}
                                    </Accordion>
                                </Section>
                            </Box>
                        </Body>
                    </ScrollableAreaWrapper>
                )}
            </Box>
        </PageLayout>
    );
});

export default BudgetDetailsPage;
