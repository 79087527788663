import { Button, Popup, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import Editor from '@monaco-editor/react';
import { Alert, NavBarMode, PageLayout, PageLayoutWidthBehavior, Toolbar, TwoFaCodePopup } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, ReactNode, useCallback, useState } from 'react';

import { QueryResultTableView } from './components';
import { messages } from './DatabaseAccessPage.messages';
import {
    ActionsSection,
    EditorSection,
    JSONButton,
    ResultsSection,
    RightButtons,
    RowCount,
} from './DatabaseAccessPage.styles';
import { useDownloadCSV } from './hooks/useDownloadCSV';
import { useDownloadJSON } from './hooks/useDownloadJSON';
import { useEditor } from './hooks/useEditor';
import { useExecuteSqlQuery } from './hooks/useExecuteSqlQuery';
import { useExecutionHistory } from './hooks/useExecutionHistory';

const DatabaseAccessPage: FC = () => {
    const [twoFaCodePopupState, setTwoFaCodePopupState] = useState<{
        isOpen: boolean;
        onEnterCode: (code: string) => Promise<void>;
    }>({
        isOpen: false,
        onEnterCode: () => Promise.resolve(),
    });

    const { executionHistory, pushIntoExecutionHistory } = useExecutionHistory();

    const [value, setValue] = useState(executionHistory[0] || '-- Write your SQL query here');

    const { executeSqlQuery, queryResult, isLoading: isSqlQueryExecuting } = useExecuteSqlQuery();
    const { downloadCSV, isLoading: isCSVExporting } = useDownloadCSV();
    const { downloadJSON, isLoading: isJSONExporting } = useDownloadJSON();
    const { editorOnChange, editorOptions, editorOnMount } = useEditor({ value, setValue });

    usePermissionsRedirect((permissionFlags) => permissionFlags.databases.any);

    const onTwoFaPopupClose = useCallback(() => {
        setTwoFaCodePopupState({
            isOpen: false,
            onEnterCode: () => Promise.resolve(),
        });
    }, []);

    const onSqlQueryExecute = useCallback(() => {
        setTwoFaCodePopupState({
            isOpen: true,
            onEnterCode: async (code) => {
                executeSqlQuery({
                    sqlQueryText: value,
                    twoFaCode: code,
                });

                pushIntoExecutionHistory(value);

                onTwoFaPopupClose();
            },
        });
    }, [executeSqlQuery, pushIntoExecutionHistory, value, onTwoFaPopupClose]);

    const lastExecutedQuery = executionHistory[0] || null;

    const onDownloadCSV = useCallback(() => {
        if (lastExecutedQuery) {
            setTwoFaCodePopupState({
                isOpen: true,
                onEnterCode: async (code) => {
                    downloadCSV({
                        sqlQueryText: lastExecutedQuery,
                        twoFaCode: code,
                    });

                    onTwoFaPopupClose();
                },
            });
        }
    }, [downloadCSV, lastExecutedQuery, onTwoFaPopupClose]);

    const onDownloadJSON = useCallback(() => {
        if (lastExecutedQuery) {
            setTwoFaCodePopupState({
                isOpen: true,
                onEnterCode: async (code) => {
                    downloadJSON({
                        sqlQueryText: lastExecutedQuery,
                        twoFaCode: code,
                    });

                    onTwoFaPopupClose();
                },
            });
        }
    }, [downloadJSON, lastExecutedQuery, onTwoFaPopupClose]);

    const isLoading = isSqlQueryExecuting || isCSVExporting || isJSONExporting;

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar title={messages.pageTitle} />

                    <ScrollableArea css='flex: 1; overflow: hidden;'>
                        <Box spacing='20 60'>
                            <Alert>
                                {messages.disclaimer({
                                    b: (chunks: ReactNode) => <b>{chunks}</b>,
                                })}
                            </Alert>

                            <EditorSection>
                                <Editor
                                    height={300}
                                    defaultLanguage='plaintext'
                                    options={editorOptions}
                                    onChange={editorOnChange}
                                    onMount={editorOnMount}
                                />
                            </EditorSection>

                            <ActionsSection>
                                <Button disabled={isLoading} execute={onSqlQueryExecute}>
                                    {messages.executeQueryButton}
                                </Button>

                                <RightButtons>
                                    <Button disabled={!queryResult || isLoading} execute={onDownloadCSV}>
                                        {messages.executeDownloadCsvButton}
                                    </Button>

                                    <JSONButton disabled={!queryResult || isLoading} execute={onDownloadJSON}>
                                        {messages.executeDownloadJsonButton}
                                    </JSONButton>
                                </RightButtons>
                            </ActionsSection>

                            {queryResult && (
                                <>
                                    <ResultsSection>
                                        <QueryResultTableView queryResult={queryResult} />
                                    </ResultsSection>

                                    <RowCount>
                                        {messages.rowsCount({ count: queryResult.columnValues.length })}
                                    </RowCount>
                                </>
                            )}
                        </Box>
                    </ScrollableArea>
                </ScrollableAreaWrapper>
            </Box>

            <Popup isOpen={twoFaCodePopupState.isOpen} onRequestClose={onTwoFaPopupClose} disableAutoClose>
                <TwoFaCodePopup isLoading={isLoading} onEnterCode={twoFaCodePopupState.onEnterCode} />
            </Popup>
        </PageLayout>
    );
};

export default memo(DatabaseAccessPage);
