import moment, { Moment } from 'moment/moment';

import { errorHelpers } from '../../helpers/error';

/**
 *
 * @param value The date to be formatted (as a moment or string). If it's a falsy value, an empty string will be returned.
 * @param format the format as defined below.
 * - `DateAndTime`: "4 Sep 1986 18:30"
 * - `FullDateAndTime`: "4 September 1986 18:30"
 */
export const formatDateTime = (
    value: Moment | string | null | undefined,
    format: 'DateAndTime' | 'FullDateAndTime' = 'DateAndTime'
) => {
    if (!value) {
        return '';
    }

    let momentFormat;

    switch (format) {
        case 'DateAndTime':
            momentFormat = 'lll';
            break;

        case 'FullDateAndTime':
            momentFormat = 'LLL';
            break;

        default:
            throw errorHelpers.assertNever(format);
    }

    return moment(value).format(momentFormat);
};
