import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { CompanySearchEntry } from 'modules/api';
import { IntegrationType } from 'modules/types';

import { messages } from './CompaniesPage.messages';
import { CompanyQueryType, IntegrationStatusOption, StatusFilterOption } from './CompaniesPage.types';

export const allQueryTypes = [
    {
        id: 'companyIdOrName',
        companyQueryType: CompanyQueryType.byIdOrName,
        text: messages.queryTypeCompanyIdOrName,
    },
    {
        id: 'integrationIdOrName',
        companyQueryType: CompanyQueryType.byIntegrationIdOrName,
        text: messages.queryTypeIntegrationIdOrName,
    },
    {
        id: 'email',
        companyQueryType: CompanyQueryType.byEmail,
        text: messages.queryTypeEmail,
    },
];

export const dataTableColumns: DataTableColumnDefinition<CompanySearchEntry>[] = [
    {
        id: 'Id',
        name: messages.idCol,
        value: (company) => company.id,
        sortable: false,
        padding: DataTablePadding.Left60,
        width: 320,
    },
    {
        id: 'Name',
        name: messages.nameCol,
        value: (company) => company.name,
        sortable: true,
        width: 320,
        filterable: true,
        filterKey: 'companyName',
        filterType: 'string',
    },
    {
        id: 'AccountOwnerEmail',
        name: messages.accountOwnerEmailCol,
        value: (company) => company.accountOwnerEmail,
        sortable: true,
        width: 300,
        filterable: true,
        filterKey: 'accountOwnerEmail',
        filterType: 'string',
    },
    {
        id: 'Status',
        name: messages.accountStatusCol,
        value: (company) => {
            if (company.isActive) {
                return messages.accountStatusActive;
            }

            if (company.isActiveTrial) {
                return messages.accountStatusTrial;
            }

            if (company.isActivePartner) {
                return messages.accountStatusPartner;
            }

            if (company.isExpiredTrial) {
                return messages.accountStatusTrialExpired;
            }

            if (company.isRetired) {
                return messages.accountStatusRetired;
            }

            return '-';
        },
        sortable: true,
        filterable: true,
        filterKey: 'status',
        filterType: 'multiple',
        filterOptions: [
            {
                id: StatusFilterOption.Active,
                text: messages.accountStatusActive,
            },
            {
                id: StatusFilterOption.Partner,
                text: messages.accountStatusPartner,
            },
            {
                id: StatusFilterOption.Retired,
                text: messages.accountStatusRetired,
            },
            {
                id: StatusFilterOption.Trial,
                text: messages.accountStatusTrial,
            },
            {
                id: StatusFilterOption.TrialExpired,
                text: messages.accountStatusTrialExpired,
            },
        ],
        width: 150,
    },
    {
        id: 'IntegrationType',
        name: messages.accountIntegrationCol,
        value: (company) => {
            return company.hasIntegration ? company.integrationType : '-';
        },
        sortable: true,
        width: 50,
        filterable: true,
        filterKey: 'companyIntegrationType',
        filterType: 'multiple',
        filterOptions: [
            {
                id: IntegrationType.Xero,
                text: IntegrationType.Xero,
            },
            {
                id: IntegrationType.QBooks,
                text: IntegrationType.QBooks,
            },
            {
                id: IntegrationType.NetSuite,
                text: IntegrationType.NetSuite,
            },
            {
                id: IntegrationType.Dear,
                text: IntegrationType.Dear,
            },
            {
                id: IntegrationType.None,
                text: IntegrationType.None,
            },
        ],
    },
    {
        id: 'IntegrationStatus',
        name: messages.accountIntegrationStatusCol,
        value: (company) => {
            return company.isIntegrationActive
                ? messages.accountIntegrationStatusTrue
                : messages.accountIntegrationStatusFalse;
        },
        sortable: true,
        filterable: true,
        filterKey: 'companyIntegrationStatus',
        filterType: 'single',
        filterOptions: [
            {
                id: IntegrationStatusOption.All,
                text: messages.integrationStatusAll,
            },
            {
                id: IntegrationStatusOption.Enabled,
                text: messages.integrationStatusEnabled,
            },
            {
                id: IntegrationStatusOption.Disabled,
                text: messages.integrationStatusDisabled,
            },
        ],
        width: 50,
    },
];
