import styled from 'styled-components';

export const Root = styled.div`
    box-shadow: 0 1px 4px rgb(0 0 0 / 12%);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 32px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const Divider = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin: 0 -20px 20px;
`;

export const Title = styled.h4`
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
`;

export const Description = styled.div`
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    margin-top: -12px;
    opacity: 0.6;
    white-space: pre-line;
`;
