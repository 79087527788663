import { useEditRequestDeletedFlag } from 'modules/customerSupport/hooks/useEditRequestDeletedFlag';

import { messages } from './BulkOperationPopup.messages';
import { BulkOperation, PopupConfigEntry } from './BulkOperationPopup.types';

export const config: Record<BulkOperation, PopupConfigEntry> = {
    delete: {
        title: messages.deleteTitle,
        buttonText: messages.deleteButton,
        description: messages.deleteDescription({
            b: (content) => <b>{content}</b>,
        }),
        useOperation: () => {
            const { isLoading, editRequestDeletedFlag } = useEditRequestDeletedFlag();

            return {
                isLoading,
                execute: (options) =>
                    editRequestDeletedFlag({
                        ...options,
                        deleted: true,
                    }),
            };
        },
    },
    restore: {
        title: messages.restoreTitle,
        buttonText: messages.restoreButton,
        description: messages.restoreDescription({
            b: (content) => <b>{content}</b>,
        }),
        useOperation: () => {
            const { isLoading, editRequestDeletedFlag } = useEditRequestDeletedFlag();

            return {
                isLoading,
                execute: (options) =>
                    editRequestDeletedFlag({
                        ...options,
                        deleted: false,
                    }),
            };
        },
    },
};
