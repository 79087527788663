import { RequestVersionItem, RequestVersionType } from 'modules/data';
import moment from 'moment';

import { messages } from './VersionItem.messages';

export const getVersionName = (options: {
    version: RequestVersionItem;
    integrationFriendlyName: string;
    isDextRequest: boolean;
}) => {
    const { version, integrationFriendlyName, isDextRequest } = options;

    const integrationName = isDextRequest ? messages.dext : integrationFriendlyName;

    const userName = version.versionRawData.actor?.userName ?? '';

    let description = '';

    switch (version.versionType) {
        case RequestVersionType.RequesterSubmitted:
            description = messages.requesterSubmitted;

            break;

        case RequestVersionType.SubmittedViaExternalSystem:
            description = messages.submittedViaExternalSystem({ integrationName });

            break;

        case RequestVersionType.StartOverByExternalUpdate:
            description = messages.startOverByExternalUpdate({ integrationName });

            break;

        case RequestVersionType.RequesterResubmitted:
            description = messages.requesterResubmitted;

            break;

        case RequestVersionType.ReviewerEdited:
            description = messages.reviewerEdited({
                userName,
            });

            break;

        case RequestVersionType.EditorEdited:
        case RequestVersionType.ApproverEdited:
            description = messages.approverEdited({
                userName,
            });

            break;

        case RequestVersionType.PostApprovalChangesDetected:
            description = messages.postApprovalChangesDetected;

            break;

        default:
            break;
    }

    const creationDate = moment(version.createdAt).format('lll');

    return description
        ? `${messages.versionTitle({ version: version.version })} - ${description} (${creationDate})`
        : `${messages.versionTitle({ version: version.version })} (${creationDate})`;
};
