import { font, ScrollableArea } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(ScrollableArea)`
    flex: 1;
    overflow: auto;
`;

export const BetaFeaturesPanel = styled.div`
    display: flex;
    align-items: center;

    & > :first-child {
        flex: 1;
        margin-right: 20px;
    }
`;

export const ConfirmationPopupHeader = styled.div`
    ${font(16)}
`;

export const ConfirmationPopupList = styled.div`
    margin: 20px 0;
    ${font(13, '#DE283E')}
`;

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;

    & > * {
        margin-right: 15px;
    }
`;
