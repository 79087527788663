import { useCallback, useEffect, useState } from 'react';

import { CheckboxProps } from '../../Checkbox.types';
import { ContextValue, GroupProps } from './Group.types';

export const useCheckedValues = (value: GroupProps['value']) => {
    const [checkedValues, setCheckedValues] = useState(value || []);

    /**
     * Updating the internal checked value when the external value changes
     */
    useEffect(() => setCheckedValues(value || []), [value]);

    /**
     * Remove from the checked values if a target checkbox is not checked
     * and add to the checked values if a target checkbox is checked.
     */
    const toggleCheckedValues = useCallback(
        (targetChecked: boolean, targetValue: CheckboxProps['value']) => {
            let newCheckedValues = [...checkedValues];

            if (targetChecked) {
                newCheckedValues = [...newCheckedValues, targetValue];
            } else {
                newCheckedValues.splice(newCheckedValues.indexOf(targetValue), 1);
            }

            setCheckedValues(newCheckedValues);

            return newCheckedValues;
        },
        [checkedValues]
    );

    /**
     * If a target checkbox value in the checked values, it is checked
     */
    const getCheckedStatus = useCallback<ContextValue['contextProps']['getCheckedStatus']>(
        (value) => Boolean(value && checkedValues.length && checkedValues.includes(value)),
        [checkedValues]
    );

    return {
        toggleCheckedValues,
        getCheckedStatus,
    };
};
