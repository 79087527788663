import { mixins, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css, keyframes } from 'styled-components';

import { ProgressProps } from './Progress.types';

const lineBarAnimation = keyframes`
    0% {
        left: -90%;
        right: 100%;
    }
    100% {
        left: 100%;
        right: -30%;
    }
`;

const circleBarAnimation = keyframes`
    0% {
        stroke-dasharray: 1, 400;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 400, 400;
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dasharray: 400, 400;
        stroke-dashoffset: -260;
    }
`;

const circleAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const CircleBack = styled.circle`
    fill: transparent;
    stroke: ${theme.color.midnight40};
    stroke-width: 8px;
`;

export const CircleBar = styled.circle<StyledMods<Pick<ProgressProps, 'value'>>>`
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-width: 8px;
    stroke: ${theme.color.blue100};

    ${mods('value')`
        transition: ${theme.duration.slow}ms;
    `};

    ${mods.not('value')`
        animation: 1.5s linear 0s infinite normal none running ${circleBarAnimation};
    `};
`;

const circleSizeMixin = (size: RequiredMods['spacing']) => css`
    width: ${size}px;
    height: ${size}px;
`;

export const Circle = styled.svg<StyledMods<Pick<ProgressProps, 'size' | 'value'>>>`
    color: ${theme.color.blue100};

    ${mods.size.uxsmall(circleSizeMixin(12))};
    ${mods.size.usmall(circleSizeMixin(16))};
    ${mods.size.xxsmall(circleSizeMixin(20))};
    ${mods.size.xsmall(circleSizeMixin(24))};
    ${mods.size.small(circleSizeMixin(36))};
    ${mods.size.medium(circleSizeMixin(48))};
    ${mods.size.large(circleSizeMixin(64))};

    ${mods.not('value')`
        animation: 2s linear 0s infinite normal none running ${circleAnimation};
    `};
`;

export const LineBar = styled.div<StyledMods<Pick<ProgressProps, 'value'>>>`
    width: ${({ $value }) => $value || 0}%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    ${mixins.colorTextWithBg('blue100')};

    ${mods('value')`
        transition: ${theme.duration.slow}ms;
    `};

    ${mods.not('value')`
        width: auto;
        animation: 1.8s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running ${lineBarAnimation};
    `};
`;

export const Line = styled.div<StyledMods<Pick<ProgressProps, 'size' | 'rounded'>>>`
    background-color: ${theme.color.midnight40};
    position: relative;
    overflow: hidden;

    ${mods.rounded.true`
        border-radius: 10px;
    `};

    ${mods.size.uxsmall` height: 2px; `};
    ${mods.size.usmall` height: 2px; `};
    ${mods.size.xxsmall` height: 2px; `};
    ${mods.size.xsmall` height: 2px; `};
    ${mods.size.small` height: 4px; `};
    ${mods.size.medium` height: 8px; `};
    ${mods.size.large` height: 2px; `};
`;
