import { booleanComparator2Asc } from './booleanComparator2Asc';
import { booleanComparator2Desc } from './booleanComparator2Desc';
import { combineComparators } from './combineComparators';
import { comparatorFor } from './comparatorFor';
import { dateComparator2Asc } from './dateComparator2Asc';
import { dateComparator2Desc } from './dateComparator2Desc';
import { durationComparator2Asc } from './durationComparator2Asc';
import { durationComparator2Desc } from './durationComparator2Desc';
import { emptyComparator } from './emptyComparator';
import { numberComparator2Asc } from './numberComparator2Asc';
import { numberComparator2Desc } from './numberComparator2Desc';
import { stringComparator2Asc } from './stringComparator2Asc';
import { stringComparator2AscI } from './stringComparator2AscI';
import { stringComparator2Desc } from './stringComparator2Desc';
import { stringComparator2DescI } from './stringComparator2DescI';

export const compareHelpers = {
    booleanComparator2Asc,
    booleanComparator2Desc,
    combineComparators,
    comparatorFor,
    dateComparator2Asc,
    dateComparator2Desc,
    durationComparator2Asc,
    durationComparator2Desc,
    emptyComparator,
    numberComparator2Asc,
    numberComparator2Desc,
    stringComparator2Asc,
    stringComparator2AscI,
    stringComparator2Desc,
    stringComparator2DescI,
};
