import styled from 'styled-components';

export const Content = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    padding-bottom: 20px;

    & > * {
        flex: 1;
        overflow: hidden;
    }
`;
