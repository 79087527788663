import { useMutation } from '@tanstack/react-query';
import { api } from 'modules/api';

export const useDeleteEmailDeliveryReport = () => {
    const { isLoading, mutateAsync: deleteEmailDeliveryReport } = useMutation(
        (options: { email: string; reason: string }) => api.internalSupport.deleteIgnoredEmail(options.email, options),
        {}
    );

    return {
        deleteEmailDeliveryReport,
        isLoading,
    };
};
