import { DataTable } from '@approvalmax/ui';
import styled from 'styled-components';

export const StyledDataTable = styled(DataTable)`
    & .ui-data-row {
        height: auto;
        min-height: 50px;
    }
`;

export const CellWithWrap = styled(DataTable.DefaultCell)`
    white-space: pre-wrap;
`;
