import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api, QueryKeys } from 'modules/api';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

export const useReportDetails = (companyId: string, reportId: string) => {
    const history = useHistory();

    const { isInitialLoading, data: reportDetails } = useQuery(
        [QueryKeys.ReportDetails, reportId],
        () => api.internalSupport.getReportDetails(companyId, reportId),
        {
            onError: (error: AxiosError) => {
                if (error.response?.data) {
                    history.push(getPath('root'));
                }
            },
        }
    );

    return {
        reportDetails,
        isInitialLoading,
    };
};
