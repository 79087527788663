import './scrollableArea.scss';

import throttle from 'lodash/throttle';
import React, { Component, ComponentType, FC, PropsWithChildren } from 'react';
import bemFactory from 'react-bem-factory';
import { createSelector } from 'reselect';
import { Subtract } from 'utility-types';

interface ScrollableAreaProps extends PropsWithChildren {
    scrollResetKey?: string;
    className?: string;
    scrollableRef?: (ref: HTMLElement | null) => void;
    overflowXAuto?: boolean;
    thickXScroll?: boolean;
    thresholdBottom?: number;
    onTopReached?: () => void;
    onBottomReached?: () => void;
}

const ScrollableAreaContext = React.createContext<{
    scrollIntoView: (el: HTMLElement) => void;
}>(null as any);

export interface ScrollableAreaInjectedProps {
    scrollIntoView?: (el: HTMLElement) => void;
}

export const withScrollableArea = <TWrappedProps extends ScrollableAreaInjectedProps>(
    WrappedComponent: ComponentType<TWrappedProps>
) => {
    type HocProps = Subtract<TWrappedProps, ScrollableAreaInjectedProps>;

    const ScrollableAreaConsumerHoc: FC<HocProps> = (props) => {
        return (
            <ScrollableAreaContext.Consumer>
                {({ scrollIntoView }) => (
                    // TS 3.2 Erases `props` a type when using spread, (temporary) workaround - cast props
                    // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
                    <WrappedComponent {...(props as TWrappedProps)} scrollIntoView={scrollIntoView} />
                )}
            </ScrollableAreaContext.Consumer>
        );
    };

    ScrollableAreaConsumerHoc.displayName = `withState(${WrappedComponent.name})`;

    return ScrollableAreaConsumerHoc;
};

const getContextValue = createSelector(
    (scrollIntoView: (el: HTMLElement) => void) => scrollIntoView,
    (scrollIntoView) => ({
        scrollIntoView,
    })
);

const bem = bemFactory.block('ui-scrollable-area');

export class ScrollableArea extends Component<ScrollableAreaProps> {
    public state = {
        scrollAtTop: true,
        scrollAtBottom: false,
    };

    private _scrollEl: HTMLElement | null = null;
    private _contentWrap: HTMLElement | null = null;

    private _updateScrollAtTopFlag = throttle((scrollAtTop: boolean) => {
        if (scrollAtTop && !this.state.scrollAtTop && this.props.onTopReached) {
            this.props.onTopReached();
        }

        this.setState({ scrollAtTop });
    }, 100);

    private _updateScrollAtBottomFlag = throttle((scrollAtBottom: boolean) => {
        if (scrollAtBottom && !this.state.scrollAtBottom && this.props.onBottomReached) {
            this.props.onBottomReached();
        }

        this.setState({ scrollAtBottom });
    }, 100);

    public UNSAFE_componentWillReceiveProps(nextProps: ScrollableAreaProps) {
        if (this.props.scrollResetKey !== nextProps.scrollResetKey && this._scrollEl) {
            this._scrollEl.scrollTop = 0;
        }
    }

    public render() {
        const { children, className, overflowXAuto, thickXScroll } = this.props;
        const scrollAtTop = this.state.scrollAtTop;

        return (
            <ScrollableAreaContext.Provider value={getContextValue(this._scrollIntoView)}>
                <div className={bem.add(className)()}>
                    <div className={bem('scroll-shadow', { active: !scrollAtTop })} />

                    <div
                        ref={this._scrollRef}
                        className={bem('content', {
                            'h-scroll': overflowXAuto,
                            'h-thick': thickXScroll,
                        })}
                        onScroll={this._onScroll}
                    >
                        <div ref={this._contentWrapRef} className={bem('content-wrap')}>
                            {children}
                        </div>
                    </div>
                </div>
            </ScrollableAreaContext.Provider>
        );
    }

    private _scrollIntoView = (el: HTMLElement) => {
        const childTop = el.offsetTop;
        const childHeight = el.offsetHeight;
        const scrollEl = this._scrollEl!;
        const viewportTop = scrollEl.scrollTop;
        const viewportHeight = scrollEl.clientHeight;

        if (childTop < viewportTop) {
            const EXTRA_VISIBLE_SPACE_TOP = 20;

            scrollEl.scrollTop = childTop - EXTRA_VISIBLE_SPACE_TOP;
        }

        if (childTop + childHeight > viewportTop + viewportHeight) {
            const EXTRA_VISIBLE_SPACE_BOTTOM = 50;

            scrollEl.scrollTop = childTop + childHeight + EXTRA_VISIBLE_SPACE_BOTTOM - viewportHeight;
        }
    };

    private _scrollRef = (ref: HTMLElement | null) => {
        this._scrollEl = ref;

        if (this.props.scrollableRef) {
            this.props.scrollableRef(ref);
        }
    };

    private _contentWrapRef = (ref: HTMLElement | null) => {
        this._contentWrap = ref;
    };

    private _onScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const thresholdBottom = this.props.thresholdBottom || 0;
        const heightTotal = this._contentWrap?.clientHeight || 0;
        const heightVisible = this._scrollEl?.clientHeight || 0;

        const isTopReached = e.currentTarget.scrollTop === 0;
        const isBottomReached = e.currentTarget.scrollTop + heightVisible + thresholdBottom >= heightTotal;

        this._updateScrollAtTopFlag(isTopReached);
        this._updateScrollAtBottomFlag(isBottomReached);
    };
}
