/**
 * Developer: Stepan Burguchev
 * Date: 3/4/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './dropdown.scss';

import { errorHelpers, globalEventService, hooks, reactWindowService } from '@approvalmax/utils';
import { PortalDef } from '@approvalmax/utils/src/services/reactWindow/reactWindow';
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import ReactDOM from 'react-dom';

import { DropdownContext } from './context';
import DropdownAnchor from './DropdownAnchor';
import ElementMoveObserver from './ElementMoveObserver';
import {
    DropdownButtonRect,
    DropdownElement,
    DropdownGetRectCallback,
    DropdownPanelFlow,
    DropdownPanelMaxWidth,
    DropdownPanelMinWidth,
    DropdownPanelPosition,
    DropdownProps,
} from './types';

const bem = bemFactory.block('drop-dropdown');

const CANVAS_BORDER_OFFSET = 10;

interface FullButtonRect extends DropdownButtonRect {
    bottom: number;
}

const getViewportRect = () => {
    const rect = document.body.getBoundingClientRect();

    return {
        height: rect.height,
        width: rect.width,
        left: -rect.left,
        top: -rect.top,
        bottom: document.body.scrollHeight - (rect.height - rect.top),
        right: document.body.scrollWidth - (rect.width - rect.left),
    };
};

const getPosition = (
    panelEl: HTMLElement,
    buttonRect: FullButtonRect,
    viewportRect: ReturnType<typeof getViewportRect>,
    position: DropdownPanelPosition
) => {
    const panelHeight = panelEl.offsetHeight;

    // switching position if there is not enough space
    let invertedPosition = false;

    switch (position) {
        case 'down':
            if (buttonRect.bottom < panelHeight && buttonRect.top > buttonRect.bottom) {
                position = 'up';
                invertedPosition = true;
            }

            break;

        case 'down-over':
            if (buttonRect.bottom + buttonRect.height < panelHeight && buttonRect.top > buttonRect.bottom) {
                position = 'up-over';
                invertedPosition = true;
            }

            break;

        case 'up':
            if (buttonRect.top < panelHeight && buttonRect.bottom > buttonRect.top) {
                position = 'down';
                invertedPosition = true;
            }

            break;

        case 'up-over':
            if (buttonRect.top + buttonRect.height < panelHeight && buttonRect.bottom > buttonRect.top) {
                position = 'down-over';
                invertedPosition = true;
            }

            break;

        default:
            throw errorHelpers.assertNever(position);
    }

    // panel positioning
    let top: number;

    switch (position) {
        case 'up':
            top = buttonRect.top - panelHeight;
            break;

        case 'up-over':
            top = buttonRect.top + buttonRect.height - panelHeight;
            break;

        case 'down':
            top = buttonRect.top + buttonRect.height;
            break;

        case 'down-over':
            top = buttonRect.top;
            break;

        default:
            throw errorHelpers.assertNever(position);
    }

    // trying to fit into the scrollable area:
    // the dropdown is not necessary fully visible on the screen, but you can scroll to it
    const canvasHeight = viewportRect.top + viewportRect.height + viewportRect.bottom;

    if (top + panelHeight > canvasHeight - CANVAS_BORDER_OFFSET) {
        top = canvasHeight - CANVAS_BORDER_OFFSET - panelHeight;
    }

    if (top < CANVAS_BORDER_OFFSET) {
        top = CANVAS_BORDER_OFFSET;
    }

    return {
        top,
        invertedPosition,
    };
};

const getFlow = (
    panelEl: HTMLElement,
    buttonRect: FullButtonRect,
    viewportRect: ReturnType<typeof getViewportRect>,
    panelFlow: DropdownPanelFlow
) => {
    const panelWidth = panelEl.offsetWidth;

    let styleLeft: number | null = null;
    let styleRight: number | null = null;

    switch (panelFlow) {
        case 'to-right': {
            const availableWidth = viewportRect.left + viewportRect.width;

            let left = buttonRect.left;

            if (left + panelWidth > availableWidth - CANVAS_BORDER_OFFSET) {
                left = availableWidth - CANVAS_BORDER_OFFSET - panelWidth;

                if (left < viewportRect.left + CANVAS_BORDER_OFFSET) {
                    left = Math.min(buttonRect.left, viewportRect.left + CANVAS_BORDER_OFFSET);
                }
            }

            if (left < CANVAS_BORDER_OFFSET) {
                left = CANVAS_BORDER_OFFSET;
            }

            styleLeft = left;
            break;
        }

        case 'center': {
            const availableWidth = viewportRect.left + viewportRect.width;

            let left = buttonRect.left - (panelWidth - buttonRect.width) / 2;

            if (left + panelWidth > availableWidth - CANVAS_BORDER_OFFSET) {
                left = availableWidth - CANVAS_BORDER_OFFSET - panelWidth;

                if (left < viewportRect.left + CANVAS_BORDER_OFFSET) {
                    left = Math.min(buttonRect.left, viewportRect.left + CANVAS_BORDER_OFFSET);
                }
            }

            if (left < CANVAS_BORDER_OFFSET) {
                left = CANVAS_BORDER_OFFSET;
            }

            styleLeft = left;
            break;
        }

        case 'to-left': {
            const canvasWidth = viewportRect.left + viewportRect.width + viewportRect.right;
            const availableWidth = viewportRect.width + viewportRect.right;

            let right = canvasWidth - (buttonRect.left + buttonRect.width);

            if (right + panelWidth > availableWidth - CANVAS_BORDER_OFFSET) {
                right = availableWidth - CANVAS_BORDER_OFFSET - panelWidth;

                if (right < viewportRect.right + CANVAS_BORDER_OFFSET) {
                    right = Math.min(
                        canvasWidth - (buttonRect.left + buttonRect.width),
                        viewportRect.right + CANVAS_BORDER_OFFSET
                    );
                }
            }

            if (right < CANVAS_BORDER_OFFSET) {
                right = CANVAS_BORDER_OFFSET;
            }

            styleRight = right - (viewportRect.left + viewportRect.right);
            break;
        }

        default:
            throw errorHelpers.assertNever(panelFlow);
    }

    return {
        left: styleLeft ? Math.round(styleLeft) : null,
        right: styleRight ? Math.round(styleRight) : null,
    };
};

const getWidth = (
    buttonRect: FullButtonRect,
    panelMinWidth: DropdownPanelMinWidth,
    panelMaxWidth: DropdownPanelMaxWidth
) => {
    let minWidth;

    switch (panelMinWidth) {
        case 'button-width':
            minWidth = buttonRect.width;
            break;

        case 'none':
            break;

        default:
            throw errorHelpers.assertNever(panelMinWidth);
    }

    let maxWidth;

    switch (panelMaxWidth) {
        case 'button-width':
            maxWidth = buttonRect.width;
            break;

        default:
            maxWidth = panelMaxWidth;
    }

    return {
        minWidth,
        maxWidth,
    };
};

const Dropdown = forwardRef<DropdownElement, DropdownProps>((props, ref) => {
    const {
        button,
        qa,
        isOpen,
        onRequestClose,
        className,
        portalClassName,
        children,
        panelPosition = 'down',
        panelFlow = 'to-right',
        panelMinWidth = 'button-width',
        panelMaxWidth = 'none',
        targetElementId,
    } = props;

    const buttonRef = useRef<HTMLElement | null>(null);
    const portalRef = useRef<PortalDef | null>(null);
    const getRectCallbackRef = useRef<DropdownGetRectCallback | null>(null);
    const elementMoveObserver = useRef(new ElementMoveObserver());
    const isOpenRef = hooks.useWrapInRef(isOpen);
    const onRequestCloseRef = hooks.useWrapInRef(onRequestClose);

    // isActive is mainly used to force re-render after building the portal el
    const [isActive, setIsActive] = useState(false);

    const getButtonRect = (): FullButtonRect => {
        if (!buttonRef.current) {
            throw errorHelpers.formatError('Dropdown has not received the buttonRef.');
        }

        let buttonRect: DropdownButtonRect;

        if (getRectCallbackRef.current) {
            buttonRect = getRectCallbackRef.current(buttonRef.current);
        } else {
            buttonRect = buttonRef.current.getBoundingClientRect();
        }

        const bodyRect = document.body.getBoundingClientRect();

        return {
            // When body is scrolled (mobile scenario, ios), we need to fix the rect position
            top: buttonRect.top - bodyRect.top,
            left: buttonRect.left - bodyRect.left,
            height: buttonRect.height,
            width: buttonRect.width,
            bottom: bodyRect.height - (buttonRect.top - bodyRect.top) - buttonRect.height,
        };
    };

    /**
     * Manually adjust position of already opened panel. You can use it when either button or panel views
     * have changed its size and don't match any more.
     */
    const adjustPanel = () => {
        if (!portalRef.current) {
            return;
        }

        const panelEl = portalRef.current.el;
        const buttonRect = getButtonRect();
        const viewportRect = getViewportRect();

        const pos = getPosition(panelEl, buttonRect, viewportRect, panelPosition);
        const flow = getFlow(panelEl, buttonRect, viewportRect, panelFlow);
        const w = getWidth(buttonRect, panelMinWidth, panelMaxWidth);

        // separately update the styles as a final step
        if (flow.left != null) {
            panelEl.style.left = '0px';
            panelEl.style.transform = `translate(${flow.left}px, ${pos.top}px)`;
        } else if (flow.right != null) {
            panelEl.style.right = '0px';
            panelEl.style.transform = `translate(-${flow.right}px, ${pos.top}px)`;
        }

        if (w.minWidth) {
            panelEl.style.minWidth = `${w.minWidth}px`;
        } else {
            panelEl.style.minWidth = '';
        }

        if (w.maxWidth) {
            panelEl.style.maxWidth = `${w.maxWidth}${typeof w.maxWidth === 'number' ? 'px' : ''}`;
        } else {
            panelEl.style.maxWidth = '';
        }

        panelEl.classList.toggle('drop-dropdown-position-inverted', pos.invertedPosition);
    };

    const adjustPanelRef = hooks.useWrapInRef(adjustPanel);

    useImperativeHandle(
        ref,
        () => ({
            adjustPanel() {
                adjustPanelRef.current();
            },
        }),
        [adjustPanelRef]
    );

    useLayoutEffect(() => {
        const onGlobalTouchWithoutMove = (event: Event) => {
            if (event.target instanceof HTMLElement) {
                onGlobalMousedown(event.target);
            }
        };

        const onGlobalMousedown = (target: HTMLElement) => {
            if (buttonRef.current === null) {
                // It happens because global mouse event comes BEFORE prop update occurs (nested dropdown case)
                return;
            }

            const isNestedInButton = (testedEl: HTMLElement) => {
                if (!buttonRef.current) {
                    return false;
                }

                return buttonRef.current === testedEl || buttonRef.current.contains(testedEl);
            };

            const isNestedInPanel = (testedEl: HTMLElement) => {
                if (!portalRef.current) {
                    return false;
                }

                return portalRef.current.containsEl(testedEl);
            };

            if (isOpenRef.current && !isNestedInPanel(target) && !isNestedInButton(target)) {
                onRequestCloseRef.current();
            }
        };

        const buildPortal = (buttonEl: HTMLElement) => {
            const portal = reactWindowService.createControlledPortal({
                hostEl: buttonEl,
                transient: true,
                onRequestClose: () => {
                    if (isOpenRef.current) {
                        onRequestCloseRef.current();
                    }
                },
                targetElementId,
            });

            portal.el.classList.add(...([bem('panel'), portalClassName].filter(Boolean) as string[]));
            buttonEl.setAttribute('aria-owns', portal.id);
            buttonEl.setAttribute('aria-controls', portal.id);
            globalEventService.on('window:mousedown:captured', onGlobalMousedown);
            globalEventService.onTouchWithoutMove(onGlobalTouchWithoutMove);
            elementMoveObserver.current.listenToElementMove(buttonEl, () => {
                if (isOpenRef.current) {
                    adjustPanelRef.current();
                }
            });

            return portal;
        };

        const destroyPortal = (portal: PortalDef) => {
            globalEventService.off('window:mousedown:captured', onGlobalMousedown);
            globalEventService.offTouchWithoutMove(onGlobalTouchWithoutMove);
            elementMoveObserver.current.stopListeningToElementMove();
            portal.closeRelatedPortals();
            portal.dispose();
        };

        if (!isOpen) {
            return;
        }

        // Build a portal and render the dropdown
        if (!buttonRef.current) {
            throw errorHelpers.formatError('Dropdown has not received the buttonRef.');
        }

        portalRef.current = buildPortal(buttonRef.current);
        setIsActive(true);

        return () => {
            if (portalRef.current) {
                // Destroy the existing portal & re-render
                destroyPortal(portalRef.current);
                portalRef.current = null;
                setIsActive(false);
            }
        };
    }, [isOpen, adjustPanelRef, isOpenRef, onRequestCloseRef, targetElementId, portalClassName]);

    // Adjust position after every render if panel is open
    useLayoutEffect(() => {
        if (isActive) {
            adjustPanel();
        }
    });

    useEffect(() => {
        if (process.env.DEBUG) {
            // Debug-only validation that buttonRef has been installed
            if (!buttonRef.current) {
                throw errorHelpers.formatError(
                    'Dropdown error: buttonRef is null. Did you forget to set ref parameter in Dropdown.button?'
                );
            }
        }
    }, []);

    let buttonContent;

    if (typeof button === 'function') {
        buttonContent = button(buttonRef);
    } else {
        buttonContent = (
            <div ref={buttonRef as React.Ref<HTMLDivElement>} data-qa={qa} className={className}>
                {button}
            </div>
        );
    }

    return (
        <DropdownContext.Provider
            value={useMemo(
                () => ({
                    register: (getRectCallback) => {
                        getRectCallbackRef.current = getRectCallback;
                    },
                    buttonRef,
                }),
                []
            )}
        >
            {buttonContent}

            {/* portalRef may not exist on first render */}

            {isOpen && portalRef.current && ReactDOM.createPortal(children, portalRef.current!.el)}
        </DropdownContext.Provider>
    );
});

(Dropdown as any).Anchor = DropdownAnchor;

export default Dropdown as typeof Dropdown & {
    Anchor: typeof DropdownAnchor;
};
