import { Button } from '@approvalmax/ui';
import { Box, Spacing } from '@approvalmax/ui/src/components';
import { getConfig } from 'config';
import { NavBarMode, PageLayout, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback } from 'react';

import { messages } from './AuditLogPage.messages';
import { Content, InfoPanel } from './AuditLogPage.styles';

const auditLogUrl = getConfig().auditLogsUrl;

const AuditLogPage: FC = () => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.accounts.any);

    const onClickToAzure = useCallback(() => {
        window.open(auditLogUrl, '_blank', 'noreferrer');
    }, []);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <Toolbar title={messages.pageTitle} />

                <Content>
                    <InfoPanel>
                        {messages.infoPanelText}

                        <Spacing height={12} />

                        <Button colorTheme='aqua' execute={onClickToAzure}>
                            {messages.goToAzureButton}
                        </Button>
                    </InfoPanel>
                </Content>
            </Box>
        </PageLayout>
    );
};

export default memo(AuditLogPage);
