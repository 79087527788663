import { Identifiable, Reference } from '@approvalmax/types';
import React, { PropsWithChildren } from 'react';

export enum DataTablePadding {
    Default = 'padding-default',
    None = 'padding-none',
    PopupLeft = 'padding-popup-left',
    PopupRight = 'padding-popup-right',
    Popup = 'padding-popup',
    Left60 = 'left-60',
    Right60 = 'right-60',
}

export interface DataTableCellComponentProps<TMeta = any, TItem = Identifiable> extends PropsWithChildren {
    item: TItem;
    value: any;
    title?: string;
    checked: boolean;
    meta: TMeta;
    columnDefinition: DataTableColumnDefinition;
    className: string;
    qa?: string;
    style: any;
    padding?: DataTablePadding;
    onAction?: (action: any) => void;
    onCheck: (item: Identifiable, checked: boolean) => void;
    errors?: (Identifiable['id'] | null)[];
}

export interface DataTableColumnHeaderComponentProps<TMeta = any, TItem = Identifiable> {
    meta: TMeta;
    className: string;
    qa?: string;
    style: any;
    columnDefinition: DataTableColumnDefinition;
    items: TItem[];
    onSort?: (columnId: string, newSortOrder: DataTableColumnSortOrder) => void;
    onAction?: (action: any) => void;
    padding?: DataTablePadding;
    checkedItems: string[];
    onCheckedItemsChange: (checkedItems: string[]) => void;
}

export type DataTableColumnSortOrder = 'asc' | 'desc';

export type DataTableColumnFilterType = 'multiple' | 'single' | 'string';

export interface DataTableColumnDefinition<TRow extends Identifiable = Identifiable> {
    id: string;
    value?: (item: TRow) => any;
    title?: (item: TRow) => any;
    cellComponent?: React.ComponentType<DataTableCellComponentProps>;
    columnComponent?: React.ComponentType<DataTableColumnHeaderComponentProps>;
    sortable?: boolean;
    sortOrder?: DataTableColumnSortOrder;
    filterable?: boolean;
    filterKey?: string;
    filterType?: DataTableColumnFilterType;
    filterOptions?: Reference[];
    width?: number;
    padding?: DataTablePadding;
    [customPropName: string]: any;
}
