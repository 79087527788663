import { mods, theme } from '@approvalmax/theme';
import { animated } from '@react-spring/web';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { ChevronUpIcon } from '../../icons';
import { Box } from '../Box/Box';
import { Text } from '../Text/Text';
import { CollapseProps } from './Collapse.types';

export const AnimatedBox = styled(animated.div as any)`
    overflow: hidden;
`;

export const Icon = styled(ChevronUpIcon)`
    transition: ${theme.duration.medium}ms ease-in-out;
    flex: none;
`;

export const Name = styled(Text)`
    transition: ${theme.duration.medium}ms ease-in-out;
    margin-right: auto;
`;

export const Actions = styled.div`
    position: relative;
    z-index: 1;
`;

export const Header = styled(Box)<StyledMods<Pick<CollapseProps, 'collapsed'> & { bgColor: CollapseProps['color'] }>>`
    cursor: pointer;
    transition: ${theme.duration.medium}ms ease-in-out;

    ${mods('bgColor', ['white100', 'silver80', 'red30'])(
        (bgColor) => css`
            ${mods.collapsed.true`
                background-color: ${theme.color[bgColor]};
            `};
        `
    )};

    ${mods.not('bgColor')`
        ${mods.collapsed.true`
            background-color: ${theme.color['midnight20']};
        `};
    `};

    &:hover,
    &:focus {
        ${Icon},
        ${Name} {
            color: ${theme.color.blue100};
        }
    }

    ${mods.collapsed.true`
        ${Icon} {
            transform: rotate(180deg);
        }
    `};

    ${mods.collapsed.false`
        background-color: transparent;
    `}
`;
