import { Field, Popup, TextAreaEditor, TextEditor, usePopupContext } from '@approvalmax/ui';
import { FC, memo, useCallback } from 'react';

import { config } from './BulkOperationPopup.config';
import { useBulkPopupFormData } from './BulkOperationPopup.hooks';
import { messages } from './BulkOperationPopup.messages';
import { Content, Description, Fields } from './BulkOperationPopup.styles';
import { BulkOperationPopupProps } from './BulkOperationPopup.types';

const BulkOperationPopup: FC<BulkOperationPopupProps> = (props) => {
    const { operation } = props;

    const { useOperation, buttonText, title, description } = config[operation];
    const { isLoading, execute } = useOperation();
    const { onRequestClose } = usePopupContext();

    const { formData, formErrors, requestIds, setCompanyId, setTicketId, setComment, setRequestIds, validateForm } =
        useBulkPopupFormData();

    const handleSubmit = useCallback(async () => {
        if (validateForm()) {
            await execute({ ...formData, requestIds });
            onRequestClose();
        }
    }, [validateForm, execute, formData, requestIds, onRequestClose]);

    return (
        <Popup.DefaultContent
            title={title}
            buttons={
                <Popup.DefaultContent.Button execute={handleSubmit} disabled={isLoading}>
                    {buttonText}
                </Popup.DefaultContent.Button>
            }
            css='width: 690px'
        >
            <Content>
                <Description>{description}</Description>

                <Fields>
                    <Field title={messages.companyId} required>
                        <TextAreaEditor
                            value={formData.companyId}
                            onChange={setCompanyId}
                            invalid={formErrors?.companyId}
                            placeholder={messages.companyIdPlaceholder}
                        />
                    </Field>

                    <Field title={messages.requestIds} required>
                        <TextAreaEditor
                            value={formData.requestIds}
                            onChange={setRequestIds}
                            invalid={formErrors?.requestIds && requestIds.length === 0}
                            placeholder={messages.requestIdsPlaceholder}
                            focusOnMount
                        />
                    </Field>

                    <Field title={messages.ticket} required>
                        <TextEditor
                            value={formData.ticketId}
                            onChange={setTicketId}
                            invalid={formErrors?.ticketId}
                            placeholder={messages.ticketIdPlaceholder}
                        />
                    </Field>

                    <Field title={messages.comment} required>
                        <TextAreaEditor
                            value={formData.comment}
                            onChange={setComment}
                            invalid={formErrors?.comment}
                            placeholder={messages.commentPlaceholder}
                        />
                    </Field>
                </Fields>
            </Content>
        </Popup.DefaultContent>
    );
};

export default memo(BulkOperationPopup);
