import { Mods, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const ContentImage = styled.div<StyledMods<Mods<'hide'>>>`
    height: 100%;
    padding: 10px 20px;
    text-align: center;
    white-space: nowrap;
    overflow: auto;

    &::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em;
    }

    ${mods.hide.true`
        visibility: hidden;
    `}
`;

export const ContentImageImg = styled.img<StyledMods<{ zoomed: boolean }>>`
    display: inline-block;
    width: auto;
    height: auto;

    ${mods('zoomed', false)`
        cursor: zoom-in;
        width: 60%;
        height: auto;
    `}

    ${mods('zoomed', true)`
        cursor: zoom-out;
    `}
`;
