import { NativeDataTable, NativeDataTableTheme } from 'modules/components';
import { FC, memo } from 'react';

import { QueryResultTableViewProps } from './QueryResultTableView.types';

const QueryResultTableView: FC<QueryResultTableViewProps> = (props) => {
    const { queryResult } = props;

    return (
        <NativeDataTable theme={NativeDataTableTheme.system} css='height: 100%'>
            <thead>
                <tr>
                    {queryResult.columnNames.map((name, i) => (
                        <th key={i}>{name}</th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {queryResult.columnValues.map((values, i) => (
                    <tr key={i}>
                        {values.map((v, j) => (
                            <td key={j}>{v}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </NativeDataTable>
    );
};

export default memo(QueryResultTableView);
