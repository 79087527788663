import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayPaymentsParams,
    UseAmaxPayPaymentsPathParams,
    UseAmaxPayPaymentsResponse,
} from './useAmaxPayPayments.types';

export const useGetAmaxPayPayments = (
    pathParams: QueryParams<UseAmaxPayPaymentsPathParams>,
    params: UseAmaxPayPaymentsParams,
    queryOptions?: QueryOptions<UseAmaxPayPaymentsResponse>
) => {
    return useGet<UseAmaxPayPaymentsResponse>(amaxPayApiPaths.openBankingPayments, {
        params: {
            path: pathParams,
            query: params,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
