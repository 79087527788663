import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.AmaxPayConsentDetailsPage', {
    pageTitle: 'Amax Pay Consent Details',
    createdAt: 'Created at',
    id: 'ID',
    consentId: 'Consent ID',
    status: 'Status',
    consentHistory: 'Consent history',
    rawData: 'Raw data',
});
