import { QueryOptions } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import { UseAmaxPayPaymentHistoryPathParams, UseAmaxPayPaymentHistoryResponse } from './useAmaxPayPaymentHistory.types';

export const useGetAmaxPayPaymentHistory = (
    pathParams: UseAmaxPayPaymentHistoryPathParams,
    queryOptions?: QueryOptions<UseAmaxPayPaymentHistoryResponse>
) => {
    return useGet<UseAmaxPayPaymentHistoryResponse>(amaxPayApiPaths.openBankingPaymentHistory, {
        params: {
            path: pathParams,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
