import { routerHelpers } from '@approvalmax/utils';

import { QueryParams } from './useQueryParams.types';

export const useQueryParams = routerHelpers.createQueryParamsHook<QueryParams>({
    mapFromQuery(queryString) {
        return {
            query: routerHelpers.getParamAsString(queryString, 'query', ''),
        };
    },
    mapToQuery(params) {
        return {
            query: params.query,
        };
    },
});
