import { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import Switch from '../../Switch';
import { ControllerProps } from './Controller.types';

/**
 * The `Switch.Controller` allows you to use the `Switch` with the `react-hook-form` library.
 * They take the same properties as the `Switch` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, name, rules, children, ...restProps } = props;

        const { field, fieldState } = useController({ control, name, rules });
        const { value, ...restField } = field;

        return (
            <Switch {...restField} {...fieldState} {...restProps} ref={ref} checked={value}>
                {children}
            </Switch>
        );
    })
);

export default Controller;
