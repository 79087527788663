import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestsPage', {
    pageTitle: 'Requests',
    breadcrumbRoot: 'Customer Success',
    filterPlaceholder: 'Request ID',
    bulkOperationsTitle: 'Bulk operations:',
    bulkDeleteButton: 'Delete',
    bulkRestoreButton: 'Restore',
    searchTip: 'Search by RequestID',
    idCol: 'ID',
    nameCol: 'Request name',
    deletedCol: 'Deleted',
    deletedYes: 'Yes',
    deletedNo: 'No',
    searchError: 'No request found or request id is invalid',
});
