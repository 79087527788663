import { Dropdown } from '@approvalmax/ui/src';
import { ArrowLeft, ArrowRight, DeleteIcon } from '@approvalmax/ui/src/old/icons';
import { intl } from '@approvalmax/utils';
import { FC, useCallback, useEffect, useState } from 'react';

import {
    ClearButton,
    DropdownButton,
    MonthGrid,
    MonthValue,
    Nav,
    NavButton,
    Panel,
    ValueContainer,
    YearValue,
} from './MonthPicker.styles';
import { MonthPickerProps } from './MonthPicker.types';
import { checkMonthIsDisabled, getMonthName, getValueString, monthList, parseValue } from './MonthPicker.utils';

const i18nPrefix = 'libs/ui/components/MonthPicker';

const MonthPicker: FC<MonthPickerProps> = (props) => {
    const { value, minValue, maxValue, disabled, className, placeholder, onMonthSelect } = props;

    const [isOpen, setIsOpen] = useState(false);

    const [selectedYear, setSelectedYear] = useState(() => {
        const parsedValue = parseValue(value);

        return parsedValue?.year ?? new Date().getFullYear();
    });

    useEffect(() => {
        const parsedValue = parseValue(value);

        if (parsedValue?.year) {
            setSelectedYear(parsedValue?.year);
        }
    }, [value]);

    const openPicker = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closePicker = useCallback(() => {
        setIsOpen(false);
    }, []);

    const decreaseYear = useCallback(() => {
        setSelectedYear((current) => current - 1);
    }, []);

    const increaseYear = useCallback(() => {
        setSelectedYear((current) => current + 1);
    }, []);

    const parsedValue = parseValue(value);

    return (
        <Dropdown
            button={
                <DropdownButton className={className}>
                    <ValueContainer onClick={openPicker} $disabled={disabled} $parsedValue={Boolean(parsedValue)}>
                        {parsedValue
                            ? `${getMonthName(parsedValue.month - 1)} ${parsedValue.year}`
                            : placeholder || ' '}
                    </ValueContainer>

                    {parsedValue && (
                        <ClearButton
                            title={intl.formatMessage({
                                id: `${i18nPrefix}.clearButtonTitle`,
                                defaultMessage: 'Clear field',
                            })}
                            focusable={false}
                            execute={() => {
                                return onMonthSelect(null);
                            }}
                        >
                            <DeleteIcon width={8} height={8} />
                        </ClearButton>
                    )}
                </DropdownButton>
            }
            isOpen={isOpen}
            onRequestClose={closePicker}
        >
            <Panel>
                <Nav>
                    <NavButton onClick={decreaseYear}>
                        <ArrowLeft />
                    </NavButton>

                    <YearValue>{selectedYear}</YearValue>

                    <NavButton onClick={increaseYear}>
                        <ArrowRight />
                    </NavButton>
                </Nav>

                <MonthGrid>
                    {monthList.map((month, monthIndex) => {
                        const monthValue = getValueString(selectedYear, monthIndex);

                        const isDisabledMonth = checkMonthIsDisabled(monthValue, minValue, maxValue);

                        return (
                            <MonthValue
                                key={month}
                                disabled={isDisabledMonth}
                                onClick={() => {
                                    if (!isDisabledMonth) {
                                        onMonthSelect(monthValue);

                                        closePicker();
                                    }
                                }}
                            >
                                {getMonthName(monthIndex)}
                            </MonthValue>
                        );
                    })}
                </MonthGrid>
            </Panel>
        </Dropdown>
    );
};

export default MonthPicker;
