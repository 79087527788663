import { PropsWithChildren } from 'react';

export interface NativeDataTableProps extends PropsWithChildren {
    theme?: NativeDataTableTheme;
}

export enum NativeDataTableTheme {
    system = 'system',
    standard = 'standard',
}
