import { PascalCaseToCamelCase } from '@approvalmax/types';
import { intl, objectHelpers } from '@approvalmax/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api, QueryKeys, ShardItem, TenantCurrentMovementData, TenantMovementData } from 'modules/api';
import { toaster } from 'modules/components';
import { useMemo } from 'react';

import { messages } from './ManageTenantsPage.messages';
import { ErrorListItem, TenantCurrentMovementTableItemWithId } from './ManageTenantsPage.types';

export const useShardList = () => {
    return useQuery([QueryKeys.ShardsList], () => api.infra.getShardList(), {
        onError: (error: AxiosError<{ errors: any }>) => {
            const data = error.response?.data;

            if (data?.errors) {
                toaster.error(messages.defaultErrorMessage);

                return;
            }
        },
    });
};

export const useMovementData = (companyId: string, shardId: string) => {
    return useQuery(
        [QueryKeys.TenantMovementData, companyId, shardId],
        () => {
            if (companyId && shardId) {
                return api.infra.getTenantMovementData(companyId, shardId);
            } else {
                return Promise.reject('Company ID or Shard ID is not defined');
            }
        },
        {
            enabled: false,
            onError: (error: AxiosError<{ errors: any }>) => {
                const data = error.response?.data;

                if (data?.errors) {
                    toaster.error(messages.defaultErrorMessage);

                    return;
                }
            },
        }
    );
};

export const useCurrentMovement = () => {
    return useQuery([QueryKeys.TenantCurrentMovement], () => api.infra.getTenantCurrentMovement(), {
        // refetch this request every 5 sec
        refetchInterval: 5 * 1000,
        select: (data) => objectHelpers.pascalCaseToCamelCase(data),
        onError: (error: AxiosError<{ errors: any }>) => {
            const data = error.response?.data;

            if (data?.errors) {
                toaster.error(messages.defaultErrorMessage);

                return;
            }
        },
    });
};

export const useCurrentMovementTotalProgress = (data?: PascalCaseToCamelCase<TenantCurrentMovementData>) => {
    if (!data) {
        return {
            progress: 0,
            copiedRowsCount: 0,
            totalRowsCount: 0,
        };
    }

    const rowsInfo = data.tables.reduce<{ copiedRowsCount: number; totalRowsCount: number }>(
        (acc, item) => {
            acc.copiedRowsCount += item.copiedRowsCount;
            acc.totalRowsCount += item.totalRowsCount;

            return acc;
        },
        { copiedRowsCount: 0, totalRowsCount: 0 }
    );

    const progress =
        rowsInfo.copiedRowsCount === rowsInfo.totalRowsCount
            ? 100
            : Math.floor((rowsInfo.copiedRowsCount / rowsInfo.totalRowsCount) * 100);

    return {
        progress,
        ...rowsInfo,
    };
};

export const useStartShardMove = (companyId: string, shardId: string) => {
    return useMutation(() => api.infra.startShardMove(companyId, shardId), {
        onSuccess: (data) => {
            if (data.started) {
                toaster.info(messages.startSuccessMessage);
            } else {
                toaster.error(messages.startFailedErrorMessage);
            }
        },
        onError: () => {
            toaster.error(messages.defaultErrorMessage);
        },
    });
};

export const useCancelShardCurrentMovement = () => {
    return useMutation(() => api.infra.cancelTenantCurrentMovement(), {
        onSuccess: () => {
            toaster.info(messages.canceledMovement);
        },
        onError: () => {
            toaster.error(messages.defaultErrorMessage);
        },
    });
};

export const useTenantMovementErrors = (movementData?: TenantMovementData) => {
    const errorList = useMemo<ErrorListItem[]>(() => {
        if (movementData?.errors) {
            return movementData.errors.map((item, index) => ({
                id: `error-${index}`,
                errorText: item,
            }));
        }

        return [];
    }, [movementData]);

    const hasAlreadyStartedError = useMemo(() => {
        return (
            errorList.findIndex((item) =>
                /Move process of tenant .* to shard .* has already been started./.test(item.errorText)
            ) !== -1
        );
    }, [errorList]);

    return { errorList, hasAlreadyStartedError };
};

export const useCurrentTenantMovement = (
    currentMovementData: ReturnType<typeof useCurrentMovement>['data'],
    shardList?: ShardItem[]
) => {
    const currentMovementTablesData = useMemo<TenantCurrentMovementTableItemWithId[]>(() => {
        return (
            currentMovementData?.tables.map((table) => ({
                ...table,
                id: String(table.order),
            })) ?? []
        );
    }, [currentMovementData]);

    const totalCurrentMovementProgress = useCurrentMovementTotalProgress(currentMovementData);

    const currentCopiedRows = intl.formatNumber(totalCurrentMovementProgress.copiedRowsCount, 0, false, ' ');
    const currentTotalRows = intl.formatNumber(totalCurrentMovementProgress.totalRowsCount, 0, false, ' ');

    const currentMovementTargetShard = useMemo(() => {
        return shardList?.find((shard) => shard.id === currentMovementData?.state.entityState?.targetShardId) ?? null;
    }, [currentMovementData, shardList]);

    return {
        currentMovementTablesData,
        currentMovementProgress: totalCurrentMovementProgress.progress,
        currentCopiedRows,
        currentTotalRows,
        currentMovementTargetShard,
    };
};
