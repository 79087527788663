import { Mods, mods } from '@approvalmax/theme';
import { font, uTransparentLink } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-flow: column;
    background: #fff;
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

export const NavigationItem = styled.div<Mods<'active'>>`
    > * {
        display: flex;
        align-items: center;
        height: 40px;
        margin: 0 15px 0 30px;
        padding: 0 10px;
        ${uTransparentLink};
        ${font(12, '#000')};

        &:hover {
            background: #f5f5f5;
        }

        ${mods.active.true`
            ${font(12, '#000', 'bold')};
            background: #e9eef0;
        `};
    }
`;

export const NavigationSection = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    margin: 0 10px;
    padding: 0 10px;
    ${uTransparentLink};
    ${font(12, '#000', 'bold')};
`;
