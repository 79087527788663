import { argumentError } from './argumentError';
import { assertArgumentNotFalsy } from './assertArgumentNotFalsy';
import { assertNever } from './assertNever';
import { captureException } from './captureException';
import { createError } from './createError';
import { formatError } from './formatError';
import { getErrorCode } from './getErrorCode';
import { invalidOperationError } from './invalidOperationError';
import { isApiError } from './isApiError';
import { notFoundError } from './notFoundError';
import { notImplementedError } from './notImplementedError';
import { notSupportedError } from './notSupportedError';
import { throwArgumentError } from './throwArgumentError';
import { throwError } from './throwError';
import { throwInvalidOperationError } from './throwInvalidOperationError';
import { throwNotFoundError } from './throwNotFoundError';
import { throwNotSupportedError } from './throwNotSupportedError';

export const errorHelpers = {
    argumentError,
    assertArgumentNotFalsy,
    assertNever,
    captureException,
    createError,
    formatError,
    invalidOperationError,
    notFoundError,
    isApiError,
    notImplementedError,
    notSupportedError,
    throwArgumentError,
    throwError,
    throwInvalidOperationError,
    throwNotFoundError,
    throwNotSupportedError,
    getErrorCode,
};
