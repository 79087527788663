import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    background: #fff6e9;
    border: 1px solid #d6da92;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    ${font(12, '#000')};

    & > :first-child {
        margin-right: 10px;
    }
`;

export const Icon = styled.div`
    line-height: 12px;
`;
