import { useEffect, useState } from 'react';

import { SwitchProps } from './Switch.types';

export const useChecked = (checked: SwitchProps['checked']) => {
    const [checkedValue, setCheckedValue] = useState(checked);

    useEffect(() => setCheckedValue(checked), [checked]);

    return {
        checkedValue,
        setCheckedValue,
    };
};

export const useValidate = (invalid: SwitchProps['invalid']) => {
    const [invalidValue, setInvalidValue] = useState(invalid);

    useEffect(() => setInvalidValue(invalid), [invalid]);

    return {
        invalidValue,
        setInvalidValue,
    };
};
