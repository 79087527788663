import { getParamsFromPath } from './getParamsFromPath';

export const testPathByParams = <Params extends Record<string, any>>(path: string, params?: Params) => {
    const pathRequiredParams = /:/g.test(path);

    if (!pathRequiredParams) return true;
    if (!params || !Object.keys(params)) return false;

    const pathParams = getParamsFromPath(path);

    return pathParams.every((param) => params[param]);
};
