import { Button } from '@approvalmax/ui';
import { eventHelpers } from '@approvalmax/utils';
import { memo } from 'react';

import { messages } from './UnknownDocumentViewer.messages';
import {
    ContentUnknown,
    ContentUnknownCard,
    ContentUnknownCardDescription,
    ContentUnknownCardDownloadLink,
    ContentUnknownCardTitle,
} from './UnknownDocumentViewer.styles';
import { UnknownDocumentViewerProps } from './UnknownDocumentViewer.types';

const UnknownDocumentViewer = memo<UnknownDocumentViewerProps>((props) => {
    const { attachment, hasError, description } = props;

    return (
        <ContentUnknown>
            <ContentUnknownCard onClick={eventHelpers.stopPropagationCallback}>
                <ContentUnknownCardTitle title={attachment.name}>{attachment.name}</ContentUnknownCardTitle>

                <ContentUnknownCardDescription>
                    {hasError ? messages.loadErr : description || messages.cannotPreview}
                </ContentUnknownCardDescription>

                <ContentUnknownCardDownloadLink href={attachment.url} target='_blank' rel='noopener noreferrer'>
                    <Button>{messages.downloadBtnInfo}</Button>
                </ContentUnknownCardDownloadLink>
            </ContentUnknownCard>
        </ContentUnknown>
    );
});

export default UnknownDocumentViewer;
