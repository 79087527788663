import take from 'lodash/take';

import { throwError } from '../error/throwError';

/**
 * Allows to perform validation of property in an object. Allows to check both direct and nested properties of the object.
 * Throws <code>MissingOptionError</code> if the attribute is undefined.
 * @example
 * // Checks that property this.view.moduleRegion exists.
 * core.utils.helpers.ensureOption(this.view, 'moduleRegion');
 * @param {Object} object An object to check.
 * @param {String} propertyName Property name or dot-separated property path.
 * */
export const ensureProperty = (object: any, propertyName: string) => {
    if (!object) {
        throwError('The object is null.', 'NullObjectError');
    }

    if (propertyName.indexOf('.') !== -1) {
        const selector = propertyName.split('.');

        for (let i = 0, len = selector.length; i < len; i++) {
            propertyName = selector[i];

            if (object[propertyName] === undefined) {
                propertyName = take(selector, i + 1).join('.');
                throwError('The property `' + propertyName + '` is required.', 'MissingPropertyError');
            }

            object = object[propertyName];
        }
    } else {
        if (object[propertyName] === undefined) {
            throwError('The property `' + propertyName + '` is required.', 'MissingPropertyError');
        }
    }
};
