import { escapeExpression } from '../string/escapeExpression';

/**
 * Highlights fragments within a text with &lt;span class='highlight'&gt;&lt;/span&gt;.
 * @param {String} text Text to highlight.
 * @param {String} fragment highlighted fragment.
 * @param {Boolean} escape If true, the text will be html-escaped before highlighting (so it's safe to show to the user).
 * @param {String} className CSS class used to highlight found fragments.
 * @return {String} Highlighted text
 * */
export const highlightText = (text: string, fragment: string, escape: boolean, className: string) => {
    if (!text) {
        return '';
    }

    if (!fragment) {
        return text;
    }

    fragment = fragment.toLowerCase();

    let lowerText = text.toLowerCase();
    let startIndex = 0;
    let index;
    let output = '';
    // eslint-disable-next-line
    while ((index = lowerText.indexOf(fragment, startIndex)) !== -1) {
        let index2 = index + fragment.length;

        let newText = text.substring(index, index2);

        if (escape) {
            newText = escapeExpression(newText);
        }

        output += `${text.substring(startIndex, index)}<span class='${className}'>${newText}</span>`;
        startIndex = index2;
    }

    if (startIndex < text.length) {
        let newText = text.substring(startIndex);

        if (escape) {
            newText = escapeExpression(newText);
        }

        output += newText;
    }

    return output;
};
