import { useAnimatedHeight } from './useAnimatedHeight';
import { useBackwardReference } from './useBackwardReference';
import { useCaptureFocus } from './useCaptureFocus';
import { useComposedRefs } from './useComposedRefs';
import { useCopyToClipboard } from './useCopyToClipboard';
import { useDebounce } from './useDebounce';
import { useDragAndDropInfo } from './useDragAndDropInfo';
import { useEndOfLoadingWithTimeout } from './useEndOfLoadingWithTimeout';
import { useEnhancedEffect } from './useEnhancedEffect';
import { useFocusOnMount } from './useFocusOnMount';
import { useForkRef } from './useForkRef';
import { useForwardedRef } from './useForwardedRef';
import { useFuzzySearch, useSearchWithFuse } from './useFuzzySearch';
import { useId } from './useId';
import { useIsLocaleExperimentOn } from './useIsLocaleExperimentOn';
import { useLoadMoreOnObserve } from './useLoadMoreOnObserve';
import { useLoadMoreOnScroll } from './useLoadMoreOnScroll';
import { useLocalizationMessages } from './useLocalizationMessages';
import { useLocalStorage } from './useLocalStorage';
import { useMonthsItems } from './useMonthsItems';
import { useOnlyClientRendering } from './useOnlyClientRendering';
import { useOnMount } from './useOnMount';
import { usePrevious } from './usePrevious';
import { useQueryParam } from './useQueryParam';
import { useSticky } from './useSticky';
import { useToggle } from './useToggle';
import { useUnmountedRef } from './useUnmountedRef';
import { useUrlSearchParams } from './useUrlSearchParams';
import { useWrapInRef } from './useWrapInRef';

export type { BackwardReferenceData } from './useBackwardReference';
export type { CopyToClipboardCallback } from './useCopyToClipboard';
export type { FuseSearchKeys } from './useFuzzySearch';

export const hooks = {
    useCaptureFocus,
    useEnhancedEffect,
    useForkRef,
    useDebounce,
    usePrevious,
    useIsLocaleExperimentOn,
    useDragAndDropInfo,
    useUnmountedRef,
    useWrapInRef,
    useAnimatedHeight,
    useId,
    useLocalStorage,
    useForwardedRef,
    useFuzzySearch,
    useSearchWithFuse,
    useComposedRefs,
    useFocusOnMount,
    useOnMount,
    useOnlyClientRendering,
    useLocalizationMessages,
    useToggle,
    useCopyToClipboard,
    useBackwardReference,
    useSticky,
    useMonthsItems,
    useLoadMoreOnObserve,
    useLoadMoreOnScroll,
    useUrlSearchParams,
    useQueryParam,
    useEndOfLoadingWithTimeout,
};
