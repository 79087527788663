export const downloadBlob = (blobParts: BlobPart[], options: { fileName: string; type: string; extension: string }) => {
    const { extension, fileName, type } = options;
    const blob = new Blob(blobParts, { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}.${extension}`);
    a.click();
};
