import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useSaveUserBetaFeatures = () => {
    const queryClient = useQueryClient();

    const { isLoading, mutate: saveUserBetaFeature } = useMutation(
        (options: { userId: string; betaFeatures: string[] }) =>
            api.internalSupport.saveUserBetaFeatures(options.userId, options),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.Users]);
            },
        }
    );

    return {
        saveUserBetaFeature,
        isLoading,
    };
};
