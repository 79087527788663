import { FC } from 'react';
import bemFactory from 'react-bem-factory';

import { Actions, Button, Description, Root, Title } from './ConfirmationPopupContent.styles';
import { ConfirmationPopupContentProps } from './ConfirmationPopupContent.types';

const qa = bemFactory.qa('ui-unsaved-changes-popup-content');

export const ConfirmationPopupContent: FC<ConfirmationPopupContentProps> = (props) => {
    const {
        onConfirm,
        onCancel,
        title,
        description,
        cancelButtonText,
        confirmButtonText,
        cancelButtonTitle,
        confirmButtonTitle,
    } = props;

    return (
        <Root>
            <Title>{title}</Title>

            <Description>{description}</Description>

            <Actions>
                <Button
                    qa={qa('discard-button')}
                    title={cancelButtonTitle}
                    colorTheme='red'
                    size='large'
                    execute={onCancel}
                >
                    {cancelButtonText}
                </Button>

                <Button qa={qa('continue-button')} title={confirmButtonTitle} size='large' execute={onConfirm}>
                    {confirmButtonText}
                </Button>
            </Actions>
        </Root>
    );
};

ConfirmationPopupContent.displayName = 'ConfirmationPopupContent';
