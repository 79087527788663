import { FC, memo } from 'react';

import { Inner, Sliding } from './components';
import { DrawerProps } from './Drawer.types';

const Drawer: FC<DrawerProps> = (props) => {
    const { isSliding, drawerWidth } = props;

    if (isSliding) return <Sliding drawerWidth={drawerWidth} />;

    return <Inner />;
};

Drawer.displayName = 'Drawer';

export default memo(Drawer);
