import { NavBar } from 'modules/components';
import { FC } from 'react';

import { useDocumentTitle } from './PageLayout.hooks';
import { ContentBody, ContentBodyInner, ContentHeader, HeaderBg, Root } from './PageLayout.styles';
import { PageLayoutProps, PageLayoutWidthBehavior } from './PageLayout.types';

const PageLayout: FC<PageLayoutProps> = (props) => {
    const { children, documentTitle, widthBehavior = PageLayoutWidthBehavior.contentBased } = props;

    useDocumentTitle(documentTitle);

    return (
        <Root>
            <HeaderBg />

            <ContentHeader>
                <NavBar {...props} />
            </ContentHeader>

            <ContentBody>
                <ContentBodyInner fixedWidth={widthBehavior === PageLayoutWidthBehavior.fixed}>
                    {children}
                </ContentBodyInner>
            </ContentBody>
        </Root>
    );
};

export default PageLayout;
