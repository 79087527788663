import { Button, DataTable, DataTableCellComponentProps, Popup } from '@approvalmax/ui';
import { StorageQueueMessage } from 'modules/api';
import { FC, memo, useCallback, useState } from 'react';

import { MessageDetailsPopup } from '../.';
import { messages } from './ActionsCell.messages';
import { CellContent } from './ActionsCell.styles';

const ActionsCell: FC<DataTableCellComponentProps<any, StorageQueueMessage>> = (props) => {
    const { item: message } = props;

    const [showDetails, setShowDetails] = useState(false);

    const onViewDetails = useCallback(() => {
        setShowDetails(true);
    }, []);

    const onRequestClose = useCallback(() => {
        setShowDetails(false);
    }, []);

    return (
        <DataTable.DefaultCell {...props}>
            <CellContent>
                <Button execute={onViewDetails} preset='compact'>
                    {messages.detailsButton}
                </Button>
            </CellContent>

            <Popup isOpen={showDetails} onRequestClose={onRequestClose}>
                <MessageDetailsPopup message={message} />
            </Popup>
        </DataTable.DefaultCell>
    );
};

export default memo(ActionsCell);
