/**
 * Developer: Stepan Burguchev
 * Date: 6/2/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './externalLink.scss';

import { FC, PropsWithChildren } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('ui-external-link');

export interface ExternalLinkProps extends PropsWithChildren {
    href: string;
    className?: string;
}

const ExternalLink: FC<ExternalLinkProps> = (props) => {
    const { className, children, href } = props;

    return (
        <a className={bem.add(className)()} href={href} target='_blank' rel='noopener noreferrer'>
            {children}
        </a>
    );
};

export default ExternalLink;
