import { mixins, mods, theme } from '@approvalmax/theme';
import { Box } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { LabelProps } from './Label.types';

export const Sub = styled.sub`
    vertical-align: top;
    color: ${theme.color.red100};
`;

export const Root = styled(Box)<StyledMods<Pick<LabelProps, 'color' | 'size' | 'wrap'>>>`
    ${mods.color(mixins.color)};

    ${mods.size(
        (value) => css`
            &,
            ${Sub} {
                ${mixins.font('label', value)};
            }
        `
    )};

    ${mods.wrap.false`
        white-space: nowrap;
    `};
`;
