import { useEffect } from 'react';

/**
 * Only triggers the effect when the component is mounted.
 * Similar to `useEffect(() => {}, [])` with a difference that
 * you don't need to disable the ESLint rule if there are dependencies.
 * The main purpose is to make the code cleaner and explicitly show the indent.
 *
 * @deprecated use `useMount` from `react-use`
 */
export const useOnMount = (callback: () => void) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, []);
};
