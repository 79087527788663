import { CloseIcon, DrawerIcon } from '@approvalmax/ui/src/old/icons';
import { useDrawerContext } from 'modules/components';
import React, { FC, memo, useMemo } from 'react';

import { UserMenu } from './components';
import { AvatarBlock, BreadcrumbIcon, Breakcrumbs, CloseButton, DrawerButton, Root } from './NavBar.styles';
import { NavBarMode, NavBarProps } from './NavBar.types';

const NavBar: FC<NavBarProps> = (props) => {
    const { breadcrumbs, mode, onClose, hideAvatarMenu } = props;

    const { onOpen: openDrawer, isPinned } = useDrawerContext();

    const isDrawerNotPinned = useMemo(() => mode === NavBarMode.withDrawer && !isPinned, [isPinned, mode]);

    return (
        <Root>
            {mode === NavBarMode.withCross && (
                <CloseButton execute={onClose}>
                    <CloseIcon width={14} height={14} />
                </CloseButton>
            )}

            {isDrawerNotPinned && (
                <DrawerButton execute={openDrawer}>
                    <DrawerIcon width={24} height={14} />
                </DrawerButton>
            )}

            <Breakcrumbs clickable={isDrawerNotPinned} onClick={isDrawerNotPinned ? openDrawer : undefined}>
                {breadcrumbs.flatMap((b, i) => [
                    i !== 0 ? <BreadcrumbIcon key={`bc-icon-${i}`} width={8} height={16} /> : null,
                    <React.Fragment key={`bc-item-${i}`}>{b}</React.Fragment>,
                ])}
            </Breakcrumbs>

            {!hideAvatarMenu && (
                <AvatarBlock>
                    <UserMenu />
                </AvatarBlock>
            )}
        </Root>
    );
};

export default memo(NavBar);
