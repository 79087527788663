import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { JsonViewer } from 'modules/components';
import { FC, memo } from 'react';

import { useVersionDetailsData } from './VersionDetails.hooks';
import { VersionDetailsProps } from './VersionDetails.types';

const VersionDetails: FC<VersionDetailsProps> = memo((props) => {
    const { companyId, workflowId, versionId } = props;

    const { isInitialLoading, versionData } = useVersionDetailsData(companyId, workflowId, versionId);

    return (
        <Box spacing='20 0'>
            {isInitialLoading ? <LoadingSpinner /> : <JsonViewer json={versionData?.workflowVersionRawData ?? null} />}
        </Box>
    );
});

export default VersionDetails;
