import decimal from './lib/commonjs/decimal';
import mathEval from './mathEval';

class MathService {
    public multiply(num1: number, num2: number): number {
        return decimal.multiply(num1, num2);
    }

    public add(num1: number, num2: number): number {
        return decimal.add(num1, num2);
    }

    public subtract(num1: number, num2: number): number {
        return decimal.subtract(num1, num2);
    }

    public divide(num1: number, num2: number): number {
        return decimal.divide(num1, num2);
    }

    public round(num: number, degree?: number): number {
        return decimal.getRounded(num, degree);
    }

    public eval(str: string) {
        return mathEval(str);
    }
}

export const mathService = new MathService();
