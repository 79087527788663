import { useMutation } from '@tanstack/react-query';
import { api } from 'modules/api';
import { useState } from 'react';

export const useDisable2FA = (userId: string) => {
    const { isLoading, mutateAsync: disable2FA } = useMutation(
        (options: { comment: string; supportTicketId: string }) => api.internalSupport.disable2FA(userId, options)
    );

    return {
        disable2FA,
        isLoading,
    };
};

export const useCommentField = () => {
    const [comment, setComment] = useState('');

    const isInvalid = comment.trim().length < 10;

    return {
        comment,
        isInvalid,
        onCommentChange: setComment,
    };
};

export const useTicketNumberField = () => {
    const [ticketNumber, setTicketNumber] = useState('');

    const isInvalid = ticketNumber.trim().length < 3 || /[^\d]/.test(ticketNumber);

    return {
        ticketNumber,
        isInvalid,
        onTicketNumberChange: setTicketNumber,
    };
};
