import { initMods } from '@styled-kit/mods';

import { config } from './config';
import * as allMixins from './mixins';

export * from './constants';
export { ThemeProvider, useTheme } from './context';
export type { Mods, RequiredMods } from './types';
export * from './types';
export * from './variablels';

export const mods = initMods(config);
export const mixins = allMixins;
