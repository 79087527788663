/**
 * Developer: Stepan Burguchev
 * Date: 6/2/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './field.scss';

import { hooks } from '@approvalmax/utils';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import bemFactory from 'react-bem-factory';

export const ALL_FIELD_COLOR_THEMES = ['default', 'bold-black'] as const;

export type FieldColorTheme = (typeof ALL_FIELD_COLOR_THEMES)[number];

export const ALL_FIELD_THEMES = ['form', 'form-horizontal'] as const;

export type FieldTheme = (typeof ALL_FIELD_THEMES)[number];

export const ALL_FIELD_SIZES = ['default', 'size-11'] as const;

export type FieldSize = (typeof ALL_FIELD_SIZES)[number];

export interface FieldProps extends PropsWithChildren {
    title: React.ReactNode;
    className?: string;
    required?: boolean;
    colorTheme?: FieldColorTheme;
    theme?: FieldTheme;
    size?: FieldSize;
    wrapLabel?: boolean;
    errorText?: ReactNode;
}

export interface FieldContextType {
    fieldId: string | undefined;
}

export const FieldContext = React.createContext<FieldContextType>({
    fieldId: undefined,
});

const Field: FC<FieldProps> = (props) => {
    const {
        required,
        children,
        title,
        className,
        colorTheme = 'default',
        theme = 'form',
        size = 'default',
        wrapLabel,
        errorText,
    } = props;

    const bem = bemFactory.block('form-field').themed(theme);

    const fieldId = hooks.useId();

    return (
        <div
            className={bem.add(className)(null, {
                required,
            })}
        >
            <label className={bem('label', colorTheme, size, { 'wrap-label': wrapLabel })} htmlFor={fieldId}>
                {title}
            </label>

            <FieldContext.Provider
                value={useMemo(
                    () => ({
                        fieldId,
                    }),
                    [fieldId]
                )}
            >
                {children}
            </FieldContext.Provider>

            {errorText && <div className={bem('error-text')}>{errorText}</div>}
        </div>
    );
};

export default Field;
