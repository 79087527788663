export const config = {
    color: [
        'transparent',

        'white100',

        'black100',

        'mint100',
        'mint90',
        'mint80',
        'mint70',
        'mint60',
        'mint50',
        'mint40',

        'silver100',
        'silver90',
        'silver80',

        'green100',
        'green90',
        'green80',
        'green70',
        'green60',
        'green50',
        'green40',
        'green30',
        'green20',

        'midnight100',
        'midnight90',
        'midnight80',
        'midnight70',
        'midnight60',
        'midnight50',
        'midnight40',
        'midnight30',
        'midnight20',

        'blue100',
        'blue90',
        'blue80',
        'blue70',
        'blue60',
        'blue50',
        'blue40',
        'blue30',
        'blue20',
        'blue10',

        'cobalt100',
        'cobalt90',
        'cobalt80',
        'cobalt70',
        'cobalt60',
        'cobalt50',

        'red100',
        'red90',
        'red80',
        'red70',
        'red60',
        'red50',
        'red40',
        'red30',
        'red20',

        'brandXero100',
        'brandXero90',
        'brandXero80',
        'brandXero70',

        'brandQBO100',
        'brandQBO90',
        'brandQBO80',
        'brandQBO70',

        'brandIntuit100',

        'brandCin7100',
        'brandCin790',
        'brandCin780',
        'brandCin770',

        'brandDext100',
        'brandDext90',

        'brandDextMustard100',
        'brandDextMustard90',
        'brandDextMustard80',
        'brandDextMustard70',

        'brandAWX100',
        'brandAWX90',
        'brandAWX80',

        'brandNetSuite100',
    ],
    gradient: ['green100', 'green70', 'green50', 'green20'],
    font: ['headline', 'body', 'label'],
    fontSize: ['xxsmall', 'xsmall', 'small', 'medium', 'large'],
    fontWeight: ['regular', 'medium', 'bold'],
    fontFamily: ['inter'],
    textAlign: ['left', 'center', 'right'],
    radius: ['xxsmall', 'xsmall', 'small', 'medium', 'large'],
    shadow: ['xxsmall', 'xsmall', 'small', 'medium'],
    duration: ['fast', 'medium', 'slow'],
    size: [
        'uxxsmall',
        'uxsmall',
        'usmall',
        'xxsmall',
        'xsmall',
        'small',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
        'ularge',
        'uxlarge',
        'uxxlarge',
    ],
    orientation: ['horizontal', 'vertical'],
    containerViewport: ['xxsmall', 'xsmall', 'small', 'medium', 'large'],
    containerBreakpoint: ['xxsmall', 'xsmall', 'small', 'medium', 'large'],
    spacing: [0, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100],
    iconSize: [8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64],
    ellipsis: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    borderWidth: [0, 1, 2, 3, 4, 5],
    active: [true, false],
    empty: [true, false],
    animated: [true, false],
    bordered: [true, false],
    block: [true, false],
    checked: [true, false],
    clickable: [true, false],
    closable: [true, false],
    clearable: [true, false],
    collapsed: [true, false],
    disabled: [true, false],
    divider: [true, false],
    indeterminate: [true, false],
    invalid: [true, false],
    focus: [true, false],
    hover: [true, false],
    hide: [true, false],
    open: [true, false],
    outline: [true, false],
    progress: [true, false],
    required: [true, false],
    resizing: [true, false],
    rounded: [true, false],
    readOnly: [true, false],
    uppercase: [true, false],
    lineThrough: [true, false],
    wrap: [true, false],
    pinned: [true, false],
    sticky: [true, false],
    drag: [true, false],
    redesign: [true, false],
    done: [true, false],
    last: [true, false],
    first: [true, false],
    selected: [true, false],
    fullScreen: [true, false],
    removed: [true, false],
    highlight: [true, false],
} as const;
