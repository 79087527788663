/**
 * Developer: Stepan Burguchev
 * Date: 3/7/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './dataTable.scss';

import { Identifiable } from '@approvalmax/types';
import { errorHelpers, objectHelpers } from '@approvalmax/utils';
import { Component, createRef } from 'react';
import bemFactory from 'react-bem-factory';

import TextButton from '../button/TextButton';
import { ScrollableArea } from '../scroll/ScrollableArea';
import CheckboxCell from './CheckboxCell';
import CheckboxColumnHeader from './CheckboxColumnHeader';
import DataRow from './DataRow';
import DefaultCell from './DefaultCell';
import DefaultColumnHeader from './DefaultColumnHeader';
import { DataTableColumnDefinition, DataTableColumnSortOrder } from './index';

interface DataTableProps {
    columns: DataTableColumnDefinition[];
    data: Identifiable[];
    meta?: any;
    className?: string;
    qa?: string;
    onSort?: (columnId: string, newSortOrder: DataTableColumnSortOrder) => void;
    onAction?: (action: any) => void;
    checkedItems?: string[];
    onCheckedItemsChange?: (checkedItems: string[]) => void;
    performanceUpdateOnColumnsChange?: boolean;
    autoHeight?: boolean;
    paddingBottom?: number;
    onRowClick?: (item: Identifiable) => void;
    errors?: (Identifiable['id'] | null)[];
    showLoadMoreButton?: boolean;
    onLoadMore?: () => void;
    isFetchingMore?: boolean;
}

interface OwnState {
    meta: any;
}

class DataTable extends Component<DataTableProps, OwnState> {
    public static CheckboxCell = CheckboxCell;
    public static DefaultCell = DefaultCell;
    public static DefaultColumnHeader = DefaultColumnHeader;
    public static CheckboxColumnHeader = CheckboxColumnHeader;

    private _bodyWrpEl!: HTMLElement;
    private _scrollbarPadding!: number;
    headRef = createRef<HTMLDivElement>();

    constructor(props: DataTableProps) {
        super(props);
        this.state = {
            meta: props.meta,
        };
    }

    public shouldComponentUpdate(nextProps: DataTableProps) {
        const diff = objectHelpers.getShallowDifference(this.props, nextProps);

        // noinspection RedundantIfStatementJS
        if (
            diff.meta &&
            Object.keys(diff).length === 1 &&
            objectHelpers.shallowEqualObjects(this.props.meta, nextProps.meta)
        ) {
            // Do not update if only meta has changed (shallowly)
            return false;
        }

        return true;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: DataTableProps) {
        if (!objectHelpers.shallowEqualObjects(this.props.meta, nextProps.meta)) {
            this.setState({
                meta: nextProps.meta,
            });
        }
    }

    public componentDidMount() {
        const scrollbarWidth = this._bodyWrpEl.offsetWidth - this._bodyWrpEl.clientWidth;

        if (this.headRef.current) {
            this.headRef.current.style.paddingRight = `${scrollbarWidth}px`;
        }

        this._scrollbarPadding = scrollbarWidth;
    }

    public componentDidUpdate() {
        const scrollbarWidth = this._bodyWrpEl.offsetWidth - this._bodyWrpEl.clientWidth;

        if (this.headRef.current && this._scrollbarPadding !== scrollbarWidth) {
            this.headRef.current.style.paddingRight = `${scrollbarWidth}px`;
        }
    }

    public render() {
        const {
            className = '',
            qa: qa$,
            data,
            onAction,
            columns: columnDefinitions,
            performanceUpdateOnColumnsChange,
            autoHeight,
            checkedItems = [],
            paddingBottom,
            onRowClick,
            errors,
            showLoadMoreButton,
            onLoadMore,
            isFetchingMore,
        } = this.props;
        const bem = bemFactory.block('ui-data-table');
        const qa = bemFactory.qa('ui-data-table');

        const columns = columnDefinitions.map((colDef) => {
            const ColumnHeader = colDef.columnComponent || DefaultColumnHeader;

            return (
                <ColumnHeader
                    columnDefinition={colDef}
                    meta={this.state.meta}
                    key={colDef.id}
                    qa={qa('column-header')}
                    onSort={this.props.onSort}
                    items={data}
                    onAction={onAction}
                    style={{ width: colDef.width }}
                    checkedItems={checkedItems}
                    onCheckedItemsChange={this._onCheckItemsChange}
                    className={bem('column-header', { fixed: colDef.width })}
                    padding={colDef.padding}
                />
            );
        });

        const rows = data.map((item) => {
            return (
                <DataRow
                    key={item.id}
                    qa={qa('row')}
                    item={item}
                    checked={checkedItems.includes(item.id)}
                    onCheck={this._onItemCheck}
                    columns={columnDefinitions}
                    meta={this.state.meta}
                    performanceUpdateOnColumnsChange={performanceUpdateOnColumnsChange}
                    onAction={onAction}
                    autoHeight={autoHeight}
                    onClick={onRowClick}
                    errors={errors}
                />
            );
        });

        return (
            <div className={bem.add(className)()} data-qa={qa$}>
                <div className={bem('table-head')} data-qa={qa('table-head')} ref={this.headRef}>
                    {columns}
                </div>

                <ScrollableArea className={bem('table-body-wrp')} scrollableRef={(ref) => (this._bodyWrpEl = ref!)}>
                    <div
                        className={bem('table-body')}
                        data-qa={qa('table-body')}
                        style={paddingBottom ? { paddingBottom } : {}}
                    >
                        {rows}

                        {showLoadMoreButton && onLoadMore && (
                            <div className={bem('load-more-btn-container')}>
                                <TextButton disabled={isFetchingMore} execute={onLoadMore}>
                                    Load More
                                </TextButton>
                            </div>
                        )}
                    </div>
                </ScrollableArea>
            </div>
        );
    }

    private _onCheckItemsChange = (checkedItems: string[]) => {
        if (!this.props.onCheckedItemsChange) {
            throw errorHelpers.invalidOperationError(
                'Property `onCheckedItemsChange` is mandatory if CheckboxColumnHeader is used.'
            );
        }

        this.props.onCheckedItemsChange(checkedItems);
    };

    private _onItemCheck = (item: Identifiable, checked: boolean) => {
        if (!this.props.onCheckedItemsChange) {
            throw errorHelpers.invalidOperationError(
                'Property `onCheckedItemsChange` is mandatory if CheckboxCell is used.'
            );
        }

        const checkedItems = this.props.checkedItems || [];

        let newCheckedItems = checkedItems;

        if (checked) {
            if (!checkedItems.includes(item.id)) {
                newCheckedItems = checkedItems.concat(item.id);
                this.props.onCheckedItemsChange(newCheckedItems);
            }
        } else {
            if (checkedItems.includes(item.id)) {
                newCheckedItems = checkedItems.filter((x) => x !== item.id);
                this.props.onCheckedItemsChange(newCheckedItems);
            }
        }
    };
}

export default DataTable;
