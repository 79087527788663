const EMAIL_FRAGMENT =
    '(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))' +
    '@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^!`\'<>()[\\]\\.,;:\\s@\\"0-9]{2,})';

const emailRegex = new RegExp(`^${EMAIL_FRAGMENT}$`, 'i');
const outlookEmailRegex = new RegExp(`^([^<]*)<(${EMAIL_FRAGMENT})>$`, 'i');

export const parseEmail = (possibleEmail: string | null | undefined): string | null => {
    if (!possibleEmail) {
        return null;
    }

    possibleEmail = possibleEmail.trim().toLowerCase();

    // email@address.com
    let match = possibleEmail.match(emailRegex);

    if (match) {
        return (match[0] || '').toLowerCase();
    }

    // name <email@address.com>
    match = possibleEmail.match(outlookEmailRegex);

    if (match) {
        return (match[2] || '').toLowerCase();
    }

    return null;
};
