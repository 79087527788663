import { font, oPopupPadding } from '@approvalmax/ui';
import styled from 'styled-components';

export const Content = styled.div`
    ${oPopupPadding};
`;

export const Label = styled.div`
    margin-bottom: 10px;
    ${font(14, '#000', 'semibold')}
`;
