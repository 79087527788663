import './actionMenuTextButton.scss';

import { DropdownTriangleIcon } from '@approvalmax/ui/src/old/icons';
import { Component } from 'react';
import bemFactory from 'react-bem-factory';

import TransparentButton from '../button/TransparentButton';
import { BaseButtonProps } from '../button/types';

const bem = bemFactory.block('ui-action-menu-text-button');

class ActionMenuTextButton extends Component<BaseButtonProps> {
    public render() {
        const { children, className, ...buttonProps } = this.props;

        return (
            <TransparentButton {...buttonProps}>
                {({ elementProps }) => (
                    <button {...elementProps} className={bem.add(className)()}>
                        <div className={bem('dropdown-text')}>{children}</div>

                        <DropdownTriangleIcon width={9} height={5} className={bem('dropdown-icon')} />
                    </button>
                )}
            </TransparentButton>
        );
    }
}

export default ActionMenuTextButton;
