import styled from 'styled-components';

import { font, uTextEllipsis } from '../../../../styles/mixins';
import TextButton from '../../../button/TextButton';
import { EmptyPopupContent } from '../EmptyPopupContent/EmptyPopupContent';

export const Root = styled(EmptyPopupContent)`
    padding: 30px 60px;
    display: flex;
    flex-flow: column;
`;

export const Title = styled.div`
    ${uTextEllipsis};
    ${font(20, '#333', 'semibold')};
`;

export const Description = styled.div`
    margin-top: 4px;
    ${font(14, '#333')};
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
`;

export const Button = styled(TextButton)`
    margin-left: 16px;
`;
