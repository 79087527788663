/**
 * Developer: Stepan Burguchev
 * Date: 2/25/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './checkboxEditor.scss';

import { Checkbox } from '@approvalmax/ui/src/old/ui';
import { errorHelpers, hooks } from '@approvalmax/utils';
import { FC, PropsWithChildren, useRef } from 'react';
import bemFactory from 'react-bem-factory';

import { getDisabled } from '../../helpers';
import { BaseEditorProps } from '../EditorBase';

export const ALL_CHECKBOX_THEMES = ['form'] as const;
export type CheckboxTheme = (typeof ALL_CHECKBOX_THEMES)[number];

export const ALL_CHECKBOX_ALIGNMENTS = ['top', 'center', 'bottom'] as const;
export type CheckboxAlignment = (typeof ALL_CHECKBOX_ALIGNMENTS)[number];

interface CheckboxEditorProps extends BaseEditorProps<boolean>, PropsWithChildren {
    theme?: CheckboxTheme;
    checkboxAlignment?: CheckboxAlignment;
    className?: string;
}

const CheckboxEditor: FC<CheckboxEditorProps> = (props) => {
    const { className, theme = 'form', value, children, qa, checkboxAlignment = 'center', onChange, invalid } = props;

    const disabled = getDisabled(props.disabled, props.command);

    const bem = bemFactory.block('form-checkbox-editor').themed(theme as any);

    if (props.focusOnMount) {
        throw errorHelpers.throwNotSupportedError();
    }

    const checkboxRef = useRef<HTMLDivElement>(null);

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus();
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur();
        }
    };

    const checkboxId = hooks.useId();
    const labelId = hooks.useId();

    return (
        <div
            onClick={() => {
                if (disabled) {
                    return;
                }

                checkboxRef.current!.focus();
                onChange(!value);
            }}
            data-qa={qa}
            className={bem.add(className)(null, `align-${checkboxAlignment}`, {
                disabled,
            })}
        >
            <Checkbox
                id={checkboxId}
                ref={checkboxRef}
                className={bem('checkbox')}
                checked={value}
                triState={value === null}
                disabled={disabled}
                onChange={() => onChange(!value)}
                onFocus={onFocus}
                onBlur={onBlur}
                invalid={invalid}
                labelledBy={labelId}
            />

            <label id={labelId} htmlFor={checkboxId} className={bem('content')}>
                {children}
            </label>
        </div>
    );
};

export default CheckboxEditor;
