import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useVersionDetailsData = (companyId: string, workflowId: string, versionId: number) => {
    const { isInitialLoading, data: versionData } = useQuery(
        [QueryKeys.WorkflowVersionDetails, companyId, workflowId, versionId],
        () => api.internalSupport.getWorkflowVersionDetails(companyId, workflowId, versionId)
    );

    return {
        isInitialLoading,
        versionData,
    };
};
