import { mods } from '@approvalmax/theme';
import { GridProps } from '@approvalmax/ui/src/components/Grid/Grid.types';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

export const Root = styled.div<
    StyledMods<
        Omit<GridProps, 'children' | 'as' | 'padding'> & {
            padding?: string;
        }
    >
>`
    display: ${({ $inline }) => ($inline ? 'inline-grid' : 'grid')};

    grid-area: ${({ $gridArea }) => $gridArea};
    grid-auto-columns: ${({ $gridAutoColumns }) => $gridAutoColumns};
    grid-auto-flow: ${({ $gridAutoFlow }) => $gridAutoFlow};
    grid-auto-rows: ${({ $gridAutoRows }) => $gridAutoRows};
    grid-column: ${({ $gridColumn }) => $gridColumn};
    grid-column-end: ${({ $gridColumnEnd }) => $gridColumnEnd};
    grid-column-start: ${({ $gridColumnStart }) => $gridColumnStart};
    grid-row: ${({ $gridRow }) => $gridRow};
    grid-row-end: ${({ $gridRowEnd }) => $gridRowEnd};
    grid-row-start: ${({ $gridRowStart }) => $gridRowStart};
    grid-template: ${({ $gridTemplate }) => $gridTemplate};
    grid-template-areas: ${({ $gridTemplateAreas }) => $gridTemplateAreas};
    grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns};
    grid-template-rows: ${({ $gridTemplateRows }) => $gridTemplateRows};

    ${mods('columnGap')(
        (value) => css`
            column-gap: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
    ${mods('rowGap')(
        (value) => css`
            row-gap: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
    ${mods('gap')(
        (value) => css`
            gap: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    align-content: ${({ $alignContent }) => $alignContent};
    align-items: ${({ $alignItems }) => $alignItems};
    justify-content: ${({ $justifyContent }) => $justifyContent};
    justify-items: ${({ $justifyItems }) => $justifyItems};

    ${mods('height')(
        (value) => css`
            height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('maxHeight')(
        (value) => css`
            max-height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('minHeight')(
        (value) => css`
            min-height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('maxWidth')(
        (value) => css`
            max-width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('overflowX')(
        (value) => css`
            overflow-x: ${value};
        `
    )};

    ${mods('overflowY')(
        (value) => css`
            overflow-y: ${value};
        `
    )};

    padding: ${({ $padding }) => $padding};
`;
