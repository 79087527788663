import { isEmptyString } from './isEmptyString';
import { isValidCompanyId } from './isValidCompanyId';
import { isValidUrl } from './isValidUrl';
import * as requiredString from './requiredString';

export const validatorHelpers = {
    isValidCompanyId,
    isValidUrl,
    isEmptyString,
    ...requiredString,
};
