import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.BudgetsPage', {
    pageTitle: 'Budgets',
    breadcrumbRoot: 'Customer Success',

    searchPlaceholder: 'Enter Company ID',
    columnCompanyId: 'Company ID',
    columnBudgetId: 'Budget ID',
    columnBudgetName: 'BudgetName',

    errorIncorrectCompanyId: 'Please, enter valid company ID',
    errorNoItemsFound: 'No budgets found for this company ID',
});
