import React, { useCallback, useRef } from 'react';

/**
 * When using React.forwardRef() you cannot use the ref you forward, only assign it to an element.
 *
 * This hook gives you an element ref that you CAN use for HTML measurements etc, as well as
 * the onMount function that has to be passed into an elements `ref={}` prop.
 */
export const useForwardedRef = <THTMLElement>(
    ref: ((instance: THTMLElement | null) => void) | React.MutableRefObject<THTMLElement | null> | null
): [(el: THTMLElement | null) => void, React.RefObject<THTMLElement>] => {
    const elRef = useRef<THTMLElement | null>(null);
    const onMount = useCallback(
        (el: THTMLElement | null) => {
            if (typeof ref === 'function') {
                ref(el);
            } else if (ref) {
                ref.current = el;
            }

            elRef.current = el;
        },
        [ref]
    );

    return [onMount, elRef];
};
