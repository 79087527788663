import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useMaintenanceInfo = () => {
    const { isInitialLoading, data: maintenanceInfo } = useQuery(
        [QueryKeys.MaintenanceInfo],
        () => api.internalSupport.getMaintenanceInfo(),
        {}
    );

    return {
        maintenanceInfo,
        isInitialLoading,
    };
};
