import { Button, Field, font, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 560px;
    max-width: 100%;
`;

export const Content = styled.div`
    padding: 0 35px 30px;
`;

export const Description = styled.div`
    margin-top: 20px;
    ${font(13, '#000')}
`;

export const StyledField = styled(Field)`
    margin-top: 30px;
`;

export const SubmitButton = styled(Button)`
    margin-top: 20px;
`;
