import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

import { EditRequestData } from './useEditRequestDeletedFlag.types';

export const useEditRequestDeletedFlag = () => {
    const queryClient = useQueryClient();

    const { isLoading, mutateAsync: editRequestDeletedFlag } = useMutation(
        (options: EditRequestData & { deleted: boolean }) =>
            options.deleted
                ? api.internalSupport.deleteRequests({
                      companyId: options.companyId,
                      requestIds: options.requestIds,
                      supportTicketId: options.ticketId,
                      comment: options.comment,
                  })
                : api.internalSupport.restoreRequests({
                      companyId: options.companyId,
                      requestIds: options.requestIds,
                      supportTicketId: options.ticketId,
                      comment: options.comment,
                  }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.Requests]);
            },
        }
    );

    return {
        editRequestDeletedFlag,
        isLoading,
    };
};
