import { ColumnDefinition } from '@approvalmax/ui/src/components';
import { UseAmaxPayBankAccountsItem } from 'modules/data';

import { messages } from './AmaxPayBankAccountsPage.messages';

export const dataTableColumns: ColumnDefinition<UseAmaxPayBankAccountsItem>[] = [
    {
        id: 'id',
        name: messages.id,
    },
    {
        id: 'nickname',
        name: messages.nickname,
    },
    {
        id: 'currency',
        name: messages.currency,
    },
    {
        id: 'institutionId',
        name: messages.institutionId,
    },
    {
        id: 'accountNumber',
        name: messages.accountNumber,
        value: (item) => item.identification.accountNumber || '-',
    },
    {
        id: 'iban',
        name: messages.iban,
        value: (item) => item.identification.iban || '-',
    },
];
