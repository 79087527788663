import './popup.scss';

import { hooks, miscHelpers, reactWindowService } from '@approvalmax/utils';
import { PortalDef } from '@approvalmax/utils/src/services/reactWindow/reactWindow';
import { useSpring } from '@react-spring/web';
import React, { FC, PropsWithChildren, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import ReactDOM from 'react-dom';

import GlobalHotKeys from '../hotkeys/GlobalHotKeys';
import { ConfirmationPopupContent } from './content/ConfirmationPopupContent/ConfirmationPopupContent';
import DefaultPopupContent from './content/DefaultPopupContent/DefaultPopupContent';
import { EmptyPopupContent } from './content/EmptyPopupContent/EmptyPopupContent';
import { FadingPanel, PopupSlider } from './Popup.styles';
import { PopupContext } from './PopupContext';

export { defaultPopupContentHeaderHeight } from './content/DefaultPopupContent/DefaultPopupContent';
export { usePopupContext } from './PopupContext';

const LONGEST_TRANSITION_TIMEOUT = 300;

const bem = bemFactory.block('ui-popup');

export interface PopupProps extends PropsWithChildren {
    isOpen: boolean;
    onRequestClose: (e?: any | undefined, reason?: 'escape' | 'auto-close' | 'cancel') => void;
    disableAutoClose?: boolean;
    targetElementId?: string;
}

const Popup: FC<PopupProps> = (props) => {
    const { isOpen, onRequestClose, children, targetElementId } = props;

    const propsRef = hooks.useWrapInRef(props);
    const [portal, setPortal] = useState<PortalDef | null>(null);
    const portalRef = useRef<PortalDef | null>(null);
    const popupSliderRef = useRef<HTMLDivElement>(null);
    const frozenPopupRef = useRef<HTMLElement | null>(null);
    const unmountedRef = hooks.useUnmountedRef();

    portalRef.current = portal;

    if (!isOpen && popupSliderRef.current && popupSliderRef.current.firstElementChild) {
        // Preserve the rendered dom el if we are closing the popup, show it during the animation
        frozenPopupRef.current = popupSliderRef.current.firstElementChild.cloneNode(true) as HTMLElement;
    }

    const contextValue = useMemo(
        () => ({
            onRequestClose,
        }),
        [onRequestClose]
    );

    const handlers = useMemo(
        () => ({
            escape: () => {
                const p = propsRef.current;

                if (p.isOpen && !p.disableAutoClose) {
                    return p.onRequestClose(undefined, 'escape');
                }
            },
        }),
        [propsRef]
    );

    useLayoutEffect(() => {
        if (!isOpen) {
            return;
        }

        const p = reactWindowService.createControlledPortal({
            hostEl: null,
            transient: false,
            onRequestClose: miscHelpers.noop, // NOTE: Popup will never close itself per a request from the window service
            targetElementId,
        });

        p.el.classList.add('popup-portal');
        setPortal(p);

        return function destroyPortal() {
            p.closeRelatedPortals();

            const frozenPopupEl = frozenPopupRef.current;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const popupSliderEl = popupSliderRef.current;

            if (popupSliderEl) {
                // Preserve the rendered data to show a graceful animation
                if (frozenPopupEl) {
                    while (popupSliderEl.firstChild) {
                        popupSliderEl.removeChild(popupSliderEl.firstChild);
                    }

                    popupSliderEl.appendChild(frozenPopupEl);
                    frozenPopupRef.current = null;
                }

                setTimeout(() => {
                    p.dispose();

                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    const isUnmounted = !unmountedRef.current;

                    if (isUnmounted && portalRef.current && p.id === portalRef.current.id) {
                        // Our popup is still here (not overriden by another popup) => clean up local state
                        portalRef.current = null;
                        setPortal(null);
                    }
                }, LONGEST_TRANSITION_TIMEOUT);
            } else {
                p.dispose();
                portalRef.current = null;
                setPortal(null);
            }
        };
    }, [isOpen, targetElementId, unmountedRef]);

    const onRequestAutoClose = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (popupSliderRef.current !== e.target) {
                return;
            }

            const p = propsRef.current;

            if (!p.disableAutoClose) {
                p.onRequestClose(e, 'auto-close');
            }
        },
        [propsRef]
    );

    const popupAnimation = useSpring({
        transform: isOpen ? 'translateY(0%)' : 'translateY(10%)',
        opacity: isOpen ? 1 : 0,
        config: { tension: 300 },
    });

    if (!portal) {
        /* portal is our guideline, isOpen can be false but the animation might still be running */
        return null;
    }

    return ReactDOM.createPortal(
        <GlobalHotKeys handlers={handlers}>
            <FadingPanel style={{ ...popupAnimation, transform: 'none' }} />

            <div className={bem('popup')}>
                <PopupSlider
                    style={popupAnimation}
                    ref={popupSliderRef}
                    onClick={isOpen ? onRequestAutoClose : undefined}
                >
                    {isOpen && <PopupContext.Provider value={contextValue}>{children}</PopupContext.Provider>}
                </PopupSlider>
            </div>
        </GlobalHotKeys>,
        portal.el
    );
};

type PopupType = typeof Popup & {
    DefaultContent: typeof DefaultPopupContent;
    EmptyContent: typeof EmptyPopupContent;
    ConfirmationContent: typeof ConfirmationPopupContent;
};

(Popup as PopupType).DefaultContent = DefaultPopupContent;
(Popup as PopupType).EmptyContent = EmptyPopupContent;
(Popup as PopupType).ConfirmationContent = ConfirmationPopupContent;

export default Popup as PopupType;
