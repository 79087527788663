import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

export const TextAreaStyled = styled(TextareaAutosize)<StyledMods<{ hideScroll: boolean }>>`
    overflow-y: auto;

    ${mods('hideScroll', true)`
        overflow-y: hidden;
    `}
`;
