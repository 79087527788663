import { errorHelpers } from '@approvalmax/utils';
import { Component, PropsWithChildren } from 'react';

import { ErrorBoundaryProps, OwnState } from './ErrorBoundary.types';

/**
 * This is an ABSTRACT ErrorBoundary.
 * Please(Add) working implementation within your app
 */
export class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>, OwnState> {
    public state = {
        error: null,
    };

    public componentDidCatch(error: Error) {
        errorHelpers.captureException(error);
        this.setState({ error });
        this.props.onError?.(error);
    }

    public render() {
        const { errorPlaceholder } = this.props;
        const { error } = this.state;

        if (error) {
            return errorPlaceholder;
        } else {
            return this.props.children;
        }
    }
}
