import { Modifiers, ModifiersConfig } from '@styled-kit/mods';

import { config } from './config';

type ModsConfig = ModifiersConfig<typeof config>;

/**
 * Automatically generated modifiers type
 */
export type Mods<
    Name extends keyof ModsConfig = keyof ModsConfig,
    Value extends ModsConfig[Name] = undefined,
> = Modifiers<ModsConfig, Name, Value>;

/**
 * Required version of the Mods type
 */
export type RequiredMods<
    Name extends keyof ModsConfig = keyof ModsConfig,
    Value extends ModsConfig[Name] = undefined,
> = Required<Mods<Name, Value>>;

/* Theme enums */
export enum ThemeTypes {
    light = 'light',
    dark = 'dark',
}
