import { routerHelpers } from '@approvalmax/utils';
import { useMutation } from '@tanstack/react-query';
import { api } from 'modules/api';
import { toaster } from 'modules/components';

import { messages } from './RequestsPage.messages';
import { QueryParams } from './RequestsPage.types';

export const useQueryParams = routerHelpers.createQueryParamsHook<QueryParams>({
    mapFromQuery(queryString) {
        return {
            query: routerHelpers.getParamAsString(queryString, 'query', ''),
        };
    },
    mapToQuery(params) {
        return {
            query: params.query,
        };
    },
});

export const useFindRequests = () => {
    const {
        isLoading,
        mutate: findRequests,
        data: request,
    } = useMutation((options: { requestId: string }) => api.internalSupport.findRequest(options.requestId), {
        onSuccess(data) {
            if (!data) {
                toaster.error(messages.searchError);
            }
        },
    });

    return {
        findRequests,
        isLoading,
        requests: request ? [request] : undefined,
    };
};
