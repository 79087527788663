import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.ManageTenantsPage', {
    pageTitle: 'Manage Tenant Data',
    breadcrumbRoot: 'Infrastructure',
    selectShardTitle: 'Shard',
    selectShardPlaceholder: 'Select shard',
    companyIdTitle: 'Company ID',
    companyIdPlaceholder: 'Enter company id',
    searchButton: 'Check',
    tableNameCol: 'Table Name',
    rowsCountCol: 'Rows Count',
    searchError: 'No data found or company id is invalid',
    noData: 'No data found',
    defaultErrorMessage: 'Oops, something went wrong',
    moveTenantButton: 'Move tenant',
    errorColName: 'Errors',
    progressColName: 'Progress',
    moveConfirmation: 'Are you sure you want to move Company {companyId} to Shard {shardName}?',
    startSuccessMessage: 'Process has been successfully started',
    startFailedErrorMessage: 'There is already running process',
    additionalOperationsProgress: 'Additional operations in progress',
    lastError: 'Last tenant movement error: {errorMessage}',
    additionalStepOngoing: '{step} ongoing...',
    cancel: 'Cancel',
    canceledMovement: 'Current tenant movement canceled.',
    resume: 'Resume',
});
