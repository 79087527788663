import { DataTable } from '@approvalmax/ui';
import styled from 'styled-components';

export const DataTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
`;

export const StyledDataTable = styled(DataTable)`
    height: 100%;
    overflow: hidden;

    & .ui-data-row {
        height: auto;
        min-height: 50px;
    }

    & .ui-data-table__table-body-wrp {
        display: flex;
        flex-direction: column;
    }
`;
