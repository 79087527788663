import { animated, useSpring } from '@react-spring/web';
import { FC, forwardRef, RefAttributes } from 'react';
import styled from 'styled-components';

import { AnimatedWrapperProps } from './AnimatedWrapper.types';

const AnimatedDiv = styled(animated.div as any)``;

export const AnimatedWrapper: FC<AnimatedWrapperProps & RefAttributes<HTMLDivElement>> = forwardRef<
    HTMLDivElement,
    AnimatedWrapperProps
>((props, ref) => {
    const { isActive, children, className, stopAnimation, animationConfig, onClick, from, to } = props;

    const animation = useSpring({
        from: {
            opacity: 0,
            ...from,
        },
        to: {
            opacity: isActive ? 1 : 0,
            ...to,
        },
        ...animationConfig,
        config: { tension: 300, clamp: true, ...animationConfig?.config },
        immediate: stopAnimation,
    });

    return (
        <AnimatedDiv className={className} style={animation} onClick={onClick} ref={ref}>
            {children}
        </AnimatedDiv>
    );
});

AnimatedWrapper.displayName = 'AnimatedWrapper';
