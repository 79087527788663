import { FC, memo } from 'react';

import { Root } from './Spacing.styles';
import { SpacingProps } from './Spacing.types';

/**
 * Use to insert spacing equals to the indent tokens
 */
export const Spacing: FC<SpacingProps> = memo((props) => {
    const { height, ...restProps } = props;

    return <Root $height={height} {...restProps} />;
});

Spacing.displayName = 'Spacing';

export default Spacing;
