import { Reference } from '@approvalmax/types';
import { DropdownEditor, Field, Popup, TextEditor, usePopupContext } from '@approvalmax/ui';
import { Spacing } from '@approvalmax/ui/src/components';
import { PlainDataProvider } from 'modules/components';
import { FC, memo, useCallback, useState } from 'react';

import { useAdminPortalPermissions } from '../../hooks/useAdminPortalPermissions';
import { useCreateAdminPortalUser } from '../../hooks/useCreateAdminPortalUser';
import { messages } from './CreateUserPopup.messages';
import { Content } from './CreateUserPopup.styles';

const CreateUserPopup: FC = () => {
    const { permissions: allPermissions } = useAdminPortalPermissions();
    const { isLoading, createAdminPortalUser } = useCreateAdminPortalUser();
    const { onRequestClose } = usePopupContext();

    const [email, setEmail] = useState('');
    const [permissions, setPermissions] = useState<Reference[]>([]);

    const onCreate = useCallback(async () => {
        await createAdminPortalUser({
            email,
            permissions: permissions.map((p) => p.id),
        });

        onRequestClose();
    }, [createAdminPortalUser, email, onRequestClose, permissions]);

    const onChange = useCallback((value: Reference[]) => setPermissions(value), []);

    return (
        <Popup.DefaultContent
            title={messages.title}
            buttons={
                <Popup.DefaultContent.Button execute={onCreate} disabled={isLoading}>
                    {messages.doneButton}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <Field title={messages.emailField}>
                    <TextEditor
                        placeholder={messages.emailPlaceholder}
                        value={email}
                        onChange={setEmail}
                        focusOnMount
                    />
                </Field>

                <Spacing height={20} />

                <Field title={messages.permissionsField}>
                    <PlainDataProvider items={allPermissions!}>
                        <DropdownEditor
                            value={permissions}
                            onChange={onChange}
                            multiple
                            placeholder={messages.placeholderPermissions}
                        />
                    </PlainDataProvider>
                </Field>
            </Content>
        </Popup.DefaultContent>
    );
};

export default memo(CreateUserPopup);
