import { DataTable, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { dataHelpers, hooks } from '@approvalmax/utils';
import { User } from 'modules/api';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, SearchBar, Toolbar } from 'modules/components';
import { useFindUsers } from 'modules/customerSupport/hooks/useFindUsers';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { SortConfig, SortOrder } from 'modules/types';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { createDataTableColumnsConfig } from './UsersPage.config';
import { useQueryParams } from './UsersPage.hooks';
import { messages } from './UsersPage.messages';
import { ColumnId } from './UsersPage.types';

const UsersPage: FC = () => {
    const history = useHistory();

    const { query, push } = useQueryParams();

    const [searchText, setSearchText] = useState(query);
    const [sortConfig, setSortConfig] = useState<SortConfig | undefined>();

    const { permissionFlags } = useCurrentAccount();
    const { findUsers, isLoading, users } = useFindUsers(sortConfig);

    usePermissionsRedirect((permissionFlags) => permissionFlags.users.any);

    hooks.useOnMount(() => {
        if (query) {
            onSearch();
        }
    });

    const onSearch = useCallback(() => {
        findUsers(
            dataHelpers.isGuid(searchText)
                ? {
                      userId: searchText,
                  }
                : {
                      email: searchText,
                  }
        );

        push({
            query: searchText,
        });
    }, [findUsers, push, searchText]);

    const onSortChange = useCallback(
        async (columnId: ColumnId, newSortOrder: SortOrder) => {
            await setSortConfig({
                columnId,
                sortOrder: newSortOrder,
            });

            await onSearch();
        },
        [onSearch]
    );

    const onRowClick = useCallback(
        (user: User) => {
            history.push(getPath('users', user.id));
        },
        [history]
    );

    const dataTableColumns = useMemo(() => {
        return createDataTableColumnsConfig(sortConfig);
    }, [sortConfig]);

    const filters = (
        <SearchBar
            placeholder={messages.userEmailPlaceholder}
            focusOnMount
            disabled={isLoading || !permissionFlags || !permissionFlags.users.read}
            onSearch={onSearch}
            value={searchText}
            onChange={setSearchText}
        />
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar filters={filters} />

                    <DataTable
                        css='flex: 1; overflow: hidden;'
                        data={users ?? []}
                        columns={dataTableColumns}
                        onRowClick={onRowClick}
                        onSort={onSortChange}
                    />
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
};

export default memo(UsersPage);
