import { Accordion, LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { JsonViewer } from 'modules/components';
import { FC, memo } from 'react';

import { VersionDetails } from '..';
import { useWorkflowDetailsData } from './WorkflowDetails.hooks';
import { WorkflowDetailsProps } from './WorkflowDetails.types';

const WorkflowDetails: FC<WorkflowDetailsProps> = memo((props) => {
    const { companyId, workflowId } = props;

    const { isInitialLoading, workflowData } = useWorkflowDetailsData(companyId, workflowId);

    return (
        <Box spacing='20 0'>
            {isInitialLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <JsonViewer json={workflowData?.workflowRawData ?? null} />

                    <Box spacing='20 0 20 20'>
                        {workflowData?.workflowVersions.map((version) => (
                            <Accordion key={version.version} title={`Version ${version.version} (${version.author})`}>
                                <VersionDetails
                                    companyId={companyId}
                                    workflowId={workflowId}
                                    versionId={version.version}
                                />
                            </Accordion>
                        ))}
                    </Box>
                </>
            )}
        </Box>
    );
});

export default WorkflowDetails;
