import { Button } from '@approvalmax/ui';
import { Popup } from '@approvalmax/ui/src/components';
import { JsonViewer, Section } from 'modules/components';
import { useGetOcrResult } from 'modules/data';
import { FC, memo, useCallback, useState } from 'react';

import { SelectOcrAttachmentPopupContent } from '../SelectOcrAttachmentPopupContent/SelectOcrAttachmentPopupContent';
import { messages } from './OcrAttachmentSection.messages';
import { OcrAttachmentParams, OcrAttachmentSectionProps } from './OcrAttachmentSection.types';

export const OcrAttachmentSection: FC<OcrAttachmentSectionProps> = memo((props) => {
    const { requestDetails } = props;

    const [ocrAttachmentParams, setOcrAttachmentParams] = useState<OcrAttachmentParams>({
        ocrAttachmentId: requestDetails?.rawData.attachments.find((attachment) => attachment.ocrStatus)?.id || '',
        showRawModel: false,
    });

    const [selectOcrAttachmentPopupOpen, setSelectOcrAttachmentPopupOpen] = useState(false);
    const { data } = useGetOcrResult(
        { requestAttachmentId: ocrAttachmentParams.ocrAttachmentId },
        { source: ocrAttachmentParams.showRawModel }
    );

    const onToggle = useCallback((open: boolean) => {
        !open && setSelectOcrAttachmentPopupOpen(false);
    }, []);

    const closePopup = useCallback(() => {
        setSelectOcrAttachmentPopupOpen(false);
    }, []);

    const openPopup = useCallback(() => {
        setSelectOcrAttachmentPopupOpen(true);
    }, []);

    return (
        <>
            <Section header={messages.header}>
                <JsonViewer
                    json={data}
                    actions={
                        <Button preset='compact' execute={openPopup}>
                            {messages.getOcrResultButtonTitle}
                        </Button>
                    }
                />
            </Section>

            <Popup open={selectOcrAttachmentPopupOpen} closable onToggle={onToggle}>
                <SelectOcrAttachmentPopupContent
                    requestDetails={requestDetails}
                    closePopup={closePopup}
                    ocrAttachmentParams={ocrAttachmentParams}
                    setOcrAttachmentParams={setOcrAttachmentParams}
                />
            </Popup>
        </>
    );
});

OcrAttachmentSection.displayName = 'OcrAttachmentSection';
