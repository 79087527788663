import { mods, theme } from '@approvalmax/theme';
import { FloatingOverlay } from '@floating-ui/react';
import { StyledMods } from '@styled-kit/mods';
import styled, { css, keyframes } from 'styled-components';

import { Root as Body } from './components/Body/Body.styles';
import { Root as Header } from './components/Header/Header.styles';
import { PopupProps } from './Popup.types';

const showOverlay = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const showContent = keyframes`
    from {
        transform: translateY(10px);
    }
    to {
        transform: translateY(0);
    }
`;

export const Overlay = styled(FloatingOverlay)<StyledMods<Pick<PopupProps, 'zIndex'>>>`
    background: rgba(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    animation: ${showOverlay} ${theme.duration.medium}ms;
    z-index: 1000;

    ${mods('zIndex')(
        (value) => css`
            z-index: ${value};
        `
    )};
`;

export const Content = styled.dialog<StyledMods<Pick<PopupProps, 'size' | 'fullScreen' | 'overflowY'>>>`
    background-color: ${theme.color.white100};
    animation: ${showContent} ${theme.duration.medium}ms;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    display: block;
    border-radius: ${theme.radius.medium};

    ${mods.size.xsmall` max-width: 480px; `};
    ${mods.size.small` max-width: 560px; `};
    ${mods.size.medium` max-width: 800px; `};
    ${mods.size.large` max-width: 960px; `};

    ${mods.fullScreen.true`
        display: flex;
        flex-direction: column;
        max-width: unset;
        height: 100%;
        overflow: hidden;

        ${Header} {
            padding: 12px 40px 0px;
        }
    `};

    ${mods('overflowY', 'hidden')`
        max-height: calc(100% - 64px);
        overflow-y: hidden;
    `};

    ${Header} + ${Body} {
        padding-top: 0;
    }
`;
