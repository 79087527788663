import { EnforcementTfaType, IntegrationType } from 'modules/types';

import { getEnforcementTfaLabelByType, getIntegrationLabelByType } from './EnforcementTfaPage.hooks';
import { EnforcementOption } from './EnforcementTfaPage.types';

export const glOptions = [
    { id: IntegrationType.None, text: getIntegrationLabelByType(IntegrationType.None) },
    { id: IntegrationType.Xero, text: getIntegrationLabelByType(IntegrationType.Xero) },
    { id: IntegrationType.QBooks, text: getIntegrationLabelByType(IntegrationType.QBooks) },
    { id: IntegrationType.Dear, text: getIntegrationLabelByType(IntegrationType.Dear) },
    { id: IntegrationType.NetSuite, text: getIntegrationLabelByType(IntegrationType.NetSuite) },
];

export const enforcementOptions: EnforcementOption[] = [
    { id: EnforcementTfaType.None, text: getEnforcementTfaLabelByType(EnforcementTfaType.None) },
    { id: EnforcementTfaType.Soft, text: getEnforcementTfaLabelByType(EnforcementTfaType.Soft) },
    { id: EnforcementTfaType.Hard, text: getEnforcementTfaLabelByType(EnforcementTfaType.Hard) },
];
