import './progress.scss';

import { browserHelpers } from '@approvalmax/utils';

class GlobalProgress {
    private _el = browserHelpers.isBrowser() ? document.getElementById('global-progress') : null;
    private _counter = 0;

    public reset() {
        this._counter = 0;
        this._hide();
    }

    public inc() {
        if (++this._counter > 0) {
            this._show();
        }
    }

    public dec() {
        if (--this._counter <= 0) {
            this._hide();
        }
    }

    private _hide() {
        if (this._el) {
            this._el.style.visibility = 'hidden';
        }
    }

    private _show() {
        if (this._el) {
            this._el.style.visibility = 'visible';
        }
    }
}

export default new GlobalProgress();
