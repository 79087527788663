/**
 * Developer: Stepan Burguchev
 * Date: 6/3/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './panel.scss';

import { Identifiable } from '@approvalmax/types';
import { eventHelpers } from '@approvalmax/utils';
import { Component, ComponentType, createRef, ReactElement } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './Panel.messages';
import { DropdownListItemProps, DropdownValueType } from './types';

interface OwnProps {
    items: Identifiable[];
    value?: DropdownValueType;
    displayTextSelector: (item: Identifiable) => string;
    displaySubTextSelector: (item: Identifiable) => string;
    itemComponent: ComponentType<DropdownListItemProps<Identifiable>>;
    singleLinePerItem?: boolean;
    activeItem: Identifiable | null;
    emptyListPlaceholder: ReactElement | string | number;
    filterText: string | null;
    hasMore: boolean;
    onItemSelect: (item: Identifiable) => void;
    onChangeActiveItem: (item: Identifiable | null) => void;
    onCreateItem: () => void;
    canCreateItem: boolean;
    createItemDisplayText: ReactElement | string | number;
    panelMaxWidth?: number | 'button-width' | 'none';
    noLimitHeight?: boolean;
}

class Panel extends Component<OwnProps> {
    private _activeItemEl: HTMLLIElement | null = null;
    listRef = createRef<HTMLUListElement>();

    public scrollActiveItemIntoView() {
        if (!this._activeItemEl || !this.listRef.current) {
            return;
        }

        const listRect = this.listRef.current.getBoundingClientRect();
        const listStyle = getComputedStyle(this.listRef.current);
        const listHeight =
            listRect.height - parseInt(listStyle.borderTopWidth!, 10) + parseInt(listStyle.borderBottomWidth!, 10);
        const itemRect = this._activeItemEl.getBoundingClientRect();
        const itemStyle = getComputedStyle(this._activeItemEl);

        let itemHeight = itemRect.height + parseInt(itemStyle.marginTop!, 10) + parseInt(itemStyle.marginBottom!, 10);

        const listScrollTop = this.listRef.current.scrollTop;
        const itemY = itemRect.top - listRect.top + listScrollTop;

        if (itemY + itemHeight > listHeight + listScrollTop) {
            // the items is below the viewport
            this.listRef.current.scrollTop = itemY - listHeight + itemHeight;
        } else if (itemY < listScrollTop) {
            // the items is above the viewport
            this.listRef.current.scrollTop = itemY;
        }
    }

    public render() {
        const {
            itemComponent: ItemComponent,
            singleLinePerItem,
            displayTextSelector,
            displaySubTextSelector,
            hasMore,
            emptyListPlaceholder,
            canCreateItem,
            createItemDisplayText,
            onCreateItem,
            activeItem,
            filterText,
            panelMaxWidth = 'auto',
            value,
            noLimitHeight,
        } = this.props;
        const bem = bemFactory.block('form-dropdown-editor-panel');
        const qa = bemFactory.qa('form-dropdown-editor-panel');

        const items = this.props.items.map((item) => {
            const active = Boolean(activeItem && item.id === activeItem.id);

            let isSelected = false;

            if (value) {
                if (Array.isArray(value)) {
                    isSelected = value.some((el) => el.id === item.id);
                } else {
                    isSelected = item.id === value.id;
                }
            }

            return (
                <ItemComponent
                    item={item}
                    active={active}
                    isSelected={isSelected}
                    highlightedText={filterText}
                    singleLinePerItem={singleLinePerItem}
                    rootRef={(ref: HTMLLIElement | null) => {
                        if (active) {
                            this._activeItemEl = ref;
                        }
                    }}
                    key={item.id}
                    displayTextSelector={displayTextSelector}
                    displaySubTextSelector={displaySubTextSelector}
                    onSelect={this._onItemSelect}
                    onHover={this._onItemHover}
                />
            );
        });

        const hasNothingToShow = items.length === 0 && !canCreateItem;

        return (
            <div
                className={bem.add('fs-mask')()}
                data-qa={qa()}
                style={{
                    maxWidth: `${panelMaxWidth}${typeof panelMaxWidth === 'number' ? 'px' : ''}`,
                }}
                onMouseDown={eventHelpers.preventDefaultCallback}
            >
                <ul role='listbox' className={bem('list', { 'no-limit-height': noLimitHeight })} ref={this.listRef}>
                    {items}

                    {hasMore && (
                        <div className={bem('has-more')}>{messages.hasMore({ count: items.length, br: <br /> })}</div>
                    )}

                    {hasNothingToShow && <div className={bem('empty-list')}>{emptyListPlaceholder}</div>}
                </ul>

                {canCreateItem && (
                    <div
                        className={bem('add-new-button')}
                        data-qa={qa('add-new-button')}
                        onClick={() => onCreateItem()}
                        onMouseEnter={this._onAddNewHover}
                    >
                        {createItemDisplayText}
                    </div>
                )}
            </div>
        );
    }

    private _onItemHover = (item: Identifiable) => {
        this.props.onChangeActiveItem(item);
    };

    private _onItemSelect = (item: Identifiable) => {
        this.props.onItemSelect(item);
    };

    private _onAddNewHover = () => {
        this.props.onChangeActiveItem(null);
    };
}

export default Panel;
