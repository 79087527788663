import { Field, Popup, TextAreaEditor, usePopupContext } from '@approvalmax/ui';
import { Spacing } from '@approvalmax/ui/src/components';
import { miscHelpers } from '@approvalmax/utils';
import { JsonViewer, Section } from 'modules/components';
import { FC, useEffect, useState } from 'react';

import { messages } from './MessageDetailsPopup.messages';
import { Content } from './MessageDetailsPopup.styles';
import { MessageDetailsPopupProps } from './MessageDetailsPopup.types';

const MessageDetailsPopup: FC<MessageDetailsPopupProps> = (props) => {
    const { message } = props;

    const { onRequestClose } = usePopupContext();

    const [messageContent, setMessageContent] = useState('');
    const [isJson, setIsJson] = useState(false);

    useEffect(() => {
        try {
            setMessageContent(JSON.parse(message.text));
            setIsJson(true);
        } catch {
            setMessageContent(message.text);
            setIsJson(false);
        }
    }, [message.text]);

    return (
        <Popup.DefaultContent
            css='width: 750px'
            title={messages.popupTitle({
                id: message.id,
            })}
            buttons={
                <Popup.DefaultContent.Button execute={onRequestClose}>
                    {messages.doneButton}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <Section header={messages.generalHeader}>
                    <Field title={messages.idField}>{message.id}</Field>

                    <Spacing height={12} />

                    <Field title={messages.insertionTimeField}>{message.insertedAt}</Field>

                    <Spacing height={12} />

                    <Field title={messages.expirationTimeField}>{message.expiresAt}</Field>
                </Section>

                <Section header={messages.rawDataHeader}>
                    {isJson ? (
                        <JsonViewer json={messageContent} />
                    ) : (
                        <TextAreaEditor value={String(messageContent)} onChange={miscHelpers.noop} />
                    )}
                </Section>
            </Content>
        </Popup.DefaultContent>
    );
};

export default MessageDetailsPopup;
