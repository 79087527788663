import { RequiredMods } from '@approvalmax/theme';
import { domHelpers, hooks } from '@approvalmax/utils';
import { FC, memo, useCallback, useMemo } from 'react';

import { CloseIcon } from '../../../../icons';
import { Button } from '../../../Button/Button';
import { Flex } from '../../../Flex/Flex';
import { FlexSpacingProp } from '../../../Flex/Flex.types';
import Text from '../../../Text/Text';
import { usePopupContext } from '../../Popup.context';
import { ActionsShiftedContainer, Root, StyledProgress } from './Header.styles';
import { HeaderProps } from './Header.types';

export const Header: FC<HeaderProps> = memo((props) => {
    const { children, postfix, title, subTitle, actions, progress, qa, ...restProps } = props;

    const { setToggle, size, closable, root } = usePopupContext();
    const { sticky, stickyRef } = hooks.useSticky({ root });

    const close = useCallback(() => setToggle(false), [setToggle]);
    const [titleSize, subtitleSize, closeSize, subtitleSpacing] = useMemo<
        [RequiredMods['fontSize'], RequiredMods['fontSize'], RequiredMods['iconSize'], FlexSpacingProp]
    >(() => {
        switch (size) {
            case 'xsmall':
                return ['xsmall', 'small', 24, '4'];

            default:
                return ['small', 'medium', 28, '8'];
        }
    }, [size]);

    return (
        <>
            <div ref={stickyRef} />

            <Root $sticky={sticky} data-qa={domHelpers.generateDataQa(qa, 'popup-header')} {...restProps}>
                {progress && <StyledProgress size='small' />}

                <Flex alignItems='start' wrap={false}>
                    {closable && (
                        <ActionsShiftedContainer $hasActions={Boolean(actions)}>
                            <Button
                                onClick={close}
                                icon
                                color='white100'
                                size='small'
                                noPadding
                                data-qa={domHelpers.generateDataQa(qa, 'popup-header-close-button')}
                            >
                                <CloseIcon size={closeSize} />
                            </Button>
                        </ActionsShiftedContainer>
                    )}

                    {children ? (
                        children
                    ) : (
                        <Flex grow={1} direction='column' spacing={subtitleSpacing}>
                            <Flex justifyContent='space-between' size={0} wrap={false}>
                                {title && (
                                    <Flex grow={1} block>
                                        <ActionsShiftedContainer $hasActions={Boolean(actions)}>
                                            <Flex wrap={false} spacing='16'>
                                                <Text as='h2' font='headline' color='midnight80' fontSize={titleSize}>
                                                    {title}
                                                </Text>

                                                {postfix && (
                                                    <Text
                                                        font='headline'
                                                        as='h2'
                                                        fontSize={titleSize}
                                                        color='midnight60'
                                                        wrap={false}
                                                    >
                                                        {` ${postfix}`}
                                                    </Text>
                                                )}
                                            </Flex>
                                        </ActionsShiftedContainer>
                                    </Flex>
                                )}

                                {actions && <Flex block>{actions}</Flex>}
                            </Flex>

                            {subTitle && (
                                <Text font='label' fontSize={subtitleSize} color='midnight70'>
                                    {subTitle}
                                </Text>
                            )}
                        </Flex>
                    )}
                </Flex>
            </Root>
        </>
    );
});

export default Header;
