import { routerHelpers } from '@approvalmax/utils';

interface QueryParams {
    query: string;
}

const useQueryParams = routerHelpers.createQueryParamsHook<QueryParams>({
    mapFromQuery(queryString) {
        return {
            query: routerHelpers.getParamAsString(queryString, 'query', ''),
        };
    },
    mapToQuery(params) {
        return {
            query: params.query,
        };
    },
});

export default useQueryParams;
