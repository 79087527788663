import { validatorHelpers } from '@approvalmax/utils';
import { useCallback, useMemo, useState } from 'react';

import { PopupFormData, PopupFormErrors } from './BulkOperationPopup.types';

export const useBulkPopupFormData = () => {
    const [requestIds, setRequestIds] = useState('');
    const [comment, setComment] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [ticketId, setTicketId] = useState('');

    const requestIdsList = useRequestIds(requestIds);

    const formData = useMemo(
        () => ({ companyId, ticketId, comment, requestIds }),
        [comment, companyId, requestIds, ticketId]
    );

    const { formErrors, validateForm } = useFormValidation(formData, requestIdsList);

    return {
        formData,
        formErrors,
        requestIds: requestIdsList,
        setCompanyId,
        setTicketId,
        setComment,
        setRequestIds,
        validateForm,
    };
};

const useRequestIds = (requestIdsText: PopupFormData['requestIds']) => {
    return useMemo(
        () =>
            requestIdsText
                .split(',')
                .map((id) => id.trim())
                .filter(Boolean),
        [requestIdsText]
    );
};

const useFormValidation = (data: PopupFormData, requestIds: string[]) => {
    const [formErrors, setFormErrors] = useState<PopupFormErrors>({});

    const validateForm = useCallback(() => {
        const errors: PopupFormErrors = {
            companyId: !validatorHelpers.isValidCompanyId(data.companyId.trim()),
            requestIds: requestIds.length === 0,
            ticketId: !data.ticketId,
            comment: !data.comment,
        };

        setFormErrors(errors);

        return Object.values(errors).filter(Boolean).length === 0;
    }, [data.comment, data.companyId, data.ticketId, requestIds.length]);

    return {
        formErrors,
        validateForm,
    };
};
