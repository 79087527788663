import { memo } from 'react';

import { Root, Unicorn } from './ProgressBar.styles';
import { ProgressBarProps } from './ProgressBar.types';

const ProgressBar = memo<ProgressBarProps>((props) => {
    const { progress, hideUnicorn } = props;

    return <Root $progress={progress}>{!hideUnicorn && <Unicorn $progress={progress} />}</Root>;
});

export default ProgressBar;
