import './button.scss';

import { forwardRef } from 'react';
import bemFactory from 'react-bem-factory';

import TransparentButton from './TransparentButton';
import { BaseButtonProps } from './types';

type ButtonFontSize = 'large16' | 'large15' | 'normal14' | 'small13' | 'small12' | 'small11';

type ButtonHeight = 'large44' | 'large40' | 'normal36' | 'small30' | 'small25' | 'small20';

type ButtonPadding = 'wide50' | 'normal28' | 'narrow20' | 'narrow15' | 'narrow10';

type ButtonPreset = 'large' | 'normal' | 'small' | 'compact';

const presetMap: {
    [presetName in ButtonPreset]: {
        fontSize: ButtonFontSize;
        height: ButtonHeight;
        padding: ButtonPadding;
    };
} = {
    large: {
        fontSize: 'large16',
        height: 'large40',
        padding: 'wide50',
    },
    normal: {
        fontSize: 'normal14',
        height: 'normal36',
        padding: 'normal28',
    },
    small: {
        fontSize: 'normal14',
        height: 'small30',
        padding: 'narrow20',
    },
    compact: {
        fontSize: 'small12',
        height: 'small25',
        padding: 'narrow20',
    },
};

export const ALL_PRESETS = Object.keys(presetMap) as ButtonPreset[];

const heightStyleMap: {
    [name in ButtonHeight]: string;
} = {
    large44: '44px',
    large40: '40px',
    normal36: '36px',
    small30: '30px',
    small25: '25px',
    small20: '20px',
};
const fontSizeStyleMap: {
    [name in ButtonFontSize]: string;
} = {
    large16: '16px',
    large15: '15px',
    normal14: '14px',
    small13: '13px',
    small12: '12px',
    small11: '11px',
};
const paddingStyleMap: {
    [name in ButtonPadding]: string;
} = {
    wide50: '0 50px',
    normal28: '0 28px',
    narrow20: '0 20px',
    narrow15: '0 15px',
    narrow10: '0 10px',
};

export const ALL_BUTTON_COLOR_THEMES = [
    'brand',
    'red',
    'aqua',
    'xero-new',
    'receipt-bank',
    'airwallex',
    'grey',
    'white',
    'purple',
    'black',
] as const;
export type ButtonColorTheme = (typeof ALL_BUTTON_COLOR_THEMES)[number];

export const ALL_BUTTON_THEMES = ['primary', 'secondary'] as const;
export type ButtonTheme = (typeof ALL_BUTTON_THEMES)[number];

export interface ButtonProps extends BaseButtonProps {
    fontSize?: ButtonFontSize;
    height?: ButtonHeight;
    padding?: ButtonPadding;
    preset?: ButtonPreset;
    colorTheme?: ButtonColorTheme;
    theme?: ButtonTheme;
    rounded?: boolean;
    alwaysPropagationExecute?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        preset = 'normal',
        type = 'button',
        theme = 'primary',
        rounded,
        className,
        children,
        ...buttonProps
    } = props;

    const bem = bemFactory.block('ui-standard-button').themed(theme!);

    const presetValue = presetMap[preset!];
    const colorTheme = 'color-' + ((props.colorTheme || 'brand') as ButtonColorTheme);
    const fontSize = props.fontSize || presetValue.fontSize;
    const padding = props.padding || presetValue.padding;
    const height = props.height || presetValue.height;
    const style = {
        fontSize: fontSizeStyleMap[fontSize],
        padding: paddingStyleMap[padding],
        height: heightStyleMap[height],
    };

    return (
        <TransparentButton {...buttonProps} ref={ref}>
            {({ elementProps }) => (
                <button
                    type={type}
                    {...elementProps}
                    className={bem.add(className)(null, colorTheme!, {
                        rounded,
                    })}
                    style={style}
                >
                    {children}
                </button>
            )}
        </TransparentButton>
    );
});

export default Button;
