import { mods } from '@approvalmax/theme';
import { Button, DataTable, Field, font } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import happyUnicornImg from './resources/happy_unicorn.png';
import sadUnicornImg from './resources/sad_unicorn.png';
import screamingUnicornImg from './resources/screaming_unicorn.png';

export const ToolbarFiltersContainer = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 10px;
`;

export const CompanyIdField = styled(Field)`
    flex-grow: 1;
`;

export const ShardSelectField = styled(Field)`
    width: 280px;
    flex-shrink: 0;
`;

export const SearchButton = styled(Button)`
    position: relative;
    bottom: 1px;
    flex-shrink: 0;
`;

export const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const Loader = styled.div`
    width: 300px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-left: 60px;
    padding-right: 40px;
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const TotalRowsCount = styled.div<StyledMods<{ isHighlited: boolean }>>`
    margin-right: 10px;

    ${font(14, '#000', 'semibold')}

    ${mods('isHighlited', true)`
        color: red;
    `}
`;

const Unicorn = styled.div`
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`;

export const HappyUnicorn = styled(Unicorn)`
    background-image: url(${happyUnicornImg});
`;

export const SadUnicorn = styled(Unicorn)`
    background-image: url(${sadUnicornImg});
`;

export const ScreamingUnicorn = styled(Unicorn)`
    background-image: url(${screamingUnicornImg});
`;

export const StyledDataTable = styled(DataTable)`
    flex: 1;
    overflow: hidden;

    .ui-data-row {
        height: 35px;
    }
`;

export const TotalProgressHeader = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 60px;
    padding-right: 40px;
`;

export const TotalProgressBarContainer = styled.div`
    width: 300px;
    margin-right: 20px;
`;

export const CurrentMovementDataTable = styled(DataTable)`
    flex: 1;
    overflow: hidden;
`;

export const AdditionalInfo = styled.div`
    padding-left: 60px;

    ${font(14, '#000', 'semibold')}
`;

export const CancelButton = styled(Button)`
    margin-left: 20px;
`;
