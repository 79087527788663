import { invalidOperationError } from './invalidOperationError';

/**
 * Throws InvalidOperationError. The exception should be thrown when a class is in invalid state to call the checked method.
 * @example
 * // Inside of implementation of some Marionette.View.
 * addKeyboardListener: function (key, callback) {
 *     if (!this.keyListener) {
 *         utilsApi.helpers.throwInvalidOperationError('You must apply keyboard listener after \'render\' event has happened.');
 *     }
 *     var keys = key.split(',');
 *     _.each(keys, function (k) {
 *         this.keyListener.simple_combo(k, callback);
 *     }, this);
 * },
 * // ...
 * @param {String} [message='Invalid operation'] Error message.
 * */
export const throwInvalidOperationError = (message?: string): never => {
    throw invalidOperationError(message);
};
