import { useMutation, useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';

import { messages } from './UserSettingsPage.messages';

export const useStartTwoFa = () => {
    const { isInitialLoading, data, refetch } = useQuery([QueryKeys.StartTwoFaInfo], () => api.account.startTwoFa(), {
        enabled: false,
    });

    return {
        isInitialLoading,
        data,
        fetchData: refetch,
    };
};

export const useVerifyTwoFaCode = () => {
    const { refetchCurrentAccountInfo } = useCurrentAccount();

    const { isLoading, mutate: verifyTwoFaCode } = useMutation(api.account.verifyTwoFaCode, {
        onSuccess: () => {
            refetchCurrentAccountInfo();

            toaster.info(messages.twoFASuccessMessage);
        },
    });

    return {
        isLoading,
        verifyTwoFaCode,
    };
};
