import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.ReportsPage', {
    pageTitle: 'Reports',
    breadcrumbRoot: 'Customer Success',
    searchBarPlaceholder: 'Enter company ID...',
    companyIdCol: 'Company ID',
    nameCol: 'Report name',
    reportIdCol: 'Report ID',
    searchError: 'No report found or id is invalid',
    defaultErrorMessage: 'Oops, something went wrong',
});
