import './searchInput.scss';

import { TbSearchGreyIcon } from '@approvalmax/ui/src/old/icons';
import React, { FC, memo, useCallback, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './SearchInput.messages';

export const ALL_SEARCH_INPUT_THEMES = ['form', 'transparent'] as const;
export type SearchInputTheme = (typeof ALL_SEARCH_INPUT_THEMES)[number];

export interface SearchInputProps {
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    className?: string;
    qa?: string;
    theme?: SearchInputTheme;
    focusOnMount?: boolean;
    disabled?: boolean;
}

const SearchInput: FC<SearchInputProps> = (props) => {
    const { value, disabled, placeholder, className, qa, theme = 'form', onChange, focusOnMount } = props;

    const bem = bemFactory.block('ui-search-input').themed(theme!);

    const [focused, setFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div
            className={bem.add(className)(null, { active: focused })}
            onClick={useCallback((e: React.MouseEvent<HTMLDivElement>) => {
                const element = e.target as HTMLElement;

                if (element.tagName !== 'INPUT') {
                    inputRef.current!.focus();
                }
            }, [])}
        >
            <TbSearchGreyIcon width={15} height={15} className={bem('icon')} />

            <input
                value={value}
                data-qa={qa}
                className={bem('input')}
                ref={inputRef}
                disabled={disabled}
                onFocus={useCallback(() => setFocused(true), [])}
                onBlur={useCallback(() => setFocused(false), [])}
                onChange={useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [onChange])}
                placeholder={placeholder || messages.defaultPlaceholder}
                autoComplete='off'
                type='text'
                autoFocus={focusOnMount}
            />
        </div>
    );
};

export default memo(SearchInput);
