import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useAdminPortalPermissions = () => {
    const { isInitialLoading, data: permissions } = useQuery(
        [QueryKeys.AdminPortalPermissions],
        () => api.portalManagement.getPermissions(),
        {}
    );

    return {
        permissions,
        isInitialLoading,
    };
};
