import { theme } from '@approvalmax/theme';
import { ArrowLeftIcon, ArrowRightIcon } from '@approvalmax/ui/src/icons';
import styled, { css } from 'styled-components';

const iconCommonStyles = css`
    cursor: pointer;

    &:hover {
        color: ${theme.color.blue100};
    }
`;

export const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
    ${iconCommonStyles}
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
    ${iconCommonStyles}
`;
