import type { ApiError, ApiVersion } from '@approvalmax/types';
import { objectHelpers, routerHelpers } from '@approvalmax/utils';
import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { ResponseType } from 'axios';
import { useCallback } from 'react';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApiLegacy from '../rootApiLegacy';
import { ApiMethods, ApiSource, GetNextPageParam, InfiniteQueryOptions, QueryParams, RequestParams } from '../types';

/** @deprecated Use {@link createUseGetInfinite} */
export const createUseGetInfiniteLegacy = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <ResponseData, MappedResponseData = ResponseData>(
        path: string,
        options: {
            /** @deprecated Use {@link params} */
            pathParams?: QueryParams<Record<string, any>>;
            /** @deprecated Use {@link params} */
            queryParams?: QueryParams<Record<string, any>>;
            params?: RequestParams;
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            queryOptions?: InfiniteQueryOptions<ResponseData, MappedResponseData>;
            responseType?: ResponseType;
            /** @deprecated Use {@link mapToCamelCase} */
            mapData?: (data: ResponseData) => MappedResponseData;
            mapToCamelCase?: boolean;
            method?: ApiMethods;
            getNextPageParam: GetNextPageParam<MappedResponseData>;
        }
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            queryParams,
            pathParams,
            params,
            queryOptions,
            responseType,
            mapData,
            mapToCamelCase,
            method = 'post',
            getNextPageParam,
        } = options;

        const requestParams = params || {
            path: pathParams,
            query: queryParams,
        };
        const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, requestParams.path)
            ? routerHelpers.pathToUrl(path, requestParams.path)
            : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData, mapToCamelCase);
        const data: Record<string, any> = method === 'get' || !requestParams.query ? {} : requestParams.query;

        const queryClient = useQueryClient();
        const queryKey = [url, data, requestParams.query, requestParams.path].filter(Boolean);

        const queryResult = useInfiniteQuery<MappedResponseData, ApiError>(
            queryKey,
            ({ pageParam }) =>
                handleApiCall(
                    rootApiLegacy({
                        baseURL,
                        method,
                        url,
                        headers: {
                            ...(Authorization && { Authorization }),
                        },
                        responseType,
                        params:
                            objectHelpers.has(pageParam, 'params') && objectHelpers.has(pageParam.params, 'query')
                                ? pageParam.params.query
                                : requestParams.query,
                        data: objectHelpers.has(pageParam, 'data') ? pageParam.data : data,
                    })
                ),
            {
                enabled: Boolean(url),
                ...queryOptions,
                getNextPageParam,
                meta: {
                    meta: queryOptions?.meta,
                    path,
                    queryParams,
                    pathParams,
                    params,
                    data,
                },
            }
        );

        const updateCachedData = useCallback(
            (getNewCachedData: (currentData: InfiniteData<MappedResponseData>) => InfiniteData<MappedResponseData>) => {
                queryClient.setQueryData(queryKey, (currentData: InfiniteData<MappedResponseData>) => {
                    return getNewCachedData(currentData);
                });
            },
            [queryClient, queryKey]
        );

        return {
            ...queryResult,
            queryKey,
            updateCachedData,
        };
    };
};
