import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

const showToast = (message: ReactNode, type: 'info' | 'error') => {
    const options: ToastOptions = {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            fontSize: '14px',
            background: '#464646',
            fontFamily: "'Open Sans',sans-serif,Arial",
        },
        bodyStyle: {
            textAlign: 'center',
        },
    };

    if (type === 'error') {
        options.style = {
            ...options.style,
            background: '#9b464f',
        };
    }

    toast(message, options);
};

export const toaster = {
    info(message: ReactNode) {
        showToast(message, 'info');
    },
    error(message: ReactNode) {
        showToast(message, 'error');
    },
};
