import { FC, memo } from 'react';

import { Root, Sub } from './Label.styles';
import { LabelProps } from './Label.types';

/**
 * A short text description for the form field
 */
const Label: FC<LabelProps> = memo((props) => {
    const { children, size = 'small', spacing = '0 0 4', color = 'midnight70', required, ...restProps } = props;

    return (
        <Root $color={color} $size={size} spacing={spacing} as='label' {...restProps}>
            {children}

            {required && <Sub> *</Sub>}
        </Root>
    );
});

export default Label;
