import { DrawerContextProvider } from 'modules/components';
import { FC, PropsWithChildren } from 'react';

import { Inner } from './components';

const RootLayout: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    return (
        <DrawerContextProvider>
            <Inner>{children}</Inner>
        </DrawerContextProvider>
    );
};

export default RootLayout;
