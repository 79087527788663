import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

import { useApiError, useSessionExpirationDate, useWebAppVersion } from './ApiContext.hooks';
import { ApiContextType } from './ApiContext.types';

const ApiContext = createContext<ApiContextType>({} as ApiContextType);

export const ApiProvider: FC<PropsWithChildren> = (props) => {
    const { children } = props;
    const apiError = useApiError();
    const sessionExpirationDate = useSessionExpirationDate();
    const webAppVersion = useWebAppVersion();

    const value = useMemo(
        () => ({
            ...apiError,
            ...sessionExpirationDate,
            ...webAppVersion,
        }),
        [apiError, sessionExpirationDate, webAppVersion]
    );

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApiContext = () => {
    return useContext(ApiContext);
};
