import { useEffect, useRef, useState } from 'react';

/**
 * Normally you want to immediately highlight the dropzone even before the cursor is over
 * the component that can accept the drop. Cursor with a file enters the page => highlight the component's dropzone.
 * This hook helps you with that.
 */
export const useDragAndDropInfo = () => {
    let counterRef = useRef<number>(0);

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const onDragEnter = () => {
            if (counterRef.current === 0) {
                setIsActive(true);
            }

            counterRef.current++;
        };

        const onDragLeave = () => {
            counterRef.current--;

            if (counterRef.current === 0) {
                setIsActive(false);
            }
        };

        const onDrop = () => {
            counterRef.current = 0;
            setIsActive(false);
        };

        window.addEventListener('dragenter', onDragEnter, true);
        window.addEventListener('dragleave', onDragLeave, true);
        window.addEventListener('drop', onDrop, true);

        return function cleanup() {
            window.removeEventListener('dragenter', onDragEnter, true);
            window.removeEventListener('dragleave', onDragLeave, true);
            window.removeEventListener('drop', onDrop, true);
        };
    }, [counterRef, setIsActive]);

    return {
        isGlobalDragActive: isActive,
    };
};
