import { ReactNode } from 'react';

export interface NavBarProps {
    breadcrumbs: ReactNode[];
    mode: NavBarMode;
    onClose?: () => void;
    hideAvatarMenu?: boolean;
}

export enum NavBarMode {
    withDrawer = 'withDrawer',
    withCross = 'withCross',
    empty = 'empty',
}
