import { Button, Field, LoadingBar, NumberEditor, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Spacing } from '@approvalmax/ui/src/components';
import { MobileClientType } from 'modules/api';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, Toolbar } from 'modules/components';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useEditSupportedClients, useGetSupportedClients } from './MobileMinVersionPage.hooks';
import { messages } from './MobileMinVersionPage.messages';

const MobileMinVersionPage: FC = memo(() => {
    const { isInitialLoading, data } = useGetSupportedClients();

    const androidData = useMemo(
        () => data?.find((client) => client.clientName === MobileClientType.androidPhone),
        [data]
    );
    const androidMinVersion = androidData ? +androidData.minVersion : 0;
    const androidMaxVersion = androidData ? +androidData.maxVersion || undefined : undefined;

    const iphoneData = useMemo(() => data?.find((client) => client.clientName === MobileClientType.iPhone), [data]);
    const iphoneMinVersion = iphoneData ? +iphoneData.minVersion : 0;
    const iphoneMaxVersion = iphoneData ? +iphoneData.maxVersion || undefined : undefined;

    const [androidMinVersionState, setAndroidMinVersionState] = useState(0);
    const [iphoneMinVersionState, setIphoneMinVersionState] = useState(0);

    useEffect(() => {
        setAndroidMinVersionState(androidMinVersion);
        setIphoneMinVersionState(iphoneMinVersion);
    }, [androidMinVersion, iphoneMinVersion]);

    const handleChangeAndroidMinVersion = useCallback((value: number) => setAndroidMinVersionState(value || 0), []);
    const handleChangeIphoneMinVersion = useCallback((value: number) => setIphoneMinVersionState(value || 0), []);

    const { editSupportedClients, isLoading } = useEditSupportedClients();

    const saveAndroidMinVersion = useCallback(
        () =>
            editSupportedClients({
                clientName: MobileClientType.androidPhone,
                minVersion: String(androidMinVersionState),
            }),
        [androidMinVersionState, editSupportedClients]
    );

    const saveIphoneMinVersion = useCallback(
        () => editSupportedClients({ clientName: MobileClientType.iPhone, minVersion: String(iphoneMinVersionState) }),
        [iphoneMinVersionState, editSupportedClients]
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                {isInitialLoading ? (
                    <LoadingBar />
                ) : (
                    <ScrollableAreaWrapper>
                        <Toolbar title={messages.pageTitle} />

                        <ScrollableArea css='flex: 1; overflow: hidden;'>
                            <Box spacing='20 60'>
                                <Field title={messages.labelAndroid}>
                                    <Box width='120px'>
                                        <NumberEditor
                                            value={androidMinVersionState}
                                            min={androidMinVersion}
                                            max={androidMaxVersion}
                                            onChange={handleChangeAndroidMinVersion}
                                            changeOnBlur={false}
                                            disabled={isLoading}
                                        />
                                    </Box>
                                </Field>

                                <Box spacing='8 0' />

                                <Button
                                    execute={saveAndroidMinVersion}
                                    disabled={androidMinVersion === androidMinVersionState || isLoading}
                                >
                                    {messages.applyChanges}
                                </Button>

                                <Spacing height={40} />

                                <Field title={messages.labelIphone}>
                                    <Box width='120px'>
                                        <NumberEditor
                                            value={iphoneMinVersionState}
                                            min={iphoneMinVersion}
                                            max={iphoneMaxVersion}
                                            onChange={handleChangeIphoneMinVersion}
                                            changeOnBlur={false}
                                            disabled={isLoading}
                                        />
                                    </Box>
                                </Field>

                                <Box spacing='8 0' />

                                <Button
                                    execute={saveIphoneMinVersion}
                                    disabled={iphoneMinVersion === iphoneMinVersionState || isLoading}
                                >
                                    {messages.applyChanges}
                                </Button>
                            </Box>
                        </ScrollableArea>
                    </ScrollableAreaWrapper>
                )}
            </Box>
        </PageLayout>
    );
});

export default MobileMinVersionPage;
