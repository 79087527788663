import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { SpacingProps } from './Spacing.types';

export const Root = styled.div<StyledMods<Pick<SpacingProps, 'height'>>>`
    ${mods('height')(
        (value) => css`
            margin-top: ${value}px;
        `
    )};
`;
