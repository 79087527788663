import { FC, memo } from 'react';

import { messages } from './DownloadButton.messages';
import { StyledDownloadIcon } from './DownloadButton.styles';
import { DownloadButtonProps } from './DownloadButton.types';

const DownloadButton: FC<DownloadButtonProps> = memo((props) => {
    const { url, qa, ...restProps } = props;

    if (!url) {
        return null;
    }

    return (
        <a
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            title={messages.buttonTitle}
            data-qa={qa?.('toolbar-download-button')}
            {...restProps}
        >
            <StyledDownloadIcon size='28' />
        </a>
    );
});

export default DownloadButton;
