import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useUrlSearchParams = (defaultParams?: URLSearchParams) => {
    const { search, pathname } = useLocation();

    const history = useHistory();

    const hasSetSearchParamsRef = useRef(false);

    const searchParams = useMemo(() => {
        const params = new URLSearchParams(search);

        // Set default search params only if set function hasn't been called yet
        if (defaultParams && !hasSetSearchParamsRef.current) {
            for (const defaultParam of defaultParams) {
                const [defaultParamKey, defaultParamValue] = defaultParam;

                params.append(defaultParamKey, defaultParamValue);
            }
        }

        return params;
    }, [search, defaultParams]);

    const setSearchParams = useCallback(
        (
            newSearchParams: URLSearchParams | ((currentSearchParams: URLSearchParams) => URLSearchParams),
            options: {
                useReplace?: boolean;
            }
        ) => {
            const { useReplace = false } = options;

            hasSetSearchParamsRef.current = true;

            const newSearchParamsValue =
                typeof newSearchParams === 'function' ? newSearchParams(searchParams) : newSearchParams;

            if (useReplace) {
                history.replace({
                    pathname,
                    search: newSearchParamsValue.toString(),
                });
            } else {
                history.push({
                    pathname,
                    search: newSearchParamsValue.toString(),
                });
            }
        },
        [history, searchParams, pathname]
    );

    return [searchParams, setSearchParams] as const;
};
