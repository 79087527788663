import { Accordion, LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { WorkflowDetails } from '..';
import { useWorkflowsList } from './WorkflowsTab.hooks';
import { Root } from './WorkflowsTab.styles';
import { WorkflowsTabProps } from './WorkflowsTab.types';

const WorkflowsTab: FC<WorkflowsTabProps> = memo((props) => {
    const { companyId } = props;

    const { isInitialLoading, workflowsList } = useWorkflowsList(companyId);

    return (
        <Root>
            {isInitialLoading ? (
                <Box spacing='60'>
                    <LoadingSpinner />
                </Box>
            ) : (
                <Box spacing='20 60'>
                    {(workflowsList || []).map((workflow) => (
                        <Accordion key={workflow.id} title={`${workflow.integrationCode} ${workflow.name}`}>
                            <div>
                                <WorkflowDetails companyId={companyId} workflowId={workflow.id} />
                            </div>
                        </Accordion>
                    ))}
                </Box>
            )}
        </Root>
    );
});

export default WorkflowsTab;
