import { Reference } from '@approvalmax/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api, CompanyDetails, QueryKeys } from 'modules/api';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { NetSuiteCacheAction } from '../NetSuiteSyncAndDeleteCachePopup/NetSuiteSyncAndDeleteCachePopup.types';

export const useCompanyDetails = (companyId: string) => {
    const history = useHistory();

    const { isInitialLoading, data: companyDetails } = useQuery(
        [QueryKeys.Companies, companyId, QueryKeys.CompanyDetails],
        () => api.internalSupport.getCompanyDetails(companyId),
        {
            onError: (error: AxiosError) => {
                if (error.response?.data) {
                    history.push(getPath('root'));
                }
            },
        }
    );

    return {
        companyDetails,
        isInitialLoading,
    };
};

export const useSaveCompanyBetaFeatures = () => {
    const queryClient = useQueryClient();

    const { isLoading, mutateAsync: saveCompanyBetaFeatures } = useMutation(
        (options: { companyId: string; betaFeatures: string[] }) =>
            api.internalSupport.saveCompanyBetaFeatures(options.companyId, options),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.Companies]);
            },
        }
    );

    return {
        saveCompanyBetaFeatures,
        isLoading,
    };
};

export const useBetaFeatures = (companyDetails: CompanyDetails | undefined) => {
    const [betaFeatures, setBetaFeatures] = useState<string[]>([]);

    const { isLoading: areBetaFeaturesSaving, saveCompanyBetaFeatures } = useSaveCompanyBetaFeatures();

    useEffect(() => {
        if (companyDetails) {
            setBetaFeatures(companyDetails.companyBetaFeatures);
        }
    }, [companyDetails]);

    const removedBetaFeatures = useMemo(
        () =>
            (companyDetails?.companyBetaFeatures ?? []).filter((savedFeature) => !betaFeatures.includes(savedFeature)),
        [companyDetails, betaFeatures]
    );

    const onBetaFeaturesChange = useCallback((newValues: Reference[]) => {
        setBetaFeatures(newValues.map((betaFeature) => betaFeature.id));
    }, []);

    const onSaveBetaFeatures = useCallback(async () => {
        if (companyDetails) {
            await saveCompanyBetaFeatures({
                companyId: companyDetails.company.id,
                betaFeatures,
            });
        }
    }, [betaFeatures, companyDetails, saveCompanyBetaFeatures]);

    return {
        areBetaFeaturesSaving,
        betaFeatures,
        removedBetaFeatures,
        onBetaFeaturesChange,
        onSaveBetaFeatures,
    };
};

export const useEnforceFraudDetectionPopup = () => {
    const [isEnforceFraudDetectionPopupOpened, setIsEnforceFraudDetectionPopupOpened] = useState(false);

    const onEnforceFraudDetectionPopupOpen = useCallback(() => {
        setIsEnforceFraudDetectionPopupOpened(true);
    }, []);

    const onEnforceFraudDetectionPopupClose = useCallback(() => {
        setIsEnforceFraudDetectionPopupOpened(false);
    }, []);

    const { permissionFlags } = useCurrentAccount();

    const hasEnforceFraudDetectionPermission = permissionFlags?.admin.enforceFraudDetection ?? false;

    return {
        isEnforceFraudDetectionPopupOpened,
        onEnforceFraudDetectionPopupOpen,
        onEnforceFraudDetectionPopupClose,
        hasEnforceFraudDetectionPermission,
    };
};

export const useForceMigrationPopup = () => {
    const [isForceMigrationPopupOpened, setIsForceMigrationPopupOpened] = useState(false);

    const onForceMigrationPopupOpen = useCallback(() => {
        setIsForceMigrationPopupOpened(true);
    }, []);

    const onForceMigrationPopupClose = useCallback(() => {
        setIsForceMigrationPopupOpened(false);
    }, []);

    const { permissionFlags } = useCurrentAccount();

    const hasForceMigrationPermission = permissionFlags?.admin.forceMatchingMigration ?? false;

    return {
        isForceMigrationPopupOpened,
        onForceMigrationPopupOpen,
        onForceMigrationPopupClose,
        hasForceMigrationPermission,
    };
};

export const useNetSuiteSyncCacheStatusPopup = () => {
    const [isNetSuiteSyncCacheStatusPopupOpened, setIsNetSuiteSyncCacheStatusPopupOpened] = useState(false);

    const onNetSuiteSyncCacheStatusPopupOpen = useCallback(() => {
        setIsNetSuiteSyncCacheStatusPopupOpened(true);
    }, []);

    const onNetSuiteSyncCacheStatussPopupClose = useCallback(() => {
        setIsNetSuiteSyncCacheStatusPopupOpened(false);
    }, []);

    return {
        isNetSuiteSyncCacheStatusPopupOpened,
        onNetSuiteSyncCacheStatusPopupOpen,
        onNetSuiteSyncCacheStatussPopupClose,
    };
};

export const useNetSuiteSyncAndDeleteCachePopup = () => {
    const [isNetSuiteSyncAndDeleteCachePopupOpened, setIsNetSuiteSyncAndDeleteCachePopupOpened] = useState(false);
    const [netSuiteCacheAction, setNetSuiteCacheAction] = useState<NetSuiteCacheAction>(NetSuiteCacheAction.sync);

    const onSyncCache = useCallback(() => {
        setIsNetSuiteSyncAndDeleteCachePopupOpened(true);
        setNetSuiteCacheAction(NetSuiteCacheAction.sync);
    }, []);

    const onDeleteCache = useCallback(() => {
        setIsNetSuiteSyncAndDeleteCachePopupOpened(true);
        setNetSuiteCacheAction(NetSuiteCacheAction.delete);
    }, []);

    const onNetSuiteSyncAndDeleteCachePopupClose = useCallback(() => {
        setIsNetSuiteSyncAndDeleteCachePopupOpened(false);
    }, []);

    return {
        isNetSuiteSyncAndDeleteCachePopupOpened,
        onNetSuiteSyncAndDeleteCachePopupClose,
        netSuiteCacheAction,
        onSyncCache,
        onDeleteCache,
    };
};
