import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Progress, Spacing, Table, Text } from '@approvalmax/ui/src/components';
import { JsonViewer, NavBarMode, PageLayout, StyledScrollableArea, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { useGetAmaxPayConsentDetails, useGetAmaxPayConsentHistory } from 'modules/data/queries';
import { memo, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { dataTableColumns } from './AmaxPayConsentDetailsPage.config';
import { messages } from './AmaxPayConsentDetailsPage.messages';
import { PathParams } from './AmaxPayConsentDetailsPage.types';

export const AmaxPayConsentDetailsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.amaxPay.paymentsView);

    const history = useHistory();

    const { companyId, consentId } = useParams<PathParams>();

    const { data: consentDetailsData, isFetching: isFetchingConsentDetails } = useGetAmaxPayConsentDetails({
        companyId,
        consentId,
    });

    const { data: consentHistoryData, isFetching: isFetchingConsentHistory } = useGetAmaxPayConsentHistory({
        companyId,
        consentId,
    });

    const isFetching = isFetchingConsentDetails || isFetchingConsentHistory;

    const consentHistoryDataWithId = useMemo(
        () => consentHistoryData?.map((item, index) => ({ id: String(index), ...item })),
        [consentHistoryData]
    );

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('amaxPayConsents'));
        }
    }, [history]);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <StyledScrollableArea>
                        <Toolbar title={messages.pageTitle} />

                        <Box height={8}>{isFetching && <Progress />}</Box>

                        <Box spacing='20 60'>
                            {consentHistoryData && (
                                <>
                                    <Text font='headline' fontSize='xxsmall'>
                                        {messages.consentHistory}
                                    </Text>

                                    <Spacing height={8} />

                                    <Table data={consentHistoryDataWithId || []} columns={dataTableColumns} />

                                    <Spacing height={20} />
                                </>
                            )}

                            {consentDetailsData && (
                                <>
                                    <Text font='headline' fontSize='xxsmall'>
                                        {messages.rawData}
                                    </Text>

                                    <Spacing height={8} />

                                    <JsonViewer json={consentDetailsData.rawData} />
                                </>
                            )}
                        </Box>
                    </StyledScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

AmaxPayConsentDetailsPage.displayName = 'AmaxPayConsentDetailsPage';
