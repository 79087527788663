import { domHelpers } from '@approvalmax/utils';

import Checkbox from '../../../Checkbox/Checkbox';
import { checkboxColumnId, typedMemo } from '../../Table.helpers';
import { BaseItem, TableCheckType } from '../../Table.types';
import { ColumnHeader } from '..';
import { HeaderCell } from '../HeaderCell/HeaderCell';
import { checkboxColumnDefinition } from './HeaderRow.constants';
import { useCheckedColumn } from './HeaderRow.hooks';
import { DragHandler, Root } from './HeaderRow.styles';
import { HeaderRowProps } from './HeaderRow.types';

export const HeaderRow = typedMemo(<Item extends BaseItem>(props: HeaderRowProps<Item>) => {
    const {
        columns,
        getRowId,
        checkedItems,
        onCheckedItemsChange,
        bordered,
        data,
        onSort,
        headerColor,
        stickyHeader,
        checkboxColumnCover,
        isReorderableColumns,
        isReorderableRows,
        checkType,
        qa,
        ...otherProps
    } = props;

    const { handleColumnCheck } = useCheckedColumn(data, checkedItems, getRowId, onCheckedItemsChange);

    return (
        <Root $bordered={bordered} data-qa={domHelpers.generateDataQa(qa, 'table-header')} {...otherProps}>
            {isReorderableRows && (
                <th data-column-id='row-drag-handler'>
                    <DragHandler $hide={!isReorderableRows} />
                </th>
            )}

            {checkedItems && (
                <th data-column-id='checked-items' key={checkboxColumnId}>
                    <ColumnHeader
                        columnDefinition={checkboxColumnCover ?? checkboxColumnDefinition}
                        color={headerColor}
                        bordered={bordered}
                        onSort={onSort}
                        stickyHeader={stickyHeader}
                    >
                        {checkType === TableCheckType.checkbox && (
                            <Checkbox
                                size='small'
                                indeterminate={(checkedItems?.length ?? 0) < data.length}
                                checked={(checkedItems?.length ?? 0) > 0}
                                onChange={handleColumnCheck}
                            />
                        )}
                    </ColumnHeader>
                </th>
            )}

            {columns.map((columnDefinition) => {
                const ChildrenComponent = columnDefinition.columnComponent || ColumnHeader;

                return (
                    <HeaderCell
                        id={columnDefinition.id}
                        columnDefinition={columnDefinition}
                        onSort={onSort}
                        reorderable={isReorderableColumns}
                        key={columnDefinition.id}
                    >
                        <ChildrenComponent
                            columnDefinition={columnDefinition}
                            color={headerColor}
                            bordered={bordered}
                            onSort={onSort}
                            stickyHeader={stickyHeader}
                            reorderable={isReorderableColumns}
                        />
                    </HeaderCell>
                );
            })}
        </Root>
    );
});
