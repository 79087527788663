import type { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { JSX, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import { intl } from '../../services/localization/localization';

type MessageValueReturnFunction = (value: any) => JSX.Element;

type MessageValues = { [key: string]: string | ReactNode | MessageValueReturnFunction };

type MessageWithProps = (values: MessageValues) => string;

export type MessageResultValue = string & MessageWithProps;

export const isMessageWithProps = (message: MessageWithProps | string): message is MessageWithProps => {
    return typeof message === 'function';
};

export const defineMessages = <T extends Record<string, Record<string, string> | string>>(
    prefix: string,
    messages: T
) => {
    return Object.keys(messages).reduce(
        (acc, item: keyof typeof messages) => {
            const messageValue = messages[item];
            const message: MessageDescriptor =
                typeof messageValue === 'string'
                    ? {
                          defaultMessage: messageValue,
                      }
                    : messageValue;

            message.id = `${prefix}.${String(message.id || item)}`;

            acc[item] = (
                typeof message.defaultMessage === 'string' && /({.+?}|<.+?>)/g.test(message.defaultMessage)
                    ? (values: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) =>
                          intl.formatMessage(message, values)
                    : intl.formatMessage(message)
            ) as MessageResultValue;

            return acc;
        },
        {} as { [N in keyof T]: MessageResultValue }
    );
};
