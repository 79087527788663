import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

import { QueryOptions, ResponseData } from './useGetIntegrationConfigurations.types';

export const useGetIntegrationConfigurations = (options: QueryOptions = {}) => {
    return useQuery<ResponseData, Error, Record<string, any>>(
        [QueryKeys.IntegrationConfigurations],
        () => api.integrationConfigurations.getIntegrationConfigurations(),
        options
    );
};
