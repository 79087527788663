import { Guid, Reference } from '@approvalmax/types';
import has from 'lodash/has';

import { EnforcementTfaType } from '../types';
import { IntegrationType } from '../types/IntegrationType';

export enum OrganisationVersion {
    GLOBAL = 'Global',
    UK = 'UnitedKingdom',
    NZ = 'NewZealand',
    AU = 'Australia',
    US = 'UnitedStates',
    QBO_AU = 'AU',
    QBO_US = 'US',
}

export enum SyncProgressStatus {
    Unknown = 'Unknown',
    Pending = 'Pending',
    InProgress = 'InProgress',
    Failed = 'Failed',
    Succeeded = 'Succeeded',
}

export enum TwoFaEnforcementType {
    None = 'None',
    Soft = 'Soft',
    Hard = 'Hard',
}
export interface UserAnswer {
    userId: Guid;
    userName: string;
    firstName: string;
    lastName: string;
    userEmail: string;
    avatarHttps: string;
    loggedIn: boolean;
    isInvited: boolean;
    isTFAEnabled?: boolean;
}

export interface RequestsSyncProgressAnswer {
    templateId: Guid;
    templateIntegrationCode: string;
    status: SyncProgressStatus;
    lastSyncDate: string;
}
export interface IntegrationAnswer {
    companyId: Guid;
    integrationId: Guid;
    integrationType: IntegrationType;
    integratedCompanyName: string;
    integratedCompanyId: string;
    lockDate: string;
    financialYearEnd?:
        | {
              day: number;
              month: number;
          }
        | string;
    modifiedDate: string;
    createdDate: string;
    enabled: boolean;
    errorStatusCode?: number | undefined;
    errorStatusMessage: string;
    externalUrl: string;
    templatesSyncProgress: RequestsSyncProgressAnswer[];
    isBudgetSynchronizationEnabled: boolean;
    integrationExtraInfo: string;
    version: OrganisationVersion;
}

export interface CompaniesReceiptBankIntegrationAnswer {
    companyId: Guid;
    integrationId: Guid;
    modifiedDate: string;
    createdDate: string;
    isConnected: boolean;
}

export interface CompaniesConfigurationKeyAnswer {
    key: string;
    name: string;
}

export interface CompanyAnswer {
    companyId: Guid;
    name: string;
    defaultCurrency: string;
    timeZone: string;
    author: UserAnswer;
    managers: UserAnswer[];
    participants: UserAnswer[];
    auditors: UserAnswer[];
    workflowManagers: UserAnswer[];
    integrations: IntegrationAnswer[];
    receiptBankIntegration: CompaniesReceiptBankIntegrationAnswer;
    licenseId: string;
    isReadOnly?: boolean;
    subscriptionId?: Guid | undefined;
    productPlanId: string;
    productPlanFeatures: string[];
    betaFeatures: CompaniesConfigurationKeyAnswer[];
    allFeaturesTrialStatus: string;
    allFeaturesTrialEndDate: string;
    enforcementTfaType: TwoFaEnforcementType;
}

interface LicenseAddonInfo {
    trialPayFeatureUsageLimit: number;
    trialPayFeatureUsagePercentage: string;
    trialCaptureFeatureUsageLimit: number;
    trialCaptureFeatureUsagePercentage: string;
}

export interface CompanyDetails {
    company: Reference;
    companyBetaFeatures: string[];
    companyRawData: CompanyAnswer;
    licenseAddonInfo: LicenseAddonInfo;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
    email: string;
}

export interface UserDetails {
    user: User;
    userBetaFeatures: string[];
    userRawData: unknown;
}

export interface AdminContext {
    companyBetaFeatures: string[];
    userBetaFeatures: string[];
}

export interface CompanySearchEntry {
    id: string;
    name: string;
    accountOwnerEmail: string;
    isActive: boolean;
    isActiveTrial: boolean;
    isExpiredTrial: boolean;
    isActivePartner: boolean;
    isRetired: boolean;
    hasIntegration: boolean;
    isIntegrationActive: boolean;
    integrationType?: string;
}

export interface ReportRaw {
    reportId: string;
    name: string;
    companyId: string;
}

export interface Report {
    id: string;
    name: string;
    companyId: string;
}

export interface ReportDetails {
    reportId: string;
    reportName: string;
    companyId: string;
    createdDate: string;
    modifiedDate: string;
    lastRunDate: string;
    settings: Record<string, unknown>;
    schedules: Array<Record<string, unknown>>;
}

export interface CompanyContextIntegration {
    CompanyId: string;
    Enabled: boolean;
    IntegratedCompanyName: string;
    IntegrationId: string;
    IntegrationType: IntegrationType;
}

export enum Plan {
    Trial = 'AMAT',
    XeroOnly = 'AMXO',
    QBOOnly = 'AMQB',
    XeroAdvanced = 'AMXV',
    QBOAdvanced = 'AMQV',
    XeroPremium = 'AMXP',
    QBOPremium = 'AMQP',
    Professional = 'AMAL',
    PartnerEdition = 'AMPE',
    Retired = 'AMRE',

    ReceiptBankProfessional = 'RBAL',
    ReceiptBankQuickBooksOnlineStandard = 'RBQB',
    ReceiptBankQuickBooksOnlineAdvanced = 'RBQV',
    ReceiptBankQuickBooksOnlinePremium = 'RBQP',
    ReceiptBankXeroStandard = 'RBXO',
    ReceiptBankXeroAdvanced = 'RBXV',
    ReceiptBankXeroPremium = 'RBXP',
}

export interface RequestFindData {
    id: string;
    companyId: string;
    isDeleted: boolean;
    name: string;
}

export enum AttachmentType {
    General = 0,
    PurchaseOrderPDF = 1,
}

export enum FilesFileUploadResult {
    OK = 0,
    TooBig = 1,
    RestrictedFileType = 2,
    VirusFound = 3,
}

export enum OcrStatus {
    Succeeded = 'Succeeded',
    InProgress = 'InProgress',
    Failed = 'Failed',
    Requested = 'Requested',
}

export interface Attachment {
    id: Guid;
    attachmentId: Guid;
    name: string;
    size: number;
    createdDate: string;
    author: UserAnswer;
    attachmentType: AttachmentType;
    uploadResult?: FilesFileUploadResult | undefined;
    shouldSerializeUploadResult: boolean;
    externalId?: string;
    isOcrOngoing?: boolean;
    ocrStatus?: OcrStatus;
}

export enum RequestOrigin {
    ApprovalMax = 'ApprovalMax',
    Xero = 'Xero',
    QBooks = 'QBooks',
    ReceiptBank = 'ReceiptBank',
    NetSuite = 'NetSuite',
    Dear = 'Dear',
    PublicApi = 'PublicApi',
}

export interface RequestDetails {
    id: string;
    name: string;
    isDeleted: boolean;
    rawData: {
        companyId: string;
        attachments: Attachment[];
        integrationFriendlyName: string;
        origin: RequestOrigin;
    };
}

export interface IgnoredEmail {
    email: string;
    reason: string;
    ignoredAt: string;
    id: string;
}

export const isIgnoredEmail = (obj: unknown): obj is IgnoredEmail => {
    if (has(obj, 'email') && has(obj, 'reason') && has(obj, 'ignoredAt')) {
        return true;
    }

    return false;
};

export interface IgnoredEmailsWithContinuationToken {
    items: IgnoredEmail[];
    continuationToken?: string;
}

export interface IgnoredEmailDetails {
    rawData: unknown;
}

export interface AdminPortalAccount {
    id: string;
    email: string;
    permissions: string[];
    isTFAEnabled?: boolean;
}

export interface AuditLogEntry {
    id: string;
    occuredAt: string;
    actorEmail: string;
    action: string;
    details: string;
}

export interface StorageQueue {
    id: string;
    messageCount: number;
    poisonMessageCount: number;
}

export interface StorageQueueMessage {
    id: string;
    text: string;
    insertedAt: string;
    expiresAt: string;
}

export interface SqlQueryExecutionResult {
    columnNames: string[];
    columnValues: string[][];
}

export interface MaintenanceInfo {
    isEnabled: boolean;
    value: string;
}

export interface AnnouncementInfo {
    valueId: string;
    value: string;
}

export interface DeleteRestoreRequestData {
    companyId: string;
    requestIds: string[];
    comment: string;
    supportTicketId: string;
}

export interface StartTwoFaData {
    accountId: string;
    secretKey: string;
    stateId: string;
}

export interface VerifyTFACodeRequestData {
    stateId: string;
    code: string;
}

export interface EligibleToEnforceFraudDetectionWorkflow {
    effectiveDate: string;
    integrationCode: string;
    workflowId: string;
}

export interface DeletedParticipant {
    deletedDate: string;
    email: string;
    id: string;
    role: string;
}

export interface IntegrationConfiguration {
    integrationType: IntegrationType;
    enforcementTfaType: EnforcementTfaType;
}

export interface NextCompanySyncCommand {
    id: string;
    status: string;
    companyId: string;
    requestId: string;
    content: Record<string, unknown>;
    timestamp: string;
}

export interface ResponseError {
    type: string;
    status: number;
    title: string;
    traceId: string;

    errorCode?: string;
    detail?: string;

    errors?: { [key: string]: string[] };
}

export interface TfaDisablingHistoryItem {
    createdDate: string;
    actorEmail: string;
    comment: string;
    supportTicketId: string;
}

export interface ShardItem {
    id: string;
    server: string;
    database: string;
    protocol: string;
    port: number;
    status: string;
}

export enum MobileClientType {
    androidPhone = 'AndroidPhone',
    iPhone = 'iPhone',
}

export interface SupportedClient {
    clientName: MobileClientType;
    minVersion: string;
    maxVersion: string;
}

export interface TenantMovementData {
    errors: string[];
    rowsCountPerTable: Record<string, number>;
}

export interface TenantCurrentMovementTableItem {
    order: number;
    name: string;
    copiedRowsCount: number;
    totalRowsCount: number;
    isComplete: boolean;
    error: string | null;
}

export enum TenantCurrentMovementStep {
    PreChecks = 'PreChecks',
    PreCopyAnalysis = 'PreCopyAnalysis',
    SwitchTenantOffline = 'SwitchTenantOffline',
    CopyTables = 'CopyTables',
    PostCopyChecks = 'PostCopyChecks',
    CleanupTables = 'CleanupTables',
    SwitchTenantOnline = 'SwitchTenantOnline',
    Finalize = 'Finalize',
}

export interface TenantCurrentMovementData {
    state: {
        instanceId: string;
        createdAt: string;
        lastUpdatedAt: string;
        isRunning: boolean;
        status: string;
        entityState: {
            TargetShardId: string;
            TenantId: string;
            LastError: string | null;
            Step: TenantCurrentMovementStep | null;
        } | null;
    };
    tables: TenantCurrentMovementTableItem[];
}
