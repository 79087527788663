export const cleanRequestParams = <T extends object = Record<string, any>>(
    params?: T,
    emptyStringAllowed: boolean = false
) => {
    if (!params) return {};

    const paramsCleaned = { ...params };

    for (let key in paramsCleaned) {
        const isNull = paramsCleaned[key] === null;
        const isUndefined = paramsCleaned[key] === undefined;
        const isEmptyString = !emptyStringAllowed && paramsCleaned[key] === '';

        if (isNull || isUndefined || isEmptyString) {
            delete paramsCleaned[key];
        }
    }

    return paramsCleaned;
};
