import { Mods, mods } from '@approvalmax/theme';
import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    padding-left: 60px;
`;

export const Tab = styled.div<Mods<'active'>>`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 13px;
    text-transform: uppercase;
    cursor: pointer;
    ${font(14, '#384650')}

    ${mods.active.true`
        ${font(14, '#4c8a5c', 'bold')}

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: #4c8a5c;
        }
    `}
`;
