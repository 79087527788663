import './defaultColumnHeader.scss';

import React from 'react';
import bemFactory from 'react-bem-factory';

import { SortArrowIcon } from '../../icons';
import { DataTableColumnHeaderComponentProps, DataTableColumnSortOrder, DataTablePadding } from './index';

const bem = bemFactory.block('ui-data-table-default-column-header');

class DefaultColumnHeader extends React.Component<DataTableColumnHeaderComponentProps> {
    public static defaultProps = {
        padding: DataTablePadding.Default,
    };

    public shouldComponentUpdate(nextProps: DataTableColumnHeaderComponentProps) {
        return this.props.columnDefinition !== nextProps.columnDefinition;
    }

    public render() {
        const { columnDefinition, className, style, padding, qa } = this.props;
        const name: string = columnDefinition.name;

        return (
            <div
                className={bem.add(className)(null, padding!, {
                    sortable: columnDefinition.sortable,
                })}
                data-qa={qa}
                data-qa-id={qa ? columnDefinition.id : undefined}
                style={style}
                title={name}
                onClick={columnDefinition.sortable ? this._onClick : undefined}
            >
                <div className={bem('text')}>{name}</div>

                {columnDefinition.sortable && (
                    <div
                        className={bem('sort-icon', {
                            desc: columnDefinition.sortOrder === 'desc',
                            hidden: !columnDefinition.sortOrder,
                        })}
                    >
                        <SortArrowIcon width={9} height={10} />
                    </div>
                )}
            </div>
        );
    }

    private _onClick = () => {
        if (this.props.columnDefinition.sortable && this.props.onSort) {
            let newSortOrder: DataTableColumnSortOrder = 'asc';

            if (this.props.columnDefinition.sortOrder === 'asc') {
                newSortOrder = 'desc';
            }

            this.props.onSort(this.props.columnDefinition.id, newSortOrder);
        }
    };
}

export default DefaultColumnHeader;
