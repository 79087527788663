import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useMyAccountPermissionsForUser = (userId: string) => {
    const { isInitialLoading, data: permissions } = useQuery(
        [QueryKeys.MyAccountPermissionsForUser],
        () => api.portalManagement.getMyAccountPermissionsForUser(userId),
        {}
    );

    return {
        permissions,
        isInitialLoading,
    };
};
