import { useEffect } from 'react';

import { HideParentElementHook } from './Item.types';

export const useHideParentElement = ({
    innerRef,
    fullscreenState,
    openState,
    renderByDefault,
    showInFullScreen,
}: HideParentElementHook) => {
    useEffect(() => {
        let display = 'initial';

        if ((openState && fullscreenState && !showInFullScreen) || (!openState && !renderByDefault)) {
            display = 'none';
        }

        if (innerRef.current?.parentElement) {
            innerRef.current.parentElement.style.display = display;
        }
    }, [fullscreenState, openState, renderByDefault, innerRef, showInFullScreen]);
};
