import * as Sentry from '@sentry/browser';
import uniqueId from 'lodash/uniqueId';

const PORTAL_ID_PREFIX = 'portal-';

const APP_ROOT_ID = 'app-root';
const classes = {
    INACTIVE_SECTION: 'rws__inactive-section',
};

export interface PortalDef {
    id: string;
    parentId: string | null;
    el: HTMLElement;
    transient: boolean;
    containsEl: (testedEl: HTMLElement) => boolean;
    requestClose(): void;
    closeRelatedPortals(): void;
    dispose(): void;
}

export enum WindowServiceEventType {
    DidCreatePortal = 'DidCreatePortal',
    WillDestroyPortal = 'WillDestroyPortal',
}

class ReactWindow {
    private _portalStack: PortalDef[] = [];
    private _externalWindow: Window | null = null;
    private _appRootId = APP_ROOT_ID;

    public setAppRootId(appRootId: string) {
        this._appRootId = appRootId;
    }

    public openNewWindow(): Window | null {
        let newWindow = window.open(
            '',
            '',
            'toolbar=no,location=no,status=1,menubar=no,scrollbars=yes,resizable=yes,width=900,height=750'
        );

        if (!newWindow) {
            Sentry.captureMessage('Failed to open a new window.');

            return null;
        }

        const stylesString = `
            <style>
                @media print {
                    body {
                        margin: 0 25mm 0 25mm;
                    }
                    table {
                        page-break-after: auto;
                    }
                    tr {
                        page-break-inside: avoid;
                        page-break-after: auto;
                    }
                    td {
                        page-break-inside: avoid;
                        page-break-after: auto;
                    }
                    thead {
                        display: table-header-group;
                    }
                    tfoot {
                            display: table-footer-group;
                    }
                }

                body {
                    margin: 0 auto;
                    padding: 60px;
                    position: relative;
                    word-wrap: break-word;
                    overflow: hidden;
                    overflow-y: auto;
                    background: #fff;
                    font-size: 13px;
                    line-height: 1.428571429;
                    -webkit-overflow-scrolling: touch;
                }

                body, input, button, textarea, select {
                    -webkit-font-smoothing: antialiased;
                    font-family: 'Open Sans', sans-serif, Arial;
                }

                @keyframes rotate {
                    0% {
                        -webkit-transform: rotate(0deg);
                        transform: rotate(0deg);
                    }
                    100% {
                        -webkit-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
            </style>
        `;

        newWindow.document.write(
            `
            <html>
                <head>
                    <title></title>
                    ${stylesString}
                </head>
                <body></body>
            </html>`
        );
        newWindow.document.close();
        newWindow.addEventListener('beforeunload', () => {
            this._externalWindow = null;
        });
        this._externalWindow = newWindow;

        return newWindow!;
    }

    public getNewWindow(): Window | null {
        return this._externalWindow;
    }

    public closeNewWindow() {
        if (this._externalWindow) {
            this._externalWindow.close();
            this._externalWindow = null;
        }
    }

    public createControlledPortal({
        transient,
        hostEl,
        onRequestClose,
        targetElementId,
    }: {
        transient: boolean;
        hostEl: HTMLElement | null;
        onRequestClose: () => void;
        targetElementId?: string;
    }): PortalDef {
        if (!transient) {
            this._removeTransientPortals();
        }

        let portalId = uniqueId(PORTAL_ID_PREFIX);
        let el = document.createElement('div');

        el.id = portalId;
        el.dataset.portalId = portalId;

        const targetElement = targetElementId ? document.getElementById(targetElementId) : null;

        if (targetElement) {
            targetElement.appendChild(el);
        } else {
            document.body.appendChild(el);
        }

        let parentPortalId: string | null = null;

        if (hostEl) {
            const closestPortal = hostEl.closest('[data-portal-id]');

            if (closestPortal) {
                parentPortalId = (closestPortal as HTMLElement).dataset.portalId || null;
            }
        }

        let portalDef: PortalDef = {
            id: portalId,
            parentId: parentPortalId,
            el,
            transient,
            containsEl: (testedEl: HTMLElement) => {
                return (
                    el === testedEl ||
                    el.contains(testedEl) ||
                    this._portalStack.some((x) => {
                        return x.parentId === portalId && (x.el === testedEl || x.el.contains(testedEl));
                    })
                );
            },
            requestClose: () => {
                portalDef.closeRelatedPortals();
                onRequestClose();
            },
            closeRelatedPortals: () => {
                if (!transient) {
                    this._removeTransientPortals();
                }

                let childrenDef = this._portalStack.filter((x) => x.parentId === portalId);

                childrenDef.forEach((c) => {
                    c.requestClose();
                });
            },
            dispose: () => {
                return this._destroyPortal(portalId);
            },
        };

        if (parentPortalId) {
            // If the parent has other children, they must be closed
            let childPortalDef = this._portalStack.find((x) => x.parentId === parentPortalId);

            if (childPortalDef) {
                // We kindly ask the portal owner to close it, rather than do it forcefully (React-friendly way)
                childPortalDef.requestClose();
            }
        }

        if (!portalDef.transient) {
            const nonTransientParentEl = this._findNonTransientParentEl(parentPortalId);

            if (nonTransientParentEl) {
                nonTransientParentEl.classList.add(classes.INACTIVE_SECTION);
            }
        }

        this._portalStack.push(portalDef);

        return portalDef;
    }

    private _removeTransientPortals() {
        this._portalStack.forEach((def) => {
            if (def.transient) {
                def.requestClose();
            }
        });
    }

    private _destroyPortal(portalId: string) {
        const portalDef = this._portalStack.find((s) => s.id === portalId);

        if (portalDef) {
            if (!portalDef.transient) {
                const nonTransientParentEl = this._findNonTransientParentEl(portalDef.parentId);

                nonTransientParentEl?.classList.remove(classes.INACTIVE_SECTION);
            }

            portalDef.el.remove();
            this._portalStack.splice(this._portalStack.indexOf(portalDef), 1);
        }
    }

    private _findNonTransientParentEl(parentId: string | null): HTMLElement {
        let portalDef = this._portalStack.find((x) => x.id === parentId);

        if (!portalDef) {
            // Parent is root
            return document.getElementById(this._appRootId)!;
        }

        if (portalDef.transient) {
            return this._findNonTransientParentEl(portalDef.parentId);
        }

        // Found a parent (portal inside another portal)
        return portalDef.el;
    }
}

export const reactWindowService = new ReactWindow();
