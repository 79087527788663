import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const FadingPanel = styled(animated.div as any)`
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.3);
`;

export const PopupSlider = styled(animated.div as any)`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
