import { DataTableColumnDefinition } from '@approvalmax/ui';
import { DeletedParticipant } from 'modules/api';

import { messages } from './DeletedParticipantsList.messages';

export const dataTableColumns: DataTableColumnDefinition<DeletedParticipant>[] = [
    {
        id: 'Id',
        name: messages.idCol,
        value: (participant) => participant.id,
    },
    {
        id: 'Email',
        name: messages.emailCol,
        value: (participant) => participant.email,
    },
    {
        id: 'Role',
        name: messages.roleCol,
        value: (participant) => participant.role,
    },
    {
        id: 'DeletedDate',
        name: messages.deletedDateCol,
        value: (participant) => participant.deletedDate,
    },
];
