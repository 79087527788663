/**
 * Developer: Stepan Burguchev
 * Date: 3/6/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './actionMenu.scss';

import { eventHelpers } from '@approvalmax/utils';
import { Component, PropsWithChildren, ReactElement } from 'react';
import bemFactory from 'react-bem-factory';

import { AutoDropdown, DropdownPanelFlow } from '../../drop';
import ActionMenuItem from './ActionMenuItem';
import ActionMenuSeparator from './ActionMenuSeparator';
import ActionMenuTextButton from './ActionMenuTextButton';
import { ActionMenuContext } from './context';

const bem = bemFactory.block('ui-action-menu');

interface ActionMenuProps extends PropsWithChildren {
    button: ReactElement;
    className?: string;
    panelFlow?: DropdownPanelFlow;
}

class ActionMenu extends Component<ActionMenuProps> {
    public static Separator = ActionMenuSeparator;
    public static TextButton = ActionMenuTextButton;
    public static Item = ActionMenuItem;

    private _providerValue: { onExecute: () => void };

    public constructor(props: ActionMenuProps) {
        super(props);
        this._providerValue = {
            onExecute: this._onItemExecute,
        };
    }

    public render() {
        const { panelFlow, className, button, children } = this.props;

        return (
            <AutoDropdown className={className} button={button} panelMinWidth='none' panelFlow={panelFlow}>
                <div className={bem('panel')} onMouseDown={eventHelpers.preventDefaultCallback}>
                    <ActionMenuContext.Provider value={this._providerValue}>{children}</ActionMenuContext.Provider>
                </div>
            </AutoDropdown>
        );
    }

    private _onItemExecute = () => {
        this._unfocus();
    };

    private _unfocus = () => {
        const activeElement: any = document.activeElement;

        if (activeElement && activeElement.blur) {
            activeElement.blur();
        }
    };
}

export default ActionMenu;
