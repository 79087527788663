import { DataTable, Popup, usePopupContext } from '@approvalmax/ui';
import { compareHelpers, miscHelpers } from '@approvalmax/utils';
import { FC, memo } from 'react';

import { useAdminPortalPermissions } from '../../hooks/useAdminPortalPermissions';
import { dataTableColumns } from './UserDetailsPopup.config';
import { messages } from './UserDetailsPopup.messages';
import { Content } from './UserDetailsPopup.styles';
import { UserDetailsPopupProps } from './UserDetailsPopup.types';

const UserDetailsPopup: FC<UserDetailsPopupProps> = (props) => {
    const { user } = props;

    const { onRequestClose } = usePopupContext();
    const { permissions } = useAdminPortalPermissions();

    const data = user.permissions
        .map((pId) => permissions?.find((p) => p.id === pId))
        .filter(miscHelpers.notEmptyFilter)
        .sort((a, b) => compareHelpers.stringComparator2AscI(a.text, b.text));

    return (
        <Popup.DefaultContent
            title={user.email}
            buttons={
                <Popup.DefaultContent.Button execute={onRequestClose}>
                    {messages.doneButton}
                </Popup.DefaultContent.Button>
            }
            css='width: 700px; height: 550px;'
        >
            <Content>
                <DataTable data={data ?? []} columns={dataTableColumns} />
            </Content>
        </Popup.DefaultContent>
    );
};

export default memo(UserDetailsPopup);
