import { RefObject, useEffect, useRef } from 'react';

/**
 * Hook to trigger load more function
 * when user scrolls to bottom of container
 *
 * @param elementRef ref for container with scroll
 * @param threshold amount of pixels from container's bottom
 * when load more fn will be triggered
 * @param onLoadMore load more callback
 */
export const useLoadMoreOnScroll = (elementRef: RefObject<HTMLElement>, threshold = 25, onLoadMore: () => void) => {
    const lastScrollTopValueRef = useRef<number>(0);

    useEffect(() => {
        const element = elementRef.current;

        lastScrollTopValueRef.current = element?.scrollTop ?? 0;

        const scrollListener = (event: Event) => {
            const target = event.currentTarget;

            if (!(target instanceof HTMLElement)) {
                return;
            }

            const scrollDiff = target.scrollTop - lastScrollTopValueRef.current;

            const isDownScroll = scrollDiff > 0;

            lastScrollTopValueRef.current = target.scrollTop;

            if (isDownScroll && target.scrollHeight - target.scrollTop - target.offsetHeight <= threshold) {
                onLoadMore();
            }
        };

        element?.addEventListener('scroll', scrollListener);

        return () => {
            element?.removeEventListener('scroll', scrollListener);
        };
    }, [elementRef, threshold, onLoadMore]);
};
