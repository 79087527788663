import { font, oPopupPadding, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const DefaultContent = styled(Popup.DefaultContent)`
    width: 650px;
`;

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    ${oPopupPadding()};
`;

export const Description = styled.div`
    ${font(13, '#000')};
`;

export const Fields = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 20px;

    & > :not(:first-child) {
        margin-top: 20px;
    }
`;
