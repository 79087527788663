import { objectHelpers } from '@approvalmax/utils';
import { RequestDetails } from 'modules/api';
import { FileType } from 'modules/types';

import { Attachment } from './RequestDetailsPage.types';

export const checkHasAttachments = (details: RequestDetails | undefined) => {
    if (details) {
        return (
            objectHelpers.has(details.rawData, 'attachments') &&
            Array.isArray(details.rawData.attachments) &&
            details.rawData.attachments.length > 0
        );
    }

    return false;
};

export const isAttachmentItem = (item: unknown): item is Attachment => {
    return (
        objectHelpers.has(item, 'attachmentId') &&
        typeof item.attachmentId === 'string' &&
        objectHelpers.has(item, 'name') &&
        typeof item.name === 'string'
    );
};

const getFileExtension = (fileName: string): string => {
    const index = fileName.lastIndexOf('.');

    if (index === -1) {
        return '';
    }

    const ext = fileName.slice(index + 1).toLowerCase();

    return ext;
};

const docTypes: {
    [extension: string]: FileType;
} = {
    png: FileType.Image,
    jpg: FileType.Image,
    jpeg: FileType.Image,
    pdf: FileType.Pdf,
};

export const getAttachmentFileType = (fileName: string) => {
    const extension = getFileExtension(fileName);

    return docTypes[extension] || FileType.Other;
};
