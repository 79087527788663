export const base64ToBytes = (base64: string) => {
    const binString = atob(base64);

    const array = new Uint8Array(new ArrayBuffer(binString.length));

    for (let index = 0; index < binString.length; index++) {
        array[index] = binString.charCodeAt(index);
    }

    return array;
};
