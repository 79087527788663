import { font, oPopupPadding } from '@approvalmax/ui';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    ${oPopupPadding};
`;

export const Description = styled.div`
    ${font(13, '#000')};
`;

export const Fields = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 20px;

    & > :not(:first-child) {
        margin-top: 20px;
    }
`;
