import { hooks } from '@approvalmax/utils';
import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useCallback,
} from 'react';

import { Controller } from './components';
import { useChecked, useValidate } from './Switch.hooks';
import { Control, Icon, Input, Label, Root } from './Switch.styles';
import { ChildrenComponents, SwitchProps } from './Switch.types';

/**
 * Switches toggle the state of a single setting on or off.
 */
export const Switch = memo(
    forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
        const {
            size = 'medium',
            color = 'blue90',
            children,
            checked = false,
            disabled,
            onChange,
            invalid,
            block,
            ...inputProps
        } = props;

        const { checkedValue, setCheckedValue } = useChecked(checked);
        const { invalidValue, setInvalidValue } = useValidate(invalid);
        const { inputRef, parentProps } = hooks.useCaptureFocus(ref);

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setCheckedValue(event.target.checked);
                setInvalidValue(false);

                onChange?.(event.target.checked, event);
            },
            [onChange, setCheckedValue, setInvalidValue]
        );

        return (
            <Root
                $size={size}
                $color={color}
                $checked={checkedValue}
                $disabled={disabled}
                $invalid={invalidValue}
                $block={block}
                {...parentProps}
            >
                <Control>
                    <Input
                        type='checkbox'
                        checked={checkedValue}
                        disabled={disabled}
                        onChange={handleChange}
                        aria-invalid={invalidValue}
                        ref={inputRef}
                        {...inputProps}
                    />

                    <Icon />
                </Control>

                {children && <Label>{children}</Label>}
            </Root>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<SwitchProps & RefAttributes<HTMLInputElement>>> & ChildrenComponents;

Switch.displayName = 'Switch';
Switch.Controller = Controller;

export default Switch;
