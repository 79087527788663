import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useDeleteAdminPortalUser = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutateAsync: deleteAdminPortalUser } = useMutation(
        (userId: string) => api.portalManagement.deleteAccount(userId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.AdminPortalAccounts]);
            },
        }
    );

    return {
        deleteAdminPortalUser,
        isLoading,
    };
};
