import { Button, DataTable, font } from '@approvalmax/ui';
import styled, { css } from 'styled-components';

export const BulkOperationsLabel = styled.div`
    ${font(12, '#6c6a6a')};
    margin-left: 80px;
`;

export const StyledBulkActionButton = styled(Button)`
    margin-left: 10px;
`;

export const SearchTip = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 100px;
`;

export const SearchTipTitle = styled.div`
    ${font(18, '#000')}
    margin-top: 20px;
    margin-bottom: 40px;
`;

export const SearchTipImage = styled.img`
    max-width: calc(min(100%, 951px));
    padding: 0 25px;
`;

export const Table = styled(DataTable)<{ fullHeight?: boolean }>`
    ${(props) =>
        props.fullHeight &&
        css`
            flex: 1;
            overflow: hidden;
        `}
`;
