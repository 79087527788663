import { RawAvatar } from '@approvalmax/ui';
import { forwardRef, memo } from 'react';

import { AvatarProps } from './Avatar.types';

const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
    const { user, size, title } = props;

    const computedTitle = title === undefined ? user.email : title || undefined;

    return (
        <RawAvatar
            userAbbreviation={user.abbreviation}
            userAvatarUrl={user.avatar}
            size={size}
            title={computedTitle}
            ref={ref}
        />
    );
});

export default memo(Avatar);
