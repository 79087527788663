import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useStorageQueue = (queueId: string) => {
    const { isInitialLoading, data: queue } = useQuery(
        [QueryKeys.StorageQueues, queueId],
        () => api.infra.getStorageQueue(queueId),
        {}
    );

    return {
        queue,
        isInitialLoading,
    };
};
