export interface DeleteRestoreRequestPopupContentProps {
    companyId: string;
    requests: string[];
    mode: DeleteRestoreRequestMode;
}

export enum DeleteRestoreRequestMode {
    delete = 'delete',
    restore = 'restore',
}

export interface DeleteRestoreFormData {
    companyId: string;
    comment: string;
    ticketId: string;
    requestIds: string;
}

export type DeleteRestoreFormErrors = Partial<Record<keyof DeleteRestoreFormData, boolean>>;
