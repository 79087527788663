export interface QueryParams {
    query: string;
}

export enum ColumnId {
    FirstName = 'FirstName',
    LastName = 'LastName',
    Email = 'Email',
    DatabaseId = 'DatabaseId',
}
