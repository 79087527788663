import { CloseIcon } from '@approvalmax/ui/src/icons';
import { FC, PropsWithChildren } from 'react';

import Button from '../Button/Button';
import { CrashedComponentPlaceholder } from '../CrashedComponentPlaceholder/CrashedComponentPlaceholder';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { messages } from './PopupErrorBoundary.messages';
import type { PopupErrorBoundaryProps } from './PopupErrorBoundary.types';

export const PopupErrorBoundary: FC<PropsWithChildren<PopupErrorBoundaryProps>> = (props) => {
    const { children, onPopupClose } = props;

    return (
        <ErrorBoundary
            errorPlaceholder={
                <CrashedComponentPlaceholder
                    subTitle={messages.youCanAlsoClose}
                    secondaryAction={
                        <Button color='blue10' size='large' startIcon={<CloseIcon />} onClick={onPopupClose}>
                            {messages.close}
                        </Button>
                    }
                />
            }
        >
            {children}
        </ErrorBoundary>
    );
};

PopupErrorBoundary.displayName = 'PopupErrorBoundary';
