/**
 * Developer: Stepan Burguchev
 * Date: 6/7/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './multiValueBoxItem.scss';

import React from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './MultiValueBoxItem.messages';
import { DropdownBoxItemProps, DropdownBoxItemTheme } from './types';

class MultiValueBoxItem extends React.Component<DropdownBoxItemProps> {
    public static defaultProps = {
        theme: DropdownBoxItemTheme.Green,
    };

    public render() {
        const { className, displayTextSelector, item, disabled, theme, strikeThrough } = this.props;
        const text = displayTextSelector(item);
        const bem = bemFactory.block('form-dropdown-editor-multi-value-item').themed(theme as any);
        const qa = bemFactory.qa('form-dropdown-editor-multi-value-item');

        return (
            <div
                className={bem.add(className)(null, { disabled })}
                title={text}
                data-qa={qa()}
                data-qa-id={item.id}
                data-qa-name={text}
            >
                <span
                    className={bem('text', {
                        'strike-through': strikeThrough,
                    })}
                >
                    {text}
                </span>

                {!disabled && (
                    <div
                        title={messages.removeButtonTitle}
                        className={bem('remove-button')}
                        data-qa={qa('remove-button')}
                        onClick={this._onRemove}
                    >
                        ×
                    </div>
                )}
            </div>
        );
    }

    private _onRemove = (e: React.SyntheticEvent<any>) => {
        this.props.onRemove(this.props.item);
        e.preventDefault();
    };
}

export default MultiValueBoxItem;
