import { useParams } from 'react-router-dom';

interface PathParams {
    companyId: string;
    requestId: string;
}

export const usePathParams = (): PathParams => {
    return useParams<PathParams>();
};
