import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

import getPermissionFlags from '../selectors/getPermissionFlags';

export const useCurrentAccount = () => {
    const {
        isInitialLoading,
        data: account,
        refetch: refetchCurrentAccountInfo,
    } = useQuery(
        [QueryKeys.AdminPortalAccounts, 'me'],
        async () => {
            try {
                return await api.portalManagement.getMyAccount();
            } catch {
                return {
                    id: '1',
                    email: 'unknown@unknown.unknown',
                    permissions: [],
                    isTFAEnabled: false,
                };
            }
        },
        {}
    );

    return {
        isInitialLoading,
        account,
        permissionFlags: account ? getPermissionFlags(account.permissions) : undefined,
        refetchCurrentAccountInfo,
    };
};
