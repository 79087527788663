import useLocalStorage from 'modules/react/hooks/useLocalStorage';
import { useCallback } from 'react';

export const useExecutionHistory = () => {
    const [history, setHistory] = useLocalStorage<string[]>('database-access.execution-history', []);

    return {
        executionHistory: history,
        pushIntoExecutionHistory: useCallback(
            (value: string) => {
                setHistory((historyEntries) => [value, ...historyEntries.slice(0, 10)]);
            },
            [setHistory]
        ),
    };
};
