import { memo, NamedExoticComponent } from 'react';
import { FormProvider } from 'react-hook-form';

import { Part } from './components';
import { prepareChildren } from './Form.helpers';
import { ChildrenComponents, FormProps } from './Form.types';

/**
 * The Form component allows you to provide a form context for nested controls.
 * Also change all the components with subcomponent `Controller` to `Component.Controller`.
 */
const Form = memo<FormProps>((props) => {
    const { onSubmit, children, form, ...restProps } = props;

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit} {...restProps}>
                {prepareChildren(children, form)}
            </form>
        </FormProvider>
    );
}) as NamedExoticComponent<FormProps> & ChildrenComponents;

Form.Part = Part;

export default Form;
