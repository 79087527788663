import { ExtractComponentProp } from '@approvalmax/types';
import { FC, memo, useCallback } from 'react';
import { useController } from 'react-hook-form';

import Group from '../Group/Group';
import { GroupControllerProps } from './GroupController.types';

/**
 * The `Radio.Group.Controller` allows you to use the `Radio.Group` with the `react-hook-form` library.
 * They take the same properties as the `Radio.Group` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const GroupController: FC<GroupControllerProps> = memo((props) => {
    const { control, name, children, onChange: onChangeProp, onBlur: onBlurProp, required, ...restProps } = props;

    const { field, fieldState } = useController({
        control,
        name,
        rules: { required },
        defaultValue: props.defaultValue || props.value,
    });

    const onChange = useCallback<ExtractComponentProp<typeof Group, 'onChange'>>(
        (checkedValues, event) => {
            field.onChange(event);
            onChangeProp?.(checkedValues, event);
        },
        [field, onChangeProp]
    );

    const onBlur = useCallback<ExtractComponentProp<typeof Group, 'onBlur'>>(
        (event) => {
            field.onBlur();
            onBlurProp?.(event);
        },
        [field, onBlurProp]
    );

    return (
        <Group {...field} {...fieldState} {...restProps} onChange={onChange} onBlur={onBlur}>
            {children}
        </Group>
    );
});

export default GroupController;
