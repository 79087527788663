import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Progress, Spacing, Table, Text } from '@approvalmax/ui/src/components';
import { JsonViewer, NavBarMode, PageLayout, StyledScrollableArea, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { useGetAmaxPayPaymentDetails, useGetAmaxPayPaymentHistory } from 'modules/data/queries';
import { memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { dataTableColumns } from './AmaxPayPaymentDetailsPage.config';
import { messages } from './AmaxPayPaymentDetailsPage.messages';
import { PathParams } from './AmaxPayPaymentDetailsPage.types';

export const AmaxPayPaymentDetailsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.amaxPay.paymentsView);

    const history = useHistory();

    const { companyId, paymentId } = useParams<PathParams>();

    const { data: paymentDetailsData, isFetching: isFetchingPaymentDetails } = useGetAmaxPayPaymentDetails({
        companyId,
        paymentId,
    });

    const { data: paymentHistoryData, isFetching: isFetchingPaymentHistory } = useGetAmaxPayPaymentHistory({
        companyId,
        paymentId,
    });

    const isFetching = isFetchingPaymentDetails || isFetchingPaymentHistory;

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('amaxPayPayments'));
        }
    }, [history]);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <StyledScrollableArea>
                        <Toolbar title={messages.pageTitle} />

                        <Box height={8}>{isFetching && <Progress />}</Box>

                        <Box spacing='20 60'>
                            <Text font='headline' fontSize='xxsmall'>
                                {messages.paymentHistory}
                            </Text>

                            <Spacing height={8} />

                            <Table data={paymentHistoryData || []} columns={dataTableColumns} />

                            <Spacing height={20} />

                            {paymentDetailsData && (
                                <Text font='headline' fontSize='xxsmall'>
                                    {messages.rawData}
                                </Text>
                            )}

                            <Spacing height={8} />

                            {paymentDetailsData && <JsonViewer json={paymentDetailsData.rawData} />}
                        </Box>
                    </StyledScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

AmaxPayPaymentDetailsPage.displayName = 'AmaxPayPaymentDetailsPage';
