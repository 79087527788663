import { createPath } from 'history';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * @deprecated Use {@link hooks.useQueryParam} instead.
 *
 * @description
 * The function is a factory that could be used to create query params hooks.
 * A query params hook is a hook that uses query params to store a certain part
 * of the application state.
 * @example
 * const usePagination = createQueryParamsHook<PaginationQueryParams>({
 *   mapFromQuery(queryString) {
 *     // read the params from the string and return them as a PaginationQueryParams type.
 *   },
 *   mapToQuery(params) {
 *     // Transform the PaginationQueryParams params into a map that will be stored in the query string.
 *   }
 * })
 */
export const createQueryParamsHook = <ParamsType>({
    mapFromQuery,
    mapToQuery,
}: {
    mapFromQuery: (queryString: string) => ParamsType;
    mapToQuery: (params: ParamsType) => Record<string, string | null | undefined>;
}) => {
    const buildPath = (path: string, queryString: string, params: ParamsType, newParams: Partial<ParamsType>) => {
        const queryParams = mapToQuery({
            ...params,
            ...newParams,
        });
        const searchParams = new URLSearchParams(queryString);

        for (const [key, value] of Object.entries(queryParams)) {
            if (value) {
                searchParams.set(key, value);
            } else {
                searchParams.delete(key);
            }
        }

        return `${path}?${searchParams.toString()}`;
    };

    return () => {
        const history = useHistory();
        const queryString = useLocation().search;

        return useMemo(() => {
            const params = mapFromQuery(queryString);

            return {
                ...params,
                push: (newParams: Partial<ParamsType>) => {
                    const oldPath = createPath(history.location);
                    const newPath = buildPath(history.location.pathname, queryString, params, newParams);

                    if (oldPath !== newPath) {
                        history.push(newPath);
                    }
                },
                replace: (newParams: Partial<ParamsType>) =>
                    history.replace(buildPath(history.location.pathname, queryString, params, newParams)),
            };
        }, [history, queryString]);
    };
};
