import { DropdownEditor, LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { SortArrowIcon } from '@approvalmax/ui/src/old/icons';
import { hooks } from '@approvalmax/utils';
import { CompanySearchEntry } from 'modules/api';
import {
    NativeDataTable,
    NavBarMode,
    PageLayout,
    PageLayoutWidthBehavior,
    PlainDataProvider,
    SearchBar,
    toaster,
    Toolbar,
} from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { allQueryTypes, dataTableColumns } from './CompaniesPage.config';
import { getQueryType } from './CompaniesPage.helpers';
import { useColumnFilters, useColumnSort, useFindCompanies, useQueryParams } from './CompaniesPage.hooks';
import { messages } from './CompaniesPage.messages';
import { HeadCell, HeadCellIcon, Row } from './CompaniesPage.styles';
import ColumnFilter from './components/ColumnFilter/ColumnFilter';

const CompaniesPage = memo(() => {
    const history = useHistory();
    const queryParams = useQueryParams();

    const { permissionFlags } = useCurrentAccount();

    const [searchText, setSearchText] = useState(queryParams.companyQuery);
    const [searchType, setSearchType] = useState(getQueryType(queryParams.companyQueryType).companyQueryType);

    const {
        appliedColumnFilters,
        columnFilters,
        onCheckboxFilterChange,
        onStringColumnFilterChange,
        applyColumnFilters,
        resetColumnFilters,
    } = useColumnFilters();

    const { sortConfig, onSortChange } = useColumnSort();

    const { isFetching, companies, findCompanies } = useFindCompanies({
        companyQuery: searchText,
        companyQueryType: searchType,
        filters: appliedColumnFilters,
        sortConfig,
        isEnabled: false,
    });

    usePermissionsRedirect((permissionFlags) => permissionFlags.companies.any);

    const onSetQueryType = useCallback((value: (typeof allQueryTypes)[0]) => {
        setSearchType(value.companyQueryType);
    }, []);

    const onSearch = useCallback(async () => {
        if (searchText.trim().length < 3) {
            toaster.info(messages.infoMinSearchValueLength);

            return;
        }

        await queryParams.push({
            companyQuery: searchText,
            companyQueryType: searchType,
        });

        await findCompanies();
    }, [searchText, queryParams, searchType, findCompanies]);

    const onRowClick = (company: CompanySearchEntry) => {
        history.push(getPath('companies', company.id));
    };

    useEffect(() => {
        if (appliedColumnFilters) {
            findCompanies();
        }
    }, [appliedColumnFilters, findCompanies]);

    useEffect(() => {
        if (sortConfig) {
            findCompanies();
        }
    }, [sortConfig, findCompanies]);

    hooks.useOnMount(() => {
        if (queryParams.companyQuery) {
            if (searchText.trim().length < 3) {
                toaster.info(messages.infoMinSearchValueLength);
            } else {
                findCompanies();
            }
        }
    });

    const filters = (
        <>
            <PlainDataProvider items={allQueryTypes}>
                <DropdownEditor value={getQueryType(searchType)} onChange={onSetQueryType} css='width: 180px' />
            </PlainDataProvider>

            <SearchBar
                focusOnMount
                disabled={!permissionFlags || !permissionFlags.companies.read}
                placeholder={messages.searchBarPlaceholder}
                value={searchText}
                onChange={setSearchText}
                onSearch={onSearch}
            />
        </>
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar filters={filters} />

                    {isFetching && <LoadingBar absolute />}

                    <ScrollableArea css='flex: 1; overflow: hidden;'>
                        <NativeDataTable>
                            <thead>
                                <tr>
                                    {dataTableColumns.map((colDef, i) => (
                                        <th key={i}>
                                            <HeadCell
                                                sortable={colDef.sortable ?? false}
                                                onClick={() => {
                                                    if (colDef.sortable) {
                                                        onSortChange(colDef.id);
                                                    }
                                                }}
                                            >
                                                {colDef.name}

                                                {colDef.sortable && sortConfig?.columnId === colDef.id && (
                                                    <HeadCellIcon sortOrder={sortConfig.sortOrder}>
                                                        <SortArrowIcon width={9} height={10} />
                                                    </HeadCellIcon>
                                                )}

                                                {colDef.filterable && colDef.filterKey && (
                                                    <ColumnFilter
                                                        filterKey={colDef.filterKey}
                                                        filterType={colDef.filterType || 'string'}
                                                        filterOptions={colDef.filterOptions}
                                                        columnFilters={columnFilters}
                                                        onCheckboxFilterChange={onCheckboxFilterChange}
                                                        onStringFilterChange={onStringColumnFilterChange}
                                                        onApplyColumnFilters={applyColumnFilters}
                                                        resetColumnFilters={resetColumnFilters}
                                                    />
                                                )}
                                            </HeadCell>
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {(companies ?? []).map((searchEntry, i) => (
                                    <Row key={i} onClick={() => onRowClick(searchEntry)}>
                                        {dataTableColumns.map((colDef, j) => (
                                            <td key={j} width={colDef.width}>
                                                {colDef.value!(searchEntry)}
                                            </td>
                                        ))}
                                    </Row>
                                ))}
                            </tbody>
                        </NativeDataTable>
                    </ScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

export default CompaniesPage;
