import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { usePathParams } from './CompanyDetailsPage.hooks';
import { messages } from './CompanyDetailsPage.messages';
import { Tab, Tabs } from './CompanyDetailsPage.styles';
import { ActiveTab } from './CompanyDetailsPage.types';
import { CompanyDataTab, WorkflowsTab } from './components';

const CompanyDetailsPage: FC = memo(() => {
    const history = useHistory();

    usePermissionsRedirect((permissionFlags) => permissionFlags.companies.read);

    const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.details);

    const { companyId } = usePathParams();

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('companies'));
        }
    }, [history]);

    const onTabChange = useCallback((tab: ActiveTab) => {
        return () => setActiveTab(tab);
    }, []);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Tabs>
                        <Tab active={activeTab === 'details'} onClick={onTabChange(ActiveTab.details)}>
                            Details
                        </Tab>

                        <Tab active={activeTab === 'workflows'} onClick={onTabChange(ActiveTab.workflows)}>
                            Workflows
                        </Tab>
                    </Tabs>

                    {activeTab === 'details' && <CompanyDataTab companyId={companyId} />}

                    {activeTab === 'workflows' && <WorkflowsTab companyId={companyId} />}
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

export default CompanyDetailsPage;
