import { mods } from '@approvalmax/theme';
import { DataTableColumnSortOrder, font } from '@approvalmax/ui';
import styled from 'styled-components';

export const HeadCell = styled.div<{ sortable: boolean }>`
    display: flex;
    align-items: center;

    ${mods('sortable', true)`
        cursor: pointer;

        &:hover {
            color: #4d9a4d;
        }
    `}
`;

export const HeadCellIcon = styled.div<{ sortOrder?: DataTableColumnSortOrder }>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    transform: rotate(180deg);
    opacity: 1;
    transition: all 300ms ease-in-out;

    ${mods('sortOrder', 'desc')`
        transform: rotate(0);
    `}
`;

export const DropdownPanel = styled.div`
    width: 200px;
    padding: 10px;
    margin: 2px 0;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
`;

export const Row = styled.tr`
    cursor: pointer;

    td {
        word-break: break-word;
    }
`;

export const FilterRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    ${font(14, '#000')}
`;
