import { useMemo } from 'react';

import { defineMessages } from '../helpers/intl/defineMessages';

const messages = defineMessages('hooks.useMonthsItems', {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
});

export const useMonthsItems = () => {
    return useMemo(
        () =>
            Object.keys(messages).map((key: keyof typeof messages, index) => ({
                id: index + 1,
                name: messages[key],
            })),
        []
    );
};
