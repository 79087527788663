import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { SortConfig } from 'modules/types';

import { BudgetValueIdentifiable } from '../../BudgetDetailsPage.types';
import { ColumnId } from './ValuesList.types';

export const columnsCreator = (sortConfig?: SortConfig): DataTableColumnDefinition<BudgetValueIdentifiable>[] => [
    {
        id: ColumnId.accountCode,
        columnComponent: () => null,
        value: (item) => item.accountCode,
        sortOrder: sortConfig?.columnId === ColumnId.accountCode ? sortConfig.sortOrder : undefined,
        padding: DataTablePadding.Left60,
    },
    {
        id: ColumnId.plannedAmount,
        columnComponent: () => null,
        value: (item) => item.plannedAmount,
        sortOrder: sortConfig?.columnId === ColumnId.plannedAmount ? sortConfig.sortOrder : undefined,
    },
    {
        id: ColumnId.startDate,
        columnComponent: () => null,
        value: (item) => item.startDate,
        sortOrder: sortConfig?.columnId === ColumnId.startDate ? sortConfig.sortOrder : undefined,
    },
];
