/**
 * Developer: Stepan Burguchev
 * Date: 1/9/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

const windowEventList = [
    {
        name: 'click',
        capture: true,
    },
    {
        name: 'keydown',
        capture: true,
    },
    {
        name: 'keyup',
        capture: false,
    },
    {
        name: 'mousedown',
        capture: true,
    },
    {
        name: 'mouseup',
        capture: true,
    },
    {
        name: 'touchstart',
        capture: true,
    },
    {
        name: 'touchend',
        capture: true,
    },
    {
        name: 'wheel',
        capture: true,
    },
    {
        name: 'resize',
        capture: false,
    },
    {
        name: 'scroll',
        capture: true,
    },
    {
        name: 'dragenter',
        capture: true,
    },
    {
        name: 'dragleave',
        capture: true,
    },
    {
        name: 'drop',
        capture: true,
    },
];

type Handler = (target: HTMLElement, event: Event) => void;

type SimpleHandler = (event: Event) => void;

class GlobalEvent {
    private _touchWithoutMoveHandlers: SimpleHandler[] = [];
    private _inTouchMove = false;
    private _handlers: {
        [eventName: string]: Handler[];
    } = {};

    constructor() {
        if (typeof window === 'undefined' || typeof window.addEventListener !== 'function') return;

        const windowEvents = windowEventList.map((x) => {
            let captureSuffix = x.capture ? ':captured' : '';
            let eventName = `window:${x.name}${captureSuffix}`;

            return {
                name: x.name,
                capture: x.capture,
                handler: (e: any) => {
                    this._trigger(eventName, e.target, e);
                },
            };
        });

        windowEvents.forEach((x) => {
            window.addEventListener(x.name, x.handler, x.capture);
        });
        window.addEventListener('touchstart', this._onTouchStart.bind(this), true);
        window.addEventListener('touchmove', this._onTouchMove.bind(this), true);
        window.addEventListener('touchend', this._onTouchEnd.bind(this), true);
    }

    public on(eventName: string, handler: Handler) {
        let handlerList = this._handlers[eventName];

        if (!handlerList) {
            this._handlers[eventName] = handlerList = [];
        }

        handlerList.push(handler);
    }

    public off(eventName: string, handler: Handler) {
        let handlerList = this._handlers[eventName];

        if (!handlerList) {
            return;
        }

        const index = handlerList.indexOf(handler);

        if (index !== -1) {
            handlerList.splice(index, 1);
        }
    }

    public onTouchWithoutMove(handler: SimpleHandler) {
        this._touchWithoutMoveHandlers.push(handler);
    }

    public offTouchWithoutMove(handler: SimpleHandler) {
        let index = this._touchWithoutMoveHandlers.indexOf(handler);

        if (index !== -1) {
            this._touchWithoutMoveHandlers.splice(index, 1);
        }
    }

    private _trigger(eventName: string, target: HTMLElement, event: Event) {
        const handlerList = this._handlers[eventName];

        if (!handlerList) {
            return;
        }

        handlerList.forEach((h) => h(target, event));
    }

    private _onTouchStart() {
        this._inTouchMove = false;
    }

    private _onTouchMove() {
        this._inTouchMove = true;
    }

    private _onTouchEnd(e: TouchEvent) {
        if (!this._inTouchMove) {
            this._touchWithoutMoveHandlers.forEach((h) => h(e));
        }
    }
}

export const globalEventService = new GlobalEvent();
