import { RefObject, useEffect, useRef, useState } from 'react';

export const useSticky = <StickyType extends HTMLDivElement>(
    options: IntersectionObserverInit = {}
): { sticky: boolean; stickyRef: RefObject<StickyType> } => {
    const stickyRef = useRef<StickyType>(null);
    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        if (!stickyRef.current || !options.root) return;

        const observer = new IntersectionObserver(
            (records) => {
                for (const record of records) {
                    const targetInfo = record.boundingClientRect;
                    const rootBoundsInfo = record.rootBounds;

                    if (!rootBoundsInfo) continue;

                    setSticky(targetInfo.top < rootBoundsInfo.top);
                }
            },
            {
                threshold: 1,
                ...options,
            }
        );

        observer.observe(stickyRef.current);

        return () => {
            observer.disconnect();
        };
    }, [options]);

    return { sticky, stickyRef };
};
