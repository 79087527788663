import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.companyDetails.NetSuiteSyncAndDeleteCachePopup', {
    popupTitle: 'NetSuite refdata entities',
    referenceEntityColumnName: 'Reference Entity Name',
    submitButton: 'Submit',
    successSyncCache: 'Successfully synced',
    successDeleteCache: 'Successfully deleted',
    failedSyncCache: 'Oops... something went wrong',
    failedDeleteCache: 'Oops... something went wrong',
});
