import { domHelpers } from '@approvalmax/utils';
import { FC, memo } from 'react';

import { usePopupContext } from '../../Popup.context';
import { Root } from './Body.styles';
import { BodyProps } from './Body.types';

const Body: FC<BodyProps> = memo((props) => {
    const { children, spacing, qa, ...restProps } = props;

    const { size, closable } = usePopupContext();

    return (
        <Root
            $size={size}
            $closable={closable}
            spacing={spacing}
            data-qa={domHelpers.generateDataQa(qa, 'popup-content')}
            {...restProps}
        >
            {children}
        </Root>
    );
});

export default Body;
