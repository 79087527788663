import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.AmaxPayPaymentDetailsPage', {
    pageTitle: 'Amax Pay Payment Details',
    createdAt: 'Created at',
    id: 'ID',
    isoStatus: 'ISO status',
    status: 'Status',
    paymentHistory: 'Payment history',
    rawData: 'Raw data',
});
