import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useAdminPortalAccounts = () => {
    const { isInitialLoading, data: users } = useQuery(
        [QueryKeys.AdminPortalAccounts],
        () => api.portalManagement.getAccounts(),
        {}
    );

    return {
        users,
        isInitialLoading,
    };
};
