import { miscHelpers } from '@approvalmax/utils';
import React, { useContext } from 'react';

export const PopupContext = React.createContext<{
    onRequestClose: (e?: any | undefined, reason?: 'escape' | 'auto-close' | 'cancel') => void;
}>({
    onRequestClose: miscHelpers.noop,
});

export const usePopupContext = () => {
    return useContext(PopupContext);
};
