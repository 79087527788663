import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { User } from 'modules/api';
import { SortConfig } from 'modules/types';

import { messages } from './UsersPage.messages';
import { ColumnId } from './UsersPage.types';

export const createDataTableColumnsConfig = (sortConfig?: SortConfig): DataTableColumnDefinition<User>[] => [
    {
        id: ColumnId.FirstName,
        name: messages.firstNameCol,
        value: (user) => user.firstName,
        sortable: true,
        sortOrder: sortConfig?.columnId === ColumnId.FirstName ? sortConfig.sortOrder : undefined,
        padding: DataTablePadding.Left60,
    },
    {
        id: ColumnId.LastName,
        name: messages.lastNameCol,
        value: (user) => user.lastName,
        sortable: true,
        sortOrder: sortConfig?.columnId === ColumnId.LastName ? sortConfig.sortOrder : undefined,
    },
    {
        id: ColumnId.Email,
        name: messages.emailCol,
        value: (user) => user.email,
        sortable: true,
        sortOrder: sortConfig?.columnId === ColumnId.Email ? sortConfig.sortOrder : undefined,
    },
    {
        id: ColumnId.DatabaseId,
        name: messages.idCol,
        value: (user) => user.id,
        sortable: false,
    },
];
