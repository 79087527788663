import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';

import { messages } from '../ReportsPage.messages';
import { QueryType } from '../ReportsPage.types';

interface useFindReportsTransfer {
    query?: string;
    queryType: QueryType;
}

export const useFindReports = ({ query = '', queryType }: useFindReportsTransfer) => {
    const { isFetching, data: reports } = useQuery(
        [QueryKeys.Reports, query, queryType],
        () => {
            if (!query) {
                return [];
            }

            if (queryType === QueryType.companyId) {
                return api.internalSupport.getReportsByCompanyId(query);
            } else {
                return api.internalSupport.getReportsByReportId(query);
            }
        },
        {
            onSuccess(data) {
                if (!data || (Array.isArray(data) && data.length === 0)) {
                    toaster.error(messages.searchError);
                }
            },
            onError: (error: AxiosError<{ errors: any; status: number }>) => {
                const data = error.response?.data;

                if (data) {
                    if (data.errors || data.status === 404) {
                        toaster.error(messages.searchError);

                        return;
                    }
                }
            },
            keepPreviousData: true,
            enabled: Boolean(query),
        }
    );

    return {
        isFetching,
        reports,
    };
};
