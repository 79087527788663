import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.UserDetailsPage', {
    pageTitle: 'User details',
    betaFeatures: 'Beta features (user-level)',
    betaFeaturesPlaceholder: 'Select beta features',
    confirmationPopupHeader: 'Following features will be removed:',
    confirmationPopupText: 'Please confirm that you want to remove some of the functionality from this customer.',
    saveBetaFeaturesButton: 'Save',
    saveBetaFeatures: 'Save',
    rawDataHeader: 'Details',
    defaultErrorMessage: 'Oops, something went wrong',
    manage2FA: 'Manage 2FA',
    tfaDisablingHistory: '2FA Disabling History',
});
