import { FC } from 'react';

import { Root, Table } from './NativeDataTable.styles';
import { NativeDataTableProps, NativeDataTableTheme } from './NativeDataTable.types';

const NativeDataTable: FC<NativeDataTableProps> = (props) => {
    const { children, theme = NativeDataTableTheme.standard } = props;

    return (
        <Root>
            <Table theme={theme}>{children}</Table>
        </Root>
    );
};

export default NativeDataTable;
