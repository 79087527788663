import { Button, Field, font, oPopupPadding, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 520px;
`;

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    ${oPopupPadding()}
`;

export const Title = styled.div`
    margin-top: 30px;
    ${font(16, '#000', 'semibold')}
`;

export const Description = styled.div`
    margin-top: 20px;
    ${font(13, '#000')}
`;

export const StyledField = styled(Field)`
    margin-top: 20px;
`;

export const NextButton = styled(Button)`
    margin-top: 20px;
`;
