import { useCallback, useState } from 'react';

export const useToggle = (initialValue: boolean): [boolean, (forceValue?: boolean) => void] => {
    const [value, setValue] = useState(initialValue);

    const toggleValue = useCallback(
        (forceValue?: boolean) => setValue((currentValue) => forceValue ?? !currentValue),
        [setValue]
    );

    return [value, toggleValue];
};
