import { Button } from '@approvalmax/ui';
import styled from 'styled-components';

export const SearchForm = styled.form`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const SearchButton = styled(Button)`
    margin-left: -1px;
    height: auto !important;
    align-self: stretch;
`;
