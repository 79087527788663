import { AmLogo, Button } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { font } from 'modules/styles';
import { Component, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { messages } from './GlobalErrorBoundary.messages';

interface OwnState {
    renderError: boolean;
}

const Root = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
`;

const Content = styled.div`
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    max-width: 340px;
    min-width: 340px;
    padding-bottom: 40px;
`;

const Logo = styled.div`
    margin-top: 80px;
    margin-bottom: 160px;
`;

const IconWrp = styled.div`
    font-size: 40px;
`;

const Title = styled.div`
    ${font(16, '#000', 'semibold')}
    text-transform: uppercase;
    margin-top: 10px;
`;

const Description = styled.div`
    margin-top: 20px;
    ${font(12, '#000')}

    a {
        ${font(12, '#4c8a5c')}
        text-decoration: underline;
        cursor: pointer;
    }
`;

class GlobalErrorBoundary extends Component<PropsWithChildren, OwnState> {
    public state: OwnState = {
        renderError: false,
    };

    public componentDidCatch(e: Error) {
        // React does not trigger global error handler in a production mode,
        // so the global error handler won't see the error in production
        // unless we handle it manually.
        errorHelpers.captureException(e);
        this.setState({
            renderError: true,
        });
    }

    public render() {
        if (!this.state.renderError) {
            return this.props.children;
        } else {
            return (
                <Root>
                    <Content>
                        <Logo>
                            <AmLogo width={200} height={38} />
                        </Logo>

                        <IconWrp>⛔️😱</IconWrp>

                        <Title>{messages.renderErrorTitle}</Title>

                        <Description>
                            {messages.renderErrorDescription({
                                supportlink: (chunks: any) => <a href='support@approvalmax.com'>{chunks}</a>,
                            })}
                        </Description>

                        <Button
                            execute={this._onReload}
                            css='margin-top: 30px;'
                            fontSize='small13'
                            height='large40'
                            padding='normal28'
                        >
                            {messages.reloadButtonText}
                        </Button>
                    </Content>
                </Root>
            );
        }
    }

    private _onReload = () => {
        window.location.reload();
    };
}

export default GlobalErrorBoundary;
