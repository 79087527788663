import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { Report } from 'modules/api';

import { messages } from './ReportsPage.messages';

export const dataTableColumns: DataTableColumnDefinition<Report>[] = [
    {
        id: 'companyId',
        name: messages.companyIdCol,
        value: (report) => report.companyId,
        sortable: true,
        padding: DataTablePadding.Left60,
        width: 320,
    },
    {
        id: 'reportId',
        name: messages.reportIdCol,
        value: (report) => report.id,
        sortable: false,
        width: 320,
    },
    {
        id: 'Name',
        name: messages.nameCol,
        value: (report) => report.name,
        sortable: false,
    },
];
