import { User } from 'oidc-client-ts';

import { Env, getAppConfig, indentityClientId } from '../configs';
import { ApiSource } from '../types';

export const getAuthToken = (apiSource: ApiSource, env: Env) => {
    const { identity } = getAppConfig(env);

    const authClientId = indentityClientId[apiSource];

    if (!authClientId) {
        return null;
    }

    const oidcStorageKey = `oidc.user:${identity}:${authClientId}`;
    const oidcStorage = localStorage.getItem(oidcStorageKey);

    const user = oidcStorage ? User.fromStorageString(oidcStorage) : null;

    return user?.access_token || null;
};

export const getAuthorization = (apiSource: ApiSource, env: Env) => {
    switch (apiSource) {
        case 'devPortal':
            return getAuthToken(apiSource, env);

        default:
            return null;
    }
};
