import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import unicornWalkingImage from '../../resources/unicorn_walking.webp';
import { ProgressBarProps } from './ProgressBar.types';

export const Root = styled.div<StyledMods<Pick<ProgressBarProps, 'progress'>>>`
    position: relative;
    width: 100%;
    height: 10px;

    &::before,
    &::after {
        content: ' ';
        position: absolute;
        left: 0;
        height: 100%;
    }

    &::before {
        width: 100%;
        background: #c2cdd647;
    }

    &::after {
        width: 100%;
        background: linear-gradient(to right, #fe7bbf 0%, #ffb7e0 100%);
        transform: ${(props) => `scaleX(${props.$progress / 100})`};
        transform-origin: center left;
        transition: transform 1s ease;
    }
`;

export const Unicorn = styled.div<StyledMods<Pick<ProgressBarProps, 'progress'>>>`
    position: absolute;
    top: -4px;
    left: ${(props) => `calc(${props.$progress}% - 24px)`};
    z-index: 2;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -12px;
    background: url(${unicornWalkingImage}) no-repeat;
    background-size: cover;
    transform: translateX(50%);
    transition: left 1s ease;
`;
