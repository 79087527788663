import { EyeDocPreviewIcon } from '@approvalmax/ui/src/old/icons';
import { memo, useCallback } from 'react';

import {
    AttachmentItemName,
    AttachmentItemPaperClip,
    AttachmentItemPreviewIcon,
    AttachmentItemWrap,
} from './AttachmentItem.styles';
import { AttachmentItemProps } from './AttachmentItem.types';

const AttachmentItem = memo<AttachmentItemProps>((props) => {
    const { attachment, onOpen } = props;

    const handleOpen = useCallback(() => {
        onOpen(attachment);
    }, [attachment, onOpen]);

    return (
        <AttachmentItemWrap title={attachment.name} onClick={handleOpen}>
            <AttachmentItemPreviewIcon>
                <EyeDocPreviewIcon width={18} height={12} />
            </AttachmentItemPreviewIcon>

            <AttachmentItemPaperClip width={16} height={15} />

            <AttachmentItemName>{attachment.name}</AttachmentItemName>
        </AttachmentItemWrap>
    );
});

export default AttachmentItem;
