import { AlertFilledIcon, CheckCircleFilledIcon, InfoCircleFilledIcon } from '@approvalmax/ui/src/icons';
import { ReactNode } from 'react';
import { toast as toastify } from 'react-toastify';

import { ExtendedToastOptions } from './Toast.types';

const showToast = (message: ReactNode | ReactNode[], options: ExtendedToastOptions) => {
    toastify(<>{message}</>, {
        className: (params) => `${params?.defaultClassName} Toastify__toast--size-${options?.size || 'medium'}`,
        ...options,
    });
};

export const toast = {
    default(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        showToast(message, {
            type: 'default',
            ...options,
        });
    },
    success(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        showToast(message, {
            type: 'success',
            icon: <CheckCircleFilledIcon />,
            role: 'alert',
            ...options,
        });
    },
    error(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        showToast(message, {
            type: 'error',
            icon: <AlertFilledIcon />,
            role: 'alert',
            ...options,
        });
    },
    info(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        showToast(message, {
            type: 'info',
            icon: <InfoCircleFilledIcon />,
            role: 'alert',
            ...options,
        });
    },
};
