import './dataRow.scss';

import { Identifiable } from '@approvalmax/types';
import { objectHelpers } from '@approvalmax/utils';
import React from 'react';
import bemFactory from 'react-bem-factory';

import DefaultCell from './DefaultCell';
import { DataTableColumnDefinition } from './index';

interface OwnProps {
    columns: DataTableColumnDefinition[];
    item: Identifiable;
    meta?: any;
    checked: boolean;
    qa?: string;
    onAction?: (action: any) => void;
    onCheck: (item: Identifiable, checked: boolean) => void;
    performanceUpdateOnColumnsChange?: boolean;
    autoHeight?: boolean;
    onClick?: (item: Identifiable) => void;
    errors?: (Identifiable['id'] | null)[];
}

type Props = OwnProps;

class DataRow extends React.Component<Props> {
    public shouldComponentUpdate(nextProps: Props) {
        const diff = objectHelpers.getShallowDifference(this.props, nextProps);

        if (!this.props.performanceUpdateOnColumnsChange && diff.columns && Object.keys(diff).length === 1) {
            return false;
        }

        return true;
    }

    public render() {
        const { item, columns, meta, onAction, checked, onCheck, autoHeight, qa: qa$, onClick, errors } = this.props;
        const bem = bemFactory.block('ui-data-row');
        const qa = bemFactory.qa('ui-data-row');

        return (
            <div
                key={item.id}
                data-qa={qa$}
                data-is-invalid={errors?.includes(item.id) || errors?.includes(null)}
                className={bem(null, { 'auto-height': autoHeight, clickable: onClick })}
                onClick={onClick ? () => onClick(item) : undefined}
            >
                {columns.map((colDef) => {
                    const Cell = colDef.cellComponent || DefaultCell;
                    const value = colDef.value ? colDef.value(item) : (item as any)[colDef.id] || null;
                    const title = colDef.title ? colDef.title(item) : typeof value === 'string' ? value : undefined;

                    return (
                        <Cell
                            item={item}
                            value={value}
                            title={title}
                            meta={meta}
                            key={colDef.id}
                            qa={qa('cell')}
                            checked={checked}
                            onCheck={onCheck}
                            onAction={onAction}
                            columnDefinition={colDef}
                            className={bem('cell', { fixed: colDef.width })}
                            style={{ width: colDef.width }}
                            padding={colDef.padding}
                            errors={errors}
                        />
                    );
                })}
            </div>
        );
    }
}

export default DataRow;
