import type { ApiError, ApiVersion } from '@approvalmax/types';
import { objectHelpers, routerHelpers } from '@approvalmax/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useApiContext } from '../ApiContext/ApiContext';
import { HEADER_SESSION_EXPIRATION_DATE, HEADER_WEB_APP_VERSION } from '../ApiContext/ApiContext.constants';
import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import rootApiLegacy from '../rootApiLegacy';
import { ApiBaseAnswer, ApiMethods, ApiSource, MutateDataParams, MutationOptions } from '../types';

/** @deprecated Use {@link createUseMutate} */
export const createUseMutateLegacy = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <
        RequestData extends MutateDataParams<Record<string, any> | unknown, Record<string, string> | undefined>,
        ResponseData extends Record<string, any> = {},
        MappedResponseData = ResponseData,
        MapToCamelCase extends boolean = false,
    >(
        path: string,
        options: {
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            method?: Exclude<ApiMethods, 'get'>;
            mutationOptions?: MutationOptions<MappedResponseData, RequestData>;
            /** @deprecated Use {@link mapToCamelCase} */
            mapData?: (data: ResponseData) => MappedResponseData;
            mapToCamelCase?: MapToCamelCase;
            /**
             * If you would like to perform manual cache update, you might want to skip
             * a fetching relative query
             */
            skipInvalidateQueries?: true;
        } = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            method = 'post',
            mutationOptions,
            mapData,
            mapToCamelCase,
        } = options;

        const queryClient = useQueryClient();
        const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const { setApiError, setSessionExpirationDate, setWebAppVersion } = useApiContext();
        const mapDataFn =
            mapData ||
            (mapToCamelCase && (objectHelpers.pascalCaseToCamelCase as (data: ResponseData) => MappedResponseData)) ||
            undefined;

        return useMutation<MappedResponseData, ApiError, RequestData>(
            async ({ pathParams, queryParams = {}, params, body }) => {
                const requestParams = params || {
                    path: pathParams,
                    query: queryParams,
                };
                const url = routerHelpers.pathToUrl(path, requestParams.path);
                const isFormData = body instanceof FormData;
                const contentTypeHeader = { 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' };

                return rootApiLegacy({
                    baseURL,
                    method,
                    url,
                    params: requestParams.query,
                    headers: {
                        ...(Authorization && { Authorization }),
                        ...contentTypeHeader,
                    },
                    data: body,
                })
                    .then((res: AxiosResponse<ResponseData & ApiBaseAnswer>): MappedResponseData => {
                        const newSessionExpirationDate = res.headers[HEADER_SESSION_EXPIRATION_DATE];

                        if (newSessionExpirationDate) {
                            setSessionExpirationDate(newSessionExpirationDate);
                        }

                        const newWebAppVersion = res.headers[HEADER_WEB_APP_VERSION];

                        if (newWebAppVersion) {
                            setWebAppVersion(newWebAppVersion);
                        }

                        return mapDataFn?.(res.data) || (res.data as MappedResponseData);
                    })
                    .catch((error: ApiError) => {
                        setApiError(error);

                        throw error;
                    });
            },
            {
                mutationKey: [path],
                ...mutationOptions,
                onSettled: async (responseData, error, requestData, context) => {
                    if (!options.skipInvalidateQueries) {
                        const url = routerHelpers.pathToUrl(path, requestData.params?.path || requestData.pathParams);

                        await queryClient.invalidateQueries([url]);
                    }

                    mutationOptions?.onSettled?.(responseData, error, requestData, context);
                },
            }
        );
    };
};
