import { mixins, theme } from '@approvalmax/theme';
import { createGlobalStyle } from 'styled-components';

import { iconStyles } from '../components/Icon/Icon.styles';

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        width: 100%;
        height: 100%;
    }

    body {
        font-family: 'Open Sans', sans-serif, Arial;
        padding: 0;
        margin: 0;
        position: relative;
        word-wrap: break-word;
        overflow: hidden;
        overflow-y: auto;
        background: #d7dfe1;
        color: #333;
        font-size: 13px;
        line-height: 1.428571429;

        html:not(.ios) & {
            // Fixes iOS bug with inputs (ghost text)
            // See https://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela
            -webkit-overflow-scrolling: touch;
        }
    }

    ${iconStyles};

    #__next {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        bottom: 0;

        > :first-child {
            height: 100%;
        }
    }

    .app-root {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-flow: column;
        transition: opacity 0.6s ease-in;
    }

    body,
    input,
    button,
    textarea,
    select {
        -webkit-font-smoothing: antialiased;
        border-radius: 0;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        margin: 0;
        font: inherit;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button {
        border: none;
        padding: 0;
        background: transparent;
        display: inline-block;
        text-align: left;
        align-items: stretch;
    }

    ul,
    ol {
        padding: 0;
        list-style: none;
    }

    body,
    h1,
    h2,
    h3,
    h4,
    p,
    ul,
    ol,
    li,
    figure,
    figcaption,
    blockquote,
    dl,
    dd {
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4 {
        font-size: inherit;
    }

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    *,
    ::after,
    ::before {
        box-sizing: border-box;
        border: initial;
    }

    *:focus {
        outline: 0 !important;
    }

    a {
        color: #478a60;
    }

    a:hover,
    a:focus {
        color: #478a60;
        box-shadow: none;
        text-decoration: underline;
    }

    input {
        word-break: normal;
    }

    input[type='file'] {
        cursor: pointer;
    }

    ::-webkit-file-upload-button {
        cursor: pointer;
    }

    input::-ms-clear {
        display: none;
    }

    input[type='number'] {
        appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input,
    select,
    textarea {
        -webkit-appearance: none;
    }

    ::placeholder {
        color: #a3a3a3;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
    }

    td,
    th {
        padding: 0;
    }

    svg path {
        pointer-events: none;
    }

    use {
        width: 100%;
        height: 100%;
    }

    dialog {
        border: none;
        padding: 0;
        margin: 0;
        position: static;
        color: inherit;
    }

    /* Nice narrow scrollbar where possible, enable smooth scrolling */

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 0;
        background: rgba(0, 0, 0, 0);
        transition: background 2.2s;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: none;
    }

    ::-webkit-scrollbar-thumb:end {
        background: rgba(0, 0, 0, 0.1);
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 0, 0, 0.1);
    }

    /* End: nice scrollbar */
`;

export const GlobalStylesRD = createGlobalStyle`
    html,
    body {
        width: 100%;
        height: 100%;
    }

    body {
        font-family: ${theme.fontFamily.roboto};
        padding: 0;
        margin: 0;
        position: relative;
        word-wrap: break-word;
        overflow: auto;
        background: ${theme.color.white100};
        ${mixins.font('body', 'medium', 'regular', 'midnight100')};

        html:not(.ios) & {
            // Fixes iOS bug with inputs (ghost text)
            // See https://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela
            -webkit-overflow-scrolling: touch;
        }
    }

    ${iconStyles};

    #__next {
        height: 100%;

        > :first-child {
            height: 100%;
        }
    }

    .app-root {
        height: 100%;
        transition: opacity ${theme.duration.medium} ease-in;
        display: flex;
        flex-direction: column;
    }

    body,
    input,
    button,
    textarea,
    select {
        -webkit-font-smoothing: antialiased;
        border-radius: 0;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        margin: 0;
        font: inherit;
        color: inherit;
        line-height: inherit;
    }

    button {
        border: none;
        padding: 0;
        background: transparent;
        display: inline-block;
        text-align: left;
        align-items: stretch;
    }

    ul,
    ol {
        padding: 0;
        list-style: none;
    }

    body,
    h1,
    h2,
    h3,
    h4,
    p,
    ul,
    ol,
    li,
    figure,
    figcaption,
    blockquote,
    dl,
    dd,
    menu {
        margin: 0;
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${theme.fontFamily.poppins};
        font-size: inherit;
        font-weight: ${theme.fontWeight.medium};
        color: ${theme.color.midnight80};
    }

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
        border: initial;
    }

    *:focus {
        outline: 0 !important;
    }

    a {
        ${mixins.link('blue100')};
        cursor: pointer;
    }

    input {
        word-break: normal;

        &::-webkit-file-upload-button {
            cursor: pointer;
        }

        &::-ms-clear {
            display: none;
        }

        &[type='file'] {
            cursor: pointer;
        }

        &[type='number'] {
            appearance: textfield;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &[type='search'] {
            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }
        }
    }

    input,
    select,
    textarea {
        -webkit-appearance: none;

        &::placeholder {
            color: ${theme.color.midnight60};
        }
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;

        td,
        th {
            padding: 0;
        }
    }

    svg path {
        pointer-events: none;
    }

    use {
        width: 100%;
        height: 100%;
    }

    dialog {
        border: none;
        padding: 0;
        margin: 0;
        position: static;
        color: inherit;
    }

     /* Nice narrow scrollbar where possible, enable smooth scrolling */

     ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 0;
        background: ${theme.color.midnight30};

        &:hover {
            background: ${theme.color.midnight40};
        }
    }

    ::-webkit-scrollbar-thumb {
        border-radius: ${theme.radius.small};
        background: ${theme.color.midnight50};

        &:hover {
            background: ${theme.color.midnight60};
        }
    }

    /* End: nice scrollbar */
`;
