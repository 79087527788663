import { Reference } from '@approvalmax/types';
import { User } from 'modules/api';

export const getFeatureOptions = (features: string[]): Reference[] => {
    return features.map((f) => ({
        id: f,
        text: f,
    }));
};

export const getUserFullName = (user: User) => {
    return (user.firstName + ' ' + user.lastName).trim();
};
