import { Button } from '@approvalmax/ui';
import styled from 'styled-components';

export const EditorSection = styled.div`
    flex: 1;
    overflow: hidden;
    margin-top: 20px;
    border: 1px solid #cacccd;
`;

export const ActionsSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

export const RightButtons = styled.div`
    display: flex;
`;

export const JSONButton = styled(Button)`
    margin-left: 15px;
`;

export const ResultsSection = styled.div`
    flex: 1;
    overflow: auto;
    border: 1px solid #cacccd;
    margin-top: 20px;
`;

export const RowCount = styled.div`
    margin-top: 10px;
    text-align: right;
`;
