import { Identifiable } from '@approvalmax/types';
import { BudgetPreview } from 'modules/types';

export interface QueryParams {
    query: string;
}

export enum ColumnId {
    companyId = 'companyId',
    budgetId = 'budgetId',
    budgetName = 'budgetName',
}

export interface BudgetPrviewIdentifiable extends Identifiable, BudgetPreview {}
