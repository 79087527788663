import { Button, DataTable, LoadingBar, Popup, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { AdminPortalAccount } from 'modules/api';
import { NavBarMode, PageLayout, Toolbar } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useState } from 'react';

import { CreateUserPopup, DeleteUserPopup, EditUserPopup, UserDetailsPopup } from './components';
import { useAdminPortalAccounts } from './hooks/useAdminPortalAccounts';
import { useAdminPortalPermissions } from './hooks/useAdminPortalPermissions';
import { dataTableColumns } from './PermissionsPage.config';
import { messages } from './PermissionsPage.messages';
import { PermissionsPageMeta } from './PermissionsPage.types';

const PermissionsPage: FC = () => {
    const { users } = useAdminPortalAccounts();
    const { permissions } = useAdminPortalPermissions();
    const { permissionFlags: currentAccountPermissionFlags } = useCurrentAccount();

    const [user, setUser] = useState<AdminPortalAccount | null>(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);

    usePermissionsRedirect((permissionFlags) => permissionFlags.accounts.any);

    const onCreateUser = () => {
        setIsCreatePopupOpen(true);
    };

    const onEditUser = (target: AdminPortalAccount) => {
        setUser(target);
        setIsEditPopupOpen(true);
    };

    const onDeleteUser = (target: AdminPortalAccount) => {
        setUser(target);
        setIsDeletePopupOpen(true);
    };

    const onViewUser = (target: AdminPortalAccount) => {
        setUser(target);
        setIsViewPopupOpen(true);
    };

    const actionButtons = (
        <Button
            execute={onCreateUser}
            disabled={!users || !permissions || !currentAccountPermissionFlags?.accounts.create}
        >
            {messages.createUserButton}
        </Button>
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar title={messages.pageTitle} actionButtons={actionButtons} />

                    {!users || !permissions || !currentAccountPermissionFlags ? (
                        <div>
                            <LoadingBar />
                        </div>
                    ) : (
                        <DataTable
                            css='flex: 1; overflow: hidden;'
                            data={users ?? []}
                            columns={dataTableColumns}
                            meta={
                                {
                                    permissions,
                                    currentAccountPermissionFlags,
                                    onEditUser,
                                    onDeleteUser,
                                    onViewUser,
                                } as PermissionsPageMeta
                            }
                        />
                    )}
                </ScrollableAreaWrapper>
            </Box>

            <Popup isOpen={isDeletePopupOpen && !!user} onRequestClose={() => setIsDeletePopupOpen(false)}>
                <DeleteUserPopup user={user!} />
            </Popup>

            <Popup isOpen={isEditPopupOpen && !!user} onRequestClose={() => setIsEditPopupOpen(false)}>
                <EditUserPopup user={user!} />
            </Popup>

            <Popup isOpen={isCreatePopupOpen} onRequestClose={() => setIsCreatePopupOpen(false)}>
                <CreateUserPopup />
            </Popup>

            <Popup isOpen={isViewPopupOpen} onRequestClose={() => setIsViewPopupOpen(false)}>
                <UserDetailsPopup user={user!} />
            </Popup>
        </PageLayout>
    );
};

export default memo(PermissionsPage);
