import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, SearchBar, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { memo } from 'react';

import { columnsCreator } from './BudgetsPage.config';
import { useBudgetPageItem, useBudgetPageSearch } from './BudgetsPage.hooks';
import { messages } from './BudgetsPage.messages';
import { Body, List } from './BudgetsPage.styles';

const BudgetsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.budgets.readBudgetsId);

    const { items, searchText, onSearchChange, onSearchSubmit, sortConfig, onSortChange } = useBudgetPageSearch();
    const columns = columnsCreator(sortConfig);

    const { onClick } = useBudgetPageItem();

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Body>
                        <Toolbar
                            filters={
                                <SearchBar
                                    placeholder={messages.searchPlaceholder}
                                    focusOnMount
                                    disabled={false}
                                    onSearch={onSearchSubmit}
                                    value={searchText}
                                    onChange={onSearchChange}
                                />
                            }
                        />

                        <List data={items} columns={columns} onRowClick={onClick} onSort={onSortChange} />
                    </Body>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

export default BudgetsPage;
