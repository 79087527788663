import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.CompaniesPage', {
    pageTitle: 'Companies',
    breadcrumbRoot: 'Customer Success',
    searchBarPlaceholder: 'Enter search query...',
    queryTypeCompanyIdOrName: 'Company ID/Name',
    queryTypeIntegrationIdOrName: 'Integration ID/Name in GL',
    queryTypeEmail: 'Account Owner Email',
    idCol: 'ID',
    nameCol: 'Organisation name',
    accountOwnerEmailCol: 'Account owner email',
    accountIntegrationCol: 'Integration',
    accountIntegrationStatusCol: 'Integration Status',
    accountIntegrationStatusTrue: 'True',
    accountIntegrationStatusFalse: 'False',
    accountStatusCol: 'Status',
    accountStatusActive: 'Active',
    accountStatusTrial: 'Trial',
    accountStatusPartner: 'Partner',
    accountStatusTrialExpired: 'Trial Expired',
    accountStatusRetired: 'Retired',
    integrationStatusAll: 'All',
    integrationStatusEnabled: 'Enabled',
    integrationStatusDisabled: 'Disabled',
    searchError: 'No company found or id is invalid',
    defaultErrorMessage: 'Oops, something went wrong',
    infoMinSearchValueLength: 'Search request must contain at least 3 characters',
});
