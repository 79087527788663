import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    flex: 1;
`;

export const LoadingPanel = styled.div``;

export const ContentTable = styled.div`
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
`;

export const ContentTableRow = styled.div`
    display: table-row;
    transition: background-color 0.2s;

    .form-dropdown-editor-button--t-form__input {
        background-color: transparent;
        border-color: transparent;
        transition:
            background-color 0.3s,
            border-color 0.3s,
            color 0.3s;
    }

    &:hover {
        background-color: #e9eef0 !important;

        .form-dropdown-editor-button--t-form__input {
            background-color: #fff;
            border-color: #cacccd;
        }
    }
`;

const ContentTableCell = styled.div`
    display: table-cell;
    min-height: 50px;
    padding: 15px 15px 15px 0;
    vertical-align: middle;

    &:first-child {
        padding-left: 60px;
    }

    &:last-child {
        padding-right: 60px;
    }
`;

export const ContentTableHeadCell = styled(ContentTableCell)``;

export const ContentTableBodyCell = styled(ContentTableCell)``;

export const ContentTableHead = styled.div`
    display: table-header-group;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f0f0f0;
`;

export const ContentTableBody = styled.div`
    display: table-row-group;

    ${ContentTableRow}:nth-child(even) {
        background-color: #f9f9f9;
    }
`;
