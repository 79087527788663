import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useWorkflowsList = (companyId: string) => {
    const { isInitialLoading, data: workflowsList } = useQuery([QueryKeys.CompanyWorkflows, companyId], () =>
        api.internalSupport.getCompanyWorkflows(companyId)
    );

    return {
        isInitialLoading,
        workflowsList,
    };
};
