import { QueryParams } from '@approvalmax/data';
import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Button, Form, Grid, Label, Progress, Spacing, Table, TextField } from '@approvalmax/ui/src/components';
import { hooks } from '@approvalmax/utils';
import { NavBarMode, PageLayout, StyledScrollableArea, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import {
    UseAmaxPayPaymentsItem,
    UseAmaxPayPaymentsParams,
    UseAmaxPayPaymentsPathParams,
    useGetAmaxPayPayments,
} from 'modules/data/queries';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { dataTableColumns } from './AmaxPayPaymentsPage.config';
import {
    accountIdQueryParam,
    companyIdQueryParam,
    idempotencyIdQueryParam,
    paymentIdQueryParam,
} from './AmaxPayPaymentsPage.constants';
import { messages } from './AmaxPayPaymentsPage.messages';
import { GetAmaxPayPaymentsPayload } from './AmaxPayPaymentsPage.types';

export const AmaxPayPaymentsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.amaxPay.paymentsView);

    const history = useHistory();

    const [companyId, setCompanyId] = hooks.useQueryParam(companyIdQueryParam);
    const [accountId, setAccountId] = hooks.useQueryParam(accountIdQueryParam);
    const [paymentId, setPaymentId] = hooks.useQueryParam(paymentIdQueryParam);
    const [idempotencyId, setIdempotencyId] = hooks.useQueryParam(idempotencyIdQueryParam);

    const form = useForm<QueryParams<UseAmaxPayPaymentsPathParams> & UseAmaxPayPaymentsParams>({
        defaultValues: {
            companyId,
            accountId,
            paymentId,
            idempotencyId,
        },
    });

    const [getAmaxPayPaymentsPayload, setGetAmaxPayPaymentsPayload] = useState<GetAmaxPayPaymentsPayload>({
        pathParams: { companyId },
        params: { accountId, paymentId, idempotencyId },
    });

    const { data, isFetching } = useGetAmaxPayPayments(
        getAmaxPayPaymentsPayload.pathParams,
        getAmaxPayPaymentsPayload.params
    );

    const onSubmit = form.handleSubmit((data) => {
        setCompanyId(data.companyId ?? '');
        setAccountId(data.accountId ?? '');
        setPaymentId(data.paymentId ?? '');
        setIdempotencyId(data.idempotencyId ?? '');

        setGetAmaxPayPaymentsPayload({
            pathParams: { companyId: data.companyId },
            params: { accountId: data.accountId, paymentId: data.paymentId, idempotencyId: data.idempotencyId },
        });
    });

    const handleRowClick = useCallback(
        (paymentsItem: UseAmaxPayPaymentsItem) => {
            const hasSelectedText = !!document.getSelection()?.toString();

            if (getAmaxPayPaymentsPayload.pathParams.companyId && !hasSelectedText) {
                history.push(
                    getPath('amaxPayPayment', getAmaxPayPaymentsPayload.pathParams.companyId, paymentsItem.id)
                );
            }
        },
        [getAmaxPayPaymentsPayload.pathParams.companyId, history]
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <StyledScrollableArea>
                        <Toolbar title={messages.pageTitle} />

                        <Box height={12}>{isFetching && <Progress />}</Box>

                        <Box spacing='0 40 20 60'>
                            <Form form={form} onSubmit={onSubmit}>
                                <Grid gridTemplateColumns='200px 1fr 200px' gap='8px' alignItems='center'>
                                    <Label required>{messages.companyId}</Label>

                                    <TextField name='companyId' placeholder={messages.placeHolder} required />

                                    <Button color='blue80' type='submit' disabled={isFetching}>
                                        {messages.search}
                                    </Button>

                                    <Label>{messages.accountId}</Label>

                                    <TextField name='accountId' placeholder={messages.placeHolder} />

                                    <div />

                                    <Label>{messages.paymentId}</Label>

                                    <TextField name='paymentId' placeholder={messages.placeHolder} />

                                    <div />

                                    <Label>{messages.idempotencyId}</Label>

                                    <TextField name='idempotencyId' placeholder={messages.placeHolder} />
                                </Grid>
                            </Form>

                            <Spacing height={16} />

                            <Table data={data || []} columns={dataTableColumns} onRowClick={handleRowClick} />
                        </Box>
                    </StyledScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

AmaxPayPaymentsPage.displayName = 'AmaxPayPaymentsPage';
