import { RequiredMods } from '@approvalmax/theme';
import { FC } from 'react';

/**
 * HOC for rendering small icon if size is less or equal to 12
 * @param Icon - base icon component
 * @param IconSmall - small icon component
 */
export const withSmallIcon =
    <
        Props extends {
            size?: RequiredMods['iconSize'] | `${RequiredMods['iconSize']}`;
        },
    >(
        Icon: FC<Props>,
        IconSmall: FC<Props>
    ): FC<Props> =>
    (props: Props) => {
        if (Number(props.size) <= 12) {
            return <IconSmall {...props} />;
        }

        return <Icon {...props} />;
    };
