/* eslint-disable */
class Decimal {
    constructor(t, e, n) {
        this.COMMA = ',';
        this.PERIOD = '.';
        this.DASH = '-';
        this.LEFT_PAREN = '(';
        this.RIGHT_PAREN = ')';
        this.LEFT_OUTSIDE = 0;
        this.LEFT_INSIDE = 1;
        this.RIGHT_INSIDE = 2;
        this.RIGHT_OUTSIDE = 3;
        this.LEFT_DASH = 0;
        this.RIGHT_DASH = 1;
        this.PARENTHESIS = 2;
        this.NO_ROUNDING = -1;
        this.num;
        this.numOriginal;
        this.hasSeparators = !1;
        this.separatorValue;
        this.inputDecimalValue;
        this.decimalValue;
        this.negativeFormat;
        this.negativeRed;
        this.hasCurrency;
        this.currencyPosition;
        this.currencyValue;
        this.places;
        this.roundToPlaces;
        this.truncate;
        null == e ? this.setNumber(t, this.PERIOD) : this.setNumber(t, e);
        this.setCommas(!0);
        this.setNegativeFormat(this.LEFT_DASH);
        this.setNegativeRed(!1);
        this.setCurrency(!1);
        this.setCurrencyPrefix('$');
        n || 0 === n ? this.setPlaces(n) : this.setPlaces(2);
    }

    setInputDecimal(t) {
        this.inputDecimalValue = t;
    }

    setNumber(t, e) {
        null != e && this.setInputDecimal(e), (this.numOriginal = t), (this.num = this.justNumber(t));
    }
    toUnformatted() {
        return this.num;
    }
    getOriginal() {
        return this.numOriginal;
    }
    setNegativeFormat(t) {
        this.negativeFormat = t;
    }
    setNegativeRed(t) {
        this.negativeRed = t;
    }
    setSeparators(t, e, n) {
        (this.hasSeparators = t),
            null == e && (e = this.COMMA),
            null == n && (n = this.PERIOD),
            (this.decimalValue = e == n ? (n == this.PERIOD ? this.COMMA : this.PERIOD) : n),
            (this.separatorValue = e);
    }
    setCommas(t) {
        this.setSeparators(t, this.COMMA, this.PERIOD);
    }
    setCurrency(t) {
        this.hasCurrency = t;
    }
    setCurrencyValue(t) {
        this.currencyValue = t;
    }
    setCurrencyPrefix(t) {
        this.setCurrencyValue(t), this.setCurrencyPosition(this.LEFT_OUTSIDE);
    }
    setCurrencyPosition(t) {
        this.currencyPosition = t;
    }
    setPlaces(t, e) {
        (this.roundToPlaces = !(t == this.NO_ROUNDING)),
            (this.truncate = null != e && e),
            (this.places = t < 0 ? 0 : t);
    }
    addSeparators(t, e, n, i) {
        t += '';
        var a = t.indexOf(e),
            o = '';
        -1 != a && ((o = n + t.substring(a + 1, t.length)), (t = t.substring(0, a)));
        for (var r = /(\d+)(\d{3})/; r.test(t); ) t = t.replace(r, '$1' + i + '$2');
        return t + o;
    }
    toFormatted(t, e) {
        var n,
            i = this.num;
        new Array(2);
        if (this.roundToPlaces) {
            var a = i < 0;
            t && (i = Math.abs(i)),
                (i = this.getRounded(i)),
                (n = this.preserveZeros(Math.abs(i), e)),
                t && a && (i *= -1);
        } else n = this.expandExponential(Math.abs(i));
        n = this.hasSeparators
            ? this.addSeparators(n, this.PERIOD, this.decimalValue, this.separatorValue)
            : n.replace(new RegExp('\\' + this.PERIOD), this.decimalValue);
        var o = '',
            r = '',
            s = '',
            l = '',
            u = '',
            c = '',
            d = '',
            h = '',
            f = this.negativeFormat == this.PARENTHESIS ? this.LEFT_PAREN : this.DASH,
            p = this.negativeFormat == this.PARENTHESIS ? this.RIGHT_PAREN : this.DASH;
        return (
            this.currencyPosition == this.LEFT_OUTSIDE
                ? (i < 0 &&
                      ((this.negativeFormat != this.LEFT_DASH && this.negativeFormat != this.PARENTHESIS) || (l = f),
                      (this.negativeFormat != this.RIGHT_DASH && this.negativeFormat != this.PARENTHESIS) || (u = p)),
                  this.hasCurrency && (o = this.currencyValue))
                : this.currencyPosition == this.LEFT_INSIDE
                  ? (i < 0 &&
                        ((this.negativeFormat != this.LEFT_DASH && this.negativeFormat != this.PARENTHESIS) || (r = f),
                        (this.negativeFormat != this.RIGHT_DASH && this.negativeFormat != this.PARENTHESIS) || (d = p)),
                    this.hasCurrency && (s = this.currencyValue))
                  : this.currencyPosition == this.RIGHT_INSIDE
                    ? (i < 0 &&
                          ((this.negativeFormat != this.LEFT_DASH && this.negativeFormat != this.PARENTHESIS) ||
                              (r = f),
                          (this.negativeFormat != this.RIGHT_DASH && this.negativeFormat != this.PARENTHESIS) ||
                              (d = p)),
                      this.hasCurrency && (c = this.currencyValue))
                    : this.currencyPosition == this.RIGHT_OUTSIDE &&
                      (i < 0 &&
                          ((this.negativeFormat != this.LEFT_DASH && this.negativeFormat != this.PARENTHESIS) ||
                              (l = f),
                          (this.negativeFormat != this.RIGHT_DASH && this.negativeFormat != this.PARENTHESIS) ||
                              (u = p)),
                      this.hasCurrency && (h = this.currencyValue)),
            (n = o + r + s + l + n + u + c + d + h),
            this.negativeRed && i < 0 && (n = '<font color="red">' + n + '</font>'),
            n
        );
    }
    toPercentage(nNum) {
        return (nNum = 100 * this.num), (nNum = this.getRounded(nNum)), nNum + '%';
    }
    getZeros(t) {
        var e,
            n = '';
        for (e = 0; e < t; e++) n += '0';
        return n;
    }
    expandExponential(t) {
        if (isNaN(t)) return t;
        var e = parseFloat(t) + '',
            n = e.toLowerCase().indexOf('e');
        if (-1 != n) {
            var i = e.toLowerCase().indexOf('+'),
                a = e.toLowerCase().indexOf('-', n),
                o = e.substring(0, n);
            if (-1 != a) {
                let r = e.substring(a + 1, e.length);
                o = this.moveDecimalAsString(o, !0, parseInt(r));
            } else {
                -1 == i && (i = n);
                let r = e.substring(i + 1, e.length);
                o = this.moveDecimalAsString(o, !1, parseInt(r));
            }
            e = o;
        }
        return e;
    }
    moveDecimalRight(t, e) {
        return null == e ? this.moveDecimal(t, !1) : this.moveDecimal(t, !1, e);
    }
    moveDecimalLeft(t, e) {
        return null == e ? this.moveDecimal(t, !0) : this.moveDecimal(t, !0, e);
    }
    moveDecimalAsString(t, e, n) {
        var i = arguments.length < 3 ? this.places : n;
        if (i <= 0) return t;
        var a = t + '',
            o = this.getZeros(i),
            r = new RegExp('([0-9.]+)');
        if (e) {
            a = a.replace(r, o + '$1');
            let s = new RegExp('(-?)([0-9]*)([0-9]{' + i + '})(\\.?)');
            a = a.replace(s, '$1$2.$3');
        } else {
            var l = r.exec(a);
            null != l && (a = a.substring(0, l.index) + l[1] + o + a.substring(l.index + l[0].length));
            let s = new RegExp('(-?)([0-9]*)(\\.?)([0-9]{' + i + '})');
            a = a.replace(s, '$1$2$4.');
        }
        return (a = a.replace(/\.$/, ''));
    }
    moveDecimal(t, e, n) {
        var i = '';
        return (i = null == n ? this.moveDecimalAsString(t, e) : this.moveDecimalAsString(t, e, n)), parseFloat(i);
    }
    toRounded() {
        return this.getRounded(this.num);
    }
    toRoundedAwayFromZero() {
        return this.num < 0 ? -this.getRounded(-this.num) : this.getRounded(this.num);
    }
    getRounded(t, e) {
        // added e
        return (
            t || (t = this.num),
            (t = this.moveDecimalRight(t, e)),
            (t = this.truncate ? (t >= 0 ? Math.floor(t) : Math.ceil(t)) : Math.round(t)),
            (t = this.moveDecimalLeft(t, e))
        );
    }
    preserveZeros(t, e) {
        var n;
        if (((e = e || this.places), (t = this.expandExponential(t)), this.places <= 0)) return t;
        var i = t.indexOf('.');
        if (-1 == i) for (t += '.', n = 0; n < e; n++) t += '0';
        else {
            var a = t.length - 1 - i,
                o = e - a;
            for (n = 0; n < o; n++) t += '0';
        }
        return t;
    }
    justNumber(t) {
        var newVal = t + '';
        var e = !1;
        -1 != newVal.indexOf('%') && ((newVal = newVal.replace(/%/g, '')), (e = !0));
        var n = new RegExp('[^\\' + this.inputDecimalValue + '\\d\\-\\+\\(\\)eE]', 'g');
        newVal = newVal.replace(n, '');
        var i = new RegExp('[' + this.inputDecimalValue + ']', 'g'),
            a = i.exec(newVal);
        if (null != a) {
            var o = newVal.substring(a.index + a[0].length);
            newVal = newVal.substring(0, a.index) + this.PERIOD + o.replace(i, '');
        }
        return (
            newVal.charAt(newVal.length - 1) == this.DASH
                ? ((newVal = newVal.substring(0, newVal.length - 1)), (newVal = '-' + newVal))
                : newVal.charAt(0) == this.LEFT_PAREN &&
                  newVal.charAt(newVal.length - 1) == this.RIGHT_PAREN &&
                  ((newVal = newVal.substring(1, newVal.length - 1)), (newVal = '-' + newVal)),
            (newVal = parseFloat(newVal)),
            isFinite(newVal) || (newVal = 0),
            e && (newVal = this.moveDecimalLeft(newVal, 2)),
            newVal
        );
    }
    abs() {
        return (this.num = Math.abs(this.num)), this;
    }
    multiply(t, e) {
        var n = this.getPlaces(t, e);
        return 0 == n ? t * e : this.moveDecimalLeft(this.moveDecimalRight(t, n) * this.moveDecimalRight(e, n), 2 * n);
    }
    subtract(t, e) {
        var n = this.getPlaces(t, e);
        return 0 == n ? t - e : this.moveDecimalLeft(this.moveDecimalRight(t, n) - this.moveDecimalRight(e, n), n);
    }
    add(t, e) {
        return this.subtract(t, this.multiply(e, -1));
    }
    getPlaces(t, e) {
        (t = t.toString()), (e = e.toString());
        var n = t.indexOf(this.PERIOD),
            i = e.indexOf(this.PERIOD);
        return (n = -1 == n ? 0 : t.length - n - 1), (i = -1 == i ? 0 : e.length - i - 1), n > i ? n : i;
    }
    divide(t, e) {
        return t / e;
    }
}

export default new Decimal();
