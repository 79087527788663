import { mixins, Mods, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { CheckboxProps } from './Checkbox.types';

export const Input = styled.input<Mods<'disabled'>>`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-radius: ${theme.radius.xxsmall};
    border: 1px solid ${theme.color.midnight70};
    display: block;
    transition: ${theme.duration.medium}ms ease-in-out;

    ${mixins.position.absoluteCenter};

    && {
        position: absolute;
    }
`;

export const Control = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const Icon = styled.svg`
    pointer-events: none;
    color: ${theme.color.white100};
    transition: ${theme.duration.medium}ms ease-in-out;
    transform: rotate(-45deg);
    position: relative;
    z-index: 1;
`;

export const Label = styled.div`
    flex-grow: 1;
`;

const rootDisabledMixin = css`
    ${mods.disabled.true`
        ${Icon} {
            color: ${theme.color.midnight40};
        }

        ${Input} {
            border-color: ${theme.color.midnight50};
            background-color: ${theme.color.midnight40};
        }

        ${Label} {
            color: ${theme.color.midnight60};
        }

        ${mods.checked.true`
            ${Icon} {
                color: ${theme.color.midnight60};
            }
        `};

        ${mods.indeterminate.true`
            ${Icon} {
                color: ${theme.color.midnight60};
            }
        `};
    `};

    ${mods.disabled.false`
        &,
        ${Input} {
            cursor: pointer;
        }
    `};
`;

const rootCheckedMixin = css`
    ${mods.checked.true`
        ${Icon} {
            transform: rotate(0deg);
        }

        ${mods.disabled.false`
            ${Input} {
                border-color: ${theme.color.blue100};
                background-color: ${theme.color.blue100};
            }
        `};
    `};
`;

const rootIndeterminateMixin = css`
    ${mods.indeterminate.true`
        ${mods.checked.true`
            ${mods.disabled.false`
                ${Icon} {
                    color: ${theme.color.midnight70};

                    ${mods.invalid.true`
                        color: ${theme.color.red70};
                    `}
                }

                ${Input} {
                    border-color: ${theme.color.midnight70};
                    background-color: transparent;

                    ${mods.invalid.true`
                        border-color: ${theme.color.red70};
                    `}
                }

                &:hover,
                &:focus,
                &.focused {
                    ${Icon} {
                        color: ${theme.color.blue100};

                        ${mods.invalid.true`
                            color: ${theme.color.red70};
                        `}
                    }

                    ${Input} {
                        background-color: ${theme.color.blue10};

                        ${mods.invalid.true`
                            background-color: ${theme.color.red20};
                        `}
                    }
                }
            `};
        `};
    `};
`;

const rootInvalidMixin = css`
    ${mods.invalid.true`
        ${mixins.invalidShakeAnimation};

        ${Input} {
            border-color: ${theme.color.red70};
        }

        ${mods.checked.true`
            ${Input} {
                border-color: ${theme.color.red70};

                ${mods.indeterminate.false`
                    background-color: ${theme.color.red70};
                `}
            }
        `};
    `};
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    box: RequiredMods['spacing'],
    spacingTop: RequiredMods['spacing'],
    spacingLeft: RequiredMods['spacing']
) => css`
    ${Control} {
        width: ${box}px;
        height: ${box}px;
    }

    ${Icon} {
        width: ${box - 4}px;
        height: ${box - 4}px;
    }

    ${Label} {
        ${mixins.font('label', fontSize)};
        margin-top: ${spacingTop}px;
        margin-left: ${spacingLeft}px;
    }
`;

export const Root = styled.label<
    StyledMods<
        Pick<CheckboxProps, 'disabled' | 'checked' | 'indeterminate' | 'size' | 'invalid' | 'block' | 'alignItems'>
    >
>`
    display: inline-flex;
    vertical-align: middle;
    align-items: ${({ $alignItems }) => $alignItems};

    &:hover,
    &:focus,
    &.focused {
        ${mods.disabled.false`
            color: ${theme.color.blue100};

            ${mods.invalid.true`
                color: ${theme.color.red100};
            `}

            ${Input} {
                border-color: ${theme.color.blue100};

                ${mods.checked.false`
                    background-color: ${theme.color.blue10};
                `}


                ${mods.invalid.true`
                    border-color: ${theme.color.red70};

                    ${mods.checked.false`
                        background-color: ${theme.color.red20};
                    `}
                `};
            }
        `};
    }

    ${mods.size.xsmall(rootSizeMixin('xsmall', 12, 0, 4))};
    ${mods.size.small(rootSizeMixin('small', 16, 2, 8))};
    ${mods.size.medium(rootSizeMixin('medium', 20, 2, 8))};
    ${mods.size.large(rootSizeMixin('large', 24, 2, 12))};

    ${mods.block.true`
        display: flex;
        width: 100%;
    `};

    ${rootDisabledMixin};

    ${rootCheckedMixin};

    ${rootIndeterminateMixin};

    ${rootInvalidMixin};
`;
