import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.CompanyDetailsPage.CompanyDataTab', {
    betaFeatures: 'Beta features (company-level)',
    betaFeaturesPlaceholder: 'Select beta features',
    confirmationPopupHeader: 'Following features will be removed:',
    confirmationPopupText: 'Please confirm that you want to remove some of the functionality from this customer.',
    saveBetaFeaturesButtonText: 'Save',
    saveBetaFeatures: 'Save',
    rawDataHeader: 'Details',
    enforceFraudDetection: 'Enforce Fraud Detection',
    forceMigration: 'Force migration',
    offboardedUsersTitle: 'Offboarded users',
    defaultErrorMessage: 'Oops, something went wrong',
    netSuiteCacheStatus: 'Get NetSuite Cache Status',
    syncNetSuiteCache: 'Sync NetSuite Cache',
    deleteNetSuiteCache: 'Delete NetSuite Cache',
});
