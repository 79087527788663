import type { ApiError } from '@approvalmax/types';
import { objectHelpers } from '@approvalmax/utils';
import type { AxiosResponse } from 'axios';

import { HEADER_SESSION_EXPIRATION_DATE, HEADER_WEB_APP_VERSION, useApiContext } from '../../api';
import type { ApiBaseAnswer } from '../types';

export const useHandleApiCall = <ResponseData, MappedResponseData>(
    mapData?: (data: ResponseData) => MappedResponseData,
    mapToCamelCase?: boolean
) => {
    const { setApiError, setSessionExpirationDate, setWebAppVersion } = useApiContext();

    const mapDataFn =
        mapData ||
        (mapToCamelCase && (objectHelpers.pascalCaseToCamelCase as (data: ResponseData) => MappedResponseData)) ||
        undefined;

    return (promise: Promise<AxiosResponse<ResponseData & ApiBaseAnswer>>) => {
        return promise
            .then((res) => {
                const newSessionExpirationDate = res.headers[HEADER_SESSION_EXPIRATION_DATE];

                if (newSessionExpirationDate) {
                    setSessionExpirationDate(newSessionExpirationDate);
                }

                const newWebAppVersion = res.headers[HEADER_WEB_APP_VERSION];

                if (newWebAppVersion) {
                    setWebAppVersion(newWebAppVersion);
                }

                return mapDataFn?.(res.data) || (res.data as MappedResponseData);
            })
            .catch((error: ApiError) => {
                setApiError(error);

                throw error;
            });
    };
};
