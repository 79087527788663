import { DataTable, DataTableCellComponentProps } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { TenantCurrentMovementTableItemWithId } from 'pages/manageTenants/ManageTenantsPage.types';
import { memo } from 'react';

import ProgressBar from '../ProgressBar/ProgressBar';
import { CellRoot, ProgressBarContainer, RowsCount } from './MovementProgressCell.styles';

const MovementProgressCell = memo<DataTableCellComponentProps<unknown, TenantCurrentMovementTableItemWithId>>(
    (props) => {
        const { item } = props;

        const progress =
            item.copiedRowsCount === item.totalRowsCount
                ? 100
                : Math.floor((item.copiedRowsCount / item.totalRowsCount) * 100);

        const copiedRows = intl.formatNumber(item.copiedRowsCount, 0, false, ' ');
        const totalRows = intl.formatNumber(item.totalRowsCount, 0, false, ' ');

        return (
            <DataTable.DefaultCell {...props}>
                <CellRoot>
                    <ProgressBarContainer>
                        <ProgressBar progress={progress} hideUnicorn={progress === 100} />
                    </ProgressBarContainer>

                    <RowsCount>{`${copiedRows}/${totalRows}`}</RowsCount>
                </CellRoot>
            </DataTable.DefaultCell>
        );
    }
);

export default MovementProgressCell;
