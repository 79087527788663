import { Reference } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { get } from 'lodash';
import { api, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';
import { IntegrationType, SortConfig } from 'modules/types';
import { useCallback, useState } from 'react';

import { messages } from './CompaniesPage.messages';
import {
    ColumnFilterState,
    IntegrationStatusOption,
    QueryParams,
    StatusFilterOption,
    UseFindCompaniesProps,
} from './CompaniesPage.types';

export const useQueryParams = routerHelpers.createQueryParamsHook<QueryParams>({
    mapFromQuery(queryString) {
        return {
            companyQuery: routerHelpers.getParamAsString(queryString, 'companyQuery', ''),
            companyQueryType: routerHelpers.getParamAsString(queryString, 'companyQueryType'),
        };
    },
    mapToQuery(params) {
        return {
            companyQuery: params.companyQuery,
            companyQueryType: params.companyQueryType,
        };
    },
});

export const useFindCompanies = (props: UseFindCompaniesProps) => {
    const { companyQuery = '', companyQueryType, filters, sortConfig, isEnabled = true } = props;

    const {
        isFetching,
        refetch: findCompanies,
        data: companies,
    } = useQuery(
        [QueryKeys.Companies, companyQuery, companyQueryType],
        () => {
            return api.internalSupport.findCompanies({
                companyQuery,
                companyQueryType,
                companyName: filters?.companyName || '',
                accountOwnerEmail: filters?.accountOwnerEmail || '',
                companyStatuses: filters
                    ? Object.keys(filters.status).filter((statusKey) => filters.status[statusKey])
                    : undefined,
                companyIntegrationTypes: filters
                    ? Object.keys(filters.companyIntegrationType).filter(
                          (statusKey) => filters.companyIntegrationType[statusKey]
                      )
                    : undefined,
                companyIntegrationStatus:
                    !filters || filters.companyIntegrationStatus === IntegrationStatusOption.All
                        ? undefined
                        : filters.companyIntegrationStatus === IntegrationStatusOption.Enabled,
                sortingBy: sortConfig?.columnId,
                sortingOrder: sortConfig?.sortOrder,
            });
        },
        {
            onSuccess(data) {
                if (!data || (Array.isArray(data) && data.length === 0)) {
                    toaster.error(messages.searchError);
                }
            },
            onError: (error: AxiosError<{ errors: any; detail: string }>) => {
                const data = error.response?.data;

                if (data) {
                    if (data.errors) {
                        toaster.error(messages.searchError);

                        return;
                    }

                    if (typeof data.detail === 'string') {
                        toaster.error(data.detail);

                        return;
                    }

                    toaster.error(messages.defaultErrorMessage);
                }
            },
            keepPreviousData: true,
            enabled: isEnabled,
        }
    );

    return {
        findCompanies,
        isFetching,
        companies,
    };
};

const defaultColumnFilterState: ColumnFilterState = {
    status: {
        [StatusFilterOption.Active]: true,
        [StatusFilterOption.Partner]: true,
        [StatusFilterOption.Retired]: true,
        [StatusFilterOption.Trial]: true,
        [StatusFilterOption.TrialExpired]: true,
    },
    companyName: '',
    accountOwnerEmail: '',
    companyIntegrationType: {
        [IntegrationType.Xero]: true,
        [IntegrationType.QBooks]: true,
        [IntegrationType.NetSuite]: true,
        [IntegrationType.Dear]: true,
        [IntegrationType.None]: true,
    },
    companyIntegrationStatus: IntegrationStatusOption.All,
};

export const useColumnFilters = () => {
    const [columnFilters, setColumnFilters] = useState<ColumnFilterState>(defaultColumnFilterState);

    const [appliedColumnFilters, setAppliedColumnFilters] = useState<ColumnFilterState | null>(null);

    const onCheckboxFilterChange = useCallback((filterKey: string, filterOption: Reference, newValue: boolean) => {
        setColumnFilters((current) => {
            const currentFilterValue = get(current, filterKey);

            if (typeof currentFilterValue === 'object') {
                const newFilter = {
                    ...current,
                    [filterKey]: {
                        ...currentFilterValue,
                        [filterOption.id]: newValue,
                    },
                };

                return newFilter;
            }

            return current;
        });
    }, []);

    const onStringColumnFilterChange = useCallback((filterKey: string, newValue: string) => {
        setColumnFilters((current) => {
            const currentFilterValue = get(current, filterKey);

            if (typeof currentFilterValue === 'string') {
                const newFilter = {
                    ...current,
                    [filterKey]: newValue,
                };

                return newFilter;
            }

            return current;
        });
    }, []);

    const applyColumnFilters = useCallback(() => {
        setAppliedColumnFilters(columnFilters);
    }, [columnFilters]);

    const resetColumnFilters = useCallback(() => {
        setColumnFilters(appliedColumnFilters || defaultColumnFilterState);
    }, [appliedColumnFilters]);

    return {
        columnFilters,
        appliedColumnFilters,
        onCheckboxFilterChange,
        onStringColumnFilterChange,
        applyColumnFilters,
        resetColumnFilters,
    };
};

export const useColumnSort = () => {
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

    const onSortChange = useCallback((columnId: string) => {
        setSortConfig((current) => {
            let newOrder: 'asc' | 'desc' = 'asc';

            if (current?.columnId === columnId) {
                newOrder = current.sortOrder === 'asc' ? 'desc' : 'asc';
            }

            return {
                columnId,
                sortOrder: newOrder,
            };
        });
    }, []);

    return {
        sortConfig,
        onSortChange,
    };
};
