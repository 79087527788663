import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import uniqueId from 'lodash/uniqueId';
import { memo, useMemo } from 'react';

import { tableDef } from './TFADisablingHistory.config';
import { use2FADisablingHistory } from './TFADisablingHistory.hooks';
import { messages } from './TFADisablingHistory.messages';
import { StyledDataTable } from './TFADisablingHistory.styles';
import { TFADisablingHistoryProps } from './TFADisablingHistory.types';

const TFADisablingHistory = memo<TFADisablingHistoryProps>((props) => {
    const { userId } = props;

    const { isInitialLoading, data } = use2FADisablingHistory(userId);

    const enrichedData = useMemo(
        () =>
            (data || []).map((item) => ({
                ...item,
                id: uniqueId('tfaDisablingHistory-'),
            })),
        [data]
    );

    if (isInitialLoading) {
        return (
            <Box spacing='20 0'>
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <Box spacing='20 0'>
            {enrichedData.length > 0 ? <StyledDataTable columns={tableDef} data={enrichedData} /> : messages.noData}
        </Box>
    );
});

export default TFADisablingHistory;
