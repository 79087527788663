import { mods } from '@approvalmax/theme';
import { Box } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { PopupContextValue } from '../../Popup.types';

export const Root = styled(Box)<StyledMods<Pick<PopupContextValue, 'size' | 'closable'>>>`
    padding-top: 32px;
    flex-grow: 1;
    width: 100%;
    max-height: 100%;

    ${mods.not('spacing')`
        padding: 32px 32px 56px 32px;

        ${mods.size.xsmall`
            padding-bottom: 40px;
        `};
    `};
`;
