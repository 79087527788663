import { AdminPortalAccount } from 'modules/api';

const getAbbreviationFromEmail = (email: string) => {
    const mainPart = email.substring(0, email.indexOf('@'));
    const fragments = mainPart.split(/\._/);

    if (fragments.length >= 2) {
        return fragments[0][0] + fragments[1][0];
    }

    return fragments[0].substring(0, 2);
};

export default function getAccountAbbreviation(account: AdminPortalAccount) {
    return getAbbreviationFromEmail(account.email);
}
