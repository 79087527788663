import { ForwardedRef, useContext, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { GroupContext, GroupProps } from './components';
import { ToggleButtonProps } from './ToggleButton.types';

export const useGroup = (hookProps: {
    ref: ForwardedRef<HTMLInputElement>;
    props: ToggleButtonProps &
        Pick<GroupProps, 'multiple'> & {
            checkedValue?: GroupProps['value'];
        };
}) => {
    const { props, ref } = hookProps;

    const { ref: groupRef, contextProps } = useContext(GroupContext);
    const { getCheckedStatus, ...groupContextProps } = contextProps;

    const isInGroup = contextProps.name;

    useEffect(() => {
        if (!isInGroup) {
            console.warn('Use the ToggleButton component only with ToggleButton.Group!');
        }
    }, [isInGroup]);

    const preparedProps = useMemo(
        () => (isInGroup ? { ...groupContextProps, ...props } : props),
        [groupContextProps, isInGroup, props]
    );

    const checked = useMemo(() => getCheckedStatus(preparedProps.value), [getCheckedStatus, preparedProps.value]);

    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    useImperativeHandle(groupRef, () => innerRef.current as HTMLInputElement);

    return {
        ...preparedProps,
        checked,
        innerRef,
    };
};
