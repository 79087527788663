import { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import TextField from '../../TextField';
import { HTMLTextFieldElement } from '../../TextField.types';
import { ControllerProps } from './Controller.types';

/**
 * The `TextField.Controller` allows you to use the `TextField` with the `react-hook-form` library.
 * They take the same properties as the `TextField` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef<HTMLTextFieldElement, ControllerProps>((props, ref) => {
        const { control, name, defaultValue, pattern, validate, ...restProps } = props;

        const { field, fieldState } = useController({
            control,
            name: name || 'textField',
            rules: {
                required: validate ? undefined : restProps.required,
                minLength: restProps.minLength,
                maxLength: restProps.maxLength,
                pattern,
                validate,
            },
            defaultValue,
        });

        const hintPlaceholder = validate ? <span>&nbsp;</span> : undefined;

        return (
            <TextField
                {...field}
                {...fieldState}
                {...restProps}
                ref={ref}
                hint={(fieldState.error?.message || restProps.hint) ?? hintPlaceholder}
            />
        );
    })
);

export default Controller;
