/**
 * Developer: Stepan Burguchev
 * Date: 3/5/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { globalEventService } from '@approvalmax/utils';

interface ObservedElementContext {
    anchorViewportPos: {
        left: number;
        top: number;
    };
    el: HTMLElement;
    callback: () => void;
}

export default class ElementMoveObserver {
    private _observedElements: ObservedElementContext[] = [];

    private _scheduledWork = new Map<ObservedElementContext, { left: number; top: number }>();
    private _requestAnimationFrameId: number | null = null;

    private _checkElements = () => {
        // Gather work to do
        this._observedElements.forEach((x) => {
            let { left, top } = x.el.getBoundingClientRect();

            left = Math.floor(left);
            top = Math.floor(top);

            if (left !== x.anchorViewportPos.left || top !== x.anchorViewportPos.top) {
                this._scheduledWork.set(x, { left, top });
            }
        });

        if (this._requestAnimationFrameId || this._scheduledWork.size === 0) {
            // Already scheduled
            return;
        }

        this._requestAnimationFrameId = requestAnimationFrame(() => {
            this._scheduledWork.forEach((newPos, observer) => {
                observer.callback();
                observer.anchorViewportPos = newPos;
            });
            this._scheduledWork.clear();
            this._requestAnimationFrameId = null;
        });
    };

    public listenToElementMove(el: HTMLElement, callback: () => void) {
        // saving el position relative to the viewport for further check
        let { left, top } = el.getBoundingClientRect();

        this._observedElements.push({
            anchorViewportPos: {
                left: Math.floor(left),
                top: Math.floor(top),
            },
            el,
            callback,
        });
        this._updateGlobalListeners(true);
    }

    public stopListeningToElementMove(el = null) {
        if (!el) {
            this._observedElements = [];
        } else {
            this._observedElements.splice(
                this._observedElements.findIndex((x) => x.el === el),
                1
            );
        }

        this._updateGlobalListeners(false);
    }

    private _updateGlobalListeners(subscribe: boolean) {
        if (this._observedElements.length > 1 && subscribe) {
            return;
        }

        if (this._observedElements.length > 0 && !subscribe) {
            return;
        }

        const method = this._observedElements.length === 0 ? 'off' : 'on';

        globalEventService[method]('window:wheel:captured', this._checkElements);
        globalEventService[method]('window:mouseup:captured', this._checkElements);
        globalEventService[method]('window:keydown:captured', this._checkElements);
        globalEventService[method]('window:touchend:captured', this._checkElements);
        globalEventService[method]('window:scroll:captured', this._checkElements);
    }
}
