const prefix = 'companies/:companyId/amaxPay/openBanking';

export const amaxPayApiPaths = {
    openBankingAccounts: `${prefix}/accounts`,
    openBankingAccountDetails: `${prefix}/accounts/:accountId`,
    openBankingPayments: `${prefix}/payments`,
    openBankingPaymentDetails: `${prefix}/payments/:paymentId`,
    openBankingPaymentHistory: `${prefix}/payments/:paymentId/history`,
    openBankingConsents: `${prefix}/consents`,
    openBankingConsentDetails: `${prefix}/consents/:consentId`,
    openBankingConsentHistory: `${prefix}/consents/:consentId/history`,
};
