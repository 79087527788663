import { memo, useEffect, useState } from 'react';

import UnknownDocumentViewer from '../UnknownDocumentViewer/UnknownDocumentViewer';
import { browserSupportsPdf } from './PdfViewer.helpers';
import { messages } from './PdfViewer.messages';
import { ContentPdf, ContentPdfOverlay } from './PdfViewer.styles';
import { PdfViewerProps } from './PdfViewer.types';

const hasBrowserSupport = browserSupportsPdf();

const PdfViewer = memo<PdfViewerProps>((props) => {
    const { attachment } = props;

    const [fileData, setFileData] = useState<Blob | null>(null);

    useEffect(() => {
        setFileData(null);

        if (!hasBrowserSupport) {
            return;
        }

        (async () => {
            const file = await fetch(attachment.url, {
                credentials: 'include',
            });

            const fileBlob = await file.blob();

            setFileData(new Blob([fileBlob], { type: 'application/pdf' }));
        })();
    }, [attachment]);

    if (!hasBrowserSupport) {
        return (
            <UnknownDocumentViewer
                attachment={attachment}
                hasError={false}
                description={messages.pdfNotSupportedInBrowser}
            />
        );
    }

    return fileData ? (
        <ContentPdf>
            <embed src={window.URL.createObjectURL(fileData)} width='100%' height='100%' />

            <ContentPdfOverlay data-type='Pdf-overlay' />
        </ContentPdf>
    ) : null;
});

export default PdfViewer;
