import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.FindCompanyShardPage', {
    pageTitle: 'Sharding Management',
    breadcrumbRoot: 'Infrastructure',
    searchPlaceholder: 'Company ID',
    companyIdCol: 'Company ID',
    shardCol: 'Shard',
    searchError: 'No data found or company id is invalid',
    noData: 'No data found',
    defaultErrorMessage: 'Oops, something went wrong',
});
