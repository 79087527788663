import { DropdownTriangleIcon } from '@approvalmax/ui/src/old/icons';
import { font } from '@approvalmax/ui/src/old/styles';
import { hooks } from '@approvalmax/utils';
import { FC, PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';

import TransparentButton from '../button/TransparentButton';

export interface AccordionProps extends PropsWithChildren {
    className?: string;
    title?: string | null;
    truncatedTitle?: boolean;
}

const AccordionItem = styled.li`
    ${font(13)}
    display: flex;
    align-items: center;
    height: 50px;
    padding-right: 22px;
    margin-left: 60px;

    &:not(:last-child) {
        border-bottom: 1px #e4e4e4 solid;
    }
`;

const HeaderButton = styled.button`
    ${font(13, '#384650', 'semibold')}
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 22px;
    height: 50px;
    width: 100%;
`;

const DropdownIcon = styled.div<{ isOpen: boolean }>`
    fill: #4c8a5c;
    width: 16px;
    height: 16px;
    margin-right: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);

    ${(props) =>
        props.isOpen &&
        css`
            transform: rotate(0deg);
        `}
`;

const TruncatedTitle = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export interface ChildrenComponents {
    ListItem: typeof AccordionItem;
}

const Accordion: FC<AccordionProps> & ChildrenComponents = (props) => {
    const { children, title, className, truncatedTitle } = props;

    const [expanded, setExpanded] = useState(false);
    const sectionId = hooks.useId();

    return (
        <div className={className}>
            <TransparentButton execute={() => setExpanded(!expanded)}>
                {({ elementProps }) => (
                    <HeaderButton {...elementProps} aria-expanded={expanded} aria-controls={sectionId}>
                        <>
                            <DropdownIcon isOpen={expanded}>
                                <DropdownTriangleIcon />
                            </DropdownIcon>

                            {truncatedTitle ? <TruncatedTitle>{title}</TruncatedTitle> : title}
                        </>
                    </HeaderButton>
                )}
            </TransparentButton>

            {expanded && <ul id={sectionId}>{children}</ul>}
        </div>
    );
};

Accordion.ListItem = AccordionItem;

export default Accordion;
