import { theme } from '@approvalmax/theme';
import { css } from 'styled-components';

export const stickyCellBorder = (fullBordered?: boolean) => stickyHeaderCellBorder(fullBordered);

export const stickyHeaderCellBorder = (
    fullBordered?: boolean,
    onlyBorderBottom?: boolean,
    leftRightZero?: boolean
) => css`
    &::before {
        content: '';
        position: absolute;

        top: -1px;
        bottom: -1px;
        ${!leftRightZero &&
        css`
            left: -1px;
            right: -1px;
        `};
        ${leftRightZero &&
        css`
            left: 0px;
            right: 0px;
        `};

        border-bottom: 1px solid ${theme.color.midnight50};
        ${!onlyBorderBottom &&
        css`
            border-top: 1px solid ${theme.color.midnight50};
        `};
        ${fullBordered &&
        css`
            border: 1px solid ${theme.color.midnight50};
        `};

        z-index: -1;
    }
`;

export const verticalAlign = (value: 'top' | 'middle' | 'bottom') => css`
    vertical-align: ${value};
`;

export const stickyShadow = (isRight?: boolean) => css`
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        ${isRight &&
        css`
            left: -20px;
        `};
        ${!isRight &&
        css`
            right: -20px;
        `};
        width: 20px;
        background: linear-gradient(
            ${isRight ? '-90deg' : '90deg'},
            rgba(201, 201, 205, 0.35),
            rgba(255, 255, 255, 0.15)
        );
        pointer-events: none;
    }
`;
