import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { leftStickyShadowTableState, rightStickyShadowTableState } from '../../Table.states';
import { BaseItem, ColumnDefinition, SortOrder, TableProps } from '../../Table.types';

export const useTableSort = <Item extends BaseItem>(
    columnDefinition: ColumnDefinition<Item>,
    onSort: TableProps<Item>['onSort']
) => {
    const { id, sortable, sortOrder } = columnDefinition;

    const handleSort = useCallback(() => {
        if (!sortable || !onSort) {
            return;
        }

        const newSortOrder = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

        onSort(id, newSortOrder);
    }, [id, sortable, sortOrder, onSort]);

    return sortable ? handleSort : undefined;
};

export const useStickyShadow = <Item extends BaseItem>(sticky: ColumnDefinition<Item>['sticky']) => {
    const cellRef = useRef<HTMLTableCellElement | null>(null);

    const [withStickyShadow, setWithStickyShadow] = useRecoilState(
        sticky === 'left' ? leftStickyShadowTableState : rightStickyShadowTableState
    );

    const setCellRef = useCallback((node: HTMLTableCellElement | null) => {
        cellRef.current = node;
    }, []);

    useEffect(() => {
        const element = cellRef.current;

        if (!sticky || !element) {
            return;
        }

        const observer = new IntersectionObserver(
            ([e]) => {
                setWithStickyShadow(e.intersectionRatio < 1);
            },
            {
                threshold: 1,
                root: document, // for correct works rootMargin
                rootMargin: '5000px 0px', // to detect an intersection with a vertical line only
            }
        );

        observer.observe(element);

        return () => {
            observer.unobserve(element);
        };
    }, [sticky, setWithStickyShadow]);

    return { setCellRef, withStickyShadow };
};
