/**
 * Type-safely checks if object has own property with given key
 */
export const has = <PropertyName extends string>(
    obj: unknown,
    propertyName: PropertyName
): obj is {
    [K in PropertyName]: unknown;
} => {
    return Object(obj) === obj && Object.prototype.hasOwnProperty.call(obj, propertyName);
};
