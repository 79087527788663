import { AmCircleLogo } from '@approvalmax/ui';
import { Box, Spacing } from '@approvalmax/ui/src/components';
import { Alert, NavBarMode, PageLayout } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { FC, memo } from 'react';

import { Welcome } from './Dashboard.styles';
import { messages } from './DashboardPage.messages';

const DashboardPage: FC = () => {
    const { account } = useCurrentAccount();

    return (
        <PageLayout documentTitle={messages.pageTitle} breadcrumbs={[messages.pageTitle]} mode={NavBarMode.withDrawer}>
            <Box color='white100' spacing='20 60' shadow='xxsmall'>
                {account && account.permissions.length === 0 && (
                    <>
                        <Alert>{messages.errorNoAccess}</Alert>

                        <Spacing height={20} />
                    </>
                )}

                <Welcome>
                    <AmCircleLogo width={32} />

                    <Spacing height={16} />

                    <b>{messages.welcome}</b>

                    <Spacing height={8} />

                    {messages.welcomeText}
                </Welcome>
            </Box>
        </PageLayout>
    );
};

export default memo(DashboardPage);
