import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.AuditLogPage', {
    pageTitle: 'Audit Log',
    breadcrumbRoot: 'Admin portal Management',
    infoPanelText:
        'All audit logs are logged into the Log Analytics workspace in Azure. Please refer to the Azure Portal to inspect them.',
    goToAzureButton: 'Inspect logs in Azure',
    occuredAtCol: 'Occured At',
    actorCol: 'Actor',
    actionCol: 'Action',
    detailsButton: 'Details',
});
