import { useEffect, useState } from 'react';
import type { ResolvedIntlConfig } from 'react-intl';

export const useLocalizationMessages = () => {
    const [localizationMessages, setLocalizationMessages] = useState<ResolvedIntlConfig['messages']>({});

    useEffect(() => {
        setLocalizationMessages(
            (window as typeof window & { localizationMap: ResolvedIntlConfig['messages'] }).localizationMap
        );
    }, []);

    return localizationMessages;
};
