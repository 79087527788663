import { QueryOptions } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import { UseAmaxPayPaymentDetailsPathParams, UseAmaxPayPaymentDetailsResponse } from './useAmaxPayPaymentDetails.types';

export const useGetAmaxPayPaymentDetails = (
    pathParams: UseAmaxPayPaymentDetailsPathParams,
    queryOptions?: QueryOptions<UseAmaxPayPaymentDetailsResponse>
) => {
    return useGet<UseAmaxPayPaymentDetailsResponse>(amaxPayApiPaths.openBankingPaymentDetails, {
        params: {
            path: pathParams,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
