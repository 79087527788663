import { Mods, mods, theme } from '@approvalmax/theme';
import { Flex } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'open'>>>`
    height: 100%;

    ${mods.open.true`
        display: flex;
        flex-direction: column;
    `};
`;

export const Toolbar = styled(Flex)<StyledMods<Mods<'open'>>>`
    padding: 12px 24px;
    border-bottom: 1px solid ${theme.color.midnight50};
    height: 52px;
    overflow: hidden;

    ${mods.open.false`
        display: none;
    `};
`;

export const Body = styled.div<StyledMods<Mods<'open'>>>`
    height: 100%;
    overflow: auto;

    ${mods.open.true`
        position: relative;
    `};
`;
