/**
 * Developer: Stepan Burguchev
 * Date: 2/25/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './numberEditor.scss';

import { errorHelpers, hooks, intl, mathService, numberHelpers } from '@approvalmax/utils';
import React, { forwardRef, InputHTMLAttributes, useCallback, useContext, useEffect, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { FieldContext } from '../../field/Field';
import { getDisabled } from '../../helpers';
import { BaseEditorProps } from '../EditorBase';
import { messages } from './NumberEditor.messages';

const ALLOWED_CHARS = '0123456789+-.,Ee';

export const ALL_NUMBER_THEMES = ['form', 'transparent'] as const;
export type NumberTheme = (typeof ALL_NUMBER_THEMES)[number];

export const ALL_NUMBER_ALIGNMENTS = ['left', 'right'] as const;
export type NumberAlignment = (typeof ALL_NUMBER_ALIGNMENTS)[number];

export const ALL_NUMBER_DISPLAY_FORMATS = ['as-is', 'currency', 'currency-2', 'currency-auto'] as const;
export type NumberDisplayFormat = (typeof ALL_NUMBER_DISPLAY_FORMATS)[number];

export interface NumberEditorProps extends BaseEditorProps<number | null> {
    theme?: NumberTheme;
    displayFormat?: NumberDisplayFormat;
    className?: string;
    warning?: boolean;
    min?: number;
    max?: number;
    placeholder?: string;
    allowFloat?: boolean;
    precision?: number;
    align?: NumberAlignment;
    onEnter?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    changeOnBlur?: boolean;
    allowCalculation?: boolean;
    resetIfEvaluatedValueExceedsLimit?: boolean;
}

const getAllowFloat = (props: { allowFloat?: boolean; precision?: number }) => {
    // When precision is set, allow float is not necessary
    return props.allowFloat || (props.precision != null && props.precision > 0);
};

const getNewValue = (
    value: string,
    props: {
        value?: number | null;
        allowFloat?: boolean;
        precision?: number;
        min?: number;
        max?: number;
        allowCalculation?: boolean;
        resetIfEvaluatedValueExceedsLimit?: boolean;
    }
) => {
    const allowFloat = getAllowFloat(props);
    const { precision, allowCalculation } = props;
    const fallbackValue = numberHelpers.isNumber(props.value) ? props.value : null;

    let result: number | null = null;

    if (value !== '' && value !== null) {
        const parsed = parseFromString(value, allowCalculation);

        if (parsed !== null) {
            result = limitToAllowedRangeOrResetToPreviousValue(value, parsed, fallbackValue, props);

            if (result !== null) {
                if (!allowFloat) {
                    result = Math.floor(result);
                } else if (precision) {
                    result = mathService.round(result, precision);
                }
            }
        } else {
            // Incorrect value has been typed in
            result = fallbackValue;
        }
    } else {
        if (value === '') {
            result = null;
        }
    }

    return result;
};

const parseFromString = (val: string, allowCalculation?: boolean) => {
    let result: number | null = null;

    if (typeof val === 'string' && val !== '') {
        const commaSeparatorPattern = /^(\d+,?)+(\.\d+)?$/;

        if (commaSeparatorPattern.test(val)) {
            val = val.replaceAll(',', '');
        } else {
            val = val.replaceAll(',', '.');
        }

        if (allowCalculation) {
            result = mathService.eval(val);
        } else {
            result = Number(val);
        }

        if (result === Number.POSITIVE_INFINITY) {
            result = Number.MAX_VALUE;
        } else if (result === Number.NEGATIVE_INFINITY) {
            result = Number.MIN_VALUE;
        }
    }

    return result !== null && isNaN(result) ? null : result;
};

const limitToAllowedRange = (value: number, props: { min?: number; max?: number }) => {
    const { min, max } = props;

    if (max !== undefined && value > max) {
        return max;
    }

    if (min !== undefined && value < min) {
        return min;
    }

    return value;
};

const limitToAllowedRangeOrResetToPreviousValue = (
    inputValue: string,
    parsedValue: number,
    fallbackValue: number | null,
    props: { min?: number; max?: number; allowCalculation?: boolean; resetIfEvaluatedValueExceedsLimit?: boolean }
) => {
    const { min, max, allowCalculation, resetIfEvaluatedValueExceedsLimit } = props;

    if (allowCalculation && resetIfEvaluatedValueExceedsLimit) {
        const mathOperatorsPattern = /[+\-*/]/;
        const hasMathOperators = mathOperatorsPattern.test(inputValue);

        if (!hasMathOperators) {
            return limitToAllowedRange(parsedValue, props);
        } else if ((min !== undefined && parsedValue < min) || (max !== undefined && parsedValue > max)) {
            return fallbackValue;
        }
    }

    return limitToAllowedRange(parsedValue, props);
};

const toStateValue = (value: number | null) => {
    return value !== null && Number.isFinite(value) ? value.toString() : '';
};

const NumberEditor = forwardRef<HTMLInputElement, NumberEditorProps>((props, ref) => {
    const {
        focusOnMount,
        className,
        theme = 'form',
        qa,
        placeholder,
        align = 'left',
        displayFormat = 'as-is',
        invalid,
        warning,
        min = 0,
        max = 100000000000000,
        changeOnBlur = true,
        precision,
    } = props;

    const disabled = getDisabled(props.disabled, props.command);

    const [stateValue, setStateValue] = useState(toStateValue(props.value || null));
    const [focused, setFocused] = useState(false);
    const precisionRef = useRef(precision);

    let inputValue;

    if (focused) {
        inputValue = stateValue || '';
    } else {
        switch (displayFormat) {
            case 'as-is':
                inputValue = stateValue || '';
                break;

            case 'currency':
                inputValue = props.value != null ? intl.formatNumber(props.value, precision ?? 'auto', true) : '';
                break;

            case 'currency-2':
                inputValue = props.value != null ? intl.formatNumber(props.value, 2) : '';
                break;

            case 'currency-auto':
                inputValue = props.value != null ? intl.formatNumber(props.value, 'auto') : '';
                break;

            default:
                throw errorHelpers.formatError();
        }
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isAllowedChar = ALLOWED_CHARS.includes(e.key);

        if (!isAllowedChar) {
            e.preventDefault();
        }
    };

    const handleChangeStateValue = (newStateValue: string, forceChange?: boolean) => {
        setStateValue(newStateValue);

        if (!changeOnBlur || forceChange) {
            const newValue = getNewValue(newStateValue, props);

            if (props.value !== newValue) {
                props.onChange(newValue);
            }
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            inputRef.current?.blur();
            props.onEnter?.(e);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStateValue = e.target.value;

        handleChangeStateValue(newStateValue);
    };

    const onFocus = () => {
        setFocused(true);

        if (props.onFocus) {
            props.onFocus();
        }
    };

    const onBlur = () => {
        setFocused(false);

        const newValue = getNewValue(stateValue, props);

        setStateValue(toStateValue(newValue));

        if (changeOnBlur) {
            if (props.value !== newValue) {
                props.onChange(newValue);
            }
        }

        if (props.onBlur) {
            props.onBlur();
        }
    };

    const onPrecisionChange = useCallback(() => {
        const newValue = getNewValue(stateValue, props);

        setStateValue(toStateValue(newValue));

        if (props.value !== newValue) {
            props.onChange(newValue);
        }
    }, [props, stateValue]);

    useEffect(() => {
        if (precisionRef.current !== precision) {
            onPrecisionChange();

            precisionRef.current = precision;
        }
    }, [onPrecisionChange, precision]);

    const { fieldId } = useContext(FieldContext);

    const [onMount, inputRef] = hooks.useForwardedRef(ref);

    hooks.useFocusOnMount(focusOnMount, inputRef);

    // Update the stateValue if props.value changes
    useEffect(() => {
        setStateValue(toStateValue(props.value || null));
    }, [props.value]);

    const bem = bemFactory.block('form-number-editor').themed(theme as any);
    const inputProps: InputHTMLAttributes<HTMLInputElement> = {
        className: bem.add(className)(null, {
            invalid,
            focused,
            disabled,
            'align-right': align === 'right',
            warning,
        }),
        value: inputValue,
        placeholder: placeholder || messages.placeholder,
        onKeyPress: props.allowCalculation ? () => null : onKeyPress,
        onKeyDown,
        onChange,
        onFocus,
        onBlur,
        disabled,
        min,
        max,
        type: 'text',
        inputMode: getAllowFloat(props) ? 'decimal' : 'numeric',
        autoComplete: 'off',
    };

    return <input id={fieldId} ref={onMount} data-qa={qa} {...inputProps} />;
});

export default NumberEditor;
