import type { ApiError, ApiVersion } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { ResponseType } from 'axios';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApiLegacy from '../rootApiLegacy';
import { ApiMethods, ApiSource, QueryOptions, QueryParams, RequestParams } from '../types';

/** @deprecated Use {@link createUseGet} */
export const createUseGetLegacy = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <ResponseData, MappedResponseData = ResponseData>(
        path: string,
        options: {
            /** @deprecated Use {@link params} */
            pathParams?: QueryParams<Record<string, any>>;
            /** @deprecated Use {@link params} */
            queryParams?: QueryParams<Record<string, any>>;
            params?: RequestParams;
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            queryOptions?: QueryOptions<ResponseData, MappedResponseData>;
            responseType?: ResponseType;
            /** @deprecated Use {@link mapToCamelCase} */
            mapData?: (data: ResponseData) => MappedResponseData;
            mapToCamelCase?: boolean;
            method?: ApiMethods;
        } = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            queryParams,
            pathParams,
            params,
            queryOptions,
            responseType,
            mapData,
            mapToCamelCase,
            method = 'post',
        } = options;

        const requestParams = params || {
            path: pathParams,
            query: queryParams,
        };
        const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, requestParams.path)
            ? routerHelpers.pathToUrl(path, requestParams.path)
            : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData, mapToCamelCase);
        const data = method === 'get' || !requestParams.query ? {} : requestParams.query;

        return useQuery<MappedResponseData, ApiError>(
            [url, requestParams.query].filter(Boolean),
            () =>
                handleApiCall(
                    rootApiLegacy({
                        baseURL,
                        method,
                        url,
                        params: requestParams.query,
                        headers: { ...(Authorization && { Authorization }) },
                        responseType,
                        data,
                    })
                ),
            {
                ...queryOptions,
                enabled: (queryOptions?.enabled ?? true) && Boolean(url),
                meta: {
                    meta: queryOptions?.meta,
                    path,
                    queryParams,
                    pathParams,
                    params,
                    data,
                },
            }
        );
    };
};
