import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.AmaxPayConsentsPage', {
    pageTitle: 'Amax Pay Consents',
    breadcrumbRoot: 'Admin portal Management',
    placeHolder: 'Enter a search query...',
    companyId: 'Company ID',
    search: 'Search',
    id: 'ID',
    consentId: 'Consent ID',
    institutionId: 'Institution ID',
    createdAt: 'Created at',
    status: 'Status',
});
