import styled from 'styled-components';

export const Welcome = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`;
