import { UseQueryOptions } from '@tanstack/react-query';

import { QueryOptions, QueryParams } from '../types';

/**
 * Check if all params are defined for the react-query requests
 */
export const enabledByParams = <Params extends Record<string, any>, Options = Record<string, any>>(
    params: QueryParams<Params>,
    queryOptions?: QueryOptions<Options> | UseQueryOptions
) => {
    const externalEnabled = typeof queryOptions?.enabled === 'boolean' ? queryOptions?.enabled : true;
    const paramsEnabled = Object.values(params).every((value) => value !== undefined && value !== null && value !== '');

    return externalEnabled && paramsEnabled;
};
