import { FC, memo } from 'react';
import { ToastContainer } from 'react-toastify';

const Toaster: FC = () => {
    return (
        <ToastContainer
            position='bottom-center'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ width: 'auto', maxWidth: 500 }}
            theme='dark'
        />
    );
};

export default memo(Toaster);
