import { Field, Popup, TextAreaEditor, TextEditor } from '@approvalmax/ui';
import { Spacing } from '@approvalmax/ui/src/components';
import { toaster } from 'modules/components';
import { memo, useCallback } from 'react';
import { useToggle } from 'react-use';

import { useCommentField, useDisable2FA, useTicketNumberField } from './Manage2FAPopup.hooks';
import { messages } from './Manage2FAPopup.messages';
import { Content } from './Manage2FAPopup.styles';
import { Manage2FAPopupProps } from './Manage2FAPopup.types';

const Manage2FAPopup = memo<Manage2FAPopupProps>((props) => {
    const { isOpened, userId, onClose } = props;

    const [showErrors, setShowErrors] = useToggle(false);

    const { comment, onCommentChange, isInvalid: isCommentInvalid } = useCommentField();

    const { ticketNumber, onTicketNumberChange, isInvalid: isTicketNumberInvalid } = useTicketNumberField();

    const { isLoading, disable2FA } = useDisable2FA(userId);

    const onSubmit = useCallback(() => {
        setShowErrors(true);

        if (isCommentInvalid) {
            toaster.error(messages.commentFieldError);
        }

        if (isTicketNumberInvalid) {
            toaster.error(messages.ticketNumberError);
        }

        if (isCommentInvalid || isTicketNumberInvalid) {
            return;
        }

        disable2FA(
            {
                comment,
                supportTicketId: ticketNumber?.toString() ?? '',
            },
            {
                onSuccess: () => {
                    onClose();

                    toaster.info(messages.successMessage);
                },
            }
        );
    }, [comment, ticketNumber, disable2FA, setShowErrors, isCommentInvalid, isTicketNumberInvalid, onClose]);

    const hasFilledValues = comment && ticketNumber;

    return (
        <Popup isOpen={isOpened} onRequestClose={onClose}>
            <Popup.DefaultContent
                buttons={
                    <Popup.DefaultContent.Button disabled={isLoading || !hasFilledValues} execute={onSubmit}>
                        {messages.submitButton}
                    </Popup.DefaultContent.Button>
                }
            >
                <Content>
                    <Field title={messages.comment} required>
                        <TextAreaEditor
                            value={comment}
                            invalid={isCommentInvalid && showErrors}
                            onChange={onCommentChange}
                        />
                    </Field>

                    <Spacing height={20} />

                    <Field title={messages.ticketNumber} required>
                        <TextEditor
                            value={ticketNumber}
                            invalid={isTicketNumberInvalid && showErrors}
                            onChange={onTicketNumberChange}
                        />
                    </Field>
                </Content>
            </Popup.DefaultContent>
        </Popup>
    );
});

export default Manage2FAPopup;
