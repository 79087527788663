import { Mods, mods } from '@approvalmax/theme';
import { font } from '@approvalmax/ui/src/old/styles';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import TransparentButton from '../../ui/button/TransparentButton';
export const Panel = styled.div`
    box-sizing: border-box;
    width: 318px;
    margin: 2px 0;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
`;

export const ClearButton = styled(TransparentButton)`
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    visibility: hidden;
`;

export const DropdownButton = styled.div`
    position: relative;
    width: 130px;

    &:hover ${ClearButton} {
        visibility: visible;
    }
`;

export const ValueContainer = styled.div<StyledMods<Mods<'disabled'> & { parsedValue?: boolean }>>`
    position: relative;
    width: 100%;
    cursor: pointer;
    border: solid 1px #cacccd;
    border-radius: 2px;
    padding: 3px 5px;

    ${font(13, '#000')};

    ${mods.disabled.true`
        cursor: default;
        color: #a0a1a1;
        background: #f8f5f5;
    `};

    ${mods('parsedValue', true)`
        padding-right: 15px;
    `};
`;

export const Nav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const NavButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 9px;
    color: #82888a;
    background-color: #fff;
    border: 1px solid #e4e7e7;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        border: 1px solid #c4c4c4;
    }
`;

export const YearValue = styled.div`
    ${font(18, '#000', 'bold')}
`;

export const MonthGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    border: 1px solid #e4e7e7;
`;

export const MonthValue = styled.div<Mods<'disabled'>>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    height: 40px;
    border-top: 1px solid #e4e7e7;
    border-right: 1px solid #e4e7e7;
    cursor: pointer;
    ${font(14, '#000')}

    ${mods.disabled.true`
        color: #cacccd;
        cursor: default;
    `}

    &:nth-child(3n) {
        border-right: 0;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        border-top: 0;
    }

    &:hover {
        background-color: #e4e7e7;
    }
`;
