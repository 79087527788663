import { ColumnDefinition } from '@approvalmax/ui/src/components';
import { dateTimeHelpers, intl } from '@approvalmax/utils';
import { UseAmaxPayPaymentsItem } from 'modules/data';

import { messages } from './AmaxPayPaymentsPage.messages';

export const dataTableColumns: ColumnDefinition<UseAmaxPayPaymentsItem>[] = [
    {
        id: 'id',
        name: messages.id,
    },
    {
        id: 'consentId',
        name: messages.consentId,
    },
    {
        id: 'amount',
        name: messages.amount,
        value: (item) => intl.formatCurrency(item.amount, item.currency),
        textAlign: 'right',
        headerTextAlign: 'right',
    },
    {
        id: 'createdAt',
        name: messages.createdAt,
        value: (item) => dateTimeHelpers.formatDateTime(item.createdAt),
    },
    {
        id: 'status',
        name: messages.status,
    },
    {
        id: 'payee',
        name: messages.payee,
        value: (item) => item.payee.fullName,
    },
    {
        id: 'payer',
        name: messages.payer,
        value: (item) => item.payer.fullName,
    },
];
