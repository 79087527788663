import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.AmaxPayPaymentsPage', {
    pageTitle: 'Amax Pay Payments',
    breadcrumbRoot: 'Admin portal Management',
    placeHolder: 'Enter a search query...',
    companyId: 'Company ID',
    accountId: 'Account ID',
    paymentId: 'Payment ID',
    idempotencyId: 'Idempotency ID',
    search: 'Search',
    id: 'ID',
    amount: 'Amount',
    status: 'Status',
    consentId: 'Consent ID',
    createdAt: 'Created at',
    payee: 'Payee',
    payer: 'Payer',
});
