import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { StorageQueue } from 'modules/api';

import { ActionsCell } from './components';
import { messages } from './StorageQueuesPage.messages';

export const dataTableColumns: DataTableColumnDefinition<StorageQueue>[] = [
    {
        id: 'id',
        name: messages.idCol,
        value: (queue) => queue.id,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'messageCount',
        name: messages.messageCountCol,
        value: (queue) => queue.messageCount,
        sortable: false,
    },
    {
        id: 'poisonMessageCount',
        name: messages.poisonMessageCountCol,
        value: (queue) => queue.poisonMessageCount,
        sortable: false,
    },
    {
        id: 'actions',
        name: '',
        width: 250,
        cellComponent: ActionsCell,
        sortable: false,
    },
];
