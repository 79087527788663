import { GlobalHotKeys } from '@approvalmax/ui';
import { reactWindowService } from '@approvalmax/utils';
import { PortalDef } from '@approvalmax/utils/src/services/reactWindow/reactWindow';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { DrawerWrapper, FadingPanel } from './Portal.styles';
import { PortalProps, PortalState } from './Portal.types';

const longestTransitionTimeout = 400;

class Portal extends Component<PortalProps, PortalState> {
    public state: PortalState = {
        isDisposing: false,
    };

    private _portal: PortalDef | null = null;
    private _fadeEl: HTMLElement | null = null;
    private _isUnmounted: boolean | undefined;

    private _handlers = {
        escape: () => this.props.onClose(),
    };

    public UNSAFE_componentWillMount() {
        if (this.props.isOpen) {
            this._buildPortal();
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: PortalProps) {
        if (!this.props.isOpen && nextProps.isOpen) {
            this._buildPortal();
        }

        if (this.props.isOpen && !nextProps.isOpen) {
            this._destroyPopup();
        }
    }

    public componentWillUnmount() {
        this._destroyPopup();
        this._isUnmounted = true;
    }

    public render() {
        const { isOpen, isPinned } = this.props;

        const isDisposing = Boolean(this.state.isDisposing);

        if (!isOpen && !isDisposing) {
            return null;
        }

        return ReactDOM.createPortal(
            <GlobalHotKeys handlers={this._handlers}>
                <FadingPanel ref={(ref) => (this._fadeEl = ref)} onClick={this._onRequestAutoClose} isActive={isOpen} />

                <DrawerWrapper
                    isActive={isOpen}
                    stopAnimation={isPinned}
                    from={{ transform: 'translateX(-100%)', opacity: 0 }}
                    to={{ transform: isOpen ? 'translateX(0%)' : 'translateX(-100%)', opacity: isOpen ? 1 : 0 }}
                >
                    {this.props.children}
                </DrawerWrapper>
            </GlobalHotKeys>,
            this._portal!.el
        );
    }

    private _onRequestAutoClose = (e: React.MouseEvent<HTMLDivElement>) => {
        if (this._fadeEl !== e.target) {
            return;
        }

        this.props.onClose();
    };

    private _buildPortal() {
        this._portal = reactWindowService.createControlledPortal({
            hostEl: null,
            transient: false,
            onRequestClose: this._onRequestClose,
        });
    }

    private _onRequestClose = () => {
        // NOTE: Popup will never close itself per a request from the window service
    };

    private _destroyPopup() {
        if (!this._portal) {
            return;
        }

        this._portal.closeRelatedPortals();

        let portal = this._portal;

        this.setState({
            isDisposing: true,
        });
        setTimeout(() => {
            portal.dispose();

            if (!this._isUnmounted && this._portal && portal.id === this._portal.id) {
                // If this branch is ignored, frozenPopupHtml has been removed in _buildPopup() already.
                this.setState({
                    isDisposing: false,
                });
                this._portal = null;
            }
        }, longestTransitionTimeout);
    }
}

export default Portal;
