import { NavBarProps } from 'modules/components';
import { PropsWithChildren } from 'react';

export interface PageLayoutProps extends NavBarProps, PropsWithChildren {
    documentTitle: string;
    widthBehavior?: PageLayoutWidthBehavior;
}

export enum PageLayoutWidthBehavior {
    contentBased = 'contentBased',
    fixed = 'fixed',
}
