import { PlainDataProviderItem } from './PlainDataProviderItem';

export const withoutExcluded = (data: PlainDataProviderItem[], excludedIds: string[]) => {
    return data.filter((x) => !excludedIds.includes(x.id));
};

export const filtered = (
    data: PlainDataProviderItem[],
    filterText: string | null,
    displayAttribute: string,
    filterFn: ((item: PlainDataProviderItem, filterText: string) => boolean) | undefined
) => {
    if (!filterText) {
        return data;
    }

    filterText = filterText.toUpperCase();

    if (filterFn) {
        // apply custom filter function provided by the user
        return data.filter((x) => {
            return filterFn(x, filterText!);
        });
    } else {
        // match with display attribute
        return data.filter((x) => {
            const displayText: string | null = (x as any)[displayAttribute];

            return displayText ? displayText.toUpperCase().includes(filterText!) : false;
        });
    }
};
