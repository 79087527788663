import { Button, DropdownEditor, LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, PlainDataProvider, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo } from 'react';

import { enforcementOptions, glOptions } from './EnforcementTfaPage.config';
import { useEnforcementTfaPageData } from './EnforcementTfaPage.hooks';
import { messages } from './EnforcementTfaPage.messages';
import {
    Content,
    ContentTable,
    ContentTableBody,
    ContentTableBodyCell,
    ContentTableHead,
    ContentTableHeadCell,
    ContentTableRow,
    Root,
} from './EnforcementTfaPage.styles';

const EnforcementTfaPage: FC = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.admin.manageIntegrationConfigurations);

    const { values, onSubmit, onChange, isLoading } = useEnforcementTfaPageData();

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <ScrollableArea css='flex: 1; overflow: hidden;'>
                        <Root>
                            <Toolbar
                                title={messages.title}
                                actionButtons={
                                    <Button preset='small' execute={onSubmit}>
                                        {messages.buttonApply}
                                    </Button>
                                }
                            />

                            {isLoading ? (
                                <LoadingBar />
                            ) : (
                                <Content>
                                    <ContentTable>
                                        <ContentTableHead>
                                            <ContentTableRow>
                                                <ContentTableHeadCell>{messages.headGL}</ContentTableHeadCell>

                                                <ContentTableHeadCell>
                                                    {messages.headTfaEnforcement}
                                                </ContentTableHeadCell>
                                            </ContentTableRow>
                                        </ContentTableHead>

                                        <ContentTableBody>
                                            {glOptions.map((glOption) => (
                                                <ContentTableRow key={glOption.id}>
                                                    <ContentTableBodyCell>{glOption.text}</ContentTableBodyCell>

                                                    <ContentTableBodyCell>
                                                        <PlainDataProvider items={enforcementOptions}>
                                                            <DropdownEditor
                                                                value={values[glOption.id] || enforcementOptions[0]}
                                                                onChange={(value) => onChange(glOption.id, value)}
                                                            />
                                                        </PlainDataProvider>
                                                    </ContentTableBodyCell>
                                                </ContentTableRow>
                                            ))}
                                        </ContentTableBody>
                                    </ContentTable>
                                </Content>
                            )}
                        </Root>
                    </ScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

export default EnforcementTfaPage;
