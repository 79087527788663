import { errorHelpers } from '@approvalmax/utils';
import { FC, ReactElement, Ref, useRef } from 'react';

import { useDropdownAnchor } from './useDropdownAnchor';

export interface DropdownAnchorProps {
    children: (ref: Ref<HTMLElement>) => ReactElement;
}

const DropdownAnchor: FC<DropdownAnchorProps> = (props) => {
    const { children } = props;

    const ref = useRef<HTMLElement>(null);

    useDropdownAnchor(() => {
        if (!ref.current) {
            throw errorHelpers.formatError('DropdownAnchor error: the ref is not set.');
        }

        return ref.current.getBoundingClientRect();
    });

    return children(ref);
};

export default DropdownAnchor;
