export default function getPermissionFlags(permissions: string[]) {
    const hasPermission = (permission: string) => {
        return permissions.includes(permission);
    };

    const hasPermissionGroup = (permissionGroup: string) => {
        return permissions.some((p) => p.startsWith(permissionGroup + '.'));
    };

    return {
        accounts: {
            view: hasPermission('accounts.view'),
            create: hasPermission('accounts.create'),
            update: hasPermission('accounts.update'),
            delete: hasPermission('accounts.delete'),
            manageMyaccountPermission: hasPermission('accounts.manage-myaccount-permission'),
            any: hasPermissionGroup('accounts'),
        },
        ignoredEmails: {
            delete: hasPermission('ignored-emails.delete'),
            view: hasPermission('ignored-emails.view'),
            any: hasPermissionGroup('ignored-emails'),
        },
        requests: {
            read: hasPermission('requests.read'),
            restore: hasPermission('requests.restore'),
            delete: hasPermission('requests.delete'),
            readAttachments: hasPermission('requests.read-attachments'),
            any: hasPermissionGroup('requests'),
        },
        databases: {
            fetchData: hasPermission('databases.fetch-data'),
            any: hasPermissionGroup('databases'),
        },
        companies: {
            manageBetaFeatures: hasPermission('companies.manage-beta-features'),
            read: hasPermission('companies.read'),
            any: hasPermissionGroup('companies'),
        },
        users: {
            manageBetaFeatures: hasPermission('users.manage-beta-features'),
            read: hasPermission('users.read'),
            any: hasPermissionGroup('users'),
        },
        admin: {
            manageBetaFeatures: hasPermission('admin.manage-beta-features'),
            readBetaFeatures: hasPermission('admin.read-beta-features'),
            enforceFraudDetection: hasPermission('admin.enforce-fraud-detection'),
            forceMatchingMigration: hasPermission('admin.force-matching-migration'),
            manageIntegrationConfigurations: hasPermission('admin.manage-integration-configurations'),
            manageSyncCommands: hasPermission('admin.manage-sync-commands'),
            manage2FA: hasPermission('admin.manage-2fa'),
            manageMobileMinVersion: hasPermission('admin.manage-mobile-min-version'),
            any: hasPermissionGroup('admin'),
        },
        maintenances: {
            manageMessage: hasPermission('maintenances.manage-message'),
            any: hasPermissionGroup('maintenances'),
        },
        announcements: {
            manageMessage: hasPermission('announcements.manage-message'),
            any: hasPermissionGroup('announcements'),
        },
        storageQueues: {
            readMessages: hasPermission('storage-queues.read-messages'),
            manageMessages: hasPermission('storage-queues.manage-messages'),
            read: hasPermission('storage-queues.read'),
            any: hasPermissionGroup('storage-queues'),
        },
        reports: {
            read: hasPermission('reports.read'),
        },
        budgets: {
            readBudgetsId: hasPermission('budgets.read'),
        },
        sharding: {
            findCompanyShard: hasPermission('sharding.find-company-shard'),
            manageTenants: hasPermission('sharding.manage-tenants'),
            any: hasPermissionGroup('sharding'),
        },
        amaxPay: {
            bankAccountsView: hasPermission('amaxpay.bank-accounts.view'),
            consentsView: hasPermission('amaxpay.consents.view'),
            paymentsView: hasPermission('amaxpay.payments.view'),
        },
    };
}

export type PermissionFlags = ReturnType<typeof getPermissionFlags>;
