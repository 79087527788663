import { SpacingProp } from '@approvalmax/types';

/**
 * Transform spacing value from `2 12 32 40` to `2px 12px 32px 40px`
 */
export const spacingPropToCss = (spacing: SpacingProp['spacing']) => {
    if (!spacing) return undefined;

    return (
        spacing
            .split(' ')
            .map((item) => `${item}px`)
            .join(' ') || ''
    );
};
