import { DataTable, DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';

import { messages } from './NetSuiteSyncAndDeleteCachePopup.messages';

export const fieldColumns: DataTableColumnDefinition[] = [
    {
        id: 'refDataType',
        name: messages.referenceEntityColumnName,
        sortable: false,
        cellComponent: (props) => <DataTable.CheckboxCell {...props} padding={DataTablePadding.PopupLeft} />,
        columnComponent: (props) => <DataTable.CheckboxColumnHeader {...props} padding={DataTablePadding.PopupLeft} />,
    },
];
