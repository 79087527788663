import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Progress } from '@approvalmax/ui/src/components';
import { JsonViewer, NavBarMode, PageLayout, StyledScrollableArea, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { useGetAmaxPayBankAccountDetails } from 'modules/data/queries';
import { memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { messages } from './AmaxPayBankAccountDetailsPage.messages';
import { PathParams } from './AmaxPayBankAccountDetailsPage.types';

export const AmaxPayBankAccountDetailsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.amaxPay.bankAccountsView);

    const history = useHistory();

    const { companyId, accountId } = useParams<PathParams>();

    const { data, isFetching } = useGetAmaxPayBankAccountDetails({ companyId, accountId });

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('amaxPayBankAccounts'));
        }
    }, [history]);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <StyledScrollableArea>
                        <Toolbar title={data?.name || messages.pageTitle} />

                        <Box height={8}>{isFetching && <Progress />}</Box>

                        <Box spacing='20 60'>{data && <JsonViewer json={data.rawData} />}</Box>
                    </StyledScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

AmaxPayBankAccountDetailsPage.displayName = 'AmaxPayBankAccountDetailsPage';
