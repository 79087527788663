import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';
import { BudgetValue } from 'modules/types';

import { QueryOptions } from './useGetBudgetValues.types';

export const useGetBudgetValues = (
    companyId?: string,
    budgetId?: string,
    year?: string | null,
    month?: string | null,
    options: QueryOptions = {}
) => {
    const params = year && month ? { year, month } : null;

    return useQuery<BudgetValue[], Error>(
        [QueryKeys.BudgetValues, companyId, budgetId],
        () => api.budgets.getBudgetValues(companyId!, budgetId!, params!),
        {
            enabled: !!companyId && !!budgetId && !!params,
            ...options,
        }
    );
};
