import { Side } from '@floating-ui/react';

export const getTransformTranslateBySide = (side: Side, size: number = 8) => {
    switch (side) {
        case 'top':
            return `translateY(${size}px)`;

        case 'left':
            return `translateX(${size}px)`;

        case 'right':
            return `translateX(-${size}px)`;

        default:
            return `translateY(-${size}px)`;
    }
};
