import { Mods, mods } from '@approvalmax/theme';
import { font, TransparentButton } from '@approvalmax/ui';
import { BreadcrumbsArrowIcon } from '@approvalmax/ui/src/old/icons';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    align-items: center;
`;

export const CloseButton = styled(TransparentButton)`
    fill: #fff;
    padding: 6px;
    margin-right: 34px;
`;

export const DrawerButton = styled(TransparentButton)`
    padding: 6px 1px;
    margin-right: 34px;
`;

export const Breakcrumbs = styled.div<Mods<'clickable'>>`
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: default;
    white-space: nowrap;
    ${font(16, '#fff')};

    ${mods.clickable.true`
        cursor: pointer;
    `};
`;

export const BreadcrumbIcon = styled(BreadcrumbsArrowIcon)`
    margin: 2px 15px 0;
`;

export const AvatarBlock = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;
`;
