import { ExtractComponentProp } from '@approvalmax/types';
import { LoadingSpinner } from '@approvalmax/ui';
import { Box, Collapse } from '@approvalmax/ui/src/components';
import { RequestOrigin } from 'modules/api';
import { JsonViewer } from 'modules/components';
import { useGetRequestVersionData } from 'modules/data';
import { memo, useCallback, useState } from 'react';

import { getVersionName } from './VersionItem.helpers';
import { VersionItemProps } from './VersionItem.types';

export const VersionItem = memo<VersionItemProps>((props) => {
    const { companyId, requestId, version, requestOrigin, integrationName } = props;

    const [collapsed, setCollapsed] = useState(true);

    const { data, isFetching } = useGetRequestVersionData(
        {
            companyId,
            requestId,
            versionId: version.version.toString(),
        },
        {
            enabled: !collapsed,
        }
    );

    const onCollapse = useCallback<ExtractComponentProp<typeof Collapse, 'onCollapse'>>((value) => {
        setCollapsed(value ?? false);
    }, []);

    const versionName = getVersionName({
        version: version,
        integrationFriendlyName: integrationName,
        isDextRequest: requestOrigin === RequestOrigin.ReceiptBank,
    });

    return (
        <Collapse
            key={version.version}
            name={versionName}
            collapsed={collapsed}
            bordered
            size='small'
            onCollapse={onCollapse}
        >
            <Box spacing='16 28'>{isFetching ? <LoadingSpinner /> : <JsonViewer json={data ?? null} />}</Box>
        </Collapse>
    );
});

VersionItem.displayName = 'VersionItem';
