import { Button, DataTable, DataTableCellComponentProps } from '@approvalmax/ui';
import { DeleteBinIcon, TbNextArrow } from '@approvalmax/ui/src/old/icons';
import { StorageQueue } from 'modules/api';
import { ConfirmationPopup } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { useClearPoisonStorageQueue } from 'modules/infra/hooks/useClearPoisonStorageQueue';
import { useMoveStorageQueuePoisonMessages } from 'modules/infra/hooks/useMoveStorageQueuePoisonMessages';
import { FC, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { messages } from './ActionsCell.messages';
import { CellContent } from './ActionsCell.styles';

const ActionsCell: FC<DataTableCellComponentProps<any, StorageQueue>> = (props) => {
    const { item: queue } = props;

    const history = useHistory();
    const { isLoading: isClearing, clearPoisonStorageQueue } = useClearPoisonStorageQueue();
    const { isLoading: isMoving, movePoisonMessages } = useMoveStorageQueuePoisonMessages();
    const { permissionFlags } = useCurrentAccount();

    const onViewDetails = useCallback(() => {
        history.push(getPath('storageQueues', queue.id));
    }, [history, queue.id]);

    const onClearQueue = useCallback(() => {
        clearPoisonStorageQueue(queue.id);
    }, [clearPoisonStorageQueue, queue.id]);

    const onMovePoisonQueue = useCallback(() => {
        movePoisonMessages(queue.id);
    }, [movePoisonMessages, queue.id]);

    return (
        <DataTable.DefaultCell {...props}>
            <CellContent>
                <ConfirmationPopup
                    execute={onClearQueue}
                    confirmationText={messages.clearPoisonConfirmation({
                        queueName: <b>{queue.id}</b>,
                    })}
                    executeButtonText={messages.clearPoisonButton}
                >
                    {({ executeWithConfirmation }) => (
                        <Button
                            css='fill: #fff'
                            preset='compact'
                            padding='narrow10'
                            title={messages.clearQueueTitle}
                            execute={executeWithConfirmation}
                            disabled={
                                isClearing ||
                                queue.poisonMessageCount === 0 ||
                                !permissionFlags ||
                                !permissionFlags.storageQueues.manageMessages
                            }
                        >
                            <DeleteBinIcon height='14px' />
                        </Button>
                    )}
                </ConfirmationPopup>

                <ConfirmationPopup
                    execute={onMovePoisonQueue}
                    confirmationText={messages.movePoisonConfirmation({
                        queueName: <b>{queue.id}</b>,
                    })}
                    executeButtonText={messages.movePoisonButton}
                >
                    {({ executeWithConfirmation }) => (
                        <Button
                            css='fill: #fff'
                            preset='compact'
                            padding='narrow10'
                            title={messages.moveQueueTitle}
                            execute={executeWithConfirmation}
                            disabled={
                                isMoving ||
                                queue.poisonMessageCount === 0 ||
                                !permissionFlags ||
                                !permissionFlags.storageQueues.manageMessages
                            }
                        >
                            <TbNextArrow height='14px' />
                        </Button>
                    )}
                </ConfirmationPopup>

                <Button
                    disabled={
                        queue.poisonMessageCount === 0 ||
                        !permissionFlags ||
                        !permissionFlags.storageQueues.readMessages
                    }
                    execute={onViewDetails}
                    preset='compact'
                >
                    {messages.detailsButton}
                </Button>
            </CellContent>
        </DataTable.DefaultCell>
    );
};

export default memo(ActionsCell);
