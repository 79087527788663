import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.FeatureFlagsPage', {
    pageTitle: 'Feature Flags',
    breadcrumbRoot: 'Customer Success',
    userBetaFeaturesTitle: 'All user-level beta features',
    placeholderUserBetaFeatures: 'Add user beta features',
    createUserBetaFeatureText: 'Add new User beta-feature "{name}"',
    companyBetaFeaturesTitle: 'All company-level beta features',
    placeholderCompanyBetaFeatures: 'Add company beta features',
    createCompanyBetaFeatureText: 'Add new Company beta-feature "{name}"',
    save: 'Save',
});
