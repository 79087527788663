import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.syncCommandManagement', {
    pageTitle: 'Sync Command Management',
    breadcrumbRoot: 'Infrastructure',
    searchPlaceholder: 'Company ID',
    idCol: 'ID',
    statusCol: 'Status',
    requestIdCol: 'Request ID',
    timestampCol: 'Timestamp',
    searchError: 'No data found or company id is invalid',
    noData: 'No data found',
    defaultErrorMessage: 'Oops, something went wrong',
});
