import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useWorkflowDetailsData = (companyId: string, workflowId: string) => {
    const { isInitialLoading, data: workflowData } = useQuery([QueryKeys.WorkflowDetails, companyId, workflowId], () =>
        api.internalSupport.getWorkflowDetails(companyId, workflowId)
    );

    return {
        isInitialLoading,
        workflowData,
    };
};
