import { routerHelpers } from '@approvalmax/utils';

interface Params {
    email: string;
}

export const useQueryParams = routerHelpers.createQueryParamsHook<Params>({
    mapFromQuery(queryString) {
        const email = routerHelpers.getParamAsString(queryString, 'email', '');

        return { email };
    },
    mapToQuery(params) {
        return {
            email: !params.email.trim() ? null : params.email,
        };
    },
});
