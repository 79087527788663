import type { ApiVersion } from '@approvalmax/types';

import { apiSources, Env, getAppConfig } from './configs';
import { getApiUrl, setApiUrl } from './helpers/getApi';
import { createUseGet } from './methods/useGet';
import { createUseGetInfinite } from './methods/useGetInfinite';
import { createUseGetInfiniteLegacy } from './methods/useGetInfiniteLegacy';
import { createUseGetLazy } from './methods/useGetLazy';
import { createUseGetLazyLegacy } from './methods/useGetLazyLegacy';
import { createUseGetLegacy } from './methods/useGetLegacy';
import { createUseMutate } from './methods/useMutate';
import { createUseMutateLegacy } from './methods/useMutateLegacy';
import rootApi from './rootApi';
import rootApiLegacy from './rootApiLegacy';
import { ApiSource } from './types';

const init = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    const getApiUrlInner = (options: { apiSource?: ApiSource; env?: Env; apiVersion?: ApiVersion } = {}) => {
        const { apiSource = defaultApiSource, env = defaultEnv, apiVersion = 'v1' } = options;

        return getApiUrl(apiSource, env, apiVersion);
    };

    const setApiUrlInner = (options: { apiUrl: string; reload?: boolean; apiSource?: ApiSource }) => {
        const { apiUrl, reload = false, apiSource = defaultApiSource } = options;

        setApiUrl(apiUrl, apiSource, reload);
    };

    const setApiUrlByEnv = (options: { env?: Env; reload?: boolean; apiSource?: ApiSource | 'all' } = {}) => {
        const { env = defaultEnv, apiSource = defaultApiSource, reload = false } = options;

        if (apiSource === 'all') {
            apiSources.forEach((apiSourceInner) => {
                const apiUrl = getAppConfig(env)[apiSourceInner];

                setApiUrl(apiUrl, apiSourceInner, reload);
            });
        } else {
            const apiUrl = getAppConfig(env)[apiSource];

            setApiUrl(apiUrl, apiSource, reload);
        }
    };

    const resetApiUrl = (options: { reload?: boolean; apiSource?: ApiSource | 'all' } = {}) => {
        const { reload = false, apiSource = defaultApiSource } = options;

        if (apiSource === 'all') {
            apiSources.forEach((apiSourceInner) => {
                setApiUrl(null, apiSourceInner, reload);
            });
        } else {
            setApiUrl(null, apiSource, reload);
        }
    };

    const useGet = createUseGet(defaultApiSource, defaultEnv);
    const useGetLazy = createUseGetLazy(defaultApiSource, defaultEnv);
    const useGetLegacy = createUseGetLegacy(defaultApiSource, defaultEnv);
    const useGetLazyLegacy = createUseGetLazyLegacy(defaultApiSource, defaultEnv);
    const useMutate = createUseMutate(defaultApiSource, defaultEnv);
    const useMutateLegacy = createUseMutateLegacy(defaultApiSource, defaultEnv);
    const useGetInfinite = createUseGetInfinite(defaultApiSource, defaultEnv);
    const useGetInfiniteLegacy = createUseGetInfiniteLegacy(defaultApiSource, defaultEnv);

    return {
        useGet,
        useGetLazy,
        useGetLegacy,
        useGetLazyLegacy,
        useMutate,
        useMutateLegacy,
        useGetInfinite,
        useGetInfiniteLegacy,
        getApiUrl: getApiUrlInner,
        setApiUrl: setApiUrlInner,
        resetApiUrl,
        setApiUrlByEnv,
        rootApi,
        rootApiLegacy,
    };
};

export default init;
