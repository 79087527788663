import type { ApiError } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApi from '../rootApi';
import rootApiLegacy from '../rootApiLegacy';
import { ApiSource, UseGetOptions } from '../types';

export const createUseGet = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <ResponseData, MappedResponseData = ResponseData>(
        path: string,
        options: UseGetOptions<ResponseData, MappedResponseData> = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            method,
            queryParams,
            pathParams,
            params,
            queryOptions,
            responseType,
            mapData,
            mapToCamelCase,
        } = options;

        const requestParams = params || {
            path: pathParams,
            query: queryParams,
        };
        const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, requestParams.path)
            ? routerHelpers.pathToUrl(path, requestParams.path)
            : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData, mapToCamelCase);
        const apiFn = apiVersion === 'v1' ? rootApiLegacy : rootApi;
        const apiMethod = apiVersion === 'v1' && !method ? 'post' : method;
        const data = method === 'get' || !requestParams.query ? {} : requestParams.query;

        return useQuery<MappedResponseData, ApiError>(
            [url, requestParams.query].filter(Boolean),
            () =>
                handleApiCall(
                    apiFn({
                        baseURL,
                        url,
                        method: apiMethod,
                        responseType,
                        headers: { ...(Authorization && { Authorization }) },
                        params: requestParams.query,
                        data,
                    })
                ),
            {
                ...queryOptions,
                enabled: (queryOptions?.enabled ?? true) && Boolean(url),
                meta: {
                    meta: queryOptions?.meta,
                    path,
                    queryParams,
                    pathParams,
                    params,
                    data,
                },
            }
        );
    };
};
