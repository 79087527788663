import { Button, Popup } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, Section, Toolbar } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import React, { FC, memo, useCallback } from 'react';

import TwoFaSetupPopup from './components/TwoFaSetupPopup/TwoFaSetupPopup';
import { useStartTwoFa } from './UserSettingsPage.hooks';
import { messages } from './UserSettingsPage.messages';

const UserSettingsPage: FC = () => {
    const [isTwoFaPopupOpened, setIsTwoFaPopupOpened] = React.useState(false);

    const { account } = useCurrentAccount();

    const { fetchData } = useStartTwoFa();

    const openTwoFaPopup = useCallback(async () => {
        await fetchData();

        setIsTwoFaPopupOpened(true);
    }, [fetchData]);

    const closeTwoFaPopup = useCallback(() => {
        setIsTwoFaPopupOpened(false);
    }, []);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <Toolbar title={messages.pageTitle} />

                <Box spacing='20 60'>
                    <Section header={messages.twoFASection}>
                        {account?.isTFAEnabled ? (
                            <Button disabled theme='secondary'>
                                {messages.enabled2FAButtonText}
                            </Button>
                        ) : (
                            <Button execute={openTwoFaPopup}>{messages.enable2FAButtonText}</Button>
                        )}
                    </Section>
                </Box>
            </Box>

            <Popup isOpen={isTwoFaPopupOpened} onRequestClose={closeTwoFaPopup} disableAutoClose>
                <TwoFaSetupPopup closeTwoFaPopup={closeTwoFaPopup} />
            </Popup>
        </PageLayout>
    );
};

export default memo(UserSettingsPage);
