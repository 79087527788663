import './radioGroupEditor.scss';

import { errorHelpers } from '@approvalmax/utils';
import { FC, PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import bemFactory from 'react-bem-factory';

import { getDisabled } from '../../helpers';
import { BaseEditorProps } from '../EditorBase';
import { RadioGroupEditorContext } from './RadioGroupEditorContext';
import RadioGroupItem from './RadioGroupItem';
import RadioGroupRelatedContent from './RadioGroupRelatedContent';
import { RadioGroupTheme } from './types';

export { RadioGroupEditorContext } from './RadioGroupEditorContext';

interface RadioGroupEditorProps extends BaseEditorProps<any>, PropsWithChildren {
    theme?: RadioGroupTheme;
    className?: string;
}

const RadioGroupEditor: FC<RadioGroupEditorProps> = (props) => {
    const { className, theme = 'form', value, qa, onChange, children } = props;

    const disabled = getDisabled(props.disabled, props.command);

    const bem = bemFactory.block('form-radio-group-editor').themed(theme as any);

    if (props.focusOnMount) {
        throw errorHelpers.throwNotSupportedError();
    }

    const radioGroupRef = useRef<HTMLDivElement>(null);
    const itemValueMap = useRef<Map<string, any>>(new Map());

    const onItemCheck = useCallback(
        (value: any) => {
            if (props.value !== value) {
                onChange(value);
            }
        },
        [onChange, props.value]
    );

    const onNavigate = useCallback(
        (forward: boolean) => {
            const checkedEl = radioGroupRef.current!.querySelector('[aria-checked=true]') as HTMLElement;
            const radioEls = Array.from(radioGroupRef.current!.querySelectorAll('[role=radio]')) as HTMLElement[];
            const index = radioEls.indexOf(checkedEl);

            let nextIndex;

            if (forward) {
                nextIndex = (index + 1) % radioEls.length;
            } else {
                nextIndex = index <= 0 ? radioEls.length - 1 : index - 1;
            }

            const newRadioEl = radioEls[nextIndex];
            const newValue = itemValueMap.current.get(newRadioEl.id);

            newRadioEl.focus();
            onChange(newValue);
        },
        [onChange]
    );

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus();
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur();
        }
    };

    return (
        <div
            ref={radioGroupRef}
            role='radiogroup'
            data-qa={qa}
            className={bem.add(className)()}
            onFocus={onFocus}
            onBlur={onBlur}
        >
            <RadioGroupEditorContext.Provider
                value={useMemo(
                    () => ({
                        value,
                        disabled: disabled || false,
                        theme,
                        onCheck: onItemCheck,
                        onPrev: () => onNavigate(false),
                        onNext: () => onNavigate(true),
                        registerItem: (elId: string, itemValue: any) => {
                            if (itemValueMap.current.has(elId)) {
                                throw errorHelpers.formatError(`Radio item with id ${elId} is already registered`);
                            }

                            itemValueMap.current.set(elId, itemValue);
                        },
                        unregisterItem: (elId: string) => {
                            itemValueMap.current.delete(elId);
                        },
                    }),
                    [disabled, onItemCheck, onNavigate, theme, value]
                )}
            >
                {children}
            </RadioGroupEditorContext.Provider>
        </div>
    );
};

type RadioGroupEditorType = typeof RadioGroupEditor & {
    Item: typeof RadioGroupItem;
    RelatedContent: typeof RadioGroupRelatedContent;
};

(RadioGroupEditor as RadioGroupEditorType).Item = RadioGroupItem;
(RadioGroupEditor as RadioGroupEditorType).RelatedContent = RadioGroupRelatedContent;

export default RadioGroupEditor as RadioGroupEditorType;
