/**
 * Developer: Stepan Burguchev
 * Date: 2/25/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './textEditor.scss';

import { hooks } from '@approvalmax/utils';
import React, { forwardRef, InputHTMLAttributes, useContext, useEffect, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { FieldContext } from '../../field/Field';
import { getDisabled } from '../../helpers';
import { BaseEditorProps } from '../EditorBase';

export const ALL_TEXT_THEMES = ['form', 'transparent', 'auth'] as const;
export type TextTheme = (typeof ALL_TEXT_THEMES)[number];

export interface Props extends BaseEditorProps<string> {
    placeholder?: string;
    spellCheck?: boolean;
    maxLength?: number;
    theme?: TextTheme;
    onEnter?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    changeOnBlur?: boolean;
    onEscape?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    onUp?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    onDown?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    type?: 'text' | 'password' | 'tel' | 'url' | 'email' | 'search';
    autoComplete?: 'on' | 'off' | 'new-password';
    name?: string;
    /**
     * Transform value before passing to state and to {@link onChange}
     *
     * Useful when you need to clean up the output from unwanted symbols, for example
     * allow only numbers
     */
    transform?: (value: string) => string;
}

export const defaultTransform = (value: string) => value;

const TextEditor = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className,
        qa,
        theme = 'form',
        placeholder,
        maxLength,
        spellCheck,
        invalid,
        changeOnBlur,
        focusOnMount,
        type = 'text',
        autoComplete = 'off',
        name,
        transform = defaultTransform,
        onEnter,
        ...restProps
    } = props;
    const disabled = getDisabled(props.disabled, props.command);

    const [stateValue, setStateValue] = useState(props.value || '');
    const value = changeOnBlur ? stateValue : props.value || '';

    const bem = bemFactory.block('form-text-editor').themed(theme!);

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter(e);
        } else if (e.key === 'Escape' && props.onEscape) {
            props.onEscape(e);
        } else if ((e.key === 'ArrowUp' || e.key === 'Up') && props.onUp) {
            props.onUp(e);
        } else if ((e.key === 'ArrowDown' || e.key === 'Down') && props.onDown) {
            props.onDown(e);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const transformedValue = transform(e.target.value);

        if (changeOnBlur) {
            setStateValue(transformedValue);
        } else {
            props.onChange(transformedValue);
        }
    };

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus();
        }
    };

    const onBlur = () => {
        if (changeOnBlur) {
            props.onChange(stateValue);
        }

        if (props.onBlur) {
            props.onBlur();
        }
    };

    const { fieldId } = useContext(FieldContext);

    const [onMount, inputRef] = hooks.useForwardedRef(ref);

    hooks.useFocusOnMount(focusOnMount, inputRef);

    // Update the stateValue if props.value changes
    useEffect(() => {
        if (changeOnBlur) {
            setStateValue(props.value || '');
        }
    }, [changeOnBlur, props.value]);

    const inputProps: InputHTMLAttributes<HTMLInputElement> = {
        ...restProps,
        className: bem.add(className)(null, {
            invalid,
            disabled,
        }),
        onKeyDown,
        onChange,
        onFocus,
        onBlur,
        disabled,
        value,
        type,
        autoComplete,
        placeholder,
        maxLength,
        spellCheck: spellCheck && type !== 'password',
        ...(name && { name }),
    };

    return <input id={fieldId} ref={onMount} data-qa={qa} {...inputProps} />;
});

export default TextEditor;
