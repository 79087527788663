import { mods } from '@approvalmax/theme';
import styled from 'styled-components';

export const MessageRoot = styled.div`
    text-align: left;
`;

export const MessageTitle = styled.div<{ hasDetails: boolean }>`
    ${mods('hasDetails', true)`
        font-weight: 600;
        margin-bottom: 10px;
    `}

    ${mods('hasDetails', false)`
        font-weight: 300;
        margin-bottom: 0;
    `}
`;
