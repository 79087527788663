import type { ApiError } from '@approvalmax/types';
import { objectHelpers, routerHelpers } from '@approvalmax/utils';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApi from '../rootApi';
import rootApiLegacy from '../rootApiLegacy';
import { ApiSource, ResponseDataWithContinuationToken, UseGetInfiniteOptions } from '../types';

export const createUseGetInfinite = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <
        ResponseData extends ResponseDataWithContinuationToken,
        MappedResponseData extends ResponseDataWithContinuationToken = ResponseData,
    >(
        path: string,
        options: UseGetInfiniteOptions<ResponseData, MappedResponseData>
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            queryParams,
            pathParams,
            params,
            queryOptions,
            responseType,
            mapData,
            mapToCamelCase,
            method = 'get',
            getNextPageParam = (lastPage) => {
                if (!lastPage.continuationToken) {
                    return;
                }

                return {
                    params: {
                        query: {
                            ...params?.query,
                            continuationToken: lastPage.continuationToken,
                        },
                        path: params?.path,
                    },
                };
            },
        } = options;

        const requestParams = params || {
            path: pathParams,
            query: queryParams,
        };
        const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, requestParams.path)
            ? routerHelpers.pathToUrl(path, requestParams.path)
            : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData, mapToCamelCase);
        const apiFn = apiVersion === 'v1' ? rootApiLegacy : rootApi;
        const apiMethod = apiVersion === 'v1' && !method ? 'post' : method;
        const data = method === 'get' || !requestParams.query ? {} : requestParams.query;

        return useInfiniteQuery<MappedResponseData, ApiError>(
            [url, requestParams.query, requestParams.path].filter(Boolean),
            ({ pageParam }) =>
                handleApiCall(
                    apiFn({
                        baseURL,
                        method: apiMethod,
                        url,
                        headers: {
                            ...(Authorization && { Authorization }),
                        },
                        responseType,
                        params:
                            objectHelpers.has(pageParam, 'params') && objectHelpers.has(pageParam.params, 'query')
                                ? pageParam.params.query
                                : requestParams.query,
                        data: objectHelpers.has(pageParam, 'data') ? pageParam.data : data,
                    })
                ),
            {
                enabled: Boolean(url),
                ...queryOptions,
                getNextPageParam,
                meta: {
                    meta: queryOptions?.meta,
                    path,
                    queryParams,
                    pathParams,
                    params,
                    data,
                },
            }
        );
    };
};
