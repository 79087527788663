import { errorHelpers } from '@approvalmax/utils';
import { FC, memo, useState } from 'react';

import TwoFaSetupStep1 from './components/TwoFaSetupStep1/TwoFaSetupStep1';
import TwoFaSetupStep2 from './components/TwoFaSetupStep2/TwoFaSetupStep2';
import { TwoFaSetupPopupProps } from './TwoFaSetupPopup.types';

const TwoFaSetupPopup: FC<TwoFaSetupPopupProps> = (props) => {
    const { closeTwoFaPopup } = props;

    const [activeStep, setActiveStep] = useState<'step1ScanSetupCode' | 'step2EnterSetupCode'>('step1ScanSetupCode');

    switch (activeStep) {
        case 'step1ScanSetupCode':
            return <TwoFaSetupStep1 onNext={() => setActiveStep('step2EnterSetupCode')} />;

        case 'step2EnterSetupCode':
            return <TwoFaSetupStep2 closeTwoFaPopup={closeTwoFaPopup} />;

        default:
            throw errorHelpers.assertNever(activeStep);
    }
};

export default memo(TwoFaSetupPopup);
