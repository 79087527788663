import { useSortable } from '@dnd-kit/sortable';
import { useCallback, useMemo, useRef } from 'react';
import { useHoverDirty } from 'react-use';

import { retrieveRowId } from '../../Table.helpers';
import { checkboxSpacing } from '../../Table.styles';
import type { BaseItem, TableProps } from '../../Table.types';
import type { BodyRowProps } from './BodyRow.types';

export const useBodyRow = <Item extends BaseItem>(props: BodyRowProps<Item>) => {
    const {
        item,
        checkedItems,
        invalidItems,
        checkboxColumnCover,
        reorderable,
        getRowId,
        getIsDisabledRow,
        onCheckedItemsChange,
    } = props;

    const rowRef = useRef<HTMLTableRowElement | null>(null);
    const isHovering = useHoverDirty(rowRef);

    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id: item.id,
        data: { type: 'row' },
        disabled: !reorderable,
    });

    const rowId = useMemo(() => retrieveRowId(item, getRowId), [getRowId, item]);
    const checked = useMemo(() => checkedItems?.includes(rowId), [checkedItems, rowId]);
    const invalid = useMemo(() => invalidItems?.includes(rowId), [invalidItems, rowId]);
    const disabled = useMemo(() => getIsDisabledRow?.(item), [getIsDisabledRow, item]);

    const checkboxCoverValue = checkboxColumnCover?.value ? checkboxColumnCover.value(item) : null;

    const isCheckboxCoverVisible = checkboxCoverValue && !isHovering && !checked;
    const checkboxSpacingValue = checkboxColumnCover?.spacing || checkboxSpacing;

    const ref = useCallback(
        (node: HTMLTableRowElement | null) => {
            rowRef.current = node;
            setNodeRef(node);
        },
        [setNodeRef]
    );

    const handleItemCheck = useCheckedItems(retrieveRowId(item, getRowId), checkedItems, onCheckedItemsChange);

    return {
        ref,
        rowId,
        attributes,
        listeners,
        transform,
        transition,
        active,
        invalid,
        checked,
        disabled,
        isHovering,
        isCheckboxCoverVisible,
        checkboxCoverValue,
        checkboxSpacingValue,
        handleItemCheck,
    };
};

export const useCheckedItems = <Item extends BaseItem>(
    itemId: string,
    checkedItems: TableProps<Item>['checkedItems'],
    onCheckedItemsChange: TableProps<Item>['onCheckedItemsChange']
) => {
    const handleItemCheck = useCallback(
        (checked: boolean) => {
            if (!onCheckedItemsChange) {
                return;
            }

            const checkedSet = new Set(checkedItems);

            if (checked) {
                checkedSet.add(itemId);
                onCheckedItemsChange([...checkedSet]);
            } else {
                checkedSet.delete(itemId);
                onCheckedItemsChange([...checkedSet]);
            }
        },
        [itemId, checkedItems, onCheckedItemsChange]
    );

    return onCheckedItemsChange ? handleItemCheck : undefined;
};
