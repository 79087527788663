import { DataTable, LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { dataTableColumns } from './DeletedParticipantsList.config';
import { useDeletedParticipants } from './DeletedParticipantsList.hooks';
import { messages } from './DeletedParticipantsList.messages';
import { DeletedParticipantsListProps } from './DeletedParticipantsList.types';

const DeletedParticipantsList: FC<DeletedParticipantsListProps> = memo((props) => {
    const { companyId } = props;

    const { isInitialLoading, data } = useDeletedParticipants(companyId);

    if (isInitialLoading) {
        return (
            <Box spacing='20 0'>
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <Box spacing='20 0'>
            {data && data.length > 0 ? <DataTable data={data} columns={dataTableColumns} /> : messages.noData}
        </Box>
    );
});

export default DeletedParticipantsList;
