import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.UserDetails.Manage2FAPopup', {
    submitButton: 'Disable 2FA',
    comment: 'Comment',
    ticketNumber: 'Ticket Number',
    successMessage: '2FA disabled',
    commentFieldError: 'Comment should be at least 10 characters',
    ticketNumberError: 'Ticket Number should be at least 3 characters, only digits are allowed',
});
