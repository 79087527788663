import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useMoveStorageQueuePoisonMessages = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutateAsync: movePoisonMessages } = useMutation(
        (queueId: string) => api.infra.moveStorageQueuePoisonMessages(queueId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.StorageQueues]);
            },
        }
    );

    return {
        movePoisonMessages,
        isLoading,
    };
};
