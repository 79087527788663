import { atom } from 'recoil';

export const leftStickyShadowTableState = atom({
    key: 'leftStickyShadowTableState',
    default: false,
});

export const rightStickyShadowTableState = atom({
    key: 'rightStickyShadowTableState',
    default: false,
});
