import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayConsentsParams,
    UseAmaxPayConsentsPathParams,
    UseAmaxPayConsentsResponse,
} from './useAmaxPayConsents.types';

export const useGetAmaxPayConsents = (
    pathParams: QueryParams<UseAmaxPayConsentsPathParams>,
    params: UseAmaxPayConsentsParams,
    queryOptions?: QueryOptions<UseAmaxPayConsentsResponse>
) => {
    return useGet<UseAmaxPayConsentsResponse>(amaxPayApiPaths.openBankingConsents, {
        params: {
            path: pathParams,
            query: params,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
