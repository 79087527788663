import { MutableRefObject } from 'react';

export const setRef = <T>(
    ref: MutableRefObject<T | null> | ((instance: T | null) => void) | null | undefined,
    value: T | null
): void => {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
};
