import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.companyDetails.ForceMigrationPopup', {
    popupTitle: 'Force Matching Migration',
    submitButton: 'Submit',
    amountTypeLabel: 'Amount type',
    gross: 'Gross',
    net: 'Net',
    successMessage: 'Matching migration forced',
    defaultErrorMessage: 'Oops, something went wrong',
});
