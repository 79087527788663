import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useClearPoisonStorageQueue = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutateAsync: clearPoisonStorageQueue } = useMutation(
        (queueId: string) => api.infra.clearStorageQueue(queueId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.StorageQueues]);
            },
        }
    );

    return {
        clearPoisonStorageQueue,
        isLoading,
    };
};
