/**
 * Developer: Stepan Burguchev
 * Date: 4/10/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './loadingSpinner.scss';

import { FC, PropsWithChildren } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('ui-loading-spinner');

export const ALL_LOADING_SPINNER_COLORS = ['green', 'white'] as const;
export type LoadingSpinnerColor = (typeof ALL_LOADING_SPINNER_COLORS)[number];

export const ALL_LOADING_SPINNER_SIZES = ['normal28', 'small12'] as const;
export type LoadingSpinnerSize = (typeof ALL_LOADING_SPINNER_SIZES)[number];

export interface LoadingSpinnerProps extends PropsWithChildren {
    color?: LoadingSpinnerColor;
    size?: LoadingSpinnerSize;
    className?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
    const { children, color, size, className } = props;

    const renderSpinner = (layerModifier: string) => (
        <div className={bem('spinner-layer', layerModifier)}>
            <div className={bem('circle-clipper')}>
                <div className={bem('circle', 'left')} />
            </div>

            <div className={bem('gap-patch')}>
                <div className={bem('circle', 'gap-patch')} />
            </div>

            <div className={bem('circle-clipper')}>
                <div className={bem('circle', 'right')} />
            </div>
        </div>
    );

    return (
        <div
            className={bem.add(className)(null, {
                'theme-white': color === 'white',
                'size-small12': size === 'small12',
            })}
        >
            <div className={bem('spinner')}>
                <div className={bem('spinner-container')}>
                    {renderSpinner('layer-1')}

                    {renderSpinner('layer-2')}

                    {renderSpinner('layer-3')}

                    {renderSpinner('layer-4')}
                </div>
            </div>

            {children && <div className={bem('text')}>{children}</div>}
        </div>
    );
};

export default LoadingSpinner;
