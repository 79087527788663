import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api } from 'modules/api';
import { toaster } from 'modules/components';
import { SortConfig } from 'modules/types';

import { messages } from './useFindUsers.messages';

export const useFindUsers = (sortConfig?: SortConfig) => {
    const {
        isLoading,
        mutate: findUsers,
        data: users,
    } = useMutation(
        (options: { email?: string; userId?: string }) =>
            options.email
                ? api.internalSupport.findUsers({
                      userEmailQuery: options.email,
                      sortingBy: sortConfig?.columnId,
                      sortingOrder: sortConfig?.sortOrder,
                  })
                : api.internalSupport.getUserDetails(options.userId!).then((data) => [data.user]),
        {
            onSuccess(data) {
                if (!data || (Array.isArray(data) && data.length === 0)) {
                    toaster.error(messages.searchError);
                }
            },
            onError: (error: AxiosError<{ errors: any }>) => {
                const data = error.response?.data;

                if (data?.errors) {
                    toaster.error(messages.searchError);

                    return;
                }
            },
        }
    );

    return {
        findUsers,
        isLoading,
        users,
    };
};
