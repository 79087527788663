import { TenantCurrentMovementStep } from 'modules/api';

export const WARNING_ROWS_COUNT_LEVEL_1 = 100000;
export const WARNING_ROWS_COUNT_LEVEL_2 = 1000000;

export const STEP_ORDERS: Record<TenantCurrentMovementStep, number> = {
    [TenantCurrentMovementStep.PreChecks]: 0,
    [TenantCurrentMovementStep.PreCopyAnalysis]: 1,
    [TenantCurrentMovementStep.SwitchTenantOffline]: 2,
    [TenantCurrentMovementStep.CopyTables]: 3,
    [TenantCurrentMovementStep.PostCopyChecks]: 4,
    [TenantCurrentMovementStep.CleanupTables]: 5,
    [TenantCurrentMovementStep.SwitchTenantOnline]: 6,
    [TenantCurrentMovementStep.Finalize]: 7,
};
