import { Accordion, Button, DropdownEditor, LoadingSpinner, Popup } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { ConfirmationPopup, JsonViewer, PlainDataProvider, Section, Toolbar } from 'modules/components';
import { useAvailableCompanyBetaFeatures } from 'modules/customerSupport/hooks/useAvailableCompanyBetaFeatures';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { IntegrationType } from 'modules/types';
import { FC, memo, useMemo } from 'react';

import { EnforceFraudDetectionPopup } from '..';
import DeletedParticipantsList from '../DeletedParticipantsList/DeletedParticipantsList';
import ForceMigrationPopup from '../ForceMigrationPopup/ForceMigrationPopup';
import { NetSuiteSyncAndDeleteCachePopup } from '../NetSuiteSyncAndDeleteCachePopup/NetSuiteSyncAndDeleteCachePopup';
import { NetSuiteSyncCacheStatusPopup } from '../NetSuiteSyncCacheStatusPopup/NetSuiteSyncCacheStatusPopup';
import { getFeatureOptions } from './CompanyDataTab.helpers';
import {
    useBetaFeatures,
    useCompanyDetails,
    useEnforceFraudDetectionPopup,
    useForceMigrationPopup,
    useNetSuiteSyncAndDeleteCachePopup,
    useNetSuiteSyncCacheStatusPopup,
} from './CompanyDataTab.hooks';
import { messages } from './CompanyDataTab.messages';
import {
    BetaFeaturesPanel,
    ButtonRow,
    ConfirmationPopupHeader,
    ConfirmationPopupList,
    Root,
} from './CompanyDataTab.styles';
import { CompanyDataTabProps } from './CompanyDataTab.types';

const CompanyDataTab: FC<CompanyDataTabProps> = memo((props) => {
    const { companyId } = props;

    const { companyDetails } = useCompanyDetails(companyId);
    const { availableCompanyBetaFeatures } = useAvailableCompanyBetaFeatures();
    const { permissionFlags } = useCurrentAccount();

    usePermissionsRedirect((permissionFlags) => permissionFlags.companies.read);

    const { areBetaFeaturesSaving, betaFeatures, removedBetaFeatures, onBetaFeaturesChange, onSaveBetaFeatures } =
        useBetaFeatures(companyDetails);

    const {
        isEnforceFraudDetectionPopupOpened,
        onEnforceFraudDetectionPopupOpen,
        onEnforceFraudDetectionPopupClose,
        hasEnforceFraudDetectionPermission,
    } = useEnforceFraudDetectionPopup();

    const {
        isForceMigrationPopupOpened,
        onForceMigrationPopupClose,
        onForceMigrationPopupOpen,
        hasForceMigrationPermission,
    } = useForceMigrationPopup();

    const {
        isNetSuiteSyncCacheStatusPopupOpened,
        onNetSuiteSyncCacheStatusPopupOpen,
        onNetSuiteSyncCacheStatussPopupClose,
    } = useNetSuiteSyncCacheStatusPopup();

    const {
        isNetSuiteSyncAndDeleteCachePopupOpened,
        onDeleteCache,
        onSyncCache,
        onNetSuiteSyncAndDeleteCachePopupClose,
        netSuiteCacheAction,
    } = useNetSuiteSyncAndDeleteCachePopup();

    const confirmationPopupText = useMemo(
        () => (
            <>
                <ConfirmationPopupHeader>{messages.confirmationPopupHeader}</ConfirmationPopupHeader>

                <ConfirmationPopupList>{removedBetaFeatures.join(', ')}</ConfirmationPopupList>

                <div>{messages.confirmationPopupText}</div>
            </>
        ),
        [removedBetaFeatures]
    );

    const isNetSuite = companyDetails?.companyRawData.integrations[0]?.integrationType === IntegrationType.NetSuite;

    return (
        <>
            {!availableCompanyBetaFeatures || !companyDetails ? (
                <Box spacing='60'>
                    <LoadingSpinner />
                </Box>
            ) : (
                <>
                    <Toolbar title={companyDetails.company.text} />

                    <Root>
                        <Box spacing='20 60'>
                            <Section header={messages.betaFeatures}>
                                <BetaFeaturesPanel>
                                    <PlainDataProvider items={getFeatureOptions(availableCompanyBetaFeatures)}>
                                        <DropdownEditor
                                            disabled={
                                                areBetaFeaturesSaving ||
                                                !permissionFlags ||
                                                !permissionFlags.companies.manageBetaFeatures
                                            }
                                            value={getFeatureOptions(betaFeatures)}
                                            onChange={onBetaFeaturesChange}
                                            placeholder={messages.betaFeaturesPlaceholder}
                                            multiple
                                        />
                                    </PlainDataProvider>

                                    <ConfirmationPopup
                                        execute={onSaveBetaFeatures}
                                        confirmationText={confirmationPopupText}
                                        executeButtonText={messages.saveBetaFeaturesButtonText}
                                        cancelButtonColorTheme='red'
                                        executeButtonColorTheme='forest-green'
                                    >
                                        {({ executeWithConfirmation }) => (
                                            <Button
                                                execute={
                                                    removedBetaFeatures.length > 0
                                                        ? executeWithConfirmation
                                                        : onSaveBetaFeatures
                                                }
                                                disabled={
                                                    areBetaFeaturesSaving ||
                                                    !permissionFlags ||
                                                    !permissionFlags.companies.manageBetaFeatures
                                                }
                                            >
                                                {messages.saveBetaFeatures}
                                            </Button>
                                        )}
                                    </ConfirmationPopup>
                                </BetaFeaturesPanel>
                            </Section>

                            <ButtonRow>
                                <Button
                                    execute={onEnforceFraudDetectionPopupOpen}
                                    disabled={!hasEnforceFraudDetectionPermission}
                                    preset='compact'
                                >
                                    {messages.enforceFraudDetection}
                                </Button>

                                <Button
                                    execute={onForceMigrationPopupOpen}
                                    disabled={!hasForceMigrationPermission}
                                    preset='compact'
                                >
                                    {messages.forceMigration}
                                </Button>

                                {isNetSuite && (
                                    <>
                                        <Button execute={onNetSuiteSyncCacheStatusPopupOpen} preset='compact'>
                                            {messages.netSuiteCacheStatus}
                                        </Button>

                                        <Button execute={onSyncCache} preset='compact'>
                                            {messages.syncNetSuiteCache}
                                        </Button>

                                        <Button execute={onDeleteCache} preset='compact'>
                                            {messages.deleteNetSuiteCache}
                                        </Button>
                                    </>
                                )}
                            </ButtonRow>

                            <Section header={messages.rawDataHeader}>
                                <JsonViewer
                                    json={{ ...companyDetails.companyRawData, ...companyDetails.licenseAddonInfo }}
                                />
                            </Section>

                            <Box spacing='0 0 20'>
                                <Accordion title={messages.offboardedUsersTitle}>
                                    <DeletedParticipantsList companyId={companyId} />
                                </Accordion>
                            </Box>
                        </Box>
                    </Root>
                </>
            )}

            <Popup isOpen={isEnforceFraudDetectionPopupOpened} onRequestClose={onEnforceFraudDetectionPopupClose}>
                <EnforceFraudDetectionPopup companyId={companyDetails?.company.id ?? ''} />
            </Popup>

            <Popup isOpen={isForceMigrationPopupOpened} onRequestClose={onForceMigrationPopupClose}>
                <ForceMigrationPopup companyId={companyId} onClose={onForceMigrationPopupClose} />
            </Popup>

            <Popup isOpen={isNetSuiteSyncCacheStatusPopupOpened} onRequestClose={onNetSuiteSyncCacheStatussPopupClose}>
                <NetSuiteSyncCacheStatusPopup companyId={companyId} />
            </Popup>

            <Popup
                isOpen={isNetSuiteSyncAndDeleteCachePopupOpened}
                onRequestClose={onNetSuiteSyncAndDeleteCachePopupClose}
            >
                <NetSuiteSyncAndDeleteCachePopup
                    companyId={companyId}
                    onClose={onNetSuiteSyncAndDeleteCachePopupClose}
                    action={netSuiteCacheAction}
                />
            </Popup>
        </>
    );
});

CompanyDataTab.displayName = 'CompanyDataTab';

export default CompanyDataTab;
