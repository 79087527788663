import { ExternalLink, TextButton } from '@approvalmax/ui';
import { getConfig } from 'config';
import { useStartTwoFa } from 'pages/userSettings/UserSettingsPage.hooks';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';

import { messages } from './TwoFaSetupStep1.messages';
import {
    Content,
    Description,
    NextButton,
    QRBlock,
    Root,
    SetupCodeButtonBlock,
    SetupCodeInput,
    SetupCodeInputBlock,
} from './TwoFaSetupStep1.styles';

interface TwoFaSetupStep1Props {
    onNext: () => void;
}

const TwoFaSetupStep1: FC<TwoFaSetupStep1Props> = (props) => {
    const { onNext } = props;

    const { data } = useStartTwoFa();

    const stateId = data?.stateId ?? '';
    const setupCode = data?.secretKey ?? '';

    const [isCodeVisible, setIsCodeVisible] = useState(false);

    const codeInputEl = useRef<HTMLInputElement>(null);

    const showCode = useCallback(() => {
        setIsCodeVisible(true);
    }, []);

    const selectInput = useCallback(() => {
        if (codeInputEl.current) {
            codeInputEl.current.select();
        }
    }, [codeInputEl]);

    const copyInputValue = useCallback(() => {
        if (codeInputEl.current) {
            codeInputEl.current.select();
            document.execCommand('copy');
        }
    }, [codeInputEl]);

    // Transform '1234567890' into '1234 5678 90'
    const formattedSetupCode = useMemo(
        () =>
            setupCode
                .replace(/\s+/gi, '')
                .split('')
                .reduce<string[]>((m, c, i, arr) => {
                    m.push(c);

                    if ((i + 1) % 4 === 0 && i < arr.length - 1) {
                        m.push(' ');
                    }

                    return m;
                }, [])
                .join(''),
        [setupCode]
    );

    return (
        <Root title={messages.title}>
            <Content>
                <Description>
                    {messages.description({
                        br: <br />,
                        supportlink: (chunks: any) => (
                            <ExternalLink href='https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-2fa'>
                                {chunks}
                            </ExternalLink>
                        ),
                    })}
                </Description>

                <QRBlock>
                    <img src={getConfig().apiBaseUrl + `/v1/accounts/me/tfa/qrCodeImage?stateId=${stateId}`} />
                </QRBlock>

                {!isCodeVisible ? (
                    <SetupCodeButtonBlock>
                        <TextButton execute={showCode}>{messages.enterManuallyButton}</TextButton>
                    </SetupCodeButtonBlock>
                ) : (
                    <SetupCodeInputBlock>
                        <SetupCodeInput ref={codeInputEl} value={formattedSetupCode} readOnly onClick={selectInput} />

                        <TextButton upperCased={false} execute={copyInputValue}>
                            {messages.copyCodeButton}
                        </TextButton>
                    </SetupCodeInputBlock>
                )}

                <NextButton execute={onNext}>{messages.nextButton}</NextButton>
            </Content>
        </Root>
    );
};

export default memo(TwoFaSetupStep1);
