import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { stickyColumnZIndex } from '../../Table.constants';
import { stickyCellBorder, stickyShadow, verticalAlign } from '../../Table.mixins';
import { BaseItem, ColumnDefinition } from '../../Table.types';

export const StyledCell = styled.td<
    StyledMods<
        Pick<ColumnDefinition<BaseItem>, 'sticky' | 'textAlign' | 'verticalAlign'> &
            Mods<'bordered'> & { spacing?: string; withStickyShadow?: boolean }
    >
>`
    padding: ${({ $spacing }) => $spacing};

    ${mods('sticky', 'left')`
        position: sticky;
        left: -1px; // -1 for Intersection Observer
        z-index: ${stickyColumnZIndex};
        background: ${theme.color.white100};

        ${mods.bordered(stickyCellBorder)};
        ${mods('withStickyShadow', true)`
            ${stickyShadow(false)}
        `};
    `}

    ${mods('sticky', 'right')`
        position: sticky;
        right: -1px; // -1 for Intersection Observer
        z-index: ${stickyColumnZIndex};
        background: ${theme.color.white100};

        ${mods.bordered(stickyCellBorder)};
        ${mods('withStickyShadow', true)`
            ${stickyShadow(true)}
        `};
    `}

    ${mods.textAlign(mixins.textAlign)};

    ${mods('verticalAlign')(verticalAlign)};
`;
