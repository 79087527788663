import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.PermissionsPage', {
    pageTitle: 'Users & Permissions',
    breadcrumbRoot: 'Admin portal Management',
    createUserButton: 'Add user',
    emailCol: 'Email',
    permissionsCol: 'Permissions',
    editMenuItem: 'Edit',
    deleteMenuItem: 'Delete',
    twoFaCol: '2FA',
    twoFaEnabled: 'Enabled',
    twoFaDisabled: 'Disabled',
});
