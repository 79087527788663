import { getRandomNumber } from './getRandomNumber';
import { isNumber } from './isNumber';
import { parseNumber } from './parseNumber';
import { round } from './round';
import { toString } from './toString';

export const numberHelpers = {
    isNumber,
    parseNumber,
    round,
    getRandomNumber,
    toString,
};
