import styled from 'styled-components';

export const DeleteRestorePanel = styled.div`
    display: flex;
    align-items: center;

    & > :first-child {
        flex: 1;
        margin-right: 20px;
    }
`;
