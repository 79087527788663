import { useMutation } from '@tanstack/react-query';
import { api } from 'modules/api';

export const useExecuteSqlQuery = () => {
    const {
        isLoading,
        mutate: executeSqlQuery,
        data: queryResult,
    } = useMutation((options: { sqlQueryText: string; twoFaCode: string }) =>
        api.infra.executeSqlQuery({ sqlQueryText: options.sqlQueryText }, options.twoFaCode)
    );

    return {
        executeSqlQuery,
        queryResult,
        isLoading,
    };
};
