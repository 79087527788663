import { Popup, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { SearchBigIcon } from '@approvalmax/ui/src/old/icons';
import { hooks } from '@approvalmax/utils';
import { RequestFindData } from 'modules/api';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, SearchBar, Toolbar } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { BulkOperation, BulkOperationPopup } from './components';
import { dataTableColumns } from './RequestsPage.config';
import { useFindRequests, useQueryParams } from './RequestsPage.hooks';
import { messages } from './RequestsPage.messages';
import {
    BulkOperationsLabel,
    SearchTip,
    SearchTipImage,
    SearchTipTitle,
    StyledBulkActionButton,
    Table,
} from './RequestsPage.styles';
import searchTipUrl from './resources/search-tip.png';

const RequestsPage: FC = () => {
    const [bulkOperationPopup, setBulkOperationPopup] = useState<undefined | BulkOperation>();
    const { findRequests, isLoading, requests } = useFindRequests();
    const history = useHistory();
    const { permissionFlags } = useCurrentAccount();
    const { query, push } = useQueryParams();
    const [searchText, setSearchText] = useState(query);

    usePermissionsRedirect((permissionFlags) => permissionFlags.requests.any);

    hooks.useOnMount(() => {
        if (query) {
            onSearch();
        }
    });

    const onSearch = useCallback(() => {
        if (!searchText) {
            return;
        }

        findRequests({
            requestId: searchText,
        });
        push({
            query: searchText,
        });
    }, [findRequests, push, searchText]);

    const onRowClick = useCallback(
        (request: RequestFindData) => {
            history.push(getPath('requestDetail', request.companyId, request.id));
        },
        [history]
    );

    const filters = (
        <SearchBar
            placeholder={messages.filterPlaceholder}
            disabled={isLoading || !permissionFlags || !permissionFlags.requests.read}
            onSearch={onSearch}
            value={searchText}
            onChange={setSearchText}
            focusOnMount
        />
    );

    const actionButtons = (
        <>
            <BulkOperationsLabel>{messages.bulkOperationsTitle}</BulkOperationsLabel>

            <StyledBulkActionButton
                execute={() => setBulkOperationPopup(BulkOperation.delete)}
                disabled={!permissionFlags?.requests.delete}
                colorTheme='red'
                preset='compact'
            >
                {messages.bulkDeleteButton}
            </StyledBulkActionButton>

            <StyledBulkActionButton
                execute={() => setBulkOperationPopup(BulkOperation.restore)}
                disabled={!permissionFlags?.requests.restore}
                colorTheme='brand'
                preset='compact'
            >
                {messages.bulkRestoreButton}
            </StyledBulkActionButton>
        </>
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar filters={filters} actionButtons={actionButtons} />

                    <Table
                        fullHeight={requests && requests.length > 0}
                        data={requests ?? []}
                        columns={dataTableColumns}
                        onRowClick={onRowClick}
                    />

                    {!requests && (
                        <SearchTip>
                            <SearchBigIcon color='midnight100' />

                            <SearchTipTitle>{messages.searchTip}</SearchTipTitle>

                            <SearchTipImage src={searchTipUrl} />
                        </SearchTip>
                    )}
                </ScrollableAreaWrapper>
            </Box>

            <Popup isOpen={!!bulkOperationPopup} onRequestClose={() => setBulkOperationPopup(undefined)}>
                <BulkOperationPopup operation={bulkOperationPopup!} />
            </Popup>
        </PageLayout>
    );
};

export default memo(RequestsPage);
