import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useStorageQueuePoisonMessages = (queueId: string) => {
    const { isInitialLoading, data: messages } = useQuery(
        [QueryKeys.StorageQueues, queueId, QueryKeys.StorageQueueMessages, 'poison'],
        () => api.infra.getStorageQueuePoisonMessages(queueId),
        {}
    );

    return {
        messages,
        isInitialLoading,
    };
};
