import Cookies from 'js-cookie';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { ThemeProvider as ThemeProviderSC } from 'styled-components';

import { ThemeTypes } from './types';
import { variables, variablesLightTheme } from './variablels';

const themeCookieName = 'theme';

const getCookieOptions = () => {
    const published = window.location.hostname.includes('approvalmax.com');

    return {
        domain: published ? window.location.hostname : undefined,
        expires: 365,
        path: '/',
    };
};

export const ThemeContext = createContext({
    theme: variablesLightTheme,
    isLightTheme: true,
    toggleTheme: () => {
        return;
    },
});

const useSetupThemeContext = () => {
    const themeFromCookie = Cookies.get(themeCookieName) === ThemeTypes.dark ? ThemeTypes.dark : ThemeTypes.light;
    const [themeType, setThemeType] = useState(themeFromCookie);

    const theme = useMemo(
        () => ({
            ...variables,
            ...variables.theme[themeType],
            theme: {
                ...variables.theme,
            },
        }),
        [themeType]
    );

    const isLightTheme = useMemo(() => themeType === ThemeTypes.light, [themeType]);

    const toggleTheme = useCallback(() => {
        const themeTypeToSet = isLightTheme ? ThemeTypes.dark : ThemeTypes.light;

        setThemeType(themeTypeToSet);
        Cookies.set(themeCookieName, themeTypeToSet, getCookieOptions());
    }, [isLightTheme]);

    return {
        theme,
        toggleTheme,
        isLightTheme,
    };
};

export const ThemeProvider: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const setupThemeContext = useSetupThemeContext();

    return (
        // I set the type of any, because depending on the chosen theme, the type of theme changes
        <ThemeContext.Provider value={setupThemeContext as any}>
            <ThemeContext.Consumer>
                {({ theme }) => <ThemeProviderSC theme={theme}>{children}</ThemeProviderSC>}
            </ThemeContext.Consumer>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
