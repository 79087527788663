/**
 * @function
 * @param {String} a Argument A.
 * @param {String} b Argument B.
 * @return {Number} Result as follows:
 * <ul>
 *     <li><code>-1</code> - if A &lt; B.</li>
 *     <li><code>0</code> - if A == B.</li>
 *     <li><code>1</code> - if A &gt; B.</li>
 * </ul>
 * */
export const stringComparator2Asc = (a: string | null | undefined, b: string | null | undefined) => {
    if (a) {
        if (b) {
            return a.localeCompare(b);
        }

        return -1;
    } else if (b) {
        return 1;
    }

    return 0;
};
