import { useCallback, useEffect } from 'react';

export const useLocalStorageSave = (props: {
    localStorageKey: string | undefined;
    setCollapsedState: (state: boolean) => void;
    collapsed?: boolean;
}) => {
    const { localStorageKey, setCollapsedState, collapsed } = props;

    const idName = `${localStorageKey}Collapse`;

    useEffect(() => {
        if (!localStorageKey) return;

        const state = localStorage.getItem(idName);

        if (state !== null) {
            setCollapsedState(state === 'true');
        }
    }, [localStorageKey, idName, setCollapsedState]);

    useEffect(() => {
        if (localStorageKey && idName && collapsed !== undefined) {
            localStorage.setItem(idName, String(collapsed));
        }
    }, [collapsed, localStorageKey, idName]);

    const saveToLocalStorage = useCallback(
        (collapsedState: boolean) => {
            if (localStorageKey) {
                localStorage.setItem(idName, String(collapsedState));
            }
        },
        [localStorageKey, idName]
    );

    return {
        saveToLocalStorage,
    };
};
