/**
 * Developer: Stepan Burguchev
 * Date: 3/4/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './defaultPopupContentButton.scss';

import { FC } from 'react';
import bemFactory from 'react-bem-factory';

import TransparentButton from '../../../button/TransparentButton';
import { BaseButtonProps } from '../../../button/types';

const bem = bemFactory.block('ui-default-popup-content-button');

const DefaultPopupContentButton: FC<BaseButtonProps> = (props) => {
    const { children, className, ...buttonProps } = props;

    return (
        <TransparentButton {...buttonProps}>
            {({ elementProps }) => (
                <button {...elementProps} className={bem.add(className)()}>
                    {children}
                </button>
            )}
        </TransparentButton>
    );
};

export default DefaultPopupContentButton;
