import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const ContentUnknown = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentUnknownCard = styled.div`
    max-width: 450px;
    padding: 40px 60px;
    background: #fff;
    display: flex;
    flex-flow: column;
`;

export const ContentUnknownCardTitle = styled.div`
    margin-bottom: 20px;
    text-align: center;
    ${font(18, '#333', 'semibold')}
`;

export const ContentUnknownCardDescription = styled.div`
    margin-bottom: 20px;
    text-align: center;
    ${font(13, '#777')}
`;

export const ContentUnknownCardDownloadLink = styled.a`
    align-self: center;
    text-decoration: none;

    &:link,
    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
    }
`;
