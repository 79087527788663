import { Button, LoadingBar, Popup, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, openSplitViewState, SplitView } from '@approvalmax/ui/src/components';
import { getConfig } from 'config';
import { JsonViewer, NavBarMode, PageLayout, Section, Toolbar } from 'modules/components';
import DocumentPreviewPanel from 'modules/components/DocumentPreviewPanel/DocumentPreviewPanel';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setRecoil } from 'recoil-nexus';
import { getPath } from 'urlBuilder';

import { DeleteRestoreRequestPopupContent } from './components';
import AttachmentItem from './components/AttachmentItem/AttachmentItem';
import { DeleteRestoreRequestMode } from './components/DeleteRestoreRequestPopupContent/DeleteRestoreRequestPopupContent.types';
import { OcrAttachmentSection } from './components/OcrAttachmentSection/OcrAttachmentSection';
import { RequestDetailsVersions } from './components/RequestDetailsVersions/RequestDetailsVersions';
import { usePathParams } from './hooks/usePathParams';
import { useRequestDetails } from './hooks/useRequestDetails';
import { checkHasAttachments, getAttachmentFileType, isAttachmentItem } from './RequestDetailsPage.helpers';
import { messages } from './RequestDetailsPage.messages';
import { DeleteRestorePanel } from './RequestDetailsPage.styles';
import { Attachment } from './RequestDetailsPage.types';

const RequestDetailsPage: FC = () => {
    const [isDeleteRestoreRequestPopupOpen, setIsDeleteRestoreRequestPopupOpen] = useState(false);

    const [activeAttachment, setActiveAttachment] = useState<Attachment | null>(null);

    const history = useHistory();
    const { companyId, requestId } = usePathParams();
    const { requestDetails } = useRequestDetails(companyId, requestId);
    const { permissionFlags } = useCurrentAccount();

    usePermissionsRedirect((permissionFlags) => permissionFlags.requests.read);

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('requests'));
        }
    }, [history]);

    const closeDeleteRestoreRequestPopup = useCallback(() => setIsDeleteRestoreRequestPopupOpen(false), []);

    const onOpenAttachment = useCallback((attachment: Attachment) => {
        setActiveAttachment(attachment);

        setRecoil(openSplitViewState, true);
    }, []);

    const extendedAttachment = useMemo(() => {
        if (activeAttachment) {
            return {
                id: activeAttachment.attachmentId,
                url: `${getConfig().apiBaseUrl}/v1/support/companies/${companyId}/requests/attachments/${
                    activeAttachment.attachmentId
                }`,
                name: activeAttachment.name,
                fileType: getAttachmentFileType(activeAttachment.name),
            };
        }

        return null;
    }, [activeAttachment, companyId]);

    const hasOcrAttachment = requestDetails?.rawData.attachments.some((attachment) => attachment.ocrStatus);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <SplitView>
                <SplitView.Item title={extendedAttachment?.name} showInFullScreen>
                    {extendedAttachment ? <DocumentPreviewPanel attachment={extendedAttachment} /> : null}
                </SplitView.Item>

                <SplitView.Item renderByDefault>
                    <Box color='white100' shadow='xxsmall'>
                        {!requestDetails ? (
                            <div>
                                <LoadingBar />
                            </div>
                        ) : (
                            <ScrollableAreaWrapper>
                                <Toolbar title={requestDetails.name} />

                                <ScrollableArea css='flex: 1; overflow: hidden;'>
                                    <Box spacing='20 60'>
                                        <Section header={messages.deleteSection}>
                                            <DeleteRestorePanel>
                                                <div>
                                                    {requestDetails.isDeleted
                                                        ? messages.deletedTitle({
                                                              b: (v: any) => <b>{v}</b>,
                                                          })
                                                        : messages.activeTitle({
                                                              b: (v: any) => <b>{v}</b>,
                                                          })}
                                                </div>

                                                <Button
                                                    execute={() => setIsDeleteRestoreRequestPopupOpen(true)}
                                                    colorTheme={requestDetails.isDeleted ? 'brand' : 'red'}
                                                    disabled={
                                                        (!requestDetails.isDeleted &&
                                                            !permissionFlags?.requests.delete) ||
                                                        (requestDetails.isDeleted && !permissionFlags?.requests.restore)
                                                    }
                                                >
                                                    {!requestDetails.isDeleted
                                                        ? messages.deleteButton
                                                        : messages.restoreButton}
                                                </Button>
                                            </DeleteRestorePanel>
                                        </Section>

                                        <Section header={messages.rawDataHeader}>
                                            <JsonViewer json={requestDetails.rawData} />
                                        </Section>

                                        {permissionFlags?.requests.readAttachments &&
                                            checkHasAttachments(requestDetails) && (
                                                <Section header={messages.filesSection}>
                                                    {Array.isArray(requestDetails.rawData.attachments)
                                                        ? requestDetails.rawData.attachments.map((attachment) => {
                                                              if (isAttachmentItem(attachment)) {
                                                                  return (
                                                                      <AttachmentItem
                                                                          key={attachment.attachmentId}
                                                                          attachment={attachment}
                                                                          onOpen={onOpenAttachment}
                                                                      />
                                                                  );
                                                              }

                                                              return null;
                                                          })
                                                        : null}
                                                </Section>
                                            )}

                                        {permissionFlags?.requests.readAttachments && hasOcrAttachment && (
                                            <OcrAttachmentSection requestDetails={requestDetails} />
                                        )}

                                        <RequestDetailsVersions
                                            companyId={companyId}
                                            requestId={requestId}
                                            requestOrigin={requestDetails.rawData.origin}
                                            integrationName={requestDetails.rawData.integrationFriendlyName}
                                        />
                                    </Box>
                                </ScrollableArea>

                                <Popup
                                    isOpen={isDeleteRestoreRequestPopupOpen}
                                    onRequestClose={closeDeleteRestoreRequestPopup}
                                >
                                    <DeleteRestoreRequestPopupContent
                                        companyId={companyId}
                                        requests={[requestDetails.id]}
                                        mode={
                                            requestDetails.isDeleted
                                                ? DeleteRestoreRequestMode.restore
                                                : DeleteRestoreRequestMode.delete
                                        }
                                    />
                                </Popup>
                            </ScrollableAreaWrapper>
                        )}
                    </Box>
                </SplitView.Item>
            </SplitView>
        </PageLayout>
    );
};

export default memo(RequestDetailsPage);
