export const defaultTransform = (value: string) => value;

export const calculateLinesByScrollHeight = (args: {
    height: number;
    paddingTop: number;
    paddingBottom: number;
    lineHeight: number;
}) => {
    const { height, lineHeight } = args;
    const paddingTop = Number.isNaN(args.paddingTop) ? 0 : args.paddingTop;
    const paddingBottom = Number.isNaN(args.paddingBottom) ? 0 : args.paddingBottom;

    return (height - paddingTop - paddingBottom) / lineHeight;
};
