/**
 * Developer: Stepan Burguchev
 * Date: 4/1/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './rawAvatar.scss';

import { forwardRef } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('ui-raw-avatar');

interface Props {
    userAvatarUrl?: string;
    userAbbreviation: string;
    size: number;
    className?: string;
    title?: string | null;
}

const RawAvatar = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { className, title, size, userAbbreviation, userAvatarUrl } = props;

    const abbreviation = userAvatarUrl ? null : userAbbreviation;

    let styles: any = {
        width: `${size}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        fontSize: `${Math.floor(size / 2.2)}px`,
        minWidth: `${size}px`,
        maxWidth: `${size}px`,
    };

    if (userAvatarUrl) {
        styles.backgroundImage = `url(${userAvatarUrl})`;
    }

    return (
        <div
            ref={ref}
            className={bem.add(className)(null, { abbreviation, image: Boolean(userAvatarUrl) })}
            style={styles}
            title={title || undefined}
        >
            {abbreviation}
        </div>
    );
});

export default RawAvatar;
