import './radioGroupRelatedContent.scss';

import { FC, PropsWithChildren, useContext } from 'react';
import bemFactory from 'react-bem-factory';

import { RadioGroupEditorContext } from './RadioGroupEditorContext';

interface RadioGroupRelatedContentProps extends PropsWithChildren {
    className?: string;
}

const RadioGroupRelatedContent: FC<RadioGroupRelatedContentProps> = (props) => {
    const { className, children } = props;

    const { theme } = useContext(RadioGroupEditorContext);

    const bem = bemFactory.block('form-radio-group-related-content').themed(theme);

    return <div className={bem.add(className)()}>{children}</div>;
};

export default RadioGroupRelatedContent;
