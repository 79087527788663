import { createError } from '../error/createError';

export const invariant = (condition: any, message: string) => {
    if (condition) {
        return;
    }

    if (process.env.DEBUG) {
        // Friendly debugging: invariant violation!
        // eslint-disable-next-line no-debugger
        debugger; // <- do not remove
    }

    throw createError(message, 'Invariant Violation');
};
