import { useEffect, useState } from 'react';

export const useEndOfLoadingWithTimeout = (condition: boolean, timeout: number = 2000) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (condition) {
            setTimeout(() => setIsLoading(false), timeout);
        } else {
            setIsLoading(false);
        }
    }, [condition, timeout]);

    return isLoading;
};
