import { Button, Field, TextAreaEditor, TextEditor } from '@approvalmax/ui';
import { Spacing } from '@approvalmax/ui/src/components';
import { Alert, Section, toaster } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { useAnnouncementInfo } from 'pages/maintenance/hooks/useAnnouncementInfo';
import { useSaveAnnouncementInfo } from 'pages/maintenance/hooks/useSaveAnnouncementInfo';
import { FC, memo, useCallback, useState } from 'react';
import { v4 as v4uuid } from 'uuid';

import { messages } from './AnnouncementPanel.messages';
import { TextEditorWrapper } from './AnnouncementPanel.styles';

const AnnouncementPanel: FC = () => {
    const { permissionFlags } = useCurrentAccount();

    const { announcementInfo } = useAnnouncementInfo();
    const { saveAnnouncementInfo, isLoading: isSavingAnnouncementInfo } = useSaveAnnouncementInfo();

    const [id, setId] = useState(announcementInfo?.valueId || '');
    const [message, setMessage] = useState(announcementInfo?.value || '');

    const onGenerateId = useCallback(() => {
        setId(v4uuid());
    }, []);

    const onSave = useCallback(() => {
        if (message && !id) {
            toaster.error(messages.idIsRequiredMessage);

            return;
        }

        saveAnnouncementInfo({
            valueId: id || '',
            value: message || '',
        });
    }, [id, message, saveAnnouncementInfo]);

    if (!permissionFlags?.announcements.manageMessage) {
        return null;
    }

    return (
        <Section header={messages.announcementHeader}>
            <Field title={messages.message}>
                <TextAreaEditor value={message} onChange={setMessage} />
            </Field>

            <Spacing height={20} />

            <Field title={messages.messageId}>
                <Spacing height={12} />

                <Alert>{messages.messageIdInfo}</Alert>

                <Spacing height={12} />

                <TextEditorWrapper>
                    <TextEditor value={id} onChange={setId} />

                    <Button preset='compact' css='margin-left: 10px' execute={onGenerateId}>
                        {messages.generateButton}
                    </Button>
                </TextEditorWrapper>
            </Field>

            <Spacing height={20} />

            <Button execute={onSave} disabled={isSavingAnnouncementInfo}>
                {messages.saveAnnouncementButton}
            </Button>
        </Section>
    );
};

export default memo(AnnouncementPanel);
