import { FC } from 'react';

import { ReloadIcon, SupportIcon } from '../../icons';
import { BlockedImage } from '../../images';
import { Box } from '../Box/Box';
import Button from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { Text } from '../Text/Text';
import { messages } from './CrashedComponentPlaceholder.messages';
import type { CrashedComponentPlaceholderProps } from './CrashedComponentPlaceholder.types';

export const CrashedComponentPlaceholder: FC<CrashedComponentPlaceholderProps> = (props) => {
    const {
        Image = BlockedImage,
        title = messages.weCouldntLoad,
        subTitle = messages.pleaseReload,
        onContactSupport,
        secondaryAction,
    } = props;

    const handleReloadPage = () => {
        window.location.reload();
    };

    return (
        <Flex justifyContent='center' alignItems='center' height='100%'>
            <Box spacing='40 0'>
                <Flex direction='column' spacing='24' alignItems='center'>
                    <Image />

                    <Text font='headline' fontSize='small' fontWeight='medium' color='midnight80'>
                        {title}
                    </Text>

                    <Text font='body' fontSize='medium' color='midnight100'>
                        {subTitle}
                    </Text>

                    <Flex spacing='24' justifyContent='center'>
                        {secondaryAction || !onContactSupport || (
                            <Button color='blue10' size='large' startIcon={<SupportIcon />} onClick={onContactSupport}>
                                {messages.contactSupport}
                            </Button>
                        )}

                        <Button color='mint80' size='large' startIcon={<ReloadIcon />} onClick={handleReloadPage}>
                            {messages.reloadPage}
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
};

CrashedComponentPlaceholder.displayName = 'CrashedComponentPlaceholder';
