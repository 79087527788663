import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestDetailsPage.VersionItem', {
    versionTitle: 'Version #{version}',
    requesterSubmitted: 'Requester submitted',
    submittedViaExternalSystem: 'Submitted via {integrationName}',
    requesterResubmitted: 'Requester resubmitted',
    reviewerEdited: 'Reviewer edited ({userName})',
    approverEdited: 'Approver edited ({userName})',
    postApprovalChangesDetected: 'Edited after approval',
    startOverByExternalUpdate: 'Start over by update in {integrationName}',
    dext: 'Dext',
});
