import { miscHelpers } from '@approvalmax/utils';
import { useRecoilValue } from 'recoil';

import { typedMemo } from '../../Table.helpers';
import { leftStickyShadowTableState, rightStickyShadowTableState } from '../../Table.states';
import { defaultSpacing } from '../../Table.styles';
import { BaseItem } from '../../Table.types';
import { StyledCell } from './BodyCell.styles';
import { BodyCellProps } from './BodyCell.types';

export const BodyCell = typedMemo(<Item extends BaseItem>(props: BodyCellProps<Item>) => {
    const { value, children, title, columnDefinition, bordered, ...otherProps } = props;

    const spacing = miscHelpers.spacingPropToCss(columnDefinition.spacing ?? defaultSpacing);

    const isLeftStickyShadow = useRecoilValue(leftStickyShadowTableState);
    const isRightStickyShadow = useRecoilValue(rightStickyShadowTableState);
    const withStickyShadow =
        (columnDefinition.sticky === 'left' && isLeftStickyShadow) ||
        (columnDefinition.sticky === 'right' && isRightStickyShadow);

    return (
        <StyledCell
            title={title}
            $spacing={spacing}
            $sticky={columnDefinition.sticky}
            $withStickyShadow={withStickyShadow}
            $bordered={bordered}
            $textAlign={columnDefinition.textAlign}
            $verticalAlign={columnDefinition.verticalAlign}
            {...otherProps}
        >
            {children || value}
        </StyledCell>
    );
});

export default BodyCell;
