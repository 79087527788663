import './listItem.scss';

import { Identifiable } from '@approvalmax/types';
import { domHelpers } from '@approvalmax/utils';
import { FC, ReactNode, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { DropdownListItemProps } from './types';

const bem = bemFactory.block('form-dropdown-editor-list-item');
const qa = bemFactory.qa('form-dropdown-editor-list-item');

type ListItemProps = DropdownListItemProps<Identifiable> & {
    children?: (node: ReactNode, item?: Identifiable) => ReactNode;
};

export const ListItem: FC<ListItemProps> = (props) => {
    const {
        nestingLevel,
        className,
        item,
        displayTextSelector,
        displaySubTextSelector,
        active,
        highlightedText,
        rootRef,
        onSelect,
        onHover,
        singleLinePerItem = true,
        children = (node) => node,
    } = props;

    const text = displayTextSelector(item);
    const subText = displaySubTextSelector ? displaySubTextSelector(item) : '';

    const onSelectInternal = useCallback(() => {
        onSelect(item);
    }, [item, onSelect]);

    const onHoverInternal = useCallback(() => {
        onHover(item);
    }, [item, onHover]);

    let paddingLeft = 10;

    if (nestingLevel) {
        paddingLeft += nestingLevel * 10;
    }

    const itemClass = bem.add(className)(null, {
        active,
        'single-line-per-item': singleLinePerItem,
    });

    const textWithHighlight = highlightedText
        ? domHelpers.highlightText(text, highlightedText || '', true, bem('highlighted-text'))
        : text;

    return (
        <li
            style={{ paddingLeft: paddingLeft }}
            className={itemClass}
            onClick={onSelectInternal}
            data-qa={qa()}
            data-qa-id={item.id}
            data-qa-name={text}
            onMouseEnter={onHoverInternal}
            ref={rootRef}
        >
            {subText ? (
                <div className={bem('text-with-subtext')}>
                    <div className={bem('text')}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: textWithHighlight,
                            }}
                        />
                    </div>

                    <div className={bem('sub-text')}>{subText}</div>
                </div>
            ) : (
                children(
                    <span
                        dangerouslySetInnerHTML={{
                            __html: textWithHighlight,
                        }}
                    />,
                    item
                )
            )}
        </li>
    );
};

export default ListItem;
