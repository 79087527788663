import { FC, memo } from 'react';

import { Button, Flex, Text } from '../../../../components';
import { messages } from './Pagination.messages';
import { StyledArrowLeftIcon, StyledArrowRightIcon } from './Pagination.styles';
import { PaginationProps } from './Pagination.types';

const Pagination: FC<PaginationProps> = memo((props) => {
    const { currentIndex, total, goToPrevDocument, goToNextDocument, qa, ...restProps } = props;

    if (!total) return null;

    return (
        <Flex alignItems='center' {...restProps}>
            <Button
                onClick={goToPrevDocument}
                icon
                disabled={currentIndex === 1}
                data-qa={qa?.('toolbar-paginator-button-prev')}
            >
                <StyledArrowLeftIcon size={28} title={messages.buttonPreviousTitle} />
            </Button>

            <Text font='body' data-qa={qa?.('toolbar-paginator-text')}>
                {messages.paginationText({ currentIndex: currentIndex, total: total })}
            </Text>

            <Button
                onClick={goToNextDocument}
                icon
                disabled={currentIndex === total}
                data-qa={qa?.('toolbar-paginator-button-next')}
            >
                <StyledArrowRightIcon size={28} />
            </Button>
        </Flex>
    );
});

export default Pagination;
