import { Button, TextAreaEditor } from '@approvalmax/ui';
import JSONFormatter from 'json-formatter-js';
import { toaster } from 'modules/components';
import { FC, useEffect, useRef, useState } from 'react';

import { cleanUpJson, copyToClipboard, formatJson } from './JsonViewer.helpers';
import { messages } from './JsonViewer.messages';
import { Actions, Root, ViewerDiv } from './JsonViewer.styles';
import { JsonViewerProps } from './JsonViewer.types';

const JsonViewer: FC<JsonViewerProps> = (props) => {
    const { json, expandDepth = 1, actions } = props;

    const elRef = useRef<HTMLDivElement>(null);
    const [showAsText, setShowAsText] = useState(false);

    const cleanedUpJson = cleanUpJson(json as Record<string, unknown>);

    useEffect(() => {
        const formatter = new JSONFormatter(cleanUpJson(cleanedUpJson), expandDepth, {
            hoverPreviewEnabled: true,
        });

        for (let c of elRef.current!.children) {
            c.remove();
        }

        elRef.current!.appendChild(formatter.render());
    }, [cleanedUpJson, expandDepth]);

    const copyJson = () => {
        copyToClipboard(formatJson(cleanedUpJson));
        toaster.info(messages.copiedToast);
    };

    return (
        <Root>
            <Actions>
                {actions}

                <Button execute={copyJson} preset='compact'>
                    {messages.copyButton}
                </Button>

                <Button execute={() => setShowAsText(!showAsText)} preset='compact'>
                    {showAsText ? messages.showInJsonViewer : messages.showAsText}
                </Button>
            </Actions>

            <ViewerDiv ref={elRef} hide={showAsText} />

            <TextAreaEditor
                css={!showAsText ? 'display: none;' : undefined}
                value={formatJson(cleanedUpJson)}
                onChange={() => undefined}
                minHeight={10}
            />
        </Root>
    );
};

export default JsonViewer;
