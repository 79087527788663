/**
 * Developer: Stepan Burguchev
 * Date: 3/6/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './actionMenuItem.scss';

import { Component, PropsWithChildren } from 'react';
import bemFactory from 'react-bem-factory';

import TransparentButton from '../button/TransparentButton';
import { BaseButtonProps } from '../button/types';
import ConfirmationDropdown from '../confirmationDropdown/ConfirmationDropdown';
import { ActionMenuContext } from './context';

const bem = bemFactory.block('ui-action-menu-item');

interface ActionMenuItemProps extends BaseButtonProps, PropsWithChildren {
    confirmation?: string;
    confirmationYesText?: string;
    confirmationNoText?: string;
}

interface State {
    isOpen: boolean;
}

class ActionMenuItem extends Component<ActionMenuItemProps, State> {
    public state = {
        isOpen: false,
    };

    public render() {
        const { children, className, confirmation, confirmationYesText, confirmationNoText, ...buttonProps } =
            this.props;

        if (!confirmation) {
            return (
                <ActionMenuContext.Consumer>
                    {({ onExecute }) => (
                        <TransparentButton
                            {...buttonProps}
                            execute={() => {
                                onExecute();

                                if (buttonProps.execute) {
                                    buttonProps.execute();
                                }
                            }}
                        >
                            {({ disabled, elementProps }) => (
                                <button
                                    {...elementProps}
                                    className={bem.add(className)(null, {
                                        disabled,
                                    })}
                                >
                                    {children}
                                </button>
                            )}
                        </TransparentButton>
                    )}
                </ActionMenuContext.Consumer>
            );
        }

        return (
            <ActionMenuContext.Consumer>
                {({ onExecute }) => (
                    <ConfirmationDropdown
                        confirmation={confirmation}
                        confirmationYesText={confirmationYesText}
                        confirmationNoText={confirmationNoText}
                    >
                        <TransparentButton
                            {...buttonProps}
                            execute={() => {
                                onExecute();

                                if (buttonProps.execute) {
                                    buttonProps.execute();
                                }
                            }}
                        >
                            {({ elementProps }) => (
                                <button {...elementProps} className={bem.add(className)()}>
                                    {children}
                                </button>
                            )}
                        </TransparentButton>
                    </ConfirmationDropdown>
                )}
            </ActionMenuContext.Consumer>
        );
    }
}

export default ActionMenuItem;
