import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';

import { messages } from './FindCompanyShardPage.messages';
import { SearchResultItem } from './FindCompanyShardPage.types';

export const dataTableColumns: DataTableColumnDefinition<SearchResultItem>[] = [
    {
        id: 'companyId',
        name: messages.companyIdCol,
        value: (item) => item.companyId,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'shard',
        name: messages.shardCol,
        value: (item) => item.database,
        sortable: false,
    },
];
