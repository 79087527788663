const getDigitsCount = (type: Intl.DateTimeFormatPart['type']) => {
    return type === 'year' ? 4 : 2;
};

export const adjustNumericDateToFormat = (value: string, locale: Intl.LocalesArgument) => {
    const dateFormatter = new Intl.DateTimeFormat(locale);
    const parts = dateFormatter.formatToParts(new Date());
    const splitByDigits = value.split('');

    return parts
        .flatMap((part) => {
            if (part.type === 'literal') {
                return part.value;
            }

            const digitsCount = getDigitsCount(part.type);

            return splitByDigits.splice(0, digitsCount);
        })
        .join('');
};
