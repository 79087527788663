import { invariant } from '../misc/invariant';

/**
 * Can be passed to the ref attribute to focus the element when mounted
 * */
export const focusOnMount = (ref: any) => {
    if (!ref) {
        return;
    }

    invariant(ref.focus, 'ref element has focus');
    ref.focus();
};
