import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.StorageQueueDetailsPage', {
    pageTitle: 'Storage Queues',
    idCol: 'Id',
    messageTextCol: 'Message text',
    insertionTimeCol: 'Insertion time',
    expirationTimeCol: 'Expiration time',
    title: '{queueId} (Poison)',
    clearPoisonConfirmation: 'Please confirm clearing the poison queue for {queueName}.',
    clearPoisonButton: 'Clear poison queue',
    clearQueueTitle: 'Clear the poison queue',
    clearQueue: 'Clear poison queue',
    movePoisonConfirmation: 'Please confirm moving the poison messages into the main queue {queueName}.',
    movePoisonButton: 'Move poison messages',
    moveQueueTitle: 'Move poison messages into the main queue',
    moveQueue: 'Move poison messages',
    countField: 'Messages in queue',
    poisonCountField: 'Messages in poison',
});
