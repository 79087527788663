import { PascalCaseToCamelCase } from '@approvalmax/types';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

export const pascalCaseToCamelCase = <Target extends Record<string, any>>(target: Target) => {
    let camelCaseObject = {} as PascalCaseToCamelCase<Target>;

    forEach(target, (value, key) => {
        let newValue: PascalCaseToCamelCase<Target[keyof Target]> | PascalCaseToCamelCase<Target[keyof Target]>[] =
            value;

        if (isPlainObject(value)) {
            newValue = pascalCaseToCamelCase(value) as PascalCaseToCamelCase<Target[keyof Target]>;
        } else if (isArray(value)) {
            newValue = value.map((item: (typeof value)[number]) =>
                isPlainObject(item) ? pascalCaseToCamelCase(item) : item
            );
        }

        const newKey = (key.charAt(0).toLowerCase() + key.slice(1)) as keyof PascalCaseToCamelCase<Target>;

        camelCaseObject[newKey] = newValue as PascalCaseToCamelCase<Target[keyof Target]>;
    });

    return camelCaseObject;
};
