import { Field, Popup, TextAreaEditor, TextEditor, usePopupContext } from '@approvalmax/ui';
import { toaster } from 'modules/components';
import { useEditRequestDeletedFlag } from 'modules/customerSupport/hooks/useEditRequestDeletedFlag';
import { FC, memo, useCallback } from 'react';

import { useDeleteRestoreFormData } from './DeleteRestoreRequestPopupContent.hooks';
import { messages } from './DeleteRestoreRequestPopupContent.messages';
import { Content, DefaultContent, Description, Fields } from './DeleteRestoreRequestPopupContent.styles';
import {
    DeleteRestoreRequestMode,
    DeleteRestoreRequestPopupContentProps,
} from './DeleteRestoreRequestPopupContent.types';

const DeleteRestoreRequestPopupContent: FC<DeleteRestoreRequestPopupContentProps> = (props) => {
    const { companyId, requests, mode } = props;

    const { onRequestClose } = usePopupContext();
    const { isLoading, editRequestDeletedFlag } = useEditRequestDeletedFlag();
    const { formData, requestIds, setRequestIds, setTicketId, setComment, setCompanyId, formErrors, validateForm } =
        useDeleteRestoreFormData(companyId, requests);

    const deleteMode = mode === DeleteRestoreRequestMode.delete;

    const onExecute = useCallback(async () => {
        if (validateForm()) {
            await editRequestDeletedFlag({
                companyId: formData.companyId,
                comment: formData.comment,
                ticketId: formData.ticketId,
                requestIds,
                deleted: deleteMode,
            });
            onRequestClose();
        } else {
            toaster.error(messages.validationErrorsToast);
        }
    }, [
        deleteMode,
        editRequestDeletedFlag,
        formData.comment,
        formData.companyId,
        formData.ticketId,
        onRequestClose,
        requestIds,
        validateForm,
    ]);

    return (
        <DefaultContent
            title={deleteMode ? messages.deleteRequestsTitle : messages.restoreRequestsTitle}
            buttons={
                <Popup.DefaultContent.Button execute={onExecute} disabled={isLoading}>
                    {deleteMode ? messages.deleteButton : messages.restoreButton}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <Description>
                    {deleteMode
                        ? messages.deleteDescription({
                              b: (v: any) => <b>{v}</b>,
                          })
                        : messages.restoreDescription({
                              b: (v: any) => <b>{v}</b>,
                          })}
                </Description>

                <Fields>
                    <Field title={messages.companyId} required>
                        <TextAreaEditor
                            value={formData.companyId}
                            onChange={setCompanyId}
                            invalid={formErrors?.companyId}
                            placeholder={messages.companyIdPlaceholder}
                            disabled
                        />
                    </Field>

                    <Field title={messages.requestIds} required>
                        <TextEditor
                            value={formData.requestIds}
                            onChange={setRequestIds}
                            invalid={formErrors.requestIds}
                            placeholder={messages.requestIdsPlaceholder}
                            disabled
                        />
                    </Field>

                    <Field title={messages.ticket} required>
                        <TextEditor
                            value={formData.ticketId}
                            onChange={setTicketId}
                            invalid={formErrors.ticketId}
                            placeholder={messages.ticketIdPlaceholder}
                        />
                    </Field>

                    <Field title={messages.comment} required>
                        <TextAreaEditor
                            value={formData.comment}
                            onChange={setComment}
                            invalid={formErrors.comment}
                            placeholder={messages.commentPlaceholder}
                            focusOnMount
                        />
                    </Field>
                </Fields>
            </Content>
        </DefaultContent>
    );
};

export default memo(DeleteRestoreRequestPopupContent);
