import { useMutation, useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';

import { messages } from './EnforceFraudDetectionPopup.messages';

export const useEligibleToEnforceFraudDetectionWorkflows = (companyId: string) => {
    const { data, isInitialLoading } = useQuery([QueryKeys.EligibleToEnforceFraudDetectionWorkflows, companyId], () =>
        api.internalSupport.getEligibleToEnforceFraudDetectionWorkflows(companyId)
    );

    return {
        data,
        isInitialLoading,
    };
};

export const useEnforceDetectionMutation = (companyId: string) => {
    const { mutate: enforceFraudDetection, isLoading } = useMutation(
        (options: { workflowId: string; effectiveDate: string }) =>
            api.internalSupport.enforceFraudDetection(companyId, options),
        {
            onSuccess: () => {
                toaster.info(messages.successMessage);
            },
        }
    );

    return {
        enforceFraudDetection,
        isLoading,
    };
};
