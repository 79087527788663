import { FC } from 'react';

import { Root } from './EmptyPopupContent.styles';
import { EmptyPopupContentProps } from './EmptyPopupContent.types';

export const EmptyPopupContent: FC<EmptyPopupContentProps> = (props) => {
    const { children, style, className, qa } = props;

    return (
        <Root open className={className} style={style} data-qa={qa}>
            {children}
        </Root>
    );
};

EmptyPopupContent.displayName = 'EmptyPopupContent';
