import type { ApiError } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApi from '../rootApi';
import rootApiLegacy from '../rootApiLegacy';
import { ApiBaseAnswer, ApiSource, MutateDataParams, UseMutateOptions } from '../types';

export const createUseMutate = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <
        RequestData extends MutateDataParams,
        ResponseData extends Record<string, any> = ApiBaseAnswer,
        MappedResponseData = ResponseData,
    >(
        path: string,
        options: UseMutateOptions<ResponseData, MappedResponseData, RequestData> & {
            // TODO: Move skipInvalidateQueries to the UseMutateOptions
            /**
             * If you would like to perform manual cache update, you might want to skip
             * a fetching relative query
             */
            skipInvalidateQueries?: true;
        } = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            method = 'post',
            mutationOptions,
            mapData,
            mapToCamelCase,
        } = options;

        const queryClient = useQueryClient();
        const handleApiCall = useHandleApiCall(mapData, mapToCamelCase);

        return useMutation<MappedResponseData, ApiError, RequestData>(
            async ({ pathParams, queryParams = {}, params, body }) => {
                const requestParams = params || {
                    path: pathParams,
                    query: queryParams,
                };

                const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
                const url = routerHelpers.pathToUrl(path, requestParams.path);
                const Authorization = getAuthorization(defaultApiSource, defaultEnv);
                const isFormData = body instanceof FormData;
                const contentTypeHeader = { 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' };
                const apiFn = apiVersion === 'v1' ? rootApiLegacy : rootApi;

                return handleApiCall(
                    apiFn({
                        baseURL,
                        url,
                        method,
                        headers: {
                            ...(Authorization && { Authorization }),
                            ...contentTypeHeader,
                        },
                        params: requestParams.query,
                        data: body,
                    })
                );
            },
            {
                mutationKey: [path],
                ...mutationOptions,
                onSuccess: async (data, variables, context) => {
                    if (!options.skipInvalidateQueries) {
                        const url = routerHelpers.pathToUrl(path, variables.params?.path || variables.pathParams);

                        await queryClient.invalidateQueries([url]);
                    }

                    mutationOptions?.onSuccess?.(data, variables, context);
                },
            }
        );
    };
};
