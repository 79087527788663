import { ExternalLink, TextEditor } from '@approvalmax/ui';
import { FC, memo, useCallback, useRef, useState } from 'react';

import { messages } from './TwoFaCodePopup.messages';
import { Content, Description, Root, StyledField, SubmitButton } from './TwoFaCodePopup.styles';

interface AppCodePanelProps {
    isLoading: boolean;
    onEnterCode: (code: string) => void | Promise<void>;
}

const TwoFaCodePopup: FC<AppCodePanelProps> = (props) => {
    const { isLoading, onEnterCode } = props;

    const [userCode, setUserCode] = useState('');
    const [wrongCode, setWrongCode] = useState<string | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const isSubmitEnabled = Boolean(userCode.trim()) && !isLoading;

    const onCodeChange = useCallback((value: string) => {
        setUserCode(value);
    }, []);

    const onSubmit = useCallback(async () => {
        if (!isSubmitEnabled) {
            return;
        }

        try {
            setWrongCode(null);
            await onEnterCode(userCode);
        } catch {
            setWrongCode(userCode);
            inputRef.current!.focus();
        }
    }, [isSubmitEnabled, onEnterCode, userCode]);

    return (
        <Root title={messages.popupTitle}>
            <Content>
                <Description>
                    {messages.description({
                        supportlink: (chunks: any) => (
                            <ExternalLink href='https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-2fa'>
                                {chunks}
                            </ExternalLink>
                        ),
                    })}
                </Description>

                <StyledField
                    title={messages.codeFieldTitle}
                    errorText={wrongCode === userCode && messages.wrongCodeErrorText}
                >
                    <TextEditor
                        focusOnMount
                        disabled={isLoading}
                        value={userCode}
                        onChange={onCodeChange}
                        placeholder={messages.userCodePlaceholder}
                        invalid={wrongCode === userCode}
                        ref={inputRef}
                        onEnter={onSubmit}
                    />
                </StyledField>

                <SubmitButton execute={onSubmit} disabled={!isSubmitEnabled}>
                    {messages.submitButtonText}
                </SubmitButton>
            </Content>
        </Root>
    );
};

export default memo(TwoFaCodePopup);
