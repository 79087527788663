import { createContext } from 'react';

import { ContextValue } from './Group.types';

export const GroupContext = createContext<ContextValue>({
    contextProps: {
        getCheckedStatus: () => false,
    },
    ref: null,
});
