import { invalidOperationError } from './invalidOperationError';

/**
 * Statically checks that the type of the given value is `never` at compile time.
 * The type might become `never` using a series of exhaustive type checks.
 * This is particularly useful when you want to statically check that all
 * enum members are listen in a switch-case statement.
 * ```
 * switch (value) {
 *     case Position.Left:
 *         isLeft = true;
 *         break;
 *     // ...
 *     default:
 *         throw helpers.assertNever(value); // <- value has type 'never' here is all cases are covered
 * }
 * ```
 */
export const assertNever = <T extends never | string>(value: T) => {
    throw invalidOperationError(
        `'assertNever' exception has been executed, which means that the value at runtime (${value}) was different ` +
            'from what the type system expects. Check the value that is passed into this function.'
    );
    // Never called
};
