import { Reference } from '@approvalmax/types';
import { Accordion, Button, DropdownEditor, LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import {
    ConfirmationPopup,
    JsonViewer,
    NavBarMode,
    PageLayout,
    PlainDataProvider,
    Section,
    Toolbar,
} from 'modules/components';
import { useAvailableUserBetaFeatures } from 'modules/customerSupport/hooks/useAvailableUserBetaFeatures';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { usePageGoBack } from '../../modules/react/hooks/usePageGoBack';
import Manage2FAPopup from './components/Manage2FAPopup/Manage2FAPopup';
import TFADisablingHistory from './components/TFADisablingHistory/TFADisablingHistory';
import { usePathParams } from './hooks/usePathParams';
import { useSaveUserBetaFeatures } from './hooks/useSaveUserBetaFeatures';
import { useUserDetails } from './hooks/useUserDetails';
import { getFeatureOptions, getUserFullName } from './UserDetailsPage.helpers';
import { useManage2FAPopup } from './UserDetailsPage.hooks';
import { messages } from './UserDetailsPage.messages';
import { BetaFeaturesPanel, ButtonRow, ConfirmationPopupHeader, ConfirmationPopupList } from './UserDetailsPage.styles';

const UserDetailsPage: FC = () => {
    const [betaFeatures, setBetaFeatures] = useState<string[]>([]);

    const { onBack } = usePageGoBack('users');

    const { userId } = usePathParams();
    const { userDetails } = useUserDetails(userId);
    const { availableUserBetaFeatures } = useAvailableUserBetaFeatures();
    const { isLoading, saveUserBetaFeature } = useSaveUserBetaFeatures();
    const { permissionFlags } = useCurrentAccount();

    usePermissionsRedirect((permissionFlags) => permissionFlags.users.read);

    useEffect(() => {
        if (userDetails) {
            setBetaFeatures(userDetails.userBetaFeatures);
        }
    }, [userDetails]);

    const removedBetaFeatures = useMemo(
        () => (userDetails?.userBetaFeatures ?? []).filter((savedFeature) => !betaFeatures.includes(savedFeature)),
        [betaFeatures, userDetails?.userBetaFeatures]
    );

    const isDisabledButtons = useMemo(
        () => isLoading || !permissionFlags || !permissionFlags.users.manageBetaFeatures,
        [isLoading, permissionFlags]
    );

    const onSaveBetaFeatures = useCallback(() => {
        saveUserBetaFeature({
            userId,
            betaFeatures,
        });
    }, [betaFeatures, saveUserBetaFeature, userId]);

    const onClickSave = useCallback(
        (executeWithConfirmation: () => void) => () =>
            removedBetaFeatures.length > 0 ? executeWithConfirmation() : onSaveBetaFeatures(),
        [onSaveBetaFeatures, removedBetaFeatures.length]
    );

    const onBetaFeaturesChanged = useCallback(
        (newValues: Reference[]) => setBetaFeatures(newValues.map((x) => x.id)),
        []
    );

    const confirmationPopupText = useMemo(
        () => (
            <>
                <ConfirmationPopupHeader>{messages.confirmationPopupHeader}</ConfirmationPopupHeader>

                <ConfirmationPopupList>{removedBetaFeatures.join(', ')}</ConfirmationPopupList>

                <div>{messages.confirmationPopupText}</div>
            </>
        ),
        [removedBetaFeatures]
    );

    const { hasManage2FAPermission, isManage2FAPopupOpened, onManage2FAPopupOpen, onManage2FAPopupClose } =
        useManage2FAPopup();

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                {!availableUserBetaFeatures || !userDetails ? (
                    <div>
                        <LoadingBar />
                    </div>
                ) : (
                    <ScrollableAreaWrapper>
                        <Toolbar title={getUserFullName(userDetails.user)} />

                        <ScrollableArea css='flex: 1; overflow: hidden;'>
                            <Box spacing='20 60'>
                                <Section header={messages.betaFeatures}>
                                    <BetaFeaturesPanel>
                                        <PlainDataProvider items={getFeatureOptions(availableUserBetaFeatures)}>
                                            <DropdownEditor
                                                disabled={isDisabledButtons}
                                                value={getFeatureOptions(betaFeatures)}
                                                onChange={onBetaFeaturesChanged}
                                                placeholder={messages.betaFeaturesPlaceholder}
                                                multiple
                                            />
                                        </PlainDataProvider>

                                        <ConfirmationPopup
                                            execute={onSaveBetaFeatures}
                                            confirmationText={confirmationPopupText}
                                            executeButtonText={messages.saveBetaFeaturesButton}
                                            cancelButtonColorTheme='red'
                                            executeButtonColorTheme='forest-green'
                                        >
                                            {({ executeWithConfirmation }) => (
                                                <Button
                                                    execute={onClickSave(executeWithConfirmation)}
                                                    disabled={isDisabledButtons}
                                                    preset='compact'
                                                >
                                                    {messages.saveBetaFeatures}
                                                </Button>
                                            )}
                                        </ConfirmationPopup>
                                    </BetaFeaturesPanel>
                                </Section>

                                <ButtonRow>
                                    <Button
                                        execute={onManage2FAPopupOpen}
                                        disabled={!hasManage2FAPermission}
                                        preset='compact'
                                    >
                                        {messages.manage2FA}
                                    </Button>
                                </ButtonRow>

                                <Section header={messages.rawDataHeader}>
                                    <JsonViewer json={userDetails.userRawData} />
                                </Section>

                                <Box spacing='0 0 20'>
                                    <Accordion title={messages.tfaDisablingHistory}>
                                        <TFADisablingHistory userId={userId} />
                                    </Accordion>
                                </Box>
                            </Box>
                        </ScrollableArea>
                    </ScrollableAreaWrapper>
                )}
            </Box>

            <Manage2FAPopup isOpened={isManage2FAPopupOpened} userId={userId} onClose={onManage2FAPopupClose} />
        </PageLayout>
    );
};

export default memo(UserDetailsPage);
