import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestDetailsPage', {
    pageTitle: 'Request details',
    deleteSection: 'Delete / Restore',
    deletedTitle: 'The request is <b>deleted</b>',
    activeTitle: 'The request is <b>active</b>',
    deleteButton: 'Delete',
    restoreButton: 'Restore',
    rawDataHeader: 'Details',
    defaultErrorMessage: 'Oops, something went wrong',
    filesSection: 'Files',
    requestDetailsVersions: 'Request details versions',
});
