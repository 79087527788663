import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-flow: column;

    margin-bottom: 20px;
`;

export const Header = styled.div`
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    ${font(14, '#000', 'semibold')};
`;
