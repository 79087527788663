import { useCallback } from 'react';

import { retrieveRowId } from '../../Table.helpers';
import type { BaseItem, TableProps } from '../../Table.types';

export const useCheckedColumn = <Item extends BaseItem>(
    items: TableProps<Item>['data'],
    checkedItems: TableProps<Item>['checkedItems'],
    getRowId: TableProps<Item>['getRowId'],
    onCheckedItemsChange: TableProps<Item>['onCheckedItemsChange']
) => {
    const handleColumnCheck = useCallback(() => {
        if (!onCheckedItemsChange) {
            return;
        }

        if (!checkedItems || !checkedItems.length) {
            onCheckedItemsChange(items.map((el) => retrieveRowId(el, getRowId)));
        } else {
            onCheckedItemsChange([]);
        }
    }, [items, checkedItems, onCheckedItemsChange, getRowId]);

    return { handleColumnCheck };
};
