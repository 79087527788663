import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { dateTimeHelpers } from '@approvalmax/utils';
import { StorageQueueMessage } from 'modules/api';

import { ActionsCell } from './components';
import { messages } from './StorageQueueDetailsPage.messages';

export const dataTableColumns: DataTableColumnDefinition<StorageQueueMessage>[] = [
    {
        id: 'id',
        name: messages.idCol,
        value: (message) => message.id,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'messageText',
        name: messages.messageTextCol,
        value: (message) => message.text,
        sortable: false,
    },
    {
        id: 'insertionTime',
        name: messages.insertionTimeCol,
        value: (message) => dateTimeHelpers.formatDateTime(message.insertedAt),
        sortable: false,
    },
    {
        id: 'expirationTime',
        name: messages.expirationTimeCol,
        value: (message) => dateTimeHelpers.formatDateTime(message.expiresAt),
        sortable: false,
    },
    {
        id: 'actions',
        name: '',
        width: 150,
        cellComponent: ActionsCell,
        sortable: false,
    },
];
