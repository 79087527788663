import { CheckboxEditor, Dropdown, RadioGroupEditor, TextButton, TextEditor, TransparentButton } from '@approvalmax/ui';
import { BulletListIcon } from '@approvalmax/ui/src/old/icons';
import { eventHelpers } from '@approvalmax/utils';
import { get } from 'lodash';
import { HeadCellIcon } from 'pages/companies/CompaniesPage.styles';
import { IntegrationStatusOption } from 'pages/companies/CompaniesPage.types';
import { memo, useCallback, useState } from 'react';

import { messages } from './ColumnFilter.messages';
import { DropdownPanel, FilterRow, PanelFooter } from './ColumnFilter.styles';
import { ColumnFilterProps } from './ColumnFilter.types';

const ColumnFilter = memo<ColumnFilterProps>((props) => {
    const {
        filterType,
        filterKey,
        filterOptions,
        columnFilters,
        onCheckboxFilterChange,
        onStringFilterChange,
        onApplyColumnFilters,
        resetColumnFilters,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => setIsOpen(true), []);

    const close = useCallback(() => {
        setIsOpen(false);

        resetColumnFilters();
    }, [resetColumnFilters]);

    const applyFilterHandler = useCallback(() => {
        setIsOpen(false);

        onApplyColumnFilters();
    }, [onApplyColumnFilters]);

    if (!filterKey) {
        return null;
    }

    const filterValue = get(columnFilters, filterKey);

    return (
        <Dropdown
            isOpen={isOpen}
            button={
                <TransparentButton execute={open}>
                    <HeadCellIcon>
                        <BulletListIcon width={10} height={10} />
                    </HeadCellIcon>
                </TransparentButton>
            }
            panelMinWidth='none'
            panelFlow='to-left'
            onRequestClose={close}
        >
            <DropdownPanel onMouseDown={eventHelpers.preventDefaultCallback}>
                {filterType === 'multiple' &&
                    filterOptions?.map((filterOption) => {
                        return (
                            <FilterRow key={filterOption.id} onMouseDown={eventHelpers.preventDefaultCallback}>
                                <CheckboxEditor
                                    value={filterValue[filterOption.id]}
                                    onChange={(newValue) => {
                                        onCheckboxFilterChange(filterKey, filterOption, newValue);
                                    }}
                                />

                                {filterOption.text}
                            </FilterRow>
                        );
                    })}

                {filterType === 'single' && (
                    <RadioGroupEditor
                        value={filterValue}
                        onChange={(newValue: IntegrationStatusOption) => {
                            onStringFilterChange(filterKey, newValue);
                        }}
                    >
                        {filterOptions?.map((filterOption) => {
                            return (
                                <RadioGroupEditor.Item key={filterOption.id} id={filterOption.id}>
                                    {filterOption.text}
                                </RadioGroupEditor.Item>
                            );
                        })}
                    </RadioGroupEditor>
                )}

                {filterType === 'string' && typeof filterValue === 'string' && (
                    <TextEditor
                        focusOnMount
                        value={filterValue}
                        onChange={(newValue) => {
                            onStringFilterChange(filterKey, newValue);
                        }}
                        placeholder={messages.inputPlaceholder}
                    />
                )}

                <PanelFooter>
                    <TextButton execute={applyFilterHandler}>{messages.apply}</TextButton>
                </PanelFooter>
            </DropdownPanel>
        </Dropdown>
    );
});

export default ColumnFilter;
