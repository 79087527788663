/**
 * Developer: Stepan Burguchev
 * Date: 4/15/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './radiobox.scss';

import { forwardRef } from 'react';
import bemFactory from 'react-bem-factory';

interface Props {
    checked: boolean;
    id?: string;
    labelledBy?: string;
    className?: string;
    disabled?: boolean;
    onPrev?: () => void;
    onNext?: () => void;
    onCheck?: () => void;
}

export default forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { checked, id, className, disabled, onPrev, onNext, onCheck, labelledBy } = props;

    const bem = bemFactory.block('ui-radiobox');

    return (
        <div
            ref={ref}
            id={id}
            role='radio'
            aria-checked={checked}
            aria-disabled={disabled}
            aria-labelledby={labelledBy}
            tabIndex={checked && !disabled ? 0 : -1}
            className={bem.add(className)(null, { checked, disabled })}
            onKeyDown={(e) => {
                if (disabled) {
                    return;
                }

                switch (e.key) {
                    case 'ArrowUp':
                    case 'ArrowLeft':
                        if (onPrev) {
                            onPrev();
                        }

                        break;

                    case 'ArrowDown':
                    case 'ArrowRight':
                        if (onNext) {
                            onNext();
                        }

                        break;

                    case ' ':
                        if (onCheck) {
                            onCheck();
                        }

                        break;

                    default:
                        break;
                }
            }}
        />
    );
});
