import styled from 'styled-components';

export const Root = styled.dialog`
    width: 640px;
    max-width: 100%;
    max-height: 100%;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    cursor: default;
    user-select: none;
`;
