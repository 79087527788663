import { mixins, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { BaseItem, TableProps } from './Table.types';

export const defaultSpacing = '12 8' as const;
export const checkboxSpacing = '12 16' as const;

export const Root = styled.table<
    StyledMods<Pick<TableProps<BaseItem>, 'fontSize' | 'width'>> & StyledMods<{ reorderableRows?: boolean }>
>`
    width: max-content;
    min-width: 100%;
    border-collapse: collapse;

    ${mods('reorderableRows', true)`
        width: calc(100% + 30px);
        margin-left: -30px;
    `};

    ${mods.fontSize((value) => mixins.font('body', value))};

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;
