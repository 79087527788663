import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';

export const useEditMyAccountPermissionsForUser = () => {
    const queryClient = useQueryClient();
    const { isLoading, mutateAsync: editMyAccountPermissionsForUser } = useMutation(
        (options: { userId: string; myAccountPermissions: string[] }) =>
            api.portalManagement.editMyAccountPermissionsForUser(options.userId, options),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.MyAccountPermissionsForUser]);
            },
        }
    );

    return {
        editMyAccountPermissionsForUser,
        isLoading,
    };
};
