import { EditRequestData } from 'modules/customerSupport/hooks/useEditRequestDeletedFlag.types';
import { ReactNode } from 'react';

export interface BulkOperationPopupProps {
    operation: BulkOperation;
}

export enum BulkOperation {
    delete = 'delete',
    restore = 'restore',
}

export interface PopupFormData {
    companyId: string;
    comment: string;
    ticketId: string;
    requestIds: string;
}

export type PopupFormErrors = Partial<Record<keyof PopupFormData, boolean>>;

export interface PopupSubmitHandler {
    (options: EditRequestData): Promise<void>;
}

export interface PopupConfigEntry {
    title: string;
    buttonText: string;
    description: ReactNode;
    useOperation: () => {
        execute: PopupSubmitHandler;
        isLoading: boolean;
    };
}
