export type UseGetRequestVersionsPathParams = {
    companyId: string;
    requestId: string;
};

export enum RequestVersionType {
    RequesterSubmitted = 'RequesterSubmitted',
    SubmittedViaExternalSystem = 'SubmittedViaExternalSystem',
    RequesterResubmitted = 'RequesterResubmitted',
    StartOverByExternalUpdate = 'StartOverByExternalUpdate',
    ReviewerEdited = 'ReviewerEdited',
    EditorEdited = 'EditorEdited',
    ApproverEdited = 'ApproverEdited',
    PostApprovalChangesDetected = 'PostApprovalChangesDetected',
}

export type RequestVersionItem = {
    version: number;
    versionType: RequestVersionType;
    createdAt: string;
    versionRawData: {
        actor?: {
            userName: string;
        };
    };
};

export type UseGetRequestVersionsResponse = RequestVersionItem[];
