import { DataTable, DropdownEditor, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import flatten from 'lodash/flatten';
import { IgnoredEmail } from 'modules/api';
import {
    NavBarMode,
    PageLayout,
    PageLayoutWidthBehavior,
    PlainDataProvider,
    SearchBar,
    Toolbar,
} from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { useFindUndeliveredEmails } from 'pages/undeliveredEmails/hooks/useFindUndeliveredEmails';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import useQueryParams from './hooks/useQueryParams';
import { dataTableColumns, emailFilterValues } from './UndeliveredEmailsPage.config';
import { messages } from './UndeliveredEmailsPage.messages';
import { EmailSearchType } from './UndeliveredEmailsPage.types';
import { getEmailFilterValue } from './UndeliveredEmailsPage.utils';

const UndeliveredEmailsPage: FC = () => {
    const { query, push } = useQueryParams();

    const [searchText, setSearchText] = useState(query);

    const [emailSearchType, setEmailSearchType] = useState(EmailSearchType.StartsWithSearch);

    const [appliedSearchFilter, setAppliedSearch] = useState<[filter: string, searchType: EmailSearchType]>([
        query,
        EmailSearchType.StartsWithSearch,
    ]);

    const { findUndeliveredEmails, undeliveredEmails, isFetchingNextPage, hasMore } = useFindUndeliveredEmails(
        ...appliedSearchFilter
    );

    const history = useHistory();

    usePermissionsRedirect((permissionFlags) => permissionFlags.ignoredEmails.any);

    useEffect(() => {
        const [appliedSearchText, appliedSearchType] = appliedSearchFilter;

        const minQueryLength = appliedSearchType === EmailSearchType.ContainsSearch ? 2 : 0;

        if (appliedSearchText.length >= minQueryLength) {
            findUndeliveredEmails();
        }
    }, [appliedSearchFilter, findUndeliveredEmails]);

    const onSearch = useCallback(() => {
        const minQueryLength = emailSearchType === EmailSearchType.ContainsSearch ? 2 : 0;

        if (searchText.length >= minQueryLength) {
            setAppliedSearch([searchText, emailSearchType]);

            push({
                query: searchText,
            });
        }
    }, [push, searchText, emailSearchType]);

    const onRowClick = useCallback(
        (emailEntry: IgnoredEmail) => {
            history.push(getPath('undeliveredEmails', emailEntry.email));
        },
        [history]
    );

    const data = useMemo(
        () => flatten(undeliveredEmails?.pages ?? []).map((x, i) => ({ ...x, id: `${i}` })),
        [undeliveredEmails]
    );

    const onSearchTypeChange = useCallback((value: (typeof emailFilterValues)[0]) => {
        setEmailSearchType(value.emailQueryType);
    }, []);

    const onLoadMore = useCallback(() => {
        findUndeliveredEmails();
    }, [findUndeliveredEmails]);

    const filters = (
        <>
            <PlainDataProvider items={emailFilterValues}>
                <DropdownEditor
                    value={getEmailFilterValue(emailSearchType)}
                    onChange={onSearchTypeChange}
                    css='width: 180px'
                />
            </PlainDataProvider>

            <SearchBar
                placeholder={
                    emailSearchType === EmailSearchType.ContainsSearch
                        ? messages.searchContainsPlaceholder
                        : messages.searchPlaceholder
                }
                focusOnMount
                disabled={isFetchingNextPage}
                onSearch={onSearch}
                value={searchText}
                onChange={setSearchText}
            />
        </>
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar filters={filters} />

                    <DataTable
                        css='flex: 1; overflow: hidden;'
                        data={data}
                        columns={dataTableColumns}
                        onRowClick={onRowClick}
                        showLoadMoreButton={hasMore}
                        isFetchingMore={isFetchingNextPage}
                        onLoadMore={onLoadMore}
                    />
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
};

export default memo(UndeliveredEmailsPage);
