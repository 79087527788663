import { Button, ConfirmationDropdown, DataTable, DataTableCellComponentProps, Popup } from '@approvalmax/ui';
import { NextCompanySyncCommand } from 'modules/api';
import { memo, useCallback, useState } from 'react';

import { useDeleteCommand } from '../../SyncCommandManagementPage.hooks';
import DetailsPopup from '../DetailsPopup/DetailsPopup';
import { DataTableMeta } from '../DetailsPopup/DetailsPopup.types';
import { messages } from './ActionsCell.messages';
import { CellContent } from './ActionsCell.styles';

const ActionsCell = memo<DataTableCellComponentProps<DataTableMeta, NextCompanySyncCommand>>((props) => {
    const {
        item: command,
        meta: { onDeleteSuccess },
    } = props;

    const [showDetails, setShowDetails] = useState(false);

    const { mutate: deleteCommand } = useDeleteCommand(command.id, command.companyId, onDeleteSuccess);

    const onViewDetails = useCallback(() => {
        setShowDetails(true);
    }, []);

    const onRequestClose = useCallback(() => {
        setShowDetails(false);
    }, []);

    return (
        <DataTable.DefaultCell {...props}>
            <CellContent>
                <Button execute={onViewDetails} preset='compact'>
                    {messages.detailsButton}
                </Button>

                <ConfirmationDropdown confirmation={messages.deleteConfirmationText}>
                    <Button execute={deleteCommand} preset='compact' colorTheme='red'>
                        {messages.deleteButton}
                    </Button>
                </ConfirmationDropdown>
            </CellContent>

            <Popup isOpen={showDetails} onRequestClose={onRequestClose}>
                <DetailsPopup id={command.id} details={command.content} />
            </Popup>
        </DataTable.DefaultCell>
    );
});

export default ActionsCell;
