import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.MaintenancePage.AnnouncementPanel', {
    announcementHeader: 'Announcement',
    message: 'Announcement Message (displayed at the top of the app page)',
    messageId: 'Message ID',
    messageIdInfo:
        'If you regenerate the message ID, the user will see the current message even if they closed it before.',
    idIsRequiredMessage:
        'A message ID must be set if you want to save an announcement message. Either generate an ID or clear the message field',
    generateButton: 'Generate',
    saveAnnouncementButton: 'Save',
});
