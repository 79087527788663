import each from 'lodash/each';
import { DeepPartial } from 'utility-types';

export const getShallowDifference = <T extends object>(objA: T, objB: T): DeepPartial<T> => {
    let diff: any = {};

    each(objB, (v, k) => {
        // eslint-disable-next-line no-prototype-builtins
        if (objA.hasOwnProperty(k)) {
            if ((objA as any)[k] !== v) {
                diff[k] = v;
            }
        } else {
            diff[k] = v;
        }
    });
    each(objA, (v, k) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!objB.hasOwnProperty(k)) {
            diff[k] = undefined;
        }
    });

    return diff;
};
