import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.DatabaseAccessPage', {
    pageTitle: 'Direct Database Access (SQL Execution)',
    breadcrumbRoot: 'Infrastructure',
    disclaimer: `
        This tool gives <b>direct access to the production database</b>.
        Any executed SQL query will be <b>recorded in the Audit Log</b>.
        An immediate report will be sent to your supervisor with the information of the executed queries.
        Only readonly queries are permitted.
    `,
    executeQueryButton: 'Execute',
    executeDownloadCsvButton: 'Download CSV',
    executeDownloadJsonButton: 'Download JSON',
    rowsCount: '{count} rows',
});
