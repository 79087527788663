import './index.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

const start = async () => {
    // intl polyfills below, see:
    // https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md
    //

    if (!window.Intl) {
        await import(/* webpackChunkName: "intl-polyfill" */ 'intl/lib/core.js');
        await import(/* webpackChunkName: "intl-polyfill" */ 'intl/locale-data/jsonp/en.js');
    }

    if (!window.Intl.PluralRules) {
        await import(/* webpackChunkName: "intl-polyfill" */ 'intl-pluralrules');
    }

    if (!window.Intl.RelativeTimeFormat) {
        await import(/* webpackChunkName: "intl-polyfill" */ '@formatjs/intl-relativetimeformat/polyfill');
    }

    const startApplication = (await import(/* webpackMode: "eager" */ 'application')).default;

    await startApplication();
};

start();
