import { LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { JsonViewer, NavBarMode, PageLayout, Section, Toolbar } from 'modules/components';
import { useAvailableCompanyBetaFeatures } from 'modules/customerSupport/hooks/useAvailableCompanyBetaFeatures';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { useReportDetails } from './hooks/useReportDetails';
import { messages } from './ReportDetailsPage.messages';
import { PathParams } from './ReportDetailsPage.types';

const ReportDetailsPage: FC = () => {
    const history = useHistory();
    const { companyId, reportId } = useParams<PathParams>();
    const { reportDetails } = useReportDetails(companyId, reportId);
    const { availableCompanyBetaFeatures } = useAvailableCompanyBetaFeatures();

    usePermissionsRedirect((permissionFlags) => permissionFlags.reports.read);

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('reports'));
        }
    }, [history]);

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                {!availableCompanyBetaFeatures || !reportDetails ? (
                    <LoadingBar />
                ) : (
                    <ScrollableAreaWrapper>
                        <Toolbar title={reportDetails.reportName} />

                        <ScrollableArea css='flex: 1; overflow: hidden;'>
                            <Box spacing='20 60'>
                                <Section header={messages.rawDataHeader}>
                                    <JsonViewer json={reportDetails} />
                                </Section>
                            </Box>
                        </ScrollableArea>
                    </ScrollableAreaWrapper>
                )}
            </Box>
        </PageLayout>
    );
};

export default memo(ReportDetailsPage);
