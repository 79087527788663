import { mixins, mods, RequiredMods, theme } from '@approvalmax/theme';
import { css } from 'styled-components';

export const iconStyles = css`
    /**
      * Styles for svg component icons. Brought here as classes, because for some reason,
      * when you create a normal Styled Component, its styles are not displayed.
       */
    .am-icon {
        display: inline-flex;
        shape-rendering: auto;
        vertical-align: top;

        ${Object.keys(mods.color).map(
            (value: RequiredMods['color']) => css`
                &_color-${value} {
                    color: ${theme.color[value]};
                }
            `
        )};

        ${Object.keys(mods.iconSize).map(
            (iconSize) => css`
                &_size-${iconSize} {
                    ${mixins.iconSize(Number(iconSize) as RequiredMods['iconSize'])};
                }
            `
        )};

        &_pointer {
            cursor: pointer;
        }
    }
`;
