export enum QueryKeys {
    AuditLogEntries = 'AuditLogEntries',
    StorageQueues = 'StorageQueues',
    StorageQueueMessages = 'StorageQueueMessages',
    AvailableUserBetaFeatures = 'AvailableUserBetaFeatures',
    AvailableCompanyBetaFeatures = 'AvailableCompanyBetaFeatures',
    Companies = 'Companies',
    CompanyDetails = 'CompanyDetails',
    Users = 'Users',
    UserDetails = 'UserDetails',
    Reports = 'Reports',
    ReportDetails = 'ReportDetails',
    Requests = 'Requests',
    RequestDetails = 'RequestDetails',
    UndeliveredEmails = 'UndeliveredEmails',
    UndeliveredEmailDetails = 'UndeliveredEmailDetails',
    AdminPortalPermissions = 'AdminPortalPermissions',
    AdminPortalAccounts = 'AdminPortalAccounts',
    AnnouncementInfo = 'AnnouncementInfo',
    MaintenanceInfo = 'MaintenanceInfo',
    StartTwoFaInfo = 'StartTwoFaInfo',
    EligibleToEnforceFraudDetectionWorkflows = 'EligibleToEnforceFraudDetectionWorkflows',
    CompanyWorkflows = 'CompanyWorkflows',
    WorkflowDetails = 'WorkflowDetails',
    WorkflowVersionDetails = 'WorkflowVersionDetails',
    DeletedParticipants = 'DeletedParticipants',
    MyAccountPermissions = 'MyAccountPermissions',
    MyAccountPermissionsForUser = 'MyAccountPermissionsForUser',
    IntegrationConfigurations = 'IntegrationConfigurations',
    CompanySyncCommandsCount = 'CompanySyncCommandsCount',
    NextCompanySyncCommand = 'NextCompanySyncCommand',
    BudgetsByCompanyId = 'BudgetsByCompanyId',
    BudgetDetails = 'BudgetDetails',
    BudgetValues = 'BudgetValues',
    TfaDisablingHistory = 'TfaDisablingHistory',
    ShardsList = 'ShardsList',
    CompanyShard = 'CompanyShard',
    SupportedClients = 'SupportedClients',
    TenantMovementData = 'TenantMovementData',
    TenantCurrentMovement = 'TenantCurrentMovement',
}
