/**
 * Developer: Stepan Burguchev
 * Date: 3/6/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './actionMenuSeparator.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('ui-action-menu-separator');

const ActionMenuSeparator: FC = ({}) => {
    return <div className={bem()} />;
};

export default memo(ActionMenuSeparator);
