import { FC, PropsWithChildren } from 'react';

import { Root } from './ScrollableAreaWrapper.styles';

const ScrollableAreaWrapper: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    return <Root>{children}</Root>;
};

export default ScrollableAreaWrapper;
