import { clearParamsByPath } from './clearParamsByPath';
import { createQueryParamsHook } from './createQueryParamsHook';
import { getParamAsNumber } from './getParamAsNumber';
import { getParamAsString } from './getParamAsString';
import { getParamsFromPath } from './getParamsFromPath';
import { pathToUrl } from './pathToUrl';
import { testPathByParams } from './testPathByParams';

export const routerHelpers = {
    createQueryParamsHook,
    getParamAsNumber,
    getParamAsString,
    getParamsFromPath,
    pathToUrl,
    testPathByParams,
    clearParamsByPath,
};
