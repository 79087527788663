import { domHelpers, hooks } from '@approvalmax/utils';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes } from 'react';

import Checkbox from '../Checkbox/Checkbox';
import Radio from '../Radio/Radio';
import { Group } from './components';
import { Control, Label, Root } from './ToggleButton.styles';
import { ChildrenComponents, ToggleButtonProps } from './ToggleButton.types';
import { useGroup } from './ToglleButton.hooks';

/**
 * A Toggle Button can be used to group related options.
 */
const ToggleButton = memo(
    forwardRef<HTMLInputElement, ToggleButtonProps>((props, ref) => {
        const {
            color = 'blue90',
            size = 'medium',
            children,
            multiple,
            value,
            innerRef,
            checked,
            block,
            disabled,
            width,
            qa,
            ...restProps
        } = useGroup({ props, ref });
        const { inputRef, parentProps } = hooks.useCaptureFocus(innerRef);

        return (
            <Root
                $color={color}
                $size={size}
                $checked={checked}
                $block={block}
                $disabled={disabled}
                $width={width}
                data-qa={domHelpers.generateDataQa(qa, 'toggle-button')}
                {...parentProps}
            >
                {multiple ? (
                    <>
                        <Control>
                            <Checkbox
                                value={value}
                                {...restProps}
                                checked={checked}
                                disabled={disabled}
                                ref={inputRef}
                                tabIndex={0}
                            />
                        </Control>

                        <Label>{children}</Label>
                    </>
                ) : (
                    <>
                        <Control>
                            <Radio
                                value={value}
                                {...restProps}
                                checked={checked}
                                disabled={disabled}
                                ref={inputRef}
                                tabIndex={0}
                            />
                        </Control>

                        <Label>{children}</Label>
                    </>
                )}
            </Root>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<ToggleButtonProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

ToggleButton.Group = Group;

export default ToggleButton;
