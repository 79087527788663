/**
 * Developer: Stepan Burguchev
 * Date: 3/6/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './confirmationDropdown.scss';

import { eventHelpers } from '@approvalmax/utils';
import React, { Children, FC, PropsWithChildren, ReactElement, ReactNode, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { Dropdown } from '../../drop';
import { BaseButtonProps } from '../button/types';
import { messages } from './ConfirmationDropdown.messages';

const bem = bemFactory.block('ui-confirmation-dropdown');
const qa = bemFactory.block('ui-confirmation-dropdown');

export interface ConfirmationDropdownProps extends PropsWithChildren {
    className?: string;
    confirmation: ReactNode;
    confirmationYesText?: ReactNode;
    confirmationNoText?: ReactNode;
}

const ConfirmationDropdown: FC<ConfirmationDropdownProps> = (props) => {
    const { children, className, confirmation, confirmationYesText, confirmationNoText } = props;

    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    let child = Children.only(children) as ReactElement;

    const childProps = child.props as BaseButtonProps;
    const childExecute = childProps.execute;

    if (childProps.command && childProps.command.hidden) {
        return null;
    }

    child = React.cloneElement(child, {
        execute: open,
    });

    return (
        <Dropdown
            className={className}
            onRequestClose={close}
            isOpen={isOpen}
            button={child}
            panelMinWidth='none'
            panelFlow='center'
        >
            <div
                className={bem('confirmation-panel')}
                data-qa={qa('confirmation-panel')}
                onMouseDown={eventHelpers.preventDefaultCallback}
            >
                <div className={bem('confirmation-panel-title')}>{confirmation}</div>

                <div className={bem('confirmation-panel-btn-list')}>
                    <div
                        className={bem('confirmation-panel-btn', { default: true })}
                        onClick={(event: React.SyntheticEvent<any>) => {
                            close();

                            eventHelpers.stopPropagationCallback(event);

                            if (childExecute) {
                                childExecute();
                            }
                        }}
                        data-qa={qa('confirmation-yes-button')}
                    >
                        {confirmationYesText || messages.yesButtonText}
                    </div>

                    <div
                        className={bem('confirmation-panel-btn')}
                        onClick={(event: React.SyntheticEvent<any>) => {
                            eventHelpers.stopPropagationCallback(event);

                            close();
                        }}
                        data-qa={qa('confirmation-no-button')}
                    >
                        {confirmationNoText || messages.noButtonText}
                    </div>
                </div>
            </div>
        </Dropdown>
    );
};

export default ConfirmationDropdown;
