/**
 * Developer: Stepan Burguchev
 * Date: 2/17/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './textButton.scss';

import { FC } from 'react';
import bemFactory from 'react-bem-factory';

import TransparentButton from './TransparentButton';
import { BaseButtonProps } from './types';

export const ALL_TEXT_BUTTON_SIZES = ['small2', 'small1', 'normal', 'normal-plus', 'large'] as const;
export type TextButtonSize = (typeof ALL_TEXT_BUTTON_SIZES)[number];

export const ALL_TEXT_BUTTON_COLOR_THEMES = [
    'forest-green',
    'dark-green',
    'red',
    'dark-grey',
    'white',
    'black',
    'green',
] as const;
export type TextButtonColorTheme = (typeof ALL_TEXT_BUTTON_COLOR_THEMES)[number];

const bem = bemFactory.block('ui-text-button');

export interface TextButtonProps extends BaseButtonProps {
    size?: TextButtonSize;
    colorTheme?: TextButtonColorTheme;
    upperCased?: boolean;
    underlined?: boolean;
}

const TextButton: FC<TextButtonProps> = (props) => {
    const {
        colorTheme = 'forest-green',
        size = 'normal',
        upperCased = true,
        underlined = false,
        type = 'button',
        className,
        children,
        ...buttonProps
    } = props;

    return (
        <TransparentButton {...buttonProps}>
            {({ elementProps }) => (
                <button
                    type={type}
                    {...elementProps}
                    className={bem.add(className)(null, 'size-' + size, 'color-' + colorTheme, {
                        'upper-cased': upperCased,
                        underlined,
                    })}
                >
                    {children}
                </button>
            )}
        </TransparentButton>
    );
};

export default TextButton;
