import { cloneElement, Component } from 'react';

import { filtered, withoutExcluded } from './PlainDataProvider.helpers';
import { PlainDataProviderItem, PlainDataProviderProps, PlainDataProviderState } from './PlainDataProviderItem';

class PlainDataProvider extends Component<PlainDataProviderProps, PlainDataProviderState> {
    public static defaultProps = {
        filterAttribute: 'text',
    };

    public state = {
        items: [],
    };

    private _filterText: string | null = null;
    private _excludedIds: string[] = [];

    public UNSAFE_componentWillReceiveProps(nextProps: PlainDataProviderProps) {
        if (nextProps.items !== this.props.items || nextProps.filterAttribute !== this.props.filterAttribute) {
            this._updateFilteredItems(nextProps.items, nextProps.filterAttribute!, nextProps.filterFn);
        }
    }

    public render() {
        return cloneElement(this.props.children, {
            hasMore: false,
            items: this.state.items,
            onLoadItems: this._onLoadItems,
            dataMode: 'sync',
        });
    }

    private _onLoadItems = (filterText: string, excludedIds: string[]) => {
        this._filterText = filterText;
        this._excludedIds = excludedIds;
        this._updateFilteredItems(this.props.items, this.props.filterAttribute!, this.props.filterFn);
    };

    private _updateFilteredItems = (
        items: PlainDataProviderItem[],
        filterAttribute: string,
        filterFn: ((item: PlainDataProviderItem, filterText: string) => boolean) | undefined
    ) => {
        const filteredItems = filtered(
            withoutExcluded(items, this._excludedIds),
            this._filterText,
            filterAttribute,
            filterFn
        );

        this.setState({
            items: filteredItems,
        });
    };
}

export default PlainDataProvider;
