import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.EnforcementTfaPage', {
    pageTitle: '2FA Enforcement',
    breadcrumbRoot: 'Customer Success',

    title: 'Two-factor Authentication Enforcement',
    buttonApply: 'Apply changes',
    headGL: 'GL',
    headTfaEnforcement: '2FA Enforcement',
    saveSuccess: 'Configurations successfully saved',

    optionNone: 'ApprovalMax',
    optionXero: 'Xero',
    optionQbooks: 'QuickBooks Online',
    optionDear: 'Dear',
    optionNetSuite: 'NetSuite',

    enforcementOptionNone: 'None',
    enforcementOptionSoft: 'Soft Enforcement',
    enforcementOptionHard: 'Hard Enforcement',
});
