import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestDetailsPage.DeleteRestoreRequestPopupContent', {
    validationErrorsToast: 'Please fill in all required fields',
    deleteRequestsTitle: 'Delete request',
    restoreRequestsTitle: 'Restore request',
    deleteButton: 'Delete',
    restoreButton: 'Restore',
    deleteDescription:
        'You are about to <b>DELETE</b> the given request. Please verify the request ID and provide a comment and related ticket ID.',
    restoreDescription:
        'You are about to <b>RESTORE</b> the given request. Please verify the request ID and provide a comment and related ticket ID.',
    companyId: 'Company ID',
    companyIdPlaceholder: 'Enter Company ID of the request below',
    requestIds: 'Request ID',
    requestIdsPlaceholder: 'Enter request ID',
    ticket: 'Ticket ID',
    ticketIdPlaceholder: 'Enter ticket ID',
    comment: 'Comment',
    commentPlaceholder: 'Enter comment',
});
