import './checkboxCell.scss';

import React, { Component } from 'react';
import bemFactory from 'react-bem-factory';

import Checkbox from '../checkbox/Checkbox';
import DefaultCell from './DefaultCell';
import { DataTableCellComponentProps, DataTablePadding } from './index';

interface CheckboxCellComponentProps extends DataTableCellComponentProps {
    disabled?: boolean;
    cellComponent?: React.ComponentType<DataTableCellComponentProps>;
}

class CheckboxCell extends Component<CheckboxCellComponentProps> {
    public static defaultProps = {
        disabled: false,
        padding: DataTablePadding.Default,
        cellComponent: DefaultCell,
    };

    public render() {
        const { className, style, checked, padding, columnDefinition, qa, disabled } = this.props;
        const bem = bemFactory.block('ui-data-table-checkbox-cell');
        const CellComponent = this.props.cellComponent!;

        return (
            <div
                className={bem.add(className)(null, padding!)}
                data-qa={qa}
                data-qa-id={columnDefinition.id}
                style={style}
            >
                <div className={bem('checkbox')} onClick={this._onCheck}>
                    <Checkbox disabled={disabled} checked={checked} size='size-18' />
                </div>

                <CellComponent {...this.props} className='' padding={DataTablePadding.Default} />
            </div>
        );
    }

    private _onCheck = (e: React.SyntheticEvent<any>) => {
        if (this.props.disabled) {
            return;
        }

        this.props.onCheck(this.props.item, !this.props.checked);
        e.preventDefault();
    };
}

export default CheckboxCell;
