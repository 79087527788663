import { useCallback, useEffect, useState } from 'react';

import { ContextValue, GroupProps } from './Group.types';

export const useCheckedValues = (value: GroupProps['value']) => {
    const [checkedValue, toggleCheckedValue] = useState(value || '');

    /**
     * Updating the internal checked value when the external value changes
     */
    useEffect(() => toggleCheckedValue(value || ''), [value]);

    /**
     * If a target radio value in the checked values, it is checked
     */
    const getCheckedStatus = useCallback<ContextValue['contextProps']['getCheckedStatus']>(
        (targetValue) => targetValue === checkedValue,
        [checkedValue]
    );

    return {
        toggleCheckedValue,
        getCheckedStatus,
    };
};
