import React, { memo, useCallback, useMemo, useRef, useState } from 'react';

import { ContentImage, ContentImageImg } from './ImageViewer.styles';
import { ImageSize, ImageViewerProps } from './ImageViewer.types';

const ImageViewer = memo<ImageViewerProps>((props) => {
    const { attachment, isLoading, onImageLoaded, onLoadError } = props;

    const [imageSize, setImageSize] = useState<ImageSize | null>(null);
    const [imageZoomed, setImageZoomed] = useState(false);

    const imageRef = useRef<HTMLImageElement | null>(null);

    const onLoad = useCallback(() => {
        const newImageSize = imageRef.current
            ? {
                  width: imageRef.current.width,
                  height: imageRef.current.height,
              }
            : null;

        setImageSize(newImageSize);

        setImageZoomed(false);

        onImageLoaded();
    }, [onImageLoaded]);

    const toggleImageZoom = useCallback((event: React.SyntheticEvent<any>) => {
        event.stopPropagation();

        setImageZoomed((current) => !current);
    }, []);

    const style = useMemo(() => {
        if (imageSize && imageZoomed) {
            return {
                width: `${imageSize.width * 2}px`,
                height: `${imageSize.height * 2}px`,
            };
        }

        return;
    }, [imageSize, imageZoomed]);

    return (
        <ContentImage $hide={isLoading}>
            <ContentImageImg
                $zoomed={imageZoomed}
                style={style}
                ref={imageRef}
                onLoad={onLoad}
                onError={onLoadError}
                src={attachment.url}
                onClick={toggleImageZoom}
                title={attachment.name}
            />
        </ContentImage>
    );
});

export default ImageViewer;
