import { RefObject, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

/**
 * Hook to trigger load more function
 * when user scrolls to bottom of container
 * based on IntersectionObserver
 *
 * @param parentRef ref for container with scroll
 * @param onLoadMore load more callback
 * @param bottomDistance amount of pixels from container's bottom
 * when load more fn will be triggered
 * @param triggerOnce trigger callback only once
 *
 * @returns ref to assign to trigger element that should be monitored
 */
export const useLoadMoreOnObserve = (
    parentRef: RefObject<HTMLElement>,
    onLoadMore: VoidFunction,
    bottomDistance = 60,
    triggerOnce = false
) => {
    const { ref: triggerRef, inView } = useInView({
        root: parentRef.current,
        rootMargin: `0px 0px ${bottomDistance}px 0px`,
        triggerOnce,
    });

    useEffect(() => {
        if (inView) {
            onLoadMore();
        }
    }, [inView, onLoadMore]);

    return triggerRef;
};
