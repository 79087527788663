import { Search } from 'history';

/**
 * @deprecated Use {@link useQueryParam} instead.
 */
export const getParamAsString = (search: Search, key: string, defaultValue = '') => {
    const param = new URLSearchParams(search).get(key);

    return param || defaultValue;
};
