import { ExternalLink, TextEditor } from '@approvalmax/ui';
import { useStartTwoFa, useVerifyTwoFaCode } from 'pages/userSettings/UserSettingsPage.hooks';
import { FC, memo, useCallback, useRef, useState } from 'react';

import { messages } from './TwoFaSetupStep2.messages';
import { Content, Description, NextButton, Root, StyledField, Title } from './TwoFaSetupStep2.styles';
import { TwoFaSetupStep2Props } from './TwoFaSetupStep2.types';

const TwoFaSetupStep2: FC<TwoFaSetupStep2Props> = (props) => {
    const { closeTwoFaPopup } = props;

    const [userCode, setUserCode] = useState('');
    const [wrongCode, setWrongCode] = useState<string | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const { data: twoFaData } = useStartTwoFa();

    const stateId = twoFaData?.stateId ?? '';

    const { verifyTwoFaCode } = useVerifyTwoFaCode();

    const isLoading = false;
    const canNext = Boolean(userCode.trim()) && !isLoading;

    const onCodeChange = useCallback((value: string) => setUserCode(value), []);

    const goToNext = useCallback(async () => {
        if (!canNext) {
            return;
        }

        try {
            setWrongCode(null);

            await verifyTwoFaCode({
                stateId,
                code: userCode,
            });

            closeTwoFaPopup();
        } catch {
            setWrongCode(userCode);

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [canNext, stateId, userCode, verifyTwoFaCode, closeTwoFaPopup]);

    return (
        <Root title={messages.popupTitle}>
            <Content>
                <Title>{messages.title}</Title>

                <Description>
                    {messages.description({
                        br: <br />,
                        supportlink: (chunks: any) => (
                            <ExternalLink href='https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-2fa'>
                                {chunks}
                            </ExternalLink>
                        ),
                    })}
                </Description>

                <StyledField
                    title={messages.codeFieldTitle}
                    errorText={wrongCode === userCode && messages.wrongCodeErrorText}
                >
                    <TextEditor
                        focusOnMount
                        disabled={isLoading}
                        value={userCode}
                        onChange={onCodeChange}
                        placeholder={messages.userCodePlaceholder}
                        invalid={wrongCode === userCode}
                        ref={inputRef}
                        onEnter={goToNext}
                    />
                </StyledField>

                <NextButton execute={goToNext} disabled={!canNext}>
                    {messages.nextButton}
                </NextButton>
            </Content>
        </Root>
    );
};

export default memo(TwoFaSetupStep2);
