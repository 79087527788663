/**
 * Developer: Stepan Burguchev
 * Date: 2/17/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './transparentButton.scss';

import { hooks, miscHelpers } from '@approvalmax/utils';
import React, {
    ButtonHTMLAttributes,
    forwardRef,
    ReactNode,
    Ref,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import bemFactory from 'react-bem-factory';

import { BaseButtonProps, ButtonElement } from './types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface TransparentButtonProps extends BaseButtonProps {
    children?:
        | ReactNode
        | ((options: {
              disabled: boolean;
              elementProps: ButtonHTMLAttributes<HTMLButtonElement> & {
                  ref: Ref<HTMLButtonElement>;
                  'data-qa'?: string;
                  'data-qa-name'?: string;
              };
              onExecute: () => boolean;
          }) => ReactNode);
    alwaysPropagationExecute?: boolean;
    'data-starter-guide'?: string;
}

const TransparentButton = forwardRef<ButtonElement, TransparentButtonProps>((props, ref) => {
    const {
        title,
        className,
        qa,
        qaName,
        command = { disabled: false },
        focusable,
        children,
        type = 'button',
        focusOnMount,
        alwaysPropagationExecute,
        execute: propsExecute = miscHelpers.noop,
        onFocus: propOnFocus,
        onBlur: propOnBlur,
        'data-starter-guide': dataStarterGuide,
    } = props;

    const bem = bemFactory.block('ui-transparent-button');

    const [focused, setFocused] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    hooks.useFocusOnMount(focusOnMount, buttonRef);

    useImperativeHandle(ref, () => ({
        focus() {
            if (buttonRef.current) {
                buttonRef.current.focus();
            }
        },
        blur() {
            if (buttonRef.current) {
                buttonRef.current.blur();
            }
        },
    }));

    const disabled: boolean = props.disabled || (command && command.disabled) || false;

    const onExecute = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (disabled) {
                return false;
            }

            if (propsExecute) {
                propsExecute(e);
            }

            return true;
        },
        [disabled, propsExecute]
    );

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (onExecute(e) && !alwaysPropagationExecute) {
                e.stopPropagation();
            }
        },
        [onExecute, alwaysPropagationExecute]
    );

    const onFocus = useCallback(() => {
        setFocused(true);

        if (propOnFocus) {
            propOnFocus();
        }
    }, [propOnFocus]);

    const onBlur = useCallback(() => {
        setFocused(false);

        if (propOnBlur) {
            propOnBlur();
        }
    }, [propOnBlur]);

    if (command && command.hidden) {
        return null;
    }

    const elementProps: ButtonHTMLAttributes<HTMLButtonElement> & {
        ref: React.Ref<HTMLButtonElement>;
        'data-qa'?: string;
        'data-qa-name'?: string;
        'data-starter-guide'?: string;
    } = {
        ref: buttonRef,
        role: 'button',
        tabIndex: disabled ? undefined : focusable === false ? -1 : 0,
        title,
        onClick,
        onFocus,
        onBlur,
        disabled,
        'data-qa': qa,
        'data-qa-name': qaName,
        'data-starter-guide': dataStarterGuide,
    };

    if (typeof children === 'function') {
        return (children as any)({
            disabled,
            focused,
            elementProps,
            onExecute,
        });
    } else {
        return (
            <button
                type={type}
                className={bem.add(className)(null, {
                    disabled,
                    focused,
                })}
                {...elementProps}
            >
                {children}
            </button>
        );
    }
});

export default TransparentButton;
