import { DataTable, Popup, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { dataHelpers, hooks } from '@approvalmax/utils';
import { User } from 'modules/api';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, SearchBar, Toolbar } from 'modules/components';
import { useFindUsers } from 'modules/customerSupport/hooks/useFindUsers';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback, useState } from 'react';

import EditPermissionsPopup from './components/EditPermissionsPopup/EditPermissionsPopup';
import { useQueryParams } from './hooks/useQueryParams';
import { dataTableColumns } from './MyAccountPermissionsPage.config';
import { messages } from './MyAccountPermissionsPage.messages';

const MyAccountPermissionsPage: FC = memo(() => {
    const { findUsers, isLoading, users } = useFindUsers();
    const { query, push } = useQueryParams();
    const [searchText, setSearchText] = useState(query);
    const { permissionFlags } = useCurrentAccount();

    const [user, setUser] = useState<User | null>(null);
    const [isEditPermissionsPopupOpen, setIsEditPermissionsPopupOpen] = useState(false);

    usePermissionsRedirect((permissionFlags) => permissionFlags.accounts.any);

    hooks.useOnMount(() => {
        if (query) {
            onSearch();
        }
    });

    const onSearch = useCallback(() => {
        findUsers(
            dataHelpers.isGuid(searchText)
                ? {
                      userId: searchText,
                  }
                : {
                      email: searchText,
                  }
        );
        push({
            query: searchText,
        });
    }, [findUsers, push, searchText]);

    const onRowClick = useCallback((user: User) => {
        setUser(user);
        setIsEditPermissionsPopupOpen(true);
    }, []);

    const filters = (
        <SearchBar
            placeholder={messages.userEmailPlaceholder}
            focusOnMount
            disabled={isLoading || !permissionFlags || !permissionFlags.accounts.any}
            onSearch={onSearch}
            value={searchText}
            onChange={setSearchText}
        />
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar filters={filters} />

                    <DataTable
                        css='flex: 1; overflow: hidden;'
                        data={users ?? []}
                        columns={dataTableColumns}
                        onRowClick={onRowClick}
                    />
                </ScrollableAreaWrapper>
            </Box>

            <Popup
                isOpen={isEditPermissionsPopupOpen && !!user}
                onRequestClose={() => setIsEditPermissionsPopupOpen(false)}
            >
                <EditPermissionsPopup user={user!} />
            </Popup>
        </PageLayout>
    );
});

export default MyAccountPermissionsPage;
