import { useInfiniteQuery } from '@tanstack/react-query';
import { api, isIgnoredEmail, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';
import { useState } from 'react';

import { messages } from '../UndeliveredEmailsPage.messages';
import { EmailSearchType } from '../UndeliveredEmailsPage.types';

const ITEMS_PER_PAGE = 25;

export const useFindUndeliveredEmails = (filter: string, emailFilterType: EmailSearchType) => {
    const [continuationToken, setContinuationToken] = useState('');

    const {
        data: undeliveredEmails,
        fetchNextPage: findUndeliveredEmails,
        isFetching,
        isFetchingNextPage,
        hasNextPage,
    } = useInfiniteQuery({
        queryKey: [QueryKeys.UndeliveredEmails, emailFilterType, filter],
        queryFn: async ({ pageParam }) => {
            if (emailFilterType === EmailSearchType.StartsWithSearch) {
                const data = await api.internalSupport.listIgnoredEmailsWithContinuationToken({
                    emailStartsWith: filter,
                    continuationToken: typeof pageParam === 'string' ? pageParam : undefined,
                    limit: ITEMS_PER_PAGE,
                });

                setContinuationToken(data.continuationToken || '');

                return data.items;
            }

            const lastEmail = isIgnoredEmail(pageParam) ? pageParam : undefined;

            return api.internalSupport.listIgnoredEmails({
                emailPart: filter,
                limit: ITEMS_PER_PAGE,
                lastEmail: lastEmail?.email,
                lastReason: lastEmail?.reason,
            });
        },
        getNextPageParam: (lastPage) => {
            if (emailFilterType === EmailSearchType.StartsWithSearch) {
                return continuationToken || undefined;
            }

            const lastEmail = lastPage[lastPage.length - 1];

            return lastPage.length === ITEMS_PER_PAGE ? lastEmail : undefined;
        },
        enabled: false,
        onSuccess: (data) => {
            if (data.pages[0] && data.pages[0].length === 0) {
                toaster.error(messages.noEmailError);
            }
        },
    });

    return {
        findUndeliveredEmails,
        undeliveredEmails,
        isFetching,
        isFetchingNextPage,
        hasMore: hasNextPage,
    };
};
