import styled from 'styled-components';

export const CellInner = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;

export const ActionMenuWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const BaseTwoFACell = styled.div`
    padding-left: 15px;
    position: relative;

    &::before {
        position: absolute;
        left: 0;
        top: 4px;
        height: 10px;
        width: 10px;
        content: ' ';
        border-radius: 50%;
    }
`;

export const DisabledTwoFACell = styled(BaseTwoFACell)`
    color: #7f7d7d;

    &::before {
        background: #384650;
    }
`;

export const EnabledTwoFACell = styled(BaseTwoFACell)`
    &::before {
        background: #77aa84;
    }
`;
