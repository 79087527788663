import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestsPage.BulkOperationPopup', {
    deleteTitle: 'Delete multiple requests',
    deleteButton: 'Delete',
    deleteDescription:
        'You are about to <b>DELETE</b> one or multiple requests. Please verify the request IDs and provide a comment and related ticket ID.',
    restoreTitle: 'Restore multiple requests',
    restoreButton: 'Restore',
    restoreDescription:
        'You are about to <b>RESTORE</b> one or multiple requests. Please verify the request IDs and provide a comment and related ticket ID.',
    companyId: 'Company ID',
    companyIdPlaceholder: 'Enter Company ID of the requests below',
    requestIds: 'Request IDs (comma separated)',
    requestIdsPlaceholder: 'Enter request IDs separated by COMMA (,)',
    ticket: 'Ticket ID',
    ticketIdPlaceholder: 'Enter ticket ID',
    comment: 'Comment',
    commentPlaceholder: 'Enter comment',
});
