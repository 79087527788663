import { QueryOptions } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import { UseAmaxPayConsentHistoryPathParams, UseAmaxPayConsentHistoryResponse } from './useAmaxPayConsentHistory.types';

export const useGetAmaxPayConsentHistory = (
    pathParams: UseAmaxPayConsentHistoryPathParams,
    queryOptions?: QueryOptions<UseAmaxPayConsentHistoryResponse>
) => {
    return useGet<UseAmaxPayConsentHistoryResponse>(amaxPayApiPaths.openBankingConsentHistory, {
        params: {
            path: pathParams,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
