import styled from 'styled-components';

import { AnimatedWrapper } from '../AnimatedWrapper/AnimatedWrapper';

export const FadingPanel = styled(AnimatedWrapper)`
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.3);
`;

export const DrawerWrapper = styled(AnimatedWrapper)`
    position: fixed;
    top: 0;
    bottom: 0;
    will-change: transform;

    & > * {
        height: 100%;
    }
`;
