import { ChangeEvent, FocusEvent, MouseEventHandler, RefObject, useCallback, useEffect, useState } from 'react';

import { calculateLinesByScrollHeight } from './TextField.helpers';
import { HTMLTextFieldElement, TextFieldProps } from './TextField.types';

export const useFocus = (
    props: Pick<TextFieldProps, 'onFocus' | 'onBlur' | 'focus' | 'initFocus'> & {
        fieldRef: RefObject<HTMLTextFieldElement>;
    }
) => {
    const { onFocus, onBlur, focus, fieldRef, initFocus = false } = props;

    const isControlledFocus = focus !== undefined;
    const [isFocus, setIsFocus] = useState(initFocus);

    useEffect(() => {
        if (initFocus !== undefined && initFocus) {
            fieldRef?.current?.focus();
        }
    }, [fieldRef, initFocus]);

    useEffect(() => {
        if (!isControlledFocus) return;

        if (focus) {
            fieldRef?.current?.focus();
        } else {
            fieldRef?.current?.blur();
        }

        setIsFocus(focus);
    }, [isControlledFocus, fieldRef, focus, setIsFocus]);

    const handleFocus = useCallback(
        (event: FocusEvent<HTMLTextFieldElement>) => {
            if (!isControlledFocus) {
                setIsFocus(true);
            }

            onFocus?.(event);
        },
        [isControlledFocus, onFocus]
    );

    const handleBlur = useCallback(
        (event: FocusEvent<HTMLTextFieldElement>) => {
            if (!isControlledFocus) {
                setIsFocus(false);
            }

            onBlur?.(event);
        },
        [isControlledFocus, onBlur]
    );

    return { isFocus, handleFocus, handleBlur };
};

export const useClearable = (
    props: Pick<TextFieldProps, 'onClear'> &
        Pick<ReturnType<typeof useValue>, 'handleChange'> & { fieldRef: RefObject<HTMLTextFieldElement> }
) => {
    const { fieldRef, handleChange, onClear } = props;

    const handleClear = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            if (!fieldRef.current) return;

            const changeEvent = new Event('clear', { bubbles: true });

            fieldRef.current.value = '';
            fieldRef.current.dispatchEvent(changeEvent);
            fieldRef.current.focus();

            onClear?.(event);
        },
        [fieldRef, onClear]
    );

    useEffect(() => {
        const field = fieldRef.current;

        field?.addEventListener('clear', handleChange);

        return () => {
            field?.removeEventListener('clear', handleChange);
        };
    }, [fieldRef, handleChange]);

    return { handleClear };
};

export const useValue = (
    props: Pick<TextFieldProps, 'value' | 'onChange' | 'controlled'> & Required<Pick<TextFieldProps, 'transform'>>
) => {
    const { value, onChange, transform, controlled } = props;

    const [fieldValue, setFieldValue] = useState(value ?? '');

    useEffect(() => {
        setFieldValue(value ?? '');
    }, [value]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLTextFieldElement> | Event) => {
            const fieldValue = event.target && 'value' in event.target ? event.target.value : '';
            const transformedValue = transform(fieldValue);

            if (!controlled) {
                setFieldValue(transformedValue);
            }

            onChange?.(transformedValue, event);
        },
        [controlled, onChange, transform]
    );

    return {
        fieldValue,
        handleChange,
    };
};

export const useValidate = (
    props: Pick<TextFieldProps, 'invalid' | 'minLength'> &
        Pick<ReturnType<typeof useValue>, 'fieldValue'> &
        Pick<ReturnType<typeof useFocus>, 'isFocus'>
) => {
    const { invalid, minLength, fieldValue, isFocus } = props;

    const [invalidValue, setInvalidValue] = useState(invalid);

    useEffect(() => setInvalidValue(invalid), [invalid]);
    useEffect(() => {
        if (!fieldValue || invalid) return;

        if (typeof minLength !== 'undefined') {
            if (fieldValue.length < minLength && !isFocus) {
                setInvalidValue(true);
            } else {
                setInvalidValue(false);
            }
        }
    }, [fieldValue, isFocus, minLength, invalid]);

    return {
        invalidValue,
        setInvalidValue,
    };
};

export const useResizeHeight = (fieldRef: RefObject<HTMLTextFieldElement>) => {
    return useCallback(() => {
        const control = fieldRef?.current;

        if (!control) {
            return;
        }

        const controlStyles = getComputedStyle(control);
        const lineHeight = parseInt(controlStyles.getPropertyValue('line-height'), 10);
        const paddingTop = parseInt(controlStyles.getPropertyValue('padding-top'), 10);
        const paddingBottom = parseInt(controlStyles.getPropertyValue('padding-bottom'), 10);
        const linesByScrollHeight = calculateLinesByScrollHeight({
            height: control.scrollHeight,
            paddingTop,
            paddingBottom,
            lineHeight,
        });

        control.style.height = 'auto';

        if (linesByScrollHeight > 1) {
            control.style.height = `${control.scrollHeight}px`;
        }
    }, [fieldRef]);
};
