import { useRef } from 'react';

/**
 * Wrap the value into a ref to be able to use the up-to-date values inside
 * various callbacks (without recreating them).
 */
export const useWrapInRef = <T>(value: T) => {
    const valueRef = useRef<T>(value);

    valueRef.current = value;

    return valueRef;
};
