import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.BudgetDetailsPage', {
    pageTitle: 'Budgets',
    pageDetailsTitle: 'Budget details',
    breadcrumbRoot: 'Customer Success',

    searchPlaceholder: 'Enter Company ID',
    columnCompanyId: 'Company ID',
    columnBudgetId: 'Budget ID',
    columnBudgetName: 'BudgetName',

    errorIncorrectCompanyId: 'Please, enter valid company ID',
    errorNoValues: 'No values provided',
    errorSelectDate: 'Please, select a date',

    sectionDetails: 'Details',
    sectionValues: 'Budget values',
    sectionValuesPlaceholder: 'Select a date',
    sectionValuesAccordion: 'Values',
});
