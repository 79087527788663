import { forwardRef, memo, useContext } from 'react';
import { useController } from 'react-hook-form';

import Checkbox from '../../Checkbox';
import { GroupContext } from '../Group/Group.context';
import { ControllerProps } from './Controller.types';

/**
 * The `Checkbox.Controller` allows you to use the `Checkbox` with the `react-hook-form` library.
 * They take the same properties as the `Checkbox` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, name, rules, children, ...restProps } = props;

        const { contextProps } = useContext(GroupContext);

        const { field, fieldState } = useController({
            control,
            name: name || contextProps.name || 'name',
            rules: { required: restProps.required },
        });
        const { value, ...restField } = field;

        return (
            <Checkbox {...restField} {...fieldState} {...restProps} ref={ref} checked={value}>
                {children}
            </Checkbox>
        );
    })
);

export default Controller;
