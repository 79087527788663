import { oPopupPadding, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const StyledPopupContent = styled(Popup.DefaultContent)`
    width: 1000px;
`;

export const Content = styled.div`
    ${oPopupPadding};
`;
