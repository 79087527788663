import { WarnIcon } from '@approvalmax/ui/src/old/icons';
import { FC, memo } from 'react';

import { Icon, Root } from './Alert.styles';
import { AlertProps } from './Alert.types';

const Alert: FC<AlertProps> = (props) => {
    const { children } = props;

    return (
        <Root>
            <Icon>
                <WarnIcon />
            </Icon>

            <div>{children}</div>
        </Root>
    );
};

export default memo(Alert);
