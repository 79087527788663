import { OnChange, OnMount } from '@monaco-editor/react';
import { useCallback } from 'react';

interface UseEditorProps {
    value: string;
    setValue: (value: string) => void;
}

const editorOptions = {
    minimap: {
        enabled: false,
    },
};

export const useEditor = (props: UseEditorProps) => {
    const { setValue, value } = props;

    const editorOnChange: OnChange = useCallback((newValue) => setValue(newValue ?? ''), [setValue]);

    const editorOnMount: OnMount = useCallback(
        (editor) => {
            const initialText = value;

            editor.setValue(initialText);
            editor.focus();
            editor.setSelection({
                startLineNumber: 1,
                startColumn: 1,
                endLineNumber: 1,
                endColumn: initialText.length + 1,
            });
        },
        [value]
    );

    return {
        editorOptions,
        editorOnChange,
        editorOnMount,
    };
};
