const pathsMap = {
    root: () => '',
    permissions: () => 'permissions',
    healthCheck: () => 'ping',
    myAccountPermissions: () => 'myAccountPermissions',
    auditLog: () => 'auditLog',
    dashboard: () => 'dashboard',
    featureFlags: () => 'featureFlags',
    databaseAccess: () => 'databaseAccess',
    users: (userId?: string) => (userId ? `users/${userId}` : 'users'),
    companies: (companyId?: string) => (companyId ? `companies/${companyId}` : 'companies'),
    requests: () => 'requests',
    requestDetail: (companyId: string, requestId: string) => `companies/${companyId}/requests/${requestId}`,
    reports: (reportId?: string, companyId?: string) =>
        reportId && companyId ? `companies/${companyId}/reports/${reportId}` : 'reports',
    storageQueues: (queueId?: string) => (queueId ? `storageQueues/${queueId}` : 'storageQueues'),
    maintenance: () => 'maintenance',
    undeliveredEmails: (email?: string) =>
        email ? `undeliveredEmails/details?email=${encodeURIComponent(email)}` : 'undeliveredEmails',
    userSettings: () => 'userSettings',
    enforcement2fa: () => 'enforcement-2fa',
    budgets: () => 'budgets',
    budgetDetails: (companyId: string, budgetId: string) => `budgets/company/${companyId}/budget/${budgetId}`,
    syncCommandManagement: () => 'syncCommandManagement',
    findCompanyShard: () => 'findCompanyShard',
    manageTenants: () => 'manageTenants',
    mobileMinVersion: () => 'mobileMinVersion',
    amaxPayBankAccounts: () => 'amaxPayBankAccounts',
    amaxPayBankAccount: (companyId: string, accountId: string) =>
        `amaxPayBankAccounts/companies/${companyId}/accounts/${accountId}`,
    amaxPayPayments: () => 'amaxPayPayments',
    amaxPayPayment: (companyId: string, paymentId: string) =>
        `amaxPayPayments/companies/${companyId}/payments/${paymentId}`,
    amaxPayConsents: () => 'amaxPayConsents',
    amaxPayConsent: (companyId: string, consentId: string) =>
        `amaxPayConsents/companies/${companyId}/consents/${consentId}`,
};

export type PathsMap = typeof pathsMap;

/**
 * Get a local path to a resource inside the web app.
 *
 * Example:
 * ```
 * getUrl('request', requestId)
 * // => request?id=989e6dfb-3f65-463c-b1c5-2df2ea9887e6
 * ```
 */
export const getPath = <TRoute extends keyof PathsMap>(route: TRoute, ...params: Parameters<PathsMap[TRoute]>) => {
    const pathCb: (...args: any[]) => string = pathsMap[route];

    return '/' + pathCb(...params);
};
