import padStart from 'lodash/padStart';
import moment from 'moment';

const validValueRegExp = /^\d{4}-\d{2}$/;

export const parseValue = (value?: string | null): { year: number; month: number } | null => {
    if (!value || !validValueRegExp.test(value)) {
        return null;
    }

    const date = moment(value);

    return {
        year: date.year(),
        month: date.month() + 1,
    };
};

export const getValueString = (year: number, month: number) => {
    const monthIndex = month + 1;

    const monthString = padStart(String(monthIndex), 2, '0');

    return `${year}-${monthString}`;
};

export const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getMonthName = (monthIndex: number) => {
    return monthList[monthIndex] || '';
};

export const checkMonthIsDisabled = (value: string, minValue?: string | null, maxValue?: string | null) => {
    if (!minValue && !maxValue) {
        return false;
    }

    const parsedValue = parseValue(value);
    const parsedMinValue = parseValue(minValue);
    const parsedMaxValue = parseValue(maxValue);

    if (parsedValue && parsedMinValue) {
        if (parsedValue.year < parsedMinValue.year) {
            return true;
        }

        if (parsedValue.year === parsedMinValue.year && parsedValue.month < parsedMinValue.month) {
            return true;
        }
    }

    if (parsedValue && parsedMaxValue) {
        if (parsedValue.year > parsedMaxValue.year) {
            return true;
        }

        if (parsedValue.year === parsedMaxValue.year && parsedValue.month > parsedMaxValue.month) {
            return true;
        }
    }

    return false;
};
