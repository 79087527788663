import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath, PathsMap } from 'urlBuilder';

export const usePageGoBack = (fallbackPage: keyof PathsMap) => {
    const history = useHistory();

    const onBack = useCallback(() => {
        if (history.length > 0) {
            history.goBack();
        } else {
            history.push(getPath(fallbackPage));
        }
    }, [fallbackPage, history]);

    return { onBack };
};
