import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { FlexProps } from './Flex.types';

export const Root = styled.div<
    StyledMods<
        Pick<
            FlexProps,
            | 'container'
            | 'wrap'
            | 'basis'
            | 'size'
            | 'offset'
            | 'justifyContent'
            | 'alignItems'
            | 'height'
            | 'width'
            | 'maxHeight'
            | 'maxWidth'
            | 'minHeight'
            | 'minWidth'
            | 'block'
            | 'grow'
            | 'shrink'
            | 'direction'
            | 'overflow'
            | 'overflowY'
        > & {
            rowSpacing: number;
            colSpacing: number;
        }
    >
>`
    display: flex;
    row-gap: ${({ $rowSpacing }) => $rowSpacing}px;
    column-gap: ${({ $colSpacing }) => $colSpacing}px;

    > * {
        flex-basis: auto;
    }

    ${mods('container', true)`
        flex-direction: row;
        margin: ${({ $rowSpacing }) => $rowSpacing / -2}px ${({ $colSpacing }) => $colSpacing / -2}px;
        row-gap: initial;
        column-gap: initial;

        > * {
            flex-basis: auto;
            padding: ${({ $rowSpacing }) => $rowSpacing / 2}px ${({ $colSpacing }) => $colSpacing / 2}px;
        }

         ${mods.not('size')`
            width: ${({ $colSpacing }) => ($colSpacing ? `calc(100% + ${$colSpacing}px)` : '100%')};
        `};
    `};

    ${mods('block', true)`
        display: block;
        row-gap: initial;
        column-gap: initial;

        > * {
            flex-basis: initial;
        }
    `};

    ${mods.wrap.true`
        flex-wrap: wrap;
    `};

    ${mods('size')((value) => {
        switch (value) {
            case 0:
                return css`
                    flex-grow: 1;
                `;

            default:
                return css`
                    width: ${(100 * value) / 24}%;
                `;
        }
    })};

    ${mods('offset')(
        (value) => css`
            margin-left: ${(100 * value) / 24}%;
        `
    )};

    ${mods('height')(
        (value) => css`
            height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('maxHeight')(
        (value) => css`
            max-height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('maxWidth')(
        (value) => css`
            max-width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('minHeight')(
        (value) => css`
            min-height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('minWidth')(
        (value) => css`
            min-width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('overflow')(
        (value) => css`
            overflow: ${value};
        `
    )};

    ${mods('overflowY')(
        (value) => css`
            overflow-y: ${value};
        `
    )};

    ${mods('basis')(
        (value) => css`
            flex-basis: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    flex-direction: ${({ $direction }) => $direction};
    align-items: ${({ $alignItems }) => $alignItems};
    justify-content: ${({ $justifyContent }) => $justifyContent};
    flex-grow: ${({ $grow }) => $grow};
    flex-shrink: ${({ $shrink }) => $shrink};
`;
