import {
    Button,
    DataTable,
    DataTableColumnDefinition,
    DataTablePadding,
    DateTimeEditor,
    LoadingBar,
} from '@approvalmax/ui';
import { FC, memo, useEffect, useMemo, useState } from 'react';

import {
    useEligibleToEnforceFraudDetectionWorkflows,
    useEnforceDetectionMutation,
} from './EnforceFraudDetectionPopup.hooks';
import { messages } from './EnforceFraudDetectionPopup.messages';
import { Content, StyledPopupContent } from './EnforceFraudDetectionPopup.styles';
import { EligableWorkflowStateItem, EnforceFraudDetectionPopupProps } from './EnforceFraudDetectionPopup.types';

const EnforceFraudDetectionPopup: FC<EnforceFraudDetectionPopupProps> = (props) => {
    const { companyId } = props;

    const { data: workflows, isInitialLoading: isWorkflowsLoading } =
        useEligibleToEnforceFraudDetectionWorkflows(companyId);

    const { enforceFraudDetection, isLoading: isEnforceMutationLoading } = useEnforceDetectionMutation(companyId);

    const [workflowsState, setWorkflowsState] = useState<EligableWorkflowStateItem[]>(() => {
        return (
            workflows?.map((item) => ({
                ...item,
                id: item.workflowId,
                newEffectiveDate: null,
            })) ?? []
        );
    });

    useEffect(() => {
        setWorkflowsState(
            workflows?.map((item) => ({
                ...item,
                id: item.workflowId,
                newEffectiveDate: null,
            })) ?? []
        );
    }, [workflows]);

    const tableDef = useMemo<DataTableColumnDefinition<EligableWorkflowStateItem>[]>(
        () => [
            {
                id: 'id',
                name: messages.idCol,
                value: (item) => item.workflowId,
                sortable: false,
                padding: DataTablePadding.Left60,
            },
            {
                id: 'integrationCode',
                name: messages.integrationCodeCol,
                value: (item) => item.integrationCode,
                sortable: false,
            },
            {
                id: 'effectiveDate',
                name: messages.effectiveDateCol,
                value: (item) => item.effectiveDate,
                sortable: false,
                cellComponent: (props) => {
                    const workflow = props.item as EligableWorkflowStateItem;

                    return (
                        <DataTable.DefaultCell {...props}>
                            <div>
                                <DateTimeEditor
                                    value={workflow.effectiveDate || null}
                                    maxDate={workflow.effectiveDate}
                                    onChange={(value) => {
                                        setWorkflowsState((current) =>
                                            current.map((item) => {
                                                if (item.id === workflow.id) {
                                                    return {
                                                        ...item,
                                                        newEffectiveDate: value,
                                                    };
                                                }

                                                return item;
                                            })
                                        );
                                    }}
                                />
                            </div>
                        </DataTable.DefaultCell>
                    );
                },
            },
            {
                id: 'actions',
                name: messages.actionsCol,
                sortable: false,
                cellComponent: (props) => {
                    const workflow = props.item as EligableWorkflowStateItem;

                    return (
                        <DataTable.DefaultCell {...props}>
                            <div>
                                <Button
                                    execute={() => {
                                        if (workflow.newEffectiveDate) {
                                            enforceFraudDetection({
                                                workflowId: workflow.id,
                                                effectiveDate: workflow.newEffectiveDate,
                                            });
                                        }
                                    }}
                                    preset='compact'
                                    disabled={!workflow.newEffectiveDate || isEnforceMutationLoading}
                                >
                                    {messages.enforceButton}
                                </Button>
                            </div>
                        </DataTable.DefaultCell>
                    );
                },
            },
        ],
        [enforceFraudDetection, isEnforceMutationLoading]
    );

    const isLoading = isWorkflowsLoading || isEnforceMutationLoading;

    return (
        <StyledPopupContent title={messages.popupTitle}>
            <>
                {isLoading && <LoadingBar />}

                <Content>
                    <DataTable data={workflowsState ?? []} columns={tableDef} />
                </Content>
            </>
        </StyledPopupContent>
    );
};

export default memo(EnforceFraudDetectionPopup);
