import { Children, createElement, isValidElement, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const prepareChildren = (children: ReactNode, form?: UseFormReturn): ReactNode => {
    return Children.map(children, (child) => {
        if (!child) return null;

        if (!isValidElement(child)) return child;

        let type = child.type;
        let control = {};

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const ref = child.ref ? { ref: child.ref } : {};

        if (typeof child.type === 'object' && 'Controller' in child.type) {
            type = child.type['Controller'];
            control = { control: form?.control };
        }

        return createElement(
            type,
            {
                ...child.props,
                ...control,
                ...ref,
            },
            child.props.children ? prepareChildren(child.props.children, form) : null
        );
    });
};
