import { Mods, mods } from '@approvalmax/theme';
import { LoadingBar, MonthPicker, ScrollableArea } from '@approvalmax/ui';
import styled from 'styled-components';

export const EmptyList = styled.div`
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoadingBarStyled = styled(LoadingBar)<Mods<'active'>>`
    opacity: 0.0001;

    ${mods.active.true`
        opacity: 1;
    `}
`;

export const FilterHeader = styled.div`
    height: 40px;
    display: flex;
    justify-content: space-between;
`;

export const FilterPicker = styled(MonthPicker)`
    width: 160px;
    position: relative;

    & > div {
        border: none;
        line-height: 40px;
        text-transform: uppercase;
        font-size: 14px;
        padding: 0 25px 0 0;
        text-align: right;
    }

    & > button {
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Body = styled(ScrollableArea)`
    flex: 1;
    overflow: hidden;
`;
