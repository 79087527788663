import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { JsonViewer } from 'modules/components';
import { useGetNetSuiteCacheStatuses } from 'modules/data';
import { memo } from 'react';

import { messages } from './NetSuiteSyncCacheStatusPopup.messages';
import { Content, StyledPopupContent } from './NetSuiteSyncCacheStatusPopup.styles';
import { NetSuiteSyncPopupProps } from './NetSuiteSyncCacheStatusPopup.types';

export const NetSuiteSyncCacheStatusPopup = memo<NetSuiteSyncPopupProps>((props) => {
    const { companyId } = props;

    const { data, isFetching } = useGetNetSuiteCacheStatuses(companyId);

    return (
        <StyledPopupContent title={messages.popupTitle}>
            <Content>
                {isFetching ? (
                    <Box spacing='60'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <JsonViewer json={data} expandDepth={2} />
                )}
            </Content>
        </StyledPopupContent>
    );
});

NetSuiteSyncCacheStatusPopup.displayName = 'NetSuiteSyncCacheStatusPopup';
