import { FC } from 'react';

import { ActionButtons, Filters, Root, Title } from './Toolbar.styles';
import { ToolbarProps } from './Toolbar.types';

const Toolbar: FC<ToolbarProps> = (props) => {
    const { title, actionButtons, filters, children } = props;

    return (
        <Root>
            {title && <Title>{title}</Title>}

            {filters && <Filters>{filters}</Filters>}

            {actionButtons && <ActionButtons>{actionButtons}</ActionButtons>}

            {children}
        </Root>
    );
};

export default Toolbar;
