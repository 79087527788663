import styled from 'styled-components';

export const ContentPdf = styled.div`
    width: 100%;
    height: 100%;
`;

export const ContentPdfOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
`;
