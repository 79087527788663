import styled from 'styled-components';

export const CellRoot = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 10px;
`;

export const ProgressBarContainer = styled.div`
    flex: 1;
    margin-right: 20px;
`;

export const RowsCount = styled.div`
    width: 150px;
`;
