import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestDetailsPage.SelectOcrAttachmentPopupContent', {
    title: 'Enter attachment id to retrieve OCR data',
    submitButtonTitle: 'Submit',
    notFoundErrorText: 'Attachment not found',
    rawModel: 'Raw model',
    amModel: 'AM model',
    placeholderText: 'Enter attachment id',
});
