import { mixins } from '@approvalmax/theme';
import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    align-items: center;
    padding: 0 60px;
    height: 60px;
    flex-shrink: 0;

    ${mixins.inlineChild(20)};
`;

export const Title = styled.div`
    flex: 1;
    margin-right: 12px;
    ${font(16, '#000', 'semibold')};
`;

export const Filters = styled.div`
    align-items: center;
    flex: 1;

    ${mixins.inlineChild(12)};
`;

export const ActionButtons = styled.div`
    align-items: center;

    ${mixins.inlineChild(12)};
`;
