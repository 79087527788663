import { recoilHelpers } from '@approvalmax/utils';
import { atom } from 'recoil';

export const fullscreenSplitViewState = atom({
    key: 'fullscreenSplitViewState',
    default: false,
    effects: [recoilHelpers.connectToLocalStorage('fullscreenSplitView')],
});

export const openSplitViewState = atom({
    key: 'openSplitViewState',
    default: false,
});
