import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.MyAccountPermissionsPage', {
    pageTitle: 'My account permissions',
    breadcrumbRoot: 'Customer Success',
    firstNameCol: 'First name',
    lastNameCol: 'Last name',
    emailCol: 'Email',
    idCol: 'ID',
    userEmailPlaceholder: 'Enter user email or user id',
    searchError: 'No user found or user id/email is invalid',
    defaultErrorMessage: 'Oops, something went wrong',
});
