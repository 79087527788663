import './loadingBar.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

export const ALL_LOADING_BAR_THEMES = ['brand', 'aqua'] as const;
export type LoadingBarTheme = (typeof ALL_LOADING_BAR_THEMES)[number];

const bem = bemFactory.block('ui-loading-bar');

export interface LoadingBarProps {
    className?: string;
    barColor?: string;
    backgroundColor?: string;
    theme?: LoadingBarTheme;
    absolute?: boolean;
}

export const LoadingBar: FC<LoadingBarProps> = memo((props) => {
    const { className, theme = 'brand', barColor, backgroundColor, absolute } = props;

    return (
        <div className={bem.add(className)(null, theme)}>
            <div className={bem('content', { absolute })} style={{ backgroundColor }}>
                <div className={bem('bar')} style={{ backgroundColor: barColor }} />
            </div>
        </div>
    );
});

export default LoadingBar;
