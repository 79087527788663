import { Button, ConfirmationDropdown, DropdownEditor, ScrollableAreaWrapper, TextEditor } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import { QueryKeys, ShardItem, TenantCurrentMovementStep } from 'modules/api';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, PlainDataProvider, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import ProgressBar from './components/ProgressBar/ProgressBar';
import { dataCurrentMovementTableColumns, dataErrorTableColumns, dataTableColumns } from './ManageTenantsPage.config';
import { STEP_ORDERS, WARNING_ROWS_COUNT_LEVEL_1, WARNING_ROWS_COUNT_LEVEL_2 } from './ManageTenantsPage.constants';
import {
    useCancelShardCurrentMovement,
    useCurrentMovement,
    useCurrentTenantMovement,
    useMovementData,
    useShardList,
    useStartShardMove,
    useTenantMovementErrors,
} from './ManageTenantsPage.hooks';
import { messages } from './ManageTenantsPage.messages';
import {
    AdditionalInfo,
    CancelButton,
    CompanyIdField,
    CurrentMovementDataTable,
    HappyUnicorn,
    Header,
    HeaderLeft,
    Loader,
    LoaderContainer,
    SadUnicorn,
    ScreamingUnicorn,
    SearchButton,
    ShardSelectField,
    StyledDataTable,
    ToolbarFiltersContainer,
    TotalProgressBarContainer,
    TotalProgressHeader,
    TotalRowsCount,
} from './ManageTenantsPage.styles';
import { TableListItem } from './ManageTenantsPage.types';
import unicornHello from './resources/unicorn_hello.lottie.json';
import unicornOuch from './resources/unicorn_ouch.lottie.json';
import unicornRainbow from './resources/unicorn_rainbow.lottie.json';

const ManageTenantsPage = memo(() => {
    const [companyId, setCompanyId] = useState('');
    const [selectedShard, setSelectedShard] = useState<ShardItem | null>(null);
    const [appliedFilter, setAppliedFilter] = useState<{
        companyId: string | null;
        shardId: string | null;
    }>({
        companyId: null,
        shardId: null,
    });

    usePermissionsRedirect((permissionFlags) => permissionFlags.sharding.manageTenants);

    const queryClient = useQueryClient();

    const { data: shardList } = useShardList();

    const {
        data: currentMovementData,
        refetch: loadCurrentMovementData,
        isFetching: isCurrentMovementDataLoading,
    } = useCurrentMovement();

    const {
        data: movementData,
        error: movementDataError,
        refetch: fetchMovementData,
        isFetching: isMovementDataLoading,
    } = useMovementData(appliedFilter.companyId ?? '', appliedFilter.shardId ?? '');

    const { mutateAsync: startShardMove, isLoading: isStartShardMoveLoading } = useStartShardMove(
        appliedFilter.companyId ?? '',
        appliedFilter.shardId ?? ''
    );

    const { mutateAsync: cancelCurrentMovement, isLoading: isCurrentMovementCancelLoading } =
        useCancelShardCurrentMovement();

    useEffect(() => {
        return () => {
            queryClient.invalidateQueries([QueryKeys.TenantMovementData]);
        };
    }, [queryClient]);

    const onSearch = useCallback(async () => {
        fetchMovementData();
    }, [fetchMovementData]);

    const onSearchSubmit = useCallback(() => {
        setAppliedFilter({
            companyId,
            shardId: selectedShard?.id ?? null,
        });
    }, [companyId, selectedShard]);

    const onCompanyIdChange = useCallback((value: string) => {
        setCompanyId(value);
    }, []);

    const onSelectedShardChange = useCallback((value: ShardItem) => {
        setSelectedShard(value);
    }, []);

    useEffect(() => {
        if (appliedFilter.companyId && appliedFilter.shardId) {
            onSearch();
        }
    }, [appliedFilter, onSearch]);

    const tableList = useMemo<TableListItem[]>(() => {
        if (movementData?.rowsCountPerTable) {
            return Object.keys(movementData.rowsCountPerTable).map((tableName) => {
                const rowsCount = movementData.rowsCountPerTable[tableName];

                return {
                    id: tableName,
                    tableName,
                    rowsCount,
                };
            });
        }

        return [];
    }, [movementData]);

    const totalRowsCount = useMemo(() => {
        if (movementData?.rowsCountPerTable) {
            return Object.values(movementData.rowsCountPerTable).reduce((totalCount, item) => totalCount + item, 0);
        }

        return 0;
    }, [movementData]);

    const { errorList, hasAlreadyStartedError } = useTenantMovementErrors(movementData);

    const {
        currentMovementTablesData,
        currentMovementProgress,
        currentCopiedRows,
        currentTotalRows,
        currentMovementTargetShard,
    } = useCurrentTenantMovement(currentMovementData, shardList);

    const onShardMove = useCallback(async () => {
        const data = await startShardMove();

        if (data.started) {
            loadCurrentMovementData();

            queryClient.invalidateQueries([QueryKeys.TenantMovementData]);

            setAppliedFilter({ companyId: null, shardId: null });
        }
    }, [startShardMove, loadCurrentMovementData, queryClient]);

    const onMovementCancel = useCallback(async () => {
        await cancelCurrentMovement();

        loadCurrentMovementData();
    }, [loadCurrentMovementData, cancelCurrentMovement]);

    const hasData =
        Object.keys(movementData?.rowsCountPerTable ?? {}).length > 0 && !currentMovementData?.state.isRunning;

    const isCurrentMovementDataFirstTimeLoading = !currentMovementData && isCurrentMovementDataLoading;

    const lastError = currentMovementData?.state.entityState?.lastError;
    const additionalStep = currentMovementData?.state.entityState?.step;
    const isMovementRunning = currentMovementData?.state.isRunning || false;

    const canCancelCurrentMovement =
        additionalStep && STEP_ORDERS[additionalStep] < STEP_ORDERS[TenantCurrentMovementStep.CleanupTables];

    const isEmptyState = !hasData && errorList.length === 0 && !movementDataError && !isMovementRunning;
    const isDataError = !hasData && errorList.length === 0 && movementDataError;

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar
                        filters={
                            <ToolbarFiltersContainer>
                                <CompanyIdField title={messages.companyIdTitle} required>
                                    <TextEditor
                                        placeholder={messages.companyIdPlaceholder}
                                        value={
                                            currentMovementData?.state.isRunning
                                                ? currentMovementData.state.entityState?.tenantId ?? ''
                                                : companyId
                                        }
                                        onChange={onCompanyIdChange}
                                        disabled={currentMovementData?.state.isRunning}
                                    />
                                </CompanyIdField>

                                <ShardSelectField title={messages.selectShardTitle} required>
                                    <PlainDataProvider items={shardList || []}>
                                        <DropdownEditor
                                            displayAttribute='database'
                                            value={
                                                currentMovementData?.state.isRunning
                                                    ? currentMovementTargetShard
                                                    : selectedShard
                                            }
                                            onChange={onSelectedShardChange}
                                            placeholder={messages.selectShardPlaceholder}
                                            disabled={currentMovementData?.state.isRunning}
                                        />
                                    </PlainDataProvider>
                                </ShardSelectField>

                                <SearchButton
                                    disabled={isMovementDataLoading || currentMovementData?.state.isRunning}
                                    preset='compact'
                                    execute={onSearchSubmit}
                                >
                                    {messages.searchButton}
                                </SearchButton>
                            </ToolbarFiltersContainer>
                        }
                        actionButtons={null}
                    />

                    {isMovementDataLoading || isCurrentMovementDataFirstTimeLoading ? (
                        <LoaderContainer>
                            <Loader>
                                <Lottie animationData={unicornRainbow} loop={true} />
                            </Loader>
                        </LoaderContainer>
                    ) : (
                        <>
                            {lastError && !isMovementRunning && (
                                <AdditionalInfo>
                                    {messages.lastError({
                                        errorMessage: lastError,
                                    })}
                                </AdditionalInfo>
                            )}

                            {hasData && (
                                <>
                                    <Header>
                                        <HeaderLeft>
                                            <TotalRowsCount $isHighlited={totalRowsCount >= WARNING_ROWS_COUNT_LEVEL_1}>
                                                Total rows count: {intl.formatNumber(totalRowsCount, 0, false, ' ')}
                                            </TotalRowsCount>

                                            {totalRowsCount < WARNING_ROWS_COUNT_LEVEL_1 && <HappyUnicorn />}

                                            {totalRowsCount >= WARNING_ROWS_COUNT_LEVEL_1 &&
                                                totalRowsCount < WARNING_ROWS_COUNT_LEVEL_2 && <SadUnicorn />}

                                            {totalRowsCount >= WARNING_ROWS_COUNT_LEVEL_2 && <ScreamingUnicorn />}
                                        </HeaderLeft>

                                        <ConfirmationDropdown
                                            confirmation={messages.moveConfirmation({
                                                companyId: <b>{companyId}</b>,
                                                shardName: <b>{selectedShard?.database || ''}</b>,
                                            })}
                                        >
                                            <Button disabled={isStartShardMoveLoading} execute={onShardMove}>
                                                {messages.moveTenantButton}
                                            </Button>
                                        </ConfirmationDropdown>
                                    </Header>

                                    <StyledDataTable data={tableList} columns={dataTableColumns} />
                                </>
                            )}

                            {errorList.length > 0 && (
                                <>
                                    <Header>
                                        <ScreamingUnicorn />

                                        {hasAlreadyStartedError && (
                                            <Button disabled={isStartShardMoveLoading} execute={onShardMove}>
                                                {messages.resume}
                                            </Button>
                                        )}
                                    </Header>

                                    <StyledDataTable data={errorList} columns={dataErrorTableColumns} />
                                </>
                            )}

                            {isEmptyState && (
                                <LoaderContainer>
                                    <Loader>
                                        <Lottie animationData={unicornHello} loop={true} />
                                    </Loader>
                                </LoaderContainer>
                            )}

                            {isDataError && (
                                <LoaderContainer>
                                    <Loader>
                                        <Lottie animationData={unicornOuch} loop={true} />
                                    </Loader>
                                </LoaderContainer>
                            )}

                            {isMovementRunning && (
                                <>
                                    <TotalProgressHeader>
                                        <TotalProgressBarContainer>
                                            <ProgressBar
                                                progress={currentMovementProgress}
                                                hideUnicorn={currentMovementProgress === 100}
                                            />
                                        </TotalProgressBarContainer>

                                        <div>{`${currentCopiedRows}/${currentTotalRows}`}</div>

                                        <CancelButton
                                            disabled={isCurrentMovementCancelLoading || !canCancelCurrentMovement}
                                            execute={onMovementCancel}
                                        >
                                            {messages.cancel}
                                        </CancelButton>
                                    </TotalProgressHeader>

                                    {currentCopiedRows !== currentTotalRows ? (
                                        <CurrentMovementDataTable
                                            data={currentMovementTablesData}
                                            columns={dataCurrentMovementTableColumns}
                                        />
                                    ) : (
                                        <>
                                            <AdditionalInfo>
                                                {additionalStep
                                                    ? messages.additionalStepOngoing({ step: additionalStep })
                                                    : messages.additionalOperationsProgress}
                                            </AdditionalInfo>

                                            <LoaderContainer>
                                                <Loader>
                                                    <Lottie animationData={unicornRainbow} loop={true} />
                                                </Loader>
                                            </LoaderContainer>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

export default ManageTenantsPage;
