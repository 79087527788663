import { DataTableColumnSortOrder } from '@approvalmax/ui';
import { compareHelpers } from '@approvalmax/utils';
import { isValidCompanyId } from '@approvalmax/utils/src/helpers/validator/isValidCompanyId';
import { toaster } from 'modules/components';
import { BudgetPreview, SortConfig } from 'modules/types';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { getPath } from 'urlBuilder';

import { useQueryParams } from '../users/UsersPage.hooks';
import { messages } from './BudgetsPage.messages';
import { BudgetPrviewIdentifiable } from './BudgetsPage.types';
import { useGetBudgetsByCompanyId } from './hooks/useGetBudgetsByCompanyId/useGetBudgetsByCompanyId';

export const useBudgetPageSearch = () => {
    const { query, push } = useQueryParams();

    const [searchText, setSearchText] = useState(query);

    const [sortConfig, setSortConfig] = useState<SortConfig<keyof BudgetPreview>>({
        columnId: 'companyId',
        sortOrder: 'asc',
    });

    const {
        data = [],
        refetch,
        isSuccess,
        isFetching,
    } = useGetBudgetsByCompanyId(searchText, {
        enabled: false,
    });

    const onSearchSubmit = useCallback(async () => {
        if (isValidCompanyId(searchText)) {
            await push({ query: searchText });
            await refetch();
        } else {
            toaster.error(messages.errorIncorrectCompanyId);
        }
    }, [push, refetch, searchText]);

    const onSortChange = useCallback((columnId: keyof BudgetPreview, sortOrder: DataTableColumnSortOrder) => {
        setSortConfig({ columnId, sortOrder });
    }, []);

    const items = (data || []).map(convertToBudgetPrviewIdentifiable).sort((a, b) => {
        return sortConfig.sortOrder === 'asc'
            ? compareHelpers.stringComparator2Desc(a[sortConfig.columnId], b[sortConfig.columnId])
            : compareHelpers.stringComparator2Asc(a[sortConfig.columnId], b[sortConfig.columnId]);
    });

    useMount(async () => {
        if (searchText) {
            await onSearchSubmit();
        }
    });

    useEffect(() => {
        if (isSuccess && !isFetching && !data.length) {
            toaster.error(messages.errorNoItemsFound);
        }
    }, [data.length, isFetching, isSuccess]);

    return {
        items,
        searchText,
        onSearchSubmit,
        onSearchChange: setSearchText,
        sortConfig,
        onSortChange,
    };
};

export const useBudgetPageItem = () => {
    const history = useHistory();

    const onClick = useCallback(
        (item: BudgetPrviewIdentifiable) => {
            history.push(getPath('budgetDetails', item.companyId, item.budgetId));
        },
        [history]
    );

    return {
        onClick,
    };
};

const convertToBudgetPrviewIdentifiable = (item: BudgetPreview): BudgetPrviewIdentifiable => {
    return { ...item, id: item.budgetId };
};
