import { AutoDropdown } from '@approvalmax/ui';
import { ProfileDropdownIcon } from '@approvalmax/ui/src/old/icons';
import { eventHelpers } from '@approvalmax/utils';
import { getConfig } from 'config';
import { api } from 'modules/api';
import { Avatar } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import getAccountAbbreviation from 'modules/data/selectors/getAccountAbbreviation';
import { FC, memo, useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { messages } from './UserMenu.messages';
import {
    ActionItem,
    ActionItemText,
    ActionItemTextLogout,
    Actions,
    ButtonDropdown,
    DropdownButton,
    DropdownPanel,
    MessagePanel,
} from './UserMenu.styles';

const UserMenu: FC = () => {
    const { account } = useCurrentAccount();

    const history = useHistory();

    const onSettingsClick = useCallback(() => {
        history.push(getPath('userSettings'));
    }, [history]);

    const onSignOut = async () => {
        await api.auth.signOut();
        window.location.href = getConfig().appUrl;
    };

    const user = useMemo(() => {
        if (!account) return;

        return {
            email: account.email,
            abbreviation: getAccountAbbreviation(account),
        };
    }, [account]);

    if (!user) {
        return <Skeleton width={40} height={40} circle />;
    }

    return (
        <AutoDropdown
            css='flex: none'
            button={
                <DropdownButton>
                    <Avatar user={user} size={40} />

                    <ButtonDropdown>
                        <ProfileDropdownIcon height={4} width={7} />
                    </ButtonDropdown>
                </DropdownButton>
            }
            panelMinWidth='none'
            panelFlow='to-left'
        >
            {({ onRequestClose }) => (
                <DropdownPanel onMouseDown={eventHelpers.preventDefaultCallback}>
                    <Actions>
                        <MessagePanel>{user.email}</MessagePanel>

                        <ActionItem onClick={onSettingsClick} title={messages.menuItemSettings}>
                            <ActionItemText>{messages.menuItemSettings}</ActionItemText>
                        </ActionItem>

                        <ActionItem
                            onClick={() => {
                                onRequestClose();
                                onSignOut();
                            }}
                            title={messages.menuItemLogout}
                        >
                            <ActionItemTextLogout>{messages.menuItemLogout}</ActionItemTextLogout>
                        </ActionItem>
                    </Actions>
                </DropdownPanel>
            )}
        </AutoDropdown>
    );
};

export default memo(UserMenu);
