import { hooks } from '@approvalmax/utils';
import { useContext } from 'react';

import { DropdownContext } from './context';
import { DropdownGetRectCallback } from './types';

export const useDropdownAnchor = (getAnchorRect: DropdownGetRectCallback) => {
    const context = useContext(DropdownContext);
    const prevGetAnchorRect = hooks.usePrevious(getAnchorRect);

    if (prevGetAnchorRect !== getAnchorRect) {
        context.register(getAnchorRect || null);
    }
};
