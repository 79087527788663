import { useCallback, useEffect } from 'react';

import { useUrlSearchParams } from './useUrlSearchParams';

export const useQueryParam = <T extends string, Required extends boolean | undefined = false>(
    name: string,
    initialValue?: T,
    {
        required = false,
    }: {
        /**
         * Query parameter marked as required and will be set on a page load with `initialValue`
         */
        required?: boolean;
    } = {}
) => {
    if (required && typeof initialValue === 'undefined') {
        throw new Error('useQueryParam: for "required" query parameter "initialValue" must be provided');
    }

    const [searchParams, setSearchParams] = useUrlSearchParams();

    const searchValue = searchParams.get(name);

    const value = searchValue || (required ? initialValue : undefined);

    const setValue = useCallback(
        (newValue: string) => {
            // get the most recent actual search param from the url to avoid overwriting with
            // the old value when we consequently change more than one param with this hook
            const actualSearchParams = new URL(document.location as unknown as URL).searchParams;

            actualSearchParams.set(name, newValue);

            setSearchParams(actualSearchParams, { useReplace: true });
        },
        [name, setSearchParams]
    );

    const clean = useCallback(() => {
        // when cleaning several query-params, we could face the race condition.
        // this is the same behavior as within {@link setValue}
        const actualSearchParams = new URL(document.location as any).searchParams;

        actualSearchParams.delete(name);

        setSearchParams(actualSearchParams, { useReplace: true });
    }, [name, setSearchParams]);

    useEffect(() => {
        if (!searchValue && required && initialValue) {
            setValue(initialValue);
        }
    }, [value, initialValue, setValue, required, searchValue]);

    return [value as Required extends false ? T : T | undefined, setValue, clean] as const;
};
