import { useDrawerContext } from 'modules/components';
import React, { useRef, useState } from 'react';

export const useDrawerResizing = () => {
    const resizeContextRef = useRef<{
        initialX: number;
        unsubscribe: () => void;
    }>();

    const { isPinned, isUnpinning, pinnedDrawerWidth, setPinnedDrawerWidth } = useDrawerContext();

    const [drawerWidth, setDrawerWidth] = useState(pinnedDrawerWidth);
    const [isResizing, setIsResizing] = useState(false);

    const calculateNewWidth = (event: MouseEvent) => {
        const diff = event.clientX - resizeContextRef.current!.initialX;
        const newWidth = Math.min(Math.max(300, drawerWidth + diff), 800);

        return newWidth;
    };

    const onHandleMouseDown = (e: React.MouseEvent) => {
        if (window.getSelection()?.toString()) {
            return;
        }

        setIsResizing(true);

        const onMouseMove = (e: MouseEvent) => {
            const newWidth = calculateNewWidth(e);

            setDrawerWidth(newWidth);
        };

        const onMouseUp = (e: MouseEvent) => {
            const newWidth = calculateNewWidth(e);

            setPinnedDrawerWidth(newWidth);
            setIsResizing(false);
            resizeContextRef.current!.unsubscribe();
        };

        resizeContextRef.current = {
            initialX: e.clientX,
            unsubscribe: () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
                setIsResizing(false);
            },
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    return {
        isResizing,
        isPinned,
        isUnpinning,
        drawerWidth,
        onHandleMouseDown,
    };
};
