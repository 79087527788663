import { Reference } from '@approvalmax/types';
import { DropdownEditor, Field, Popup, usePopupContext } from '@approvalmax/ui';
import { PlainDataProvider } from 'modules/components';
import { FC, memo, useCallback, useState } from 'react';

import { useAdminPortalPermissions } from '../../hooks/useAdminPortalPermissions';
import { useEditAdminPortalUser } from '../../hooks/useEditAdminPortalUser';
import { messages } from './EditUserPopup.messages';
import { Content } from './EditUserPopup.styles';
import { EditUserPopupProps } from './EditUserPopup.types';

const EditUserPopup: FC<EditUserPopupProps> = (props) => {
    const { user } = props;

    const { permissions: allPermissions } = useAdminPortalPermissions();
    const { isLoading, editAdminPortalUser } = useEditAdminPortalUser();
    const { onRequestClose } = usePopupContext();

    const [permissions, setPermissions] = useState<Reference[]>(
        allPermissions!.filter((p) => user.permissions.includes(p.id))
    );

    const onCreate = useCallback(async () => {
        await editAdminPortalUser({
            id: user.id,
            permissions: permissions.map((p) => p.id),
        });

        onRequestClose();
    }, [editAdminPortalUser, onRequestClose, permissions, user.id]);

    return (
        <Popup.DefaultContent
            title={messages.title({
                user: user.email,
            })}
            buttons={
                <Popup.DefaultContent.Button execute={onCreate} disabled={isLoading}>
                    {messages.doneButton}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <Field title={messages.permissionsField}>
                    <PlainDataProvider items={allPermissions!}>
                        <DropdownEditor
                            value={permissions}
                            onChange={(v: Reference[]) => setPermissions(v)}
                            multiple
                            placeholder={messages.placeholderPermissions}
                        />
                    </PlainDataProvider>
                </Field>
            </Content>
        </Popup.DefaultContent>
    );
};

export default memo(EditUserPopup);
