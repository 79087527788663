import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';
import { BudgetDetails } from 'modules/types';

import { QueryOptions } from './useGetBudgetDetails.types';

export const useGetBudgetDetails = (companyId?: string, budgetId?: string, options: QueryOptions = {}) => {
    return useQuery<BudgetDetails, Error>(
        [QueryKeys.BudgetDetails, companyId, budgetId],
        () => api.budgets.getBudgetDetails(companyId!, budgetId!),
        {
            enabled: !!companyId && !!budgetId,
            ...options,
        }
    );
};
