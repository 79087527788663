import { QueryParams } from '@approvalmax/data';
import { ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Button, Form, Grid, Label, Progress, Spacing, Table, TextField } from '@approvalmax/ui/src/components';
import { hooks } from '@approvalmax/utils';
import { NavBarMode, PageLayout, StyledScrollableArea, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import {
    UseAmaxPayConsentItem,
    UseAmaxPayConsentsParams,
    UseAmaxPayConsentsPathParams,
    useGetAmaxPayConsents,
} from 'modules/data/queries';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { dataTableColumns } from './AmaxPayConsentsPage.config';
import { companyIdQueryParam, consentIdQueryParam, institutionIdQueryParam } from './AmaxPayConsentsPage.constants';
import { messages } from './AmaxPayConsentsPage.messages';
import { GetAmaxPayConsentsPayload } from './AmaxPayConsentsPage.types';

export const AmaxPayConsentsPage = memo(() => {
    usePermissionsRedirect((permissionFlags) => permissionFlags.amaxPay.paymentsView);

    const history = useHistory();

    const [companyId, setCompanyId] = hooks.useQueryParam(companyIdQueryParam);
    const [consentId, setConsentId] = hooks.useQueryParam(consentIdQueryParam);
    const [institutionId, setInstitutionId] = hooks.useQueryParam(institutionIdQueryParam);

    const form = useForm<QueryParams<UseAmaxPayConsentsPathParams> & UseAmaxPayConsentsParams>({
        defaultValues: {
            companyId,
            consentId,
            institutionId,
        },
    });

    const [getAmaxPayPaymentsPayload, setGetAmaxPayPaymentsPayload] = useState<GetAmaxPayConsentsPayload>({
        pathParams: { companyId },
        params: { consentId, institutionId },
    });

    const { data, isFetching } = useGetAmaxPayConsents(
        getAmaxPayPaymentsPayload.pathParams,
        getAmaxPayPaymentsPayload.params
    );

    const onSubmit = form.handleSubmit((data) => {
        setCompanyId(data.companyId ?? '');
        setConsentId(data.consentId ?? '');
        setInstitutionId(data.institutionId ?? '');

        setGetAmaxPayPaymentsPayload({
            pathParams: { companyId: data.companyId },
            params: { consentId: data.consentId, institutionId: data.institutionId },
        });
    });

    const handleRowClick = useCallback(
        (consentItem: UseAmaxPayConsentItem) => {
            const hasSelectedText = !!document.getSelection()?.toString();

            if (getAmaxPayPaymentsPayload.pathParams.companyId && !hasSelectedText) {
                history.push(getPath('amaxPayConsent', getAmaxPayPaymentsPayload.pathParams.companyId, consentItem.id));
            }
        },
        [getAmaxPayPaymentsPayload.pathParams.companyId, history]
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <StyledScrollableArea>
                        <Toolbar title={messages.pageTitle} />

                        <Box height={12}>{isFetching && <Progress />}</Box>

                        <Box spacing='0 40 20 60'>
                            <Form form={form} onSubmit={onSubmit}>
                                <Grid gridTemplateColumns='200px 1fr 200px' gap='8px' alignItems='center'>
                                    <Label required>{messages.companyId}</Label>

                                    <TextField name='companyId' placeholder={messages.placeHolder} required />

                                    <Button color='blue80' type='submit' disabled={isFetching}>
                                        {messages.search}
                                    </Button>

                                    <Label>{messages.consentId}</Label>

                                    <TextField name='consentId' placeholder={messages.placeHolder} />

                                    <div />

                                    <Label>{messages.institutionId}</Label>

                                    <TextField name='institutionId' placeholder={messages.placeHolder} />
                                </Grid>
                            </Form>

                            <Spacing height={16} />

                            <Table data={data || []} columns={dataTableColumns} onRowClick={handleRowClick} />
                        </Box>
                    </StyledScrollableArea>
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

AmaxPayConsentsPage.displayName = 'AmaxPayConsentsPage';
