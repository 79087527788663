import './DefaultPopupContent.scss';

import { Command } from '@approvalmax/types';
import { CloseIcon } from '@approvalmax/ui/src/old/icons';
import { hooks, intl } from '@approvalmax/utils';
import React, { FC, PropsWithChildren, ReactNode, useRef } from 'react';
import bemFactory from 'react-bem-factory';

import TransparentButton from '../../../button/TransparentButton';
import { usePopupContext } from '../../PopupContext';
import DefaultPopupContentButton from '../defaultContent/DefaultPopupContentButton';

const bem = bemFactory.block('ui-default-popup-content');
const qa = bemFactory.qa('ui-default-popup-content');

const i18nPrefix = 'ui/popup/content/DefaultPopupContent';

export const defaultPopupContentHeaderHeight = 80;

export interface DefaultPopupContentProps extends PropsWithChildren {
    buttons?: ReactNode;
    closeCommand?: Command;
    title?: ReactNode;
    qa?: string;
    style?: any;
    className?: string;
    customCloseHandler?: () => void;
    showCloseButton?: boolean;
    disableCloseButton?: boolean;
    stickyHeader?: boolean;
    headerDivider?: boolean;
}

const DefaultPopupContent: FC<DefaultPopupContentProps> = (props) => {
    const {
        children,
        title,
        buttons,
        closeCommand,
        style,
        className,
        qa: qa$,
        customCloseHandler,
        showCloseButton = true,
        disableCloseButton = false,
        stickyHeader = false,
        headerDivider = false,
    } = props;

    const { onRequestClose } = usePopupContext();
    const rootRef = useRef<HTMLDialogElement>(null);
    const { sticky, stickyRef } = hooks.useSticky({ root: rootRef.current });

    return (
        <dialog open ref={rootRef} className={bem.add(className)()} style={style} data-qa={qa$}>
            <div ref={stickyRef} />

            <header
                className={bem('header', {
                    sticky: stickyHeader,
                    'active-sticky': sticky,
                    'border-botom': headerDivider,
                })}
            >
                <div className={bem('header-close')}>
                    {showCloseButton && (
                        <TransparentButton
                            execute={customCloseHandler || ((e) => onRequestClose(e, 'cancel'))}
                            command={closeCommand}
                            qa={qa('close-button')}
                            title={intl.formatMessage({
                                id: `${i18nPrefix}.closePopupButtonTitle`,
                                defaultMessage: 'Close the dialog',
                            })}
                            disabled={disableCloseButton}
                        >
                            {({ elementProps }) => (
                                <button
                                    {...elementProps}
                                    className={bem('header-close-button')}
                                    aria-label={intl.formatMessage({
                                        id: `${i18nPrefix}.closePopupButtonLabel`,
                                        defaultMessage: 'Close the dialog',
                                    })}
                                >
                                    <CloseIcon width={14} height={14} className={bem('close-icon')} />
                                </button>
                            )}
                        </TransparentButton>
                    )}
                </div>

                <h1 className={bem('header-title')}>{title}</h1>

                <div className={bem('header-buttons')}>{buttons}</div>
            </header>

            {React.Children.only(children)}
        </dialog>
    );
};

type DefaultPopupContentFull = typeof DefaultPopupContent & {
    Button: typeof DefaultPopupContentButton;
};

(DefaultPopupContent as DefaultPopupContentFull).Button = DefaultPopupContentButton;

export default DefaultPopupContent as DefaultPopupContentFull;
