import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api, QueryKeys } from 'modules/api';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

export const useUserDetails = (userId: string) => {
    const history = useHistory();

    const { isInitialLoading, data: userDetails } = useQuery(
        [QueryKeys.Users, userId, QueryKeys.UserDetails],
        () => api.internalSupport.getUserDetails(userId),
        {
            onError: (error: AxiosError) => {
                if (error.response?.data) {
                    history.push(getPath('root'));
                }
            },
        }
    );

    return {
        userDetails,
        isInitialLoading,
    };
};
