import { emptyObject } from './emptyObject';
import { ensureProperty } from './ensureProperty';
import { getShallowDifference } from './getShallowDifference';
import { has } from './has';
import { pascalCaseToCamelCase } from './pascalCaseToCamelCase';
import { shallowEqualObjects } from './shallowEqualObjects';

export const objectHelpers = {
    emptyObject,
    ensureProperty,
    getShallowDifference,
    shallowEqualObjects,
    has,
    pascalCaseToCamelCase,
};
