import { useQuery } from '@tanstack/react-query';
import { api, QueryKeys } from 'modules/api';
import { BudgetPreview } from 'modules/types';

import { QueryOptions } from './useGetBudgetsByCompanyId.types';

export const useGetBudgetsByCompanyId = (companyId?: string, options: QueryOptions = {}) => {
    return useQuery<BudgetPreview[], Error>(
        [QueryKeys.BudgetsByCompanyId],
        () => api.budgets.getBudgetsByCompanyId(companyId!),
        {
            enabled: !!companyId,
            retry: false,
            ...options,
        }
    );
};
