export const getDateFormatMask = (locale: Intl.LocalesArgument, mask?: string) => {
    const dateFormatter = new Intl.DateTimeFormat(locale);
    const parts = dateFormatter.formatToParts(new Date());

    const literal = parts.find((part) => part.type === 'literal')?.value ?? '/';

    const order = parts
        .filter((part) => part.type !== 'literal')
        .map((part) => part.type)
        .join(literal);

    return order
        .replace('day', mask ? `${mask}${mask}` : 'DD')
        .replace('month', mask ? `${mask}${mask}` : 'MM')
        .replace('year', mask ? `${mask}${mask}${mask}${mask}` : 'yyyy');
};
