import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.StorageQueuesPage.ActionsCell', {
    clearPoisonConfirmation: 'Please confirm clearing the poison queue for {queueName}.',
    clearPoisonButton: 'Clear poison queue',
    clearQueueTitle: 'Clear the poison queue',
    movePoisonConfirmation: 'Please confirm moving the poison messages into the main queue {queueName}.',
    movePoisonButton: 'Move poison messages',
    moveQueueTitle: 'Move poison messages into the main queue',
    detailsButton: 'View poison',
});
