import 'allotment/dist/style.css';

import { ThemeProvider } from '@approvalmax/theme';
import { GlobalStyles } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RootLayout, Toaster } from 'modules/components';
import { AmaxPayBankAccountDetailsPage } from 'pages/amaxPayBankAccountDetails/AmaxPayBankAccountDetailsPage';
import { AmaxPayBankAccountsPage } from 'pages/amaxPayBankAccounts/AmaxPayBankAccountsPage';
import { AmaxPayConsentsPage } from 'pages/amaxPayConsents/AmaxPayConsentsPage';
import { AmaxPayPaymentsPage } from 'pages/amaxPayPayments/AmaxPayPaymentsPage';
import AuditLogPage from 'pages/auditLog/AuditLogPage';
import BudgetDetailsPage from 'pages/budgetDetails/BudgetDetailsPage';
import BudgetsPage from 'pages/budgets/BudgetsPage';
import CompaniesPage from 'pages/companies/CompaniesPage';
import CompanyDetailsPage from 'pages/companyDetails/CompanyDetailsPage';
import DashboardPage from 'pages/dashboard/DashboardPage';
import DatabaseAccessPage from 'pages/databaseAccess/DatabaseAccessPage';
import EnforcementTfaPage from 'pages/enforcementTfa/EnforcementTfaPage';
import FeatureFlagsPage from 'pages/featureFlags/FeatureFlagsPage';
import FindCompanyShardPage from 'pages/findCompanyShard/FindCompanyShardPage';
import MaintenancePage from 'pages/maintenance/MaintenancePage';
import ManageTenantsPage from 'pages/manageTenants/ManageTenantsPage';
import MobileMinVersionPage from 'pages/mobileMinVersion/MobileMinVersionPage';
import MyAccountPermissionsPage from 'pages/myAccountPermissions/MyAccountPermissionsPage';
import PermissionsPage from 'pages/permissions/PermissionsPage';
import ReportDetailPage from 'pages/reportDetails/ReportDetailsPage';
import ReportsPage from 'pages/reports/ReportsPage';
import RequestDetailsPage from 'pages/requestDetails/RequestDetailsPage';
import RequestsPage from 'pages/requests/RequestsPage';
import StorageQueueDetailsPage from 'pages/storageQueueDetails/StorageQueueDetailsPage';
import StorageQueuesPage from 'pages/storageQueues/StorageQueuesPage';
import SyncCommandManagementPage from 'pages/syncCommandManagement/SyncCommandManagementPage';
import UndeliveredEmailDetailsPage from 'pages/undeliveredEmailDetails/UndeliveredEmailDetailsPage';
import UndeliveredEmailsPage from 'pages/undeliveredEmails/UndeliveredEmailsPage';
import UserDetailsPage from 'pages/userDetails/UserDetailsPage';
import UsersPage from 'pages/users/UsersPage';
import UserSettingsPage from 'pages/userSettings/UserSettingsPage';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import { AmaxPayConsentDetailsPage } from '../pages/amaxPayConsentDetails/AmaxPayConsentDetailsPage';
import { AmaxPayPaymentDetailsPage } from '../pages/amaxPayPaymentDetails/AmaxPayPaymentDetailsPage';
import GlobalErrorBoundary from './components/GlobalErrorBoundary';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 1,
        },
    },
});

export default () => (
    <RecoilRoot>
        <RecoilNexus />

        <ThemeProvider>
            <IntlProvider locale={intl.locale} messages={window.localizationMap}>
                <GlobalStyles />

                <GlobalErrorBoundary>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <RootLayout>
                                <Switch>
                                    <Route exact path='/dashboard'>
                                        <DashboardPage />
                                    </Route>

                                    <Route exact path='/users'>
                                        <UsersPage />
                                    </Route>

                                    <Route exact path='/users/:userId'>
                                        <UserDetailsPage />
                                    </Route>

                                    <Route exact path='/companies'>
                                        <CompaniesPage />
                                    </Route>

                                    <Route exact path='/companies/:companyId'>
                                        <CompanyDetailsPage />
                                    </Route>

                                    <Route exact path='/requests'>
                                        <RequestsPage />
                                    </Route>

                                    <Route exact path='/companies/:companyId/requests/:requestId'>
                                        <RequestDetailsPage />
                                    </Route>

                                    <Route exact path='/reports'>
                                        <ReportsPage />
                                    </Route>

                                    <Route exact path='/companies/:companyId/reports/:reportId'>
                                        <ReportDetailPage />
                                    </Route>

                                    <Route exact path='/budgets'>
                                        <BudgetsPage />
                                    </Route>

                                    <Route exact path='/budgets/company/:companyId/budget/:budgetId'>
                                        <BudgetDetailsPage />
                                    </Route>

                                    <Route exact path='/featureFlags'>
                                        <FeatureFlagsPage />
                                    </Route>

                                    <Route exact path='/undeliveredEmails'>
                                        <UndeliveredEmailsPage />
                                    </Route>

                                    <Route exact path='/undeliveredEmails/details'>
                                        <UndeliveredEmailDetailsPage />
                                    </Route>

                                    <Route exact path='/maintenance'>
                                        <MaintenancePage />
                                    </Route>

                                    <Route exact path='/enforcement-2fa'>
                                        <EnforcementTfaPage />
                                    </Route>

                                    <Route exact path='/databaseAccess'>
                                        <DatabaseAccessPage />
                                    </Route>

                                    <Route exact path='/permissions'>
                                        <PermissionsPage />
                                    </Route>

                                    <Route exact path='/myAccountPermissions'>
                                        <MyAccountPermissionsPage />
                                    </Route>

                                    <Route exact path='/auditLog'>
                                        <AuditLogPage />
                                    </Route>

                                    <Route exact path='/amaxPayBankAccounts'>
                                        <AmaxPayBankAccountsPage />
                                    </Route>

                                    <Route exact path='/amaxPayBankAccounts/companies/:companyId/accounts/:accountId'>
                                        <AmaxPayBankAccountDetailsPage />
                                    </Route>

                                    <Route exact path='/amaxPayPayments'>
                                        <AmaxPayPaymentsPage />
                                    </Route>

                                    <Route exact path='/amaxPayPayments/companies/:companyId/payments/:paymentId'>
                                        <AmaxPayPaymentDetailsPage />
                                    </Route>

                                    <Route exact path='/amaxPayConsents'>
                                        <AmaxPayConsentsPage />
                                    </Route>

                                    <Route exact path='/amaxPayConsents/companies/:companyId/consents/:consentId'>
                                        <AmaxPayConsentDetailsPage />
                                    </Route>

                                    <Route exact path='/storageQueues'>
                                        <StorageQueuesPage />
                                    </Route>

                                    <Route exact path='/storageQueues/:queueId'>
                                        <StorageQueueDetailsPage />
                                    </Route>

                                    <Route exact path='/userSettings'>
                                        <UserSettingsPage />
                                    </Route>

                                    <Route exact path='/syncCommandManagement'>
                                        <SyncCommandManagementPage />
                                    </Route>

                                    <Route exact path='/findCompanyShard'>
                                        <FindCompanyShardPage />
                                    </Route>

                                    <Route exact path='/manageTenants'>
                                        <ManageTenantsPage />
                                    </Route>

                                    <Route exact path='/mobileMinVersion'>
                                        <MobileMinVersionPage />
                                    </Route>

                                    <Route path='/'>
                                        <Redirect to='/dashboard' />
                                    </Route>
                                </Switch>
                            </RootLayout>
                        </BrowserRouter>
                    </QueryClientProvider>
                </GlobalErrorBoundary>

                <Toaster />
            </IntlProvider>
        </ThemeProvider>
    </RecoilRoot>
);
