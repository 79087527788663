import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';

import { messages } from './FindCompanyShardPage.messages';

export const useShardList = () => {
    return useQuery([QueryKeys.ShardsList], () => api.infra.getShardList(), {
        onError: (error: AxiosError<{ errors: any }>) => {
            const data = error.response?.data;

            if (data?.errors) {
                toaster.error(messages.searchError);

                return;
            }
        },
    });
};

export const useGetCompanyShard = (companyId: string) => {
    return useQuery([QueryKeys.CompanyShard, companyId], () => api.infra.getCompanyShard(companyId), {
        onSuccess: (data) => {
            if (!data) {
                toaster.error(messages.noData);
            }
        },
        onError: (error: AxiosError<{ errors: any }>) => {
            const data = error.response?.data;

            if (data?.errors) {
                toaster.error(messages.searchError);

                return;
            }
        },
        enabled: false,
        cacheTime: 0,
        retry: false,
    });
};
