import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { dateTimeHelpers } from '@approvalmax/utils';
import { NextCompanySyncCommand } from 'modules/api';

import ActionsCell from './components/ActionsCell/ActionsCell';
import { messages } from './SyncCommandManagementPage.messages';

export const dataTableColumns: DataTableColumnDefinition<NextCompanySyncCommand>[] = [
    {
        id: 'id',
        name: messages.idCol,
        value: (item) => item.id,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'status',
        name: messages.statusCol,
        value: (item) => item.status,
        sortable: false,
    },
    {
        id: 'requestId',
        name: messages.requestIdCol,
        value: (item) => item.requestId,
        sortable: false,
    },
    {
        id: 'timestamp',
        name: messages.timestampCol,
        value: (item) => dateTimeHelpers.formatDateTime(item.timestamp),
        sortable: false,
    },
    {
        id: 'actions',
        name: '',
        width: 220,
        cellComponent: ActionsCell,
        sortable: false,
    },
];
