import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api, QueryKeys } from 'modules/api';
import { toaster } from 'modules/components';

import { messages } from './SyncCommandManagementPage.messages';

export const useCompanySyncCommandsCount = (companyId: string) => {
    return useQuery(
        [QueryKeys.CompanySyncCommandsCount, companyId],
        () => api.infra.getCompanySyncCommandsCount(companyId),
        {
            onError: (error: AxiosError<{ errors: any }>) => {
                const data = error.response?.data;

                if (data?.errors) {
                    toaster.error(messages.searchError);

                    return;
                }
            },
            enabled: false,
            cacheTime: 0,
        }
    );
};

export const useNextCompanySyncCommand = (companyId: string) => {
    return useQuery(
        [QueryKeys.NextCompanySyncCommand, companyId],
        () => api.infra.getNextCompanySyncCommand(companyId),
        {
            onSuccess: (data) => {
                if (!data) {
                    toaster.error(messages.noData);
                }
            },
            onError: (error: AxiosError<{ errors: any }>) => {
                const data = error.response?.data;

                const responseCode = error.response?.status;

                if (responseCode === 404) {
                    toaster.error(messages.defaultErrorMessage);

                    return;
                }

                if (data?.errors) {
                    toaster.error(messages.searchError);

                    return;
                }
            },
            enabled: false,
            cacheTime: 0,
            retry: false,
        }
    );
};

export const useDeleteCommand = (syncCommandId: string, companyId: string, onSuccess?: () => void) => {
    return useMutation(() => api.infra.deleteSyncCommand(syncCommandId, companyId), {
        onSuccess: () => {
            onSuccess?.();
        },
    });
};
