import { Button, DataTable, LoadingBar, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Spacing } from '@approvalmax/ui/src/components';
import { ConfirmationPopup, NavBarMode, PageLayout, Toolbar } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { useClearPoisonStorageQueue } from 'modules/infra/hooks/useClearPoisonStorageQueue';
import { useMoveStorageQueuePoisonMessages } from 'modules/infra/hooks/useMoveStorageQueuePoisonMessages';
import { FC, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { usePathParams } from './hooks/usePathParams';
import { useStorageQueue } from './hooks/useStorageQueue';
import { useStorageQueuePoisonMessages } from './hooks/useStorageQueuePoisonMessages';
import { dataTableColumns } from './StorageQueueDetailsPage.config';
import { messages } from './StorageQueueDetailsPage.messages';

const StorageQueueDetailsPage: FC = () => {
    const history = useHistory();
    const { queueId } = usePathParams();
    const { queue } = useStorageQueue(queueId);
    const { messages: messagesQueue } = useStorageQueuePoisonMessages(queueId);
    const { isLoading: isClearing, clearPoisonStorageQueue } = useClearPoisonStorageQueue();
    const { isLoading: isMoving, movePoisonMessages } = useMoveStorageQueuePoisonMessages();
    const { permissionFlags } = useCurrentAccount();

    usePermissionsRedirect((permissionFlags) => permissionFlags.storageQueues.readMessages);

    const onClearQueue = useCallback(() => {
        clearPoisonStorageQueue(queue!.id);
    }, [clearPoisonStorageQueue, queue]);

    const onMovePoisonQueue = useCallback(() => {
        movePoisonMessages(queue!.id);
    }, [movePoisonMessages, queue]);

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('storageQueues'));
        }
    }, [history]);

    const isDisabledActionButtons = useMemo(
        () =>
            !queue ||
            queue.poisonMessageCount === 0 ||
            !permissionFlags ||
            !permissionFlags.storageQueues.manageMessages,
        [permissionFlags, queue]
    );

    const actionButtons = (
        <>
            <ConfirmationPopup
                execute={onClearQueue}
                confirmationText={messages.clearPoisonConfirmation({
                    queueName: <b>{queue?.id}</b>,
                })}
                executeButtonText={messages.clearPoisonButton}
            >
                {({ executeWithConfirmation }) => (
                    <Button
                        css='fill: #fff'
                        preset='small'
                        padding='narrow10'
                        title={messages.clearQueueTitle}
                        execute={executeWithConfirmation}
                        disabled={isClearing || isDisabledActionButtons}
                    >
                        {messages.clearQueue}
                    </Button>
                )}
            </ConfirmationPopup>

            <ConfirmationPopup
                execute={onMovePoisonQueue}
                confirmationText={messages.movePoisonConfirmation({
                    queueName: <b>{queue?.id}</b>,
                })}
                executeButtonText={messages.movePoisonButton}
            >
                {({ executeWithConfirmation }) => (
                    <Button
                        css='fill: #fff'
                        preset='small'
                        padding='narrow10'
                        title={messages.moveQueueTitle}
                        execute={executeWithConfirmation}
                        disabled={isMoving || isDisabledActionButtons}
                    >
                        {messages.moveQueue}
                    </Button>
                )}
            </ConfirmationPopup>
        </>
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar
                        title={messages.title({
                            queueId,
                        })}
                        actionButtons={actionButtons}
                    />

                    {!queue || !messagesQueue ? (
                        <div>
                            <LoadingBar />
                        </div>
                    ) : (
                        <>
                            <Box spacing='20 60'>
                                <div>
                                    <span>{messages.countField}: </span>

                                    <b>{queue.poisonMessageCount}</b>
                                </div>

                                <Spacing height={12} />

                                <div>
                                    <span>{messages.poisonCountField}: </span>

                                    <b>{queue.poisonMessageCount}</b>
                                </div>
                            </Box>

                            <DataTable
                                css='flex: 1; overflow: hidden;'
                                data={messagesQueue}
                                columns={dataTableColumns}
                            />
                        </>
                    )}
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
};

export default memo(StorageQueueDetailsPage);
