import { mixins, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { RadioProps } from './Radio.types';

export const Input = styled.input<Pick<RadioProps, 'disabled'>>`
    margin: 0;
    padding: 0;
    border-radius: 50%;
    border: 1px solid ${theme.color.midnight70};
    display: block;
    transition: ${theme.duration.medium}ms ease-in-out;
    width: 100%;
    height: 100%;
    ${mixins.position.absoluteCenter};

    && {
        position: absolute;
    }
`;

export const Control = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const Icon = styled.div`
    pointer-events: none;
    border-radius: 50%;
    transition: ${theme.duration.medium}ms ease-in-out;
    transform: scale(0);
    position: relative;
    z-index: 1;
`;

export const Label = styled.div`
    flex-grow: 1;
`;

const rootDisabledMixin = css`
    ${mods.disabled.true`
        ${Icon} {
            background-color: ${theme.color.midnight40};
        }

        ${Input} {
            background-color: ${theme.color.midnight40};
            border-color: ${theme.color.midnight50};
        }

        ${Label} {
            color: ${theme.color.midnight60};
        }

        ${mods.checked.true`
            ${Icon} {
                background-color: ${theme.color.midnight60};
            }
        `};
    `};

    ${mods.disabled.false`
        &,
        ${Input} {
            cursor: pointer;
        }
    `};
`;

const rootCheckedMixin = css`
    ${mods.checked.true`
        ${Icon} {
            transform: scale(1);
        }

        ${mods.disabled.false`
            ${Input} {
                border-color: ${theme.color.blue100};
            }

            ${Icon} {
                background-color: ${theme.color.blue100};
            }
        `};
    `};
`;

const rootInvalidMixin = css`
    ${mods.invalid.true`
        ${mixins.invalidShakeAnimation};

        ${Input} {
            border-color: ${theme.color.red70};
        }

        ${mods.checked.true`
            ${Icon} {
                background-color: ${theme.color.red70};
            }
        `};
    `};
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    box: RequiredMods['spacing'],
    spacingTop: RequiredMods['spacing'],
    spacingLeft: RequiredMods['spacing']
) => css`
    gap: ${spacingLeft}px;

    ${Control} {
        width: ${box}px;
        height: ${box}px;
        flex-shrink: 0;
    }

    ${Icon} {
        width: ${box - 6}px;
        height: ${box - 6}px;
    }

    ${Label} {
        ${mixins.font('label', fontSize)};
        margin-top: ${spacingTop}px;
    }
`;

export const Root = styled.label<
    StyledMods<Pick<RadioProps, 'disabled' | 'checked' | 'size' | 'invalid' | 'block' | 'alignItems'>>
>`
    display: inline-flex;
    vertical-align: middle;
    align-items: ${({ $alignItems }) => $alignItems};

    &:hover,
    &:focus,
    &.focused {
        ${mods.disabled.false`
            color: ${theme.color.blue100};

            ${mods.invalid.true`
                color: ${theme.color.red100};
            `}

            ${Input} {
                border-color: ${theme.color.blue100};

                ${mods.invalid.true`
                    border-color: ${theme.color.red100};
                `};
            }
            }
        `};
    }

    ${mods.size.xsmall(rootSizeMixin('xsmall', 12, 0, 4))};
    ${mods.size.small(rootSizeMixin('small', 16, 2, 8))};
    ${mods.size.medium(rootSizeMixin('medium', 20, 2, 8))};
    ${mods.size.large(rootSizeMixin('large', 24, 2, 12))};

    ${mods.block.true`
        display: flex;
        width: 100%;
    `};

    ${rootDisabledMixin};

    ${rootCheckedMixin};

    ${rootInvalidMixin};
`;
