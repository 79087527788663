import { FC, memo } from 'react';
import { useController } from 'react-hook-form';

import Group from '../Group/Group';
import { GroupControllerProps } from './GroupController.types';

/**
 * The `ToggleButton.Group.Controller` allows you to use the `ToggleButton.Group` with the `react-hook-form` library.
 * They take the same properties as the `ToggleButton.Group` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const GroupController: FC<GroupControllerProps> = memo((props) => {
    const { control, name, rules, children, defaultValue, ...restProps } = props;

    const { field, fieldState } = useController({
        control,
        name,
        rules,
        defaultValue: props.defaultValue || props.value,
    });

    return (
        <Group {...field} {...fieldState} {...restProps}>
            {children}
        </Group>
    );
});

export default GroupController;
