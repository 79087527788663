import { ForwardedRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { CheckboxProps } from './Checkbox.types';
import { GroupContext } from './components';

export const useChecked = (checked: CheckboxProps['checked']) => {
    const [checkedValue, setCheckedValue] = useState(checked);

    useEffect(() => setCheckedValue(checked), [checked]);

    return {
        checkedValue,
        setCheckedValue,
    };
};

export const useValidate = (invalid: CheckboxProps['invalid']) => {
    const [invalidValue, setInvalidValue] = useState(invalid);

    useEffect(() => setInvalidValue(invalid), [invalid]);

    return {
        invalidValue,
        setInvalidValue,
    };
};

export const useGroup = (hookProps: { ref: ForwardedRef<HTMLInputElement>; props: CheckboxProps }) => {
    const { props, ref } = hookProps;

    const { contextProps, ref: groupRef } = useContext(GroupContext);
    const { getCheckedStatus, ...groupContextProps } = contextProps;

    /**
     * If `groupContextProps` has the property `name`, then checkbox in a group.
     */
    const isInGroup = groupContextProps.name;

    /**
     * Merge props from the Group component with props from the Checkbox component.
     */
    const preparedProps = useMemo(
        () => (isInGroup ? { ...props, ...groupContextProps } : props),
        [groupContextProps, isInGroup, props]
    );

    /**
     * If the checkbox in the Group Component, check if his value in an array with checked values.
     */
    const checked = useMemo(
        () => (isInGroup ? getCheckedStatus(preparedProps.value) : preparedProps.checked),
        [preparedProps.checked, preparedProps.value, getCheckedStatus, isInGroup]
    );

    /**
     * Create inner ref from the Group component and Checkbox component refs.
     */
    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    useImperativeHandle(groupRef, () => innerRef.current as HTMLInputElement);

    return {
        innerRef,
        ...preparedProps,
        checked,
    };
};
