import { Popup, usePopupContext } from '@approvalmax/ui';
import { JsonViewer, Section } from 'modules/components';
import { FC } from 'react';

import { messages } from './DetailsPopup.messages';
import { Root } from './DetailsPopup.styles';
import { DetailsPopupProps } from './DetailsPopup.types';

const DetailsPopup: FC<DetailsPopupProps> = (props) => {
    const { id, details } = props;

    const { onRequestClose } = usePopupContext();

    return (
        <Popup.DefaultContent
            css='width: 750px'
            title={messages.popupTitle({
                id,
            })}
            buttons={
                <Popup.DefaultContent.Button execute={onRequestClose}>
                    {messages.doneButton}
                </Popup.DefaultContent.Button>
            }
        >
            <Root>
                <Section header={messages.dataHeader}>
                    <JsonViewer json={details} />
                </Section>
            </Root>
        </Popup.DefaultContent>
    );
};

export default DetailsPopup;
