import { font, uAbsoluteFill, uFlexCenter, uTextEllipsis } from '@approvalmax/ui';
import { PaperClipIcon } from '@approvalmax/ui/src/old/icons';
import styled from 'styled-components';

export const AttachmentItemWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 36px;
    border: 1px solid #cdd2d2;
    border-radius: 2px;
    cursor: pointer;
    padding-left: 10px;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:hover {
        & > * {
            visibility: visible;
        }
    }
`;

export const AttachmentItemName = styled.div`
    margin: 0 6px;
    ${font(12, '#384650', 'semibold')}
    ${uTextEllipsis}
    flex: 1;
`;

export const AttachmentItemPreviewIcon = styled.div`
    visibility: hidden;
    ${uAbsoluteFill()}
    ${uFlexCenter}
    z-index: 1;
    background: #f2f0f0;
    opacity: 0.85;
`;

export const AttachmentItemPaperClip = styled(PaperClipIcon)`
    color: #c1c3c4;
    fill: currentColor;
`;
