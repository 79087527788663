import { Button, font, oPopupPadding, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 520px;
    user-select: unset;
    cursor: unset;
`;

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    ${oPopupPadding()}
`;

export const Description = styled.div`
    ${font(13, '#000')}
`;

export const QRBlock = styled.div`
    margin-top: 5px;
    text-align: center;
`;

export const SetupCodeButtonBlock = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
`;

export const SetupCodeInputBlock = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
`;

export const SetupCodeInput = styled.input`
    border: none;
    padding: 3px;
    background-color: transparent;
    width: 100%;
    margin-bottom: 10px;
    align-self: stretch;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    ${font(20, '#000')}

    &::-ms-clear {
        display: none;
    }
`;

export const NextButton = styled(Button)`
    margin-top: 30px;
`;
