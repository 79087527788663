import { Drawer } from 'modules/components';
import { FC, PropsWithChildren } from 'react';

import { useDrawerResizing } from './Inner.hooks';
import { Content, GlobalStyle, Handle, PinnedDrawerContainer, Root, ScrollContainer } from './Inner.styles';

const Inner: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const { isResizing, isPinned, isUnpinning, onHandleMouseDown, drawerWidth } = useDrawerResizing();

    return (
        <>
            <GlobalStyle isResizing={isResizing} />

            <Drawer drawerWidth={drawerWidth} isSliding />

            <Root isUnpinning={isUnpinning} drawerWidth={drawerWidth}>
                {isPinned && (
                    <PinnedDrawerContainer drawerWidth={drawerWidth}>
                        <Drawer />

                        <Handle onMouseDown={onHandleMouseDown} isResizing={isResizing} />
                    </PinnedDrawerContainer>
                )}

                <ScrollContainer>
                    <Content>{children}</Content>
                </ScrollContainer>
            </Root>
        </>
    );
};

export default Inner;
