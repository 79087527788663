import { DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';

import MovementProgressCell from './components/MovementProgressCell/MovementProgressCell';
import { messages } from './ManageTenantsPage.messages';
import { ErrorListItem, TableListItem, TenantCurrentMovementTableItemWithId } from './ManageTenantsPage.types';

export const dataTableColumns: DataTableColumnDefinition<TableListItem>[] = [
    {
        id: 'tableName',
        name: messages.tableNameCol,
        value: (item) => item.tableName,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'rowCount',
        name: messages.rowsCountCol,
        value: (item) => intl.formatNumber(item.rowsCount, 0, false, ' '),
        sortable: false,
    },
];

export const dataErrorTableColumns: DataTableColumnDefinition<ErrorListItem>[] = [
    {
        id: 'errorText',
        name: messages.errorColName,
        value: (item) => item.errorText,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
];

export const dataCurrentMovementTableColumns: DataTableColumnDefinition<TenantCurrentMovementTableItemWithId>[] = [
    {
        id: 'tableName',
        name: messages.tableNameCol,
        value: (item) => item.name,
        sortable: false,
        padding: DataTablePadding.Left60,
    },
    {
        id: 'progress',
        name: messages.progressColName,
        sortable: false,
        padding: DataTablePadding.Left60,
        cellComponent: MovementProgressCell,
    },
];
