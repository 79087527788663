import { TransparentButton } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.section`
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
`;

export const Head = styled.div`
    margin: 18px 15px 18px 15px;
    display: flex;
`;

export const HeadContent = styled.div`
    flex: 1;
    padding-bottom: 6px;
`;

export const PinButton = styled(TransparentButton)`
    position: relative;
    top: -1px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #384650;
    border-radius: 4px;
    margin-left: 15px;

    &:hover {
        background: #eff0f0;
    }

    &:active {
        background: #d8d8d8;
    }
`;
