import { LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box, Spacing } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo } from 'react';

import { AnnouncementPanel, MaintenancePanel } from './components';
import { useAnnouncementInfo } from './hooks/useAnnouncementInfo';
import { useMaintenanceInfo } from './hooks/useMaintenanceInfo';
import { messages } from './MaintenancePage.messages';

const MaintenancePage: FC = () => {
    const { maintenanceInfo } = useMaintenanceInfo();
    const { announcementInfo } = useAnnouncementInfo();

    usePermissionsRedirect(
        (permissionFlags) => permissionFlags.maintenances.manageMessage || permissionFlags.announcements.manageMessage
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
        >
            <Box color='white100' shadow='xxsmall'>
                {!maintenanceInfo || !announcementInfo ? (
                    <div>
                        <LoadingBar />
                    </div>
                ) : (
                    <ScrollableAreaWrapper>
                        <Toolbar title={messages.pageTitle} />

                        <ScrollableArea css='flex: 1; overflow: hidden;'>
                            <Box spacing='20 60'>
                                <AnnouncementPanel />

                                <Spacing height={20} />

                                <MaintenancePanel />
                            </Box>
                        </ScrollableArea>
                    </ScrollableAreaWrapper>
                )}
            </Box>
        </PageLayout>
    );
};

export default memo(MaintenancePage);
