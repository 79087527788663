import { Button, LoadingBar, ScrollableArea, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { JsonViewer, NavBarMode, PageLayout, Toolbar } from 'modules/components';
import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { FC, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getPath } from 'urlBuilder';

import { useDeleteEmailDeliveryReport } from './hooks/useDeleteEmailDeliveryReport';
import { useQueryParams } from './hooks/useQueryParams';
import { useUndeliveredEmailDetails } from './hooks/useUndeliveredEmailDetails';
import { messages } from './UndeliveredEmailDetailsPage.messages';

const CompanyDetailsPage: FC = () => {
    const history = useHistory();
    const { email } = useQueryParams();
    const { permissionFlags } = useCurrentAccount();

    const { undeliveredEmailDetails } = useUndeliveredEmailDetails(email);
    const { isLoading, deleteEmailDeliveryReport } = useDeleteEmailDeliveryReport();

    usePermissionsRedirect((permissionFlags) => permissionFlags.ignoredEmails.any);

    const onDeleteEmailDeliveryReport = useCallback(async () => {
        await deleteEmailDeliveryReport({
            email,
            reason: undeliveredEmailDetails!.reason,
        });

        history.replace(getPath('undeliveredEmails'));
    }, [deleteEmailDeliveryReport, email, history, undeliveredEmailDetails]);

    const isDisabledDeleteButton = useMemo(
        () => isLoading || !permissionFlags || !permissionFlags.ignoredEmails.delete,
        [isLoading, permissionFlags]
    );

    const onBack = useCallback(() => {
        if (history.length) {
            history.goBack();
        } else {
            history.push(getPath('undeliveredEmails'));
        }
    }, [history]);

    const actionButtons = (
        <Button
            execute={onDeleteEmailDeliveryReport}
            disabled={isDisabledDeleteButton}
            colorTheme='red'
            preset='compact'
        >
            {messages.deleteButton}
        </Button>
    );

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.pageTitle]}
            mode={NavBarMode.withCross}
            onClose={onBack}
        >
            <Box color='white100' shadow='xxsmall'>
                {!undeliveredEmailDetails ? (
                    <div>
                        <LoadingBar />
                    </div>
                ) : (
                    <ScrollableAreaWrapper>
                        <Toolbar title={undeliveredEmailDetails.email} actionButtons={actionButtons} />

                        <ScrollableArea css='flex: 1; overflow: hidden;'>
                            <Box spacing='20 60'>
                                <JsonViewer json={undeliveredEmailDetails.rawData} />
                            </Box>
                        </ScrollableArea>
                    </ScrollableAreaWrapper>
                )}
            </Box>
        </PageLayout>
    );
};

export default memo(CompanyDetailsPage);
