import { isEmptyString } from './isEmptyString';
import { messages } from './messages';

export const requiredString = (errorMessage?: string) => (value: string) => {
    if (isEmptyString(value)) {
        return errorMessage ?? false;
    }

    return true;
};

export const requiredStringWithErrorMessage = (value: string) => {
    return requiredString(messages.required)(value);
};
