/**
 * Developer: Stepan Burguchev
 * Date: 4/15/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './checkbox.scss';

import { forwardRef } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('ui-checkbox');

export const ALL_CHECKBOX_SIZES = ['size-16', 'size-18'] as const;
export type CheckboxSize = (typeof ALL_CHECKBOX_SIZES)[number];

export interface Props {
    checked?: boolean;
    triState?: boolean;
    className?: string;
    disabled?: boolean;
    size?: CheckboxSize;
    id?: string;
    labelledBy?: string;
    invalid?: boolean;
    onChange?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export default forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        id,
        checked,
        className,
        disabled,
        size = 'size-16',
        invalid,
        onFocus,
        onBlur,
        onChange,
        labelledBy,
    } = props;
    const triState = props.triState && !checked;

    return (
        <div
            id={id}
            ref={ref}
            role='checkbox'
            aria-checked={triState ? 'mixed' : Boolean(checked)}
            aria-disabled={Boolean(disabled)}
            aria-labelledby={labelledBy}
            tabIndex={disabled ? -1 : 0}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={(e) => {
                if (disabled) {
                    return;
                }

                if (e.key === ' ' && onChange) {
                    onChange();
                }
            }}
            className={bem.add(className)(null, size, {
                disabled,
                checked,
                invalid,
                'tri-state': triState,
            })}
        />
    );
});
