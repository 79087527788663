export const cleanUpJson = (json: Record<string, unknown>) => {
    const result = {
        ...json,
    };

    Object.keys(result).forEach((key) => {
        if (result[key] == null) {
            delete result[key];
        }
    });

    return result;
};

export const formatJson = (value: unknown) => {
    return JSON.stringify(value, undefined, 2);
};

export const copyToClipboard = (value: string) => {
    const listener = (event: ClipboardEvent) => {
        event.clipboardData?.setData('text/plain', value);
        event.preventDefault();
        document.removeEventListener('copy', listener);
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
};
