import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.userSettings.components.TwoFaSetupPopup.components.TwoFaSetupStep2', {
    popupTitle: 'Two-factor Authentication Setup',
    title: 'Enter code from Authenticator app',
    description: `
        Enter 6-digit code provided by the Authenticator app.
        {br}
        If you have problems entering the code please see <supportlink>support article</supportlink>
    `,
    codeFieldTitle: 'Authenticator code',
    wrongCodeErrorText: 'Wrong code',
    userCodePlaceholder: 'Enter authenticator code...',
    nextButton: 'Continue',
});
