import useLocalStorage from 'modules/react/hooks/useLocalStorage';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import { DrawerContextValues } from './Drawer.types';

const DrawerContext = createContext<DrawerContextValues>({
    isPinned: false,
    isUnpinning: true,
    setIsPinned: () => {
        return;
    },
    pinnedDrawerWidth: 0,
    setPinnedDrawerWidth: () => {
        return;
    },
    isOpen: false,
    onClose: () => {
        return;
    },
    onOpen: () => {
        return;
    },
});

export const DrawerContextProvider: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isUnpinning, setIsUnpinning] = useState(false);

    const [isPinned, setIsPinned] = useLocalStorage<boolean>('preferences:drawer:pin', false);
    const [pinnedDrawerWidth, setPinnedDrawerWidth] = useLocalStorage<number>('preferences:drawer:width', 300);

    const handleSetIsPinned = useCallback(
        (value: boolean, synchronously?: boolean) => {
            if (!value) {
                if (synchronously) {
                    setIsPinned(value);
                } else {
                    setIsUnpinning(true);
                    setTimeout(() => {
                        setIsUnpinning(false);
                        setIsPinned(value);
                    }, 300);
                }
            } else {
                setIsPinned(value);
            }
        },
        [setIsPinned, setIsUnpinning]
    );

    const value = useMemo(
        () => ({
            isOpen,
            isPinned,
            isUnpinning,
            setIsPinned: handleSetIsPinned,
            pinnedDrawerWidth,
            setPinnedDrawerWidth,
            onClose: () => setIsOpen(false),
            onOpen: () => setIsOpen(true),
        }),
        [isOpen, isPinned, pinnedDrawerWidth, setPinnedDrawerWidth, handleSetIsPinned, isUnpinning]
    );

    return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export const useDrawerContext = () => useContext(DrawerContext);
