import { argumentError } from './argumentError';

/**
 * Throws ArgumentError. The exception should be thrown when one of the arguments provided to a method is not valid.
 * Should be thrown only when one particular argument is invalid. If a combination of arguments is invalid use <code>FormatError</code>.
 * @example
 * function (url, parameterNames, parameters, callback) {
 *     // Some code here ...
 *     if (parameterNames.Length !== 2) {
 *         utilsApi.helpers.throwArgumentError('The array `parameterNames` should contain exactly 2 elements.');
 *     }
 *     // Some code here ...
 * @param {String} [message='Invalid argument'] Error message.
 * */
export const throwArgumentError = (message?: string): never => {
    throw argumentError(message);
};
