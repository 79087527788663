import { QueryOptions } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { requestsApiPaths } from '../paths';
import { UseGetRequestVersionsPathParams, UseGetRequestVersionsResponse } from './useGetRequestVersions.types';

export const useGetRequestVersions = (
    pathParams: UseGetRequestVersionsPathParams,
    queryOptions?: QueryOptions<UseGetRequestVersionsResponse>
) => {
    return useGet<UseGetRequestVersionsResponse>(requestsApiPaths.getRequestVersions, {
        params: {
            path: pathParams,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
