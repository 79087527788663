import { Popup, TextButton } from '@approvalmax/ui';
import { FC, useCallback, useState } from 'react';

import { messages } from './ConfirmationPopup.messages';
import { Actions, Content, Description } from './ConfirmationPopup.styles';
import { ConfirmationPopupProps } from './ConfirmationPopup.types';

const ConfirmationPopup: FC<ConfirmationPopupProps> = (props) => {
    const {
        children,
        confirmationText,
        executeButtonText,
        execute,
        cancelButtonColorTheme = 'dark-grey',
        executeButtonColorTheme = 'red',
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);

    const onCancelButtonClick = useCallback(() => setIsOpen(false), []);
    const onExecuteButtonClick = useCallback(async () => {
        setIsExecuting(true);

        try {
            await execute();
        } finally {
            setIsExecuting(false);
        }

        setIsOpen(false);
    }, [execute]);

    return (
        <>
            {children({ executeWithConfirmation: () => setIsOpen(true) })}

            <Popup css='width: 400px' isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
                <Popup.EmptyContent>
                    <Content>
                        <Description>{confirmationText}</Description>

                        <Actions>
                            <TextButton
                                disabled={isExecuting}
                                execute={onCancelButtonClick}
                                colorTheme={cancelButtonColorTheme}
                            >
                                {messages.cancelButton}
                            </TextButton>

                            <TextButton
                                disabled={isExecuting}
                                execute={onExecuteButtonClick}
                                colorTheme={executeButtonColorTheme}
                            >
                                {executeButtonText}
                            </TextButton>
                        </Actions>
                    </Content>
                </Popup.EmptyContent>
            </Popup>
        </>
    );
};

export default ConfirmationPopup;
