import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.PermissionsPage.CreateUserPopup', {
    title: 'New Admin Portal user',
    doneButton: 'Create',
    emailField: 'Email',
    emailPlaceholder: 'Enter the email',
    permissionsField: 'Permissions',
    placeholderPermissions: 'Add permissions',
});
