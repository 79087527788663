import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const DropdownPanel = styled.div`
    width: 200px;
    padding: 10px;
    margin: 2px 0;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
`;

export const FilterRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    ${font(14, '#000')}
`;

export const PanelFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
`;
