import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { GroupProps } from './Group.types';

export const Root = styled.div<StyledMods<Pick<GroupProps, 'width'>>>`
    display: flex;

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;
