import { uAbsoluteFill } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    height: 100%;
`;

export const ContentLoading = styled.div`
    ${uAbsoluteFill()}
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
`;
