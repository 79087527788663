import { LoadingBar, ScrollableAreaWrapper } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { NavBarMode, PageLayout, PageLayoutWidthBehavior, SearchBar, Toolbar } from 'modules/components';
import { usePermissionsRedirect } from 'modules/data/hooks/usePermissionsRedirect';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { dataTableColumns } from './FindCompanyShardPage.config';
import { useGetCompanyShard, useShardList } from './FindCompanyShardPage.hooks';
import { messages } from './FindCompanyShardPage.messages';
import { StyledDataTable } from './FindCompanyShardPage.styles';
import { SearchResultItem } from './FindCompanyShardPage.types';

const FindCompanyShardPage = memo(() => {
    const [searchText, setSearchText] = useState('');
    const [companyId, setCompanyId] = useState<string | null>(null);

    usePermissionsRedirect((permissionFlags) => permissionFlags.sharding.any);

    const { data: shardList, isFetching: isShardListLoading } = useShardList();

    const {
        data: companyShard,
        error: companyShardError,
        refetch: loadCompanyShard,
        isFetching: isCompanyShardLoading,
    } = useGetCompanyShard(companyId || '');

    const onSearch = useCallback(async () => {
        loadCompanyShard();
    }, [loadCompanyShard]);

    const onSearchSubmit = useCallback(() => {
        if (searchText === companyId) {
            onSearch();
        } else {
            setCompanyId(searchText);
        }
    }, [searchText, companyId, onSearch]);

    useEffect(() => {
        if (companyId && companyId.length > 0) {
            onSearch();
        }
    }, [companyId, onSearch]);

    const searchResult = useMemo<SearchResultItem[]>(() => {
        if (companyShard && companyId) {
            const shard = shardList?.find((item) => item.id === companyShard);

            if (shard) {
                return [
                    {
                        id: companyId,
                        companyId,
                        database: shard.database,
                    },
                ];
            }
        }

        return [];
    }, [companyShard, companyId, shardList]);

    const isLoading = isShardListLoading || isCompanyShardLoading;

    const hasData = companyShard && !companyShardError;

    return (
        <PageLayout
            documentTitle={messages.pageTitle}
            breadcrumbs={[messages.breadcrumbRoot, messages.pageTitle]}
            mode={NavBarMode.withDrawer}
            widthBehavior={PageLayoutWidthBehavior.fixed}
        >
            <Box color='white100' shadow='xxsmall'>
                <ScrollableAreaWrapper>
                    <Toolbar
                        filters={
                            <SearchBar
                                placeholder={messages.searchPlaceholder}
                                disabled={isLoading}
                                onSearch={onSearchSubmit}
                                value={searchText}
                                onChange={setSearchText}
                                focusOnMount
                            />
                        }
                        actionButtons={null}
                    />

                    {isLoading ? (
                        <div>
                            <LoadingBar />
                        </div>
                    ) : (
                        <>{hasData && <StyledDataTable data={searchResult} columns={dataTableColumns} />}</>
                    )}
                </ScrollableAreaWrapper>
            </Box>
        </PageLayout>
    );
});

export default FindCompanyShardPage;
