import { QueryOptions } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayBankAccountDetailsPathParams,
    UseAmaxPayBankAccountDetailsResponse,
} from './useAmaxPayBankAccountDetails.types';

export const useGetAmaxPayBankAccountDetails = (
    pathParams: UseAmaxPayBankAccountDetailsPathParams,
    queryOptions?: QueryOptions<UseAmaxPayBankAccountDetailsResponse>
) => {
    return useGet<UseAmaxPayBankAccountDetailsResponse>(amaxPayApiPaths.openBankingAccountDetails, {
        params: {
            path: pathParams,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
