import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGet } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayBankAccountsParams,
    UseAmaxPayBankAccountsPathParams,
    UseAmaxPayBankAccountsResponse,
} from './useAmaxPayBankAccounts.types';

export const useGetAmaxPayBankAccounts = (
    pathParams: QueryParams<UseAmaxPayBankAccountsPathParams>,
    params: UseAmaxPayBankAccountsParams,
    queryOptions?: QueryOptions<UseAmaxPayBankAccountsResponse>
) => {
    return useGet<UseAmaxPayBankAccountsResponse>(amaxPayApiPaths.openBankingAccounts, {
        params: {
            path: pathParams,
            query: params,
        },
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
