import { useCurrentAccount } from 'modules/data/hooks/useCurrentAccount';
import { useCallback } from 'react';
import { useToggle } from 'react-use';

export const useManage2FAPopup = () => {
    const [isManage2FAPopupOpened, toggleIsManage2FAPopupOpened] = useToggle(false);

    const onManage2FAPopupOpen = useCallback(() => {
        toggleIsManage2FAPopupOpened(true);
    }, [toggleIsManage2FAPopupOpened]);

    const onManage2FAPopupClose = useCallback(() => {
        toggleIsManage2FAPopupOpened(false);
    }, [toggleIsManage2FAPopupOpened]);

    const { permissionFlags } = useCurrentAccount();

    const hasManage2FAPermission = permissionFlags?.admin.manage2FA ?? false;

    return {
        isManage2FAPopupOpened,
        onManage2FAPopupOpen,
        onManage2FAPopupClose,
        hasManage2FAPermission,
    };
};
